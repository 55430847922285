import "./TransferSummary.scss";
import primaryComponents from "../../../primaryComponents";
import { formatCurrency } from "../../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import FundCheckoutValidation from "./validation";
import { Controller } from "react-hook-form";
import { motion } from "framer-motion";

const TransferSummary = ({ fundTransferLogic }) => {
  const {
    accountDetails,
    handlePreviousStep,
    amount,
    handleFundTransfer,
    isTranferringFunds,
    bank,
    bankName,
    transferCharge,
    description,
  } = fundTransferLogic;
  const { account_name, account_number } = accountDetails;
  const [password, setPassword] = useState("");
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = FundCheckoutValidation();

  return (
    <motion.div
      initial={{ opacity: 0, marginTop: "100px" }}
      animate={{ opacity: 1, marginTop: "25px", transition: { duration: 0.3 } }}
      exit={{ opacity: 0 }}
      className="transfer-summary"
      data-testid="transfer-summary"
    >
      <div className="d-flex align-items-center mb-4">
        <FontAwesomeIcon
          onClick={() => handlePreviousStep()}
          className="mb-2 mr-3"
          icon="angle-left"
          style={{ fontSize: "25px", cursor: "pointer" }}
        />
        <h4>Confirmation</h4>
      </div>
      <p>Confirm the following details for your withdrawal</p>

      <div className="transfer-summary__details">
        <div className="withdrawal__amount">
          <p className="font-weight-semibold dark--text">
            You are withdrawing:
          </p>
          <h5 className="purple--text">{formatCurrency(amount)}</h5>
        </div>
        <div className="withdrawal__amount">
          <p className="font-weight-semibold dark--text">Charges</p>
          <h5 className="purple--text">{formatCurrency(transferCharge)}</h5>
        </div>
        <div className="withdrawal__amount">
          <p className="font-weight-semibold dark--text">Total</p>
          <h5 className="purple--text">
            {formatCurrency(parseFloat(amount) + parseFloat(transferCharge))}
          </h5>
        </div>
        <div className="beneficiary__details mt-4">
          <p className="font-weight-semibold dark--text mb-3">To:</p>
          <div className="beneficiary__details__items">
            <p className="beneficiary__details__item">
              <span>Bank:</span>
              <span>{bankName}</span>
            </p>
            <p className="beneficiary__details__item">
              <span>Account Number:</span>
              <span>{account_number}</span>
            </p>
            <p className="beneficiary__details__item">
              <span>Beneficiary:</span>
              <span>{account_name}</span>
            </p>
            <p className="beneficiary__details__item">
              <span>Description:</span>
              <span>{description}</span>
            </p>
          </div>
        </div>
      </div>

      <form
        onSubmit={handleSubmit(() =>
          handleFundTransfer({ account_number, bank, amount, password })
        )}
      >
        <div className="mt-5">
          <Controller
            name="password"
            defaultValue={password}
            render={({ field: { value, onChange } }) => (
              <primaryComponents.PasswordInputField
                label="Password"
                isRequired={true}
                placeholder="Password"
                name="amount"
                value={value}
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setPassword(value);
                }}
                errorMessage={errors.password}
              />
            )}
            control={control}
          />
        </div>
        <primaryComponents.Button
          isDisabled={isTranferringFunds}
          isLoading={isTranferringFunds}
          classNames={"btn btn--primary cover mt-3"}
          loadingText="Withdrawing..."
          type="submit"
        >
          Withdraw
        </primaryComponents.Button>
      </form>
    </motion.div>
  );
};

export default TransferSummary;
