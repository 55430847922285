import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartDetails: {
    data: [],
    detail: null,
    total_items: 0,
    total_items_amount: 0,
    total_amount: 0,
    vat: 0,
    vat_amount: 0,
  },
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartDetails: (state, action) => {
      state.cartDetails = action.payload;
    },
  },
});

export const { setCartDetails } = cartSlice.actions;
