import "./TransactionDetails.scss";
import { useNavigate } from "react-router-dom";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import Icon from "../../../../assets/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import TransactionDetailsLogic from "./TransactionDetailsLogic";
import { formatCurrency } from "../../../../helpers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Fragment } from "react";

const TransactionDetails = () => {
  const navigate = useNavigate();
  const {
    isFetchingTnxDetails,
    transactionDetails,
    setCopiedTnxId,
    copiedTnxId,
    openSendReceiptWidget,
    setOpenSendReceiptWidget,
    business_role,
    openLinkCustomerWidget,
    setOpenLinkCustomerWidget,
    setRefreshDetails,
  } = TransactionDetailsLogic();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Transaction details"
      description="View the details about a transaction"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className="transaction"
        >
          {/* back btn */}
          <primaryComponents.Button
            classNames="btn btn--outline"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon
              icon="angle-left"
              className="mr-3"
              style={{ fontSize: "18px" }}
            />
            <span>Back</span>
          </primaryComponents.Button>

          <secondaryComponents.LoaderHelper
            isLoading={isFetchingTnxDetails}
            classNames="mt-5"
          >
            <div className="transaction__content">
              <div className="transaction__content__header">
                <div className="transaction__content__header__title mr-4">
                  <h3 className="font-weight-semibold">
                    {formatCurrency(transactionDetails.amount)}
                  </h3>
                  <span
                    style={{ fontSize: "10px" }}
                    className={`ml-3 ${
                      transactionDetails.status === "settled"
                        ? "success--text"
                        : transactionDetails.status === "unpaid"
                        ? "pending--text"
                        : "failed--text"
                    }`}
                  >
                    {transactionDetails.status}
                  </span>
                </div>
                <div className="d-flex ml-auto">
                  <primaryComponents.Button
                    onClick={() => {
                      window.open(transactionDetails.receipt_link, "_blank");
                    }}
                    classNames="btn btn--primary mr-2"
                  >
                    Download Receipt
                  </primaryComponents.Button>
                  <primaryComponents.Button
                    onClick={() => setOpenSendReceiptWidget(true)}
                    classNames="btn btn--primary"
                  >
                    Send Receipt
                  </primaryComponents.Button>
                </div>
              </div>
              <p className="mt-2">
                Payment from{" "}
                {transactionDetails.customer
                  ? transactionDetails.customer.email
                  : "-"}
              </p>
              <div className="transaction__content__details">
                <div className="transaction__content__details__left">
                  <div>
                    <p className="font-weight-semibold dark--text">
                      Transaction ID
                    </p>
                    <span className="gray--text">{transactionDetails.id}</span>
                  </div>
                  <div>
                    <p className="font-weight-semibold dark--text">Date</p>
                    <span className="gray--text">
                      {new Date(transactionDetails.created_at).toLocaleString(
                        "en-us"
                      )}
                    </span>
                  </div>
                  <div className="d-flex">
                    <div>
                      <p className="font-weight-semibold dark--text">
                        Payment References
                      </p>
                      <span className="gray--text">
                        {transactionDetails.reference}
                      </span>
                    </div>
                  </div>
                  <div>
                    <p className="font-weight-semibold dark--text">
                      Amount Received
                    </p>
                    <span className="gray--text">
                      {formatCurrency(transactionDetails?.amount)}
                    </span>
                  </div>
                  {business_role !== "CUSTOMER_SUPPORT" && (
                    <div>
                      <p className="font-weight-semibold dark--text">
                        Transaction Fees
                      </p>
                      <span className="gray--text">
                        {formatCurrency(transactionDetails?.settlement_fee)}
                      </span>
                    </div>
                  )}
                  {business_role !== "CUSTOMER_SUPPORT" && (
                    <div>
                      <p className="font-weight-semibold dark--text">
                        Stamp Duty
                      </p>
                      <span className="gray--text">
                        {formatCurrency(transactionDetails?.stamp_duty)}
                      </span>
                    </div>
                  )}
                  <div>
                    <p className="font-weight-semibold dark--text">
                      Cashback Paid
                    </p>
                    <span className="gray--text">
                      {formatCurrency(transactionDetails.cash_earned)}
                    </span>
                  </div>
                  {/* amount settled */}
                  {business_role !== "CUSTOMER_SUPPORT" && (
                    <div>
                      <p className="font-weight-semibold dark--text">
                        Amount Settled
                      </p>
                      <span className="gray--text">
                        {formatCurrency(transactionDetails?.amount_settled)}
                      </span>
                    </div>
                  )}
                  {/* account credited */}
                  <div>
                    <p className="font-weight-semibold dark--text">
                      Account Credited
                    </p>
                    <span className="gray--text">
                      {transactionDetails.business_tag}
                    </span>
                  </div>
                  {transactionDetails.status === "failed" && (
                    <div>
                      <p className="font-weight-semibold dark--text">
                        Reason for failed transaction
                      </p>
                      <span className="gray--text">
                        {transactionDetails.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="transaction__content__details__right">
                  <CopyToClipboard
                    onCopy={() => setCopiedTnxId(true)}
                    text={transactionDetails.id}
                  >
                    <primaryComponents.Button classNames="btn btn--outline">
                      <img
                        className="mr-2"
                        width="16px"
                        height="16px"
                        src={Icon.copy}
                        alt="Copy"
                      />
                      <span>{copiedTnxId ? "Copied" : "Copy"}</span>
                    </primaryComponents.Button>
                  </CopyToClipboard>
                </div>
              </div>
              <div className="transaction__content__customer-details">
                <div className="transaction__content__customer-details__header">
                  <p className="font-weight-semibold dark--text">
                    Customer Information
                  </p>
                </div>
                <div className="transaction__content__customer-details__content">
                  {transactionDetails.customer !== null &&
                  !transactionDetails?.loyalty_customer ? (
                    <Fragment>
                      <div>
                        <p className="font-weight-semibold dark--text">
                          Customer
                        </p>
                        <span className="gray--text">
                          {transactionDetails.customer?.full_name || "N/A"}
                        </span>
                      </div>
                      <div>
                        <p className="font-weight-semibold dark--text">
                          Email Address
                        </p>
                        <span className="gray--text">
                          {transactionDetails.customer?.email || "N/A"}
                        </span>
                      </div>
                      <div>
                        <p className="font-weight-semibold dark--text">
                          Phone Number
                        </p>
                        <span className="gray--text">
                          {transactionDetails.customer?.phone || "N/A"}
                        </span>
                      </div>
                      <div>
                        <p className="font-weight-semibold dark--text">
                          Date Joined
                        </p>
                        <span className="gray--text">
                          {transactionDetails.customer
                            ? new Date(
                                transactionDetails.customer?.created_at
                              ).toLocaleString("en-us")
                            : "N/A"}
                        </span>
                      </div>
                      <div>
                        <primaryComponents.Button
                          classNames={"btn btn--outline"}
                          onClick={() => setOpenLinkCustomerWidget(true)}
                        >
                          Update
                        </primaryComponents.Button>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div>
                        <p className="font-weight-semibold dark--text">
                          Customer
                        </p>
                        <span className="gray--text">
                          {transactionDetails?.loyalty_customer?.first_name ||
                            "N/A"}
                        </span>
                      </div>
                      <div>
                        <p className="font-weight-semibold dark--text">
                          Email Address
                        </p>
                        <span className="gray--text">
                          {transactionDetails?.loyalty_customer?.email || "N/A"}
                        </span>
                      </div>
                      <div>
                        <p className="font-weight-semibold dark--text">
                          Phone Number
                        </p>
                        <span className="gray--text">
                          {transactionDetails?.loyalty_customer?.phone || "N/A"}
                        </span>
                      </div>
                      <div>
                        <p className="font-weight-semibold dark--text">
                          Date Joined
                        </p>
                        <span className="gray--text">
                          {transactionDetails?.loyalty_customer?.created_at
                            ? new Date(
                                transactionDetails?.loyalty_customer?.created_at
                              ).toLocaleString("en-us")
                            : "N/A"}
                        </span>
                      </div>
                      <div>
                        <primaryComponents.Button
                          classNames={"btn btn--outline"}
                          onClick={() => setOpenLinkCustomerWidget(true)}
                        >
                          Update
                        </primaryComponents.Button>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>

              <div className="transaction__content__other-information">
                <div className="transaction__content__other-information__header">
                  <p className="font-weight-semibold dark--text">
                    Other Information
                  </p>
                </div>
                <div className="transaction__content__other-information__content">
                  <div>
                    <p className="font-weight-semibold dark--text">
                      Originator Name
                    </p>
                    <span className="gray--text">
                      {transactionDetails.payer
                        ? transactionDetails.payer
                        : "-"}
                    </span>
                  </div>
                  <div>
                    <p className="font-weight-semibold dark--text">Bank Name</p>
                    <span className="gray--text">
                      {transactionDetails.payer_bank
                        ? transactionDetails.payer_bank
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </secondaryComponents.LoaderHelper>

          {/* send receipt widget */}
          <secondaryComponents.SideModal
            isActive={openSendReceiptWidget}
            closeModal={() => setOpenSendReceiptWidget(false)}
          >
            <secondaryComponents.SendReceiptWidget
              closeWidget={() => setOpenSendReceiptWidget(false)}
              transactionId={transactionDetails.id}
              receiptType="transactions"
            />
          </secondaryComponents.SideModal>

          {/* link customer widget */}
          <secondaryComponents.SideModal
            isActive={openLinkCustomerWidget}
            closeModal={() => setOpenLinkCustomerWidget(false)}
          >
            <secondaryComponents.LinkCustomerWidget
              closeWidget={() => setOpenLinkCustomerWidget(false)}
              transactionId={transactionDetails.id}
              setRefreshDetails={setRefreshDetails}
            />
          </secondaryComponents.SideModal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default TransactionDetails;
