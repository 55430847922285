import { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { ToastContext } from "../../../../hooks/context";
import {
  checkoutByTransfer,
  getTransactionViaTransfer,
} from "../../../../services/sales-point";

const TransferPaymentLogic = ({ closePaymentSession }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("order_id");
  const triggerToast = useContext(ToastContext);
  const [selectedTransfer, setSelectedTransfer] = useState("");
  const [isEnabled, setIsEnabled] = useState(true);
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
  const fetchTransfers = async () => {
    return await getTransactionViaTransfer().then(
      (response) => response.data.data
    );
  };
  const { data: transfers, isLoading: isLoadingTransfers } = useQuery(
    "transfers",
    fetchTransfers,
    {
      enabled: isEnabled,
      onSuccess: () => {
        setIsEnabled(false);
      },
      onError: (error) => {
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      },
    }
  );

  const payViaTransfer = () => {
    setIsConfirmingPayment(true);
    checkoutByTransfer({ order_id: orderId, transaction_id: selectedTransfer })
      .then(() => {
        setIsConfirmingPayment(false);
        triggerToast("Payment confirmation successful", "success");
        closePaymentSession();
      })
      .catch((error) => {
        setIsConfirmingPayment(false);
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      });
  };

  const { isLoading: loadingExternal, mutate: paidExternally } = useMutation(
    (data) => checkoutByTransfer(data)
  );
  const payViaExternal = () => {
    paidExternally(
      { order_id: orderId, transaction_id: null },
      {
        onSuccess: () => {
          triggerToast("Payment linked successful", "success");
          closePaymentSession();
        },
        onError: (error) => {
          if (error?.response?.status === 400)
            triggerToast(error?.response?.data?.detail, "warning");
        },
      }
    );
  };

  return {
    transfers,
    isLoadingTransfers,
    selectedTransfer,
    isEnabled,
    isConfirmingPayment,
    loadingExternal,
    payViaExternal,
    setSelectedTransfer,
    setIsEnabled,
    payViaTransfer,
  };
};
export default TransferPaymentLogic;
