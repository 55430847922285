import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const fundTransferSchema = yup
  .object({
    bank: yup.string().required("Select a bank"),
    subAccount: yup.string().required("Select sub account"),
    account_number: yup
      .string()
      .min(10, "Minimum of 10 digits is allowed")
      .max(10, "Maximum of 10 digits is allowed"),
    amount: yup
      .number()
      .typeError("Amount must be a number")
      .required("Amount is required")
      .positive("Invalid amount"),
    transferDescription: yup
      .string()
      .required("Reason for transfer is required"),
  })
  .required();

const TransferFormValidation = (
  bank,
  accountNumber,
  amount,
  transferDescription,
  subAccount
) => {
  return useForm({
    resolver: yupResolver(fundTransferSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      bank: bank,
      account_number: accountNumber,
      amount: amount,
      transferDescription: transferDescription,
      subAccount: subAccount,
    },
  });
};

export default TransferFormValidation;
