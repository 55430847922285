import "./StockHistoryTable.scss";
import secondaryComponents from "..";
import { useStockHistoryTable } from "./StockHistoryTableLogic";

const StockHistoryTable = () => {
  const { instance, isFetchingHistory, products, paginationDetails, gotoPage } =
    useStockHistoryTable();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="stock-history-table">
      <div className="stock-history-table__content">
        <secondaryComponents.LoaderHelper
          isLoading={isFetchingHistory}
          classNames={"mt-5"}
        >
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => (
                    <th
                      key={i}
                      className="font-weight-semibold"
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr key={i} {...row.getRowProps({ key: i })}>
                    {row.cells.map((cell, i) => {
                      return (
                        <td key={i} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {products.length > 0 && (
            <div className="mt-auto">
              <secondaryComponents.Pagination
                totalPageNumber={paginationDetails.last_page}
                handlePageClick={(value) => gotoPage(value.selected + 1)}
                currentPage={paginationDetails.current_page}
              />
            </div>
          )}
        </secondaryComponents.LoaderHelper>
      </div>
    </div>
  );
};

export default StockHistoryTable;
