import primaryComponents from "../../primaryComponents";
import ProfilePersonalInfoFormLogic from "./ProfilePersonalInfoFormLogic";
import ProfilePersonalInfoFormValidation from "./ProfilePersonalInfoFormValidation";
import { useEffect } from "react";
import localData from "../../../localdata";
import { Controller } from "react-hook-form";

const ProfilePersonalInfoForm = () => {
  const {
    setEmail,
    email,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phoneNumber,
    setPhoneNumber,
    jobTitle,
    setJobTitle,
    updatePersonalInfo,
    setIsEdited,
    isEdited,
    isSubmitting,
  } = ProfilePersonalInfoFormLogic();

  const {
    register,
    handleSubmit,
    setError,
    control,
    trigger,
    formState: { errors },
  } = ProfilePersonalInfoFormValidation(
    email,
    firstName,
    lastName,
    phoneNumber,
    jobTitle
  );

  useEffect(() => {
    window.setPersonalInfoError = setError;
  }, []);

  return (
    <form
      onSubmit={handleSubmit(updatePersonalInfo)}
      className="mt-4 personal-info__form"
    >
      {/* email address */}
      <div>
        <Controller
          name="email"
          control={control}
          defaultValue={email}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label="Email Address"
              placeholder="Enter your email address"
              isRequired={true}
              isDisabled={true}
              type="email"
              name="email"
              errorMessage={errors.email}
              onKeyUp={() => errors.email !== undefined && trigger("email")}
              onChange={({ target: { value } }) => {
                onChange(value);
                setEmail(value);
                setIsEdited(true);
              }}
              register={register}
            />
          )}
        />
      </div>
      {/* first name */}
      <div className="mt-1">
        <Controller
          name="firstName"
          control={control}
          defaultValue={firstName}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label="First Name"
              placeholder="Enter your first Name"
              isRequired={true}
              type="text"
              name="firstName"
              errorMessage={errors.firstName}
              onChange={({ target: { value } }) => {
                onChange(value);
                setFirstName(value);
                setIsEdited(true);
              }}
              onKeyUp={() =>
                errors.firstName !== undefined && trigger("firstName")
              }
              register={register}
            />
          )}
        />
      </div>
      {/* last name */}
      <div className="mt-1">
        <Controller
          name="lastName"
          control={control}
          defaultValue={firstName}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label="Last Name"
              placeholder="Enter your last Name"
              isRequired={true}
              type="text"
              name="lastName"
              errorMessage={errors.lastName}
              onChange={({ target: { value } }) => {
                onChange(value);
                setLastName(value);
                setIsEdited(true);
              }}
              onKeyUp={() =>
                errors.lastName !== undefined && trigger("lastName")
              }
              register={register}
            />
          )}
        />
      </div>
      {/* phone number */}
      <div className="mt-1">
        <Controller
          name="phoneNumber"
          control={control}
          defaultValue={phoneNumber}
          render={({ field: { onChange } }) => (
            <primaryComponents.PhoneNumberInputField
              label="Phone Number"
              name="phoneNumber"
              placeholder="Enter your phone number"
              errorMessage={errors.phoneNumber}
              onKeyUp={() =>
                errors.phoneNumber !== undefined && trigger("phoneNumber")
              }
              onChange={({ target: { value } }) => {
                onChange(value);
                setPhoneNumber(value);
                setIsEdited(true);
              }}
              isRequired={true}
              register={register}
            />
          )}
        />
      </div>
      {/* job title */}
      <div className="mt-1">
        <Controller
          control={control}
          defaultValue={jobTitle}
          name="jobTitle"
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              label="Job title"
              isRequired={true}
              placeholder="Enter job title"
              name="jobTitle"
              value={jobTitle}
              errorMessage={errors.jobTitle}
              options={localData.jobTitleOptions}
              onChange={(value) => {
                onChange(value);
                setJobTitle(value);
                setIsEdited(true);
              }}
            />
          )}
        />
      </div>
      <div className="d-flex justify-content-end">
        <primaryComponents.Button
          isDisabled={!isEdited || isSubmitting}
          isLoading={isSubmitting}
          classNames={"btn btn--primary"}
          type="submit"
          loadingText="Saving..."
        >
          Save Changes
        </primaryComponents.Button>
      </div>
    </form>
  );
};

export default ProfilePersonalInfoForm;
