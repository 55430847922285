import "./CreateCampaign.scss";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CreateCampaign = () => {
  const navigate = useNavigate();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Loyalty - Create campaign"
      description="Create campaign on lumi merchant and see an improve customer loyalty"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className="create-campaign"
        >
          {/* back btn */}
          <primaryComponents.Button
            classNames="btn btn--outline mb-4"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon
              icon="angle-left"
              className="mr-3"
              style={{ fontSize: "18px" }}
            />
            <span>Back</span>
          </primaryComponents.Button>

          <div className="create-campaign__content mt-4">
            <div className="create-campaign__content__header">
              <h3 className="font-weight-semibold">Create Campaign</h3>
            </div>
            <div className="create-campaign__content__body">
              <secondaryComponents.CampaignForm />
            </div>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default CreateCampaign;
