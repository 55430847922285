import "./StockUnitInput.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { motion } from "framer-motion";
import StockUnitInputLogic from "./StockUnitInputLogic";

const StockUnitInput = ({
  label,
  name = "",
  value = "",
  onChange = () => {},
  errorMessage,
  ...rest
}) => {
  const {
    isOpen,
    handleToggle,
    // isFetchinUnits,
    units,
    selectedUnit,
    setIsOpen,
    // setSelectedUnit,
    handleChange,
    handleSelectUnit,
  } = StockUnitInputLogic({ onChange });

  useEffect(() => {
    const toggleOptions = (event) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };
    window.addEventListener("keydown", toggleOptions);

    return () => window.removeEventListener("keydown", toggleOptions);
  }, []);

  return (
    <div className="stock-unit-input-field">
      <label htmlFor="stock-unit">
        <span>{label ? label : "Stock Quantity"}</span>
      </label>
      <div className="stock-unit-container">
        <input
          type="text"
          id="stock-unit"
          name={name}
          value={value}
          onChange={handleChange}
          {...rest}
        />
        <div className="unit-option" onClick={() => handleToggle()}>
          <span>{selectedUnit?.unit || "Kg"}</span>
          <FontAwesomeIcon
            icon={["fas", isOpen ? "angle-up" : "angle-down"]}
            style={{ fontSize: "12px" }}
            className="ml-2"
          />
        </div>
      </div>
      <motion.ul
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { duration: 0.3 },
        }}
        className={`stock-unit-list ${isOpen ? "active" : ""}`}
      >
        {units.length > 0 &&
          units.map((unit) => (
            <li
              className={`${selectedUnit?.id === unit.id ? "selected" : ""}`}
              onClick={() => {
                handleSelectUnit(unit);
                setIsOpen(false);
              }}
              key={unit.id}
            >
              <span>{unit.name}</span>
              <span>({unit.unit})</span>
            </li>
          ))}
      </motion.ul>
      <p
        className={`error-message text-danger ${
          errorMessage !== undefined ? "show" : ""
        }`}
        data-testid="error-message"
      >
        {errorMessage !== undefined && (
          <>
            <FontAwesomeIcon
              icon={["fas", "exclamation-circle"]}
              className="mr-2"
              style={{ fontSize: "13px" }}
            />
            {errorMessage !== undefined && <span>{errorMessage.message}</span>}
          </>
        )}
      </p>
    </div>
  );
};

export default StockUnitInput;
