import "./TerminalCredentials.scss";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TerminalCredentialsLogic from "./TerminalCredentialsLogic";
import { formatCurrency } from "../../../../helpers";

const TerminalCredentials = () => {
  const {
    terminalDetails,
    navigate,
    isLoading,
    handleResetTerminal,
    isResetingTerminal,
  } = TerminalCredentialsLogic();

  return (
    <secondaryComponents.MetaSetter
      title={"POS Terminals"}
      description="POS Terminal credentials"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          <div className="terminal-credentials">
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
            <secondaryComponents.LoaderHelper
              isLoading={isLoading}
              classNames="mt-5"
            >
              <div className="terminal-credentials__header">
                <div className="terminal-credentials__header-item">
                  <h3 className="font-weight-semibold">
                    {terminalDetails.terminal_id}
                  </h3>
                  <span
                    className={`${
                      terminalDetails.status === "ACTIVE" ||
                      terminalDetails.status === "RETRIEVED"
                        ? "success--text"
                        : terminalDetails.status === "PENDING"
                        ? "pending--text"
                        : "failed--text"
                    }`}
                  >
                    {terminalDetails.status}
                  </span>
                </div>
                <p>
                  {new Date(terminalDetails.last_activity).toLocaleString(
                    "en-us"
                  )}
                </p>
              </div>
              <div className="terminal-credentials__content">
                <div className="terminal-details__content-item">
                  <p className="font-weight-semibold dark--text">Terminal ID</p>
                  <span>{terminalDetails.terminal_id}</span>
                </div>
                <div className="terminal-credentials__content-item">
                  <p className="font-weight-semibold dark--text">
                    Bank Provider
                  </p>
                  <span>{terminalDetails.bank_provider}</span>
                </div>
                <div className="terminal-credentials__content-item">
                  <p className="font-weight-semibold dark--text">
                    Request Date
                  </p>
                  <span>
                    {new Date(terminalDetails.requested_at).toLocaleString(
                      "en-us"
                    )}
                  </span>
                </div>
                <div className="terminal-credentials__content-item">
                  <p className="font-weight-semibold dark--text">
                    Approval Date
                  </p>
                  <span>
                    {new Date(terminalDetails.approved_at).toLocaleString(
                      "en-us"
                    )}
                  </span>
                </div>
                <div className="terminal-credentials__content-item">
                  <p className="font-weight-semibold dark--text">
                    Volume Processed
                  </p>
                  <span>
                    {formatCurrency(terminalDetails.volume_processed)}
                  </span>
                </div>
                <div className="terminal-credentials__content-item">
                  <p className="font-weight-semibold dark--text">
                    Store Manager
                  </p>
                  <span>N/A</span>
                </div>
                <div className="terminal-credentials__content-item">
                  <p className="font-weight-semibold dark--text">Status</p>
                  <span>{terminalDetails.status}</span>
                </div>
                <div className="terminal-credentials__content-item">
                  <p className="font-weight-semibold dark--text">
                    Store Location
                  </p>
                  <span>N/A</span>
                </div>
              </div>
              <p className="font-weight-semibold dark--text mt-4">
                POS Credentials
              </p>
              <div className="terminal-credentials__content">
                <div className="terminal-credentials__content-item">
                  <p className="font-weight-semibold dark--text">Username</p>
                  <span>
                    {terminalDetails.cashier_username === null
                      ? "N/A"
                      : terminalDetails.cashier_username}
                  </span>
                </div>
                <div className="terminal-credentials__content-item">
                  <p className="font-weight-semibold dark--text">Password</p>
                  <span>
                    {terminalDetails.cashier_password === null
                      ? "N/A"
                      : terminalDetails.cashier_password}
                  </span>
                </div>
                <div className="terminal-credentials__content-item">
                  <p className="font-weight-semibold dark--text">OTP</p>
                  <span>
                    {terminalDetails.login_otp === null
                      ? "N/A"
                      : terminalDetails.login_otp}
                  </span>
                </div>
                <div className="terminal-credentials__content-item reset__btn">
                  <primaryComponents.Button
                    classNames={"btn btn--primary"}
                    onClick={() => handleResetTerminal()}
                    isLoading={isResetingTerminal}
                    loadingText="Resetting..."
                    isDisabled={
                      terminalDetails.cashier_password === null ||
                      terminalDetails.cashier_username === null ||
                      terminalDetails.login_otp === null ||
                      isResetingTerminal
                    }
                  >
                    Reset
                  </primaryComponents.Button>
                </div>
              </div>
            </secondaryComponents.LoaderHelper>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default TerminalCredentials;
