import secondaryComponents from "../..";
import TransferFormValidation from "./validation";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import primaryComponents from "../../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

const TransferForm = ({ fundTransferLogic, closeWidget }) => {
  const {
    bankList,
    isFetchingBankList,
    isValidatingAccountNumber,
    isAccountNumberValid,
    handleResolveAccountNumber,
    amount,
    accountNumber,
    accountDetails,
    bank,
    bankName,
    setBankName,
    transferDescription,
    setTransferDescription,
    setBank,
    setAmount,
    setAccountNumber,
    validateBalance,
    isFetchingCharges,
    business_role,
    businessList,
    subAccount,
    setSubAccount,
    saveBeneficiary,
    setSaveBeneficiary,
    subAccountName,
    setSubAccountName,
    tags,
    setTags,
    tagsList,
  } = fundTransferLogic;
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    trigger,
    register,
    formState: { errors },
  } = TransferFormValidation(
    bank,
    accountNumber,
    amount,
    transferDescription,
    subAccount
  );

  useEffect(() => {
    window.setAccountError = setError;
    window.clearAccountErrors = clearErrors;
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, marginTop: "100px" }}
      animate={{ opacity: 1, marginTop: "25px", transition: { duration: 0.3 } }}
      exit={{ opacity: 0 }}
      data-testid="transfer-form"
    >
      <div className="d-flex justify-content-between">
        <h4 className="font-weight-semibold">Transfer Funds</h4>
        <FontAwesomeIcon
          onClick={() => closeWidget(false)}
          className="close-icon"
          icon="times"
        />
      </div>
      <p>Provide the following details for your withdrawal</p>

      <primaryComponents.Button
        className="mt-4 btn btn--outline ml-auto"
        style={{ height: "35px", padding: "0 15px", fontSize: "12px" }}
        onClick={() => fundTransferLogic.navigation.go(2)}
        data-testid="find-beneficiary"
      >
        Find Beneficiary
      </primaryComponents.Button>

      <secondaryComponents.LoaderHelper
        classNames="mt-4"
        isLoading={isFetchingBankList}
      >
        <form
          onSubmit={handleSubmit(validateBalance)}
          className="transfer-form"
        >
          {/* select sub account */}
          <div>
            <Controller
              name="subAccount"
              defaultValue={subAccount}
              control={control}
              render={({ field: { onChange } }) => (
                <primaryComponents.SelectField
                  label="Account"
                  isRequired={true}
                  value={
                    subAccount === "" ? "Select account..." : subAccountName
                  }
                  options={businessList}
                  nameKey="tag"
                  idKey="id"
                  onChange={(value, label) => {
                    setSubAccount(value);
                    setSubAccountName(label);
                    onChange(value);
                  }}
                  errorMessage={errors.subAccount}
                />
              )}
            />
          </div>
          {/* select bank name */}
          <div>
            <Controller
              name="bank"
              defaultValue={bank}
              control={control}
              render={({ field: { onChange } }) => (
                <primaryComponents.SelectField
                  label="Bank"
                  isRequired={true}
                  value={bank === "" ? "Select Bank" : bankName}
                  options={bankList}
                  nameKey="bank_name"
                  idKey="bank_code"
                  onChange={(value, label) => {
                    setBankName(label);
                    onChange(value);
                    setBank(value);
                    if (accountNumber !== "")
                      handleResolveAccountNumber(value, accountNumber);
                  }}
                  errorMessage={errors.bank}
                />
              )}
            />
          </div>
          {/* account number */}
          <div className="mb-3" style={{ position: "relative" }}>
            <Controller
              name="account_number"
              defaultValue={accountNumber}
              render={({ field: { onChange, value } }) => (
                <primaryComponents.InputField
                  label="Account Number"
                  isRequired={true}
                  placeholder="Enter 10-digit account number"
                  name="accountNumber"
                  value={value}
                  floatError={false}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setAccountNumber(value);
                    if (value.length === 10)
                      handleResolveAccountNumber(bank, value);
                  }}
                  onKeyUp={() =>
                    errors.account_number !== undefined &&
                    trigger("account_number")
                  }
                  register={register}
                  errorMessage={errors.account_number}
                />
              )}
              control={control}
            />
            {accountDetails !== null && (
              <p className="purple--text">{accountDetails.account_name}</p>
            )}
            {isValidatingAccountNumber && (
              <FontAwesomeIcon
                style={{
                  position: "absolute",
                  right: "10px",
                  // bottom: "50%",
                  top: "51%",
                  transform: "translateY(-50%)",
                }}
                icon={["fas", "spinner"]}
                spin
              />
            )}
          </div>
          {/* amount field */}
          <div>
            <Controller
              name="amount"
              defaultValue={amount}
              render={({ field: { value, onChange } }) => (
                <primaryComponents.InputField
                  label="Amount"
                  isRequired={true}
                  placeholder="₦ Enter Amount"
                  name="amount"
                  value={value}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setAmount(value);
                  }}
                  onKeyUp={() =>
                    errors.amount !== undefined && trigger("amount")
                  }
                  register={register}
                  errorMessage={errors.amount}
                />
              )}
              control={control}
            />
          </div>
          {/* tag  */}
          <div>
            <Controller
              control={control}
              defaultValue={tags}
              name="tags"
              render={({ field: { onChange } }) => (
                <primaryComponents.MultipleSelectField
                  label="Add tag"
                  isRequired={false}
                  placeholder="Add tag"
                  name="tags"
                  errorMessage={errors.tags}
                  options={tagsList}
                  idKey="id"
                  nameKey="name"
                  onChange={(values) => {
                    onChange(values);
                    let newValues = [];
                    values.forEach((item) => {
                      newValues.push(item.value);
                    });
                    setTags(newValues);
                  }}
                />
              )}
            />
          </div>
          {/* transfer description */}
          <div>
            <Controller
              name="transferDescription"
              defaultValue={transferDescription}
              render={({ field: { value, onChange } }) => (
                <primaryComponents.InputField
                  label="Transfer purpose"
                  isRequired={true}
                  placeholder="Enter reason for transfer"
                  name="transferDescription"
                  value={value}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setTransferDescription(value);
                  }}
                  onKeyUp={() =>
                    errors.transferDescription !== undefined &&
                    trigger("transferDescription")
                  }
                  errorMessage={errors.transferDescription}
                  register={register}
                />
              )}
              control={control}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <p className="dark--text">Save beneficiary</p>

            <primaryComponents.Switch
              isOn={saveBeneficiary}
              name="saveBeneficiary"
              onChange={() => setSaveBeneficiary((prev) => !prev)}
            />
          </div>

          <primaryComponents.Button
            type="submit"
            classNames="btn btn--primary cover mt-4"
            isDisabled={
              isValidatingAccountNumber ||
              !isAccountNumberValid ||
              isFetchingCharges
            }
            isLoading={isFetchingCharges}
            loadingText="Processing..."
          >
            {business_role === "BUSINESS_MANAGER" ? "Confirm" : "Continue"}
          </primaryComponents.Button>
        </form>
      </secondaryComponents.LoaderHelper>
    </motion.div>
  );
};
export default TransferForm;
