import httpClient from "../axiosBase/axiosHandler";

export const getCustomers = (queries) => {
  const response = httpClient.get(`/customers/${queries}`);
  return response;
};

export const getCustomer = (customerId) => {
  const response = httpClient.get(`/customers/${customerId}/`);
  return response;
};

export const getCustomerTransactions = (customerId) => {
  const response = httpClient.get(
    `/transactions/?all_business=true&customer_id=${customerId}`
  );
  return response;
};

export const addLoyaltyCustomer = (payload) => {
  return httpClient.post("/loyalty/customer-profile/", { ...payload });
};

export const getCustomersHistory = (queries) => {
  const response = httpClient.get(
    `/loyalty/customers/customer-history/${queries}`
  );
  return response;
};

export const getLoyaltyCustomers = () => {
  return httpClient.get("/loyalty/customers/");
};

export const getLoyaltyCustomerDetails = (id) => {
  return httpClient.get(`/loyalty/customers/${id}/`);
};

export const toggleCustomerLoyalty = (id) => {
  return httpClient.post(`/loyalty/customers/${id}/update-loyalty-status/`, {});
};
