import "./DeleteTagDialog.scss";
import secondaryComponents from "..";
import DeleteTagDialogLogic from "./DeleteTagDialogLogic";

const DeleteTagDialog = ({ isOpen, setIsOpen, tagDetails, setIsRefresh }) => {
  const { handleDeleteTag, isDeletingTag } = DeleteTagDialogLogic({
    tagDetails,
    setIsRefresh,
    setIsOpen,
  });
  return (
    <secondaryComponents.ConfirmDialog
      title="Are you sure you want to delete this tag?
        All associated data would be deleted as well."
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handler={handleDeleteTag}
      isLoading={isDeletingTag}
    >
      <div className="delete-tag-dialog">
        <p
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span className=" mb-2">Tag:</span>
          <span className="dark--text font-weight-semibold">
            {tagDetails?.name}
          </span>
        </p>
      </div>
    </secondaryComponents.ConfirmDialog>
  );
};

export default DeleteTagDialog;
