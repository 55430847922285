import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const CartItemsCheckoutValidationSchema = yup
  .object({
    amount: yup
      .string()
      .matches(/^[0-9]+$/, "Amount must contain only digits")
      .test(
        "num",
        "Please, enter the correct amount collected from customer",
        (val) => !val.startsWith("0")
      )
      .required("Please, enter the amount collected from customer"),
  })
  .required();

const CartItemsCheckoutValidation = (amount) => {
  return useForm({
    resolver: yupResolver(CartItemsCheckoutValidationSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      amount: amount,
    },
  });
};

export default CartItemsCheckoutValidation;
