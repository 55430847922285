import "./StoreDevices.scss";
import secondaryComponents from "../../../components/secondaryComponents";

const StoreDevices = () => {
  return (
    <div className="store-devices">
      <div className="store-devices">
        <secondaryComponents.StoreDevicesTable />
      </div>
    </div>
  );
};

export default StoreDevices;
