import { useState, useEffect, useContext } from "react";
import {
  fetchAllCustomerAnalytics,
  fetchNewCustomerAnalytics,
  fetchReturningCustomerAnalytics,
  fetchAVSUCustomerAnalytics,
  fetchTopCustomers,
} from "../../../../services/dashboard";
import { getUrlQuerysection } from "../../../../helpers";
import { ToastContext, RequestLoader } from "../../../../hooks/context";

const CustomerAnalyticsLogic = ({ isRefresh, setIsRefresh }) => {
  const [isLoadingAllCustomers, setIsLoadingAllCustomers] = useState(false);
  const [isLoadingNewCustomers, setIsLoadingNewCustomers] = useState(false);
  const [isLoadingReturnedCustomers, setIsLoadingReturnedCustomers] =
    useState(false);
  const [isLoadingASPU, setIsLoadingASPU] = useState(false);
  const [isLoadingTopCustomers, setIsLoadingTopCustomers] = useState(false);
  const [allCustomerData, setAllCustomerData] = useState({ trend: [] });
  const [newCustomerData, setNewCustomerData] = useState({ trend: [] });
  const [returnedCustomerData, setReturnedCustomerData] = useState({
    trend: [],
  });
  const [ASPUCustomerData, setASPUCustomerData] = useState({ trend: [] });
  const [topCustomers, setTopCustomers] = useState([]);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  useEffect(() => {
    setRequestLoaderProgress(20);
    getAllCustomerAnalytics();
    getNewCustomerAnalytics();
    getASPUAnalytics();
    getReturnedCustomerAnalytics();
    getTopCustomers();
    setRequestLoaderProgress(80);
  }, []);

  useEffect(() => {
    if (isRefresh) {
      setRequestLoaderProgress(20);
      getAllCustomerAnalytics();
      getNewCustomerAnalytics();
      getASPUAnalytics();
      getReturnedCustomerAnalytics();
      getTopCustomers();
      setIsRefresh(false);
      setRequestLoaderProgress(80);
    }
  }, [isRefresh]);

  const getAllCustomerAnalytics = () => {
    setIsLoadingAllCustomers(true);
    let queries = getUrlQuerysection();
    fetchAllCustomerAnalytics(queries)
      .then((response) => {
        setIsLoadingAllCustomers(false);
        setAllCustomerData(response.data.data);
      })
      .catch((error) => {
        setIsLoadingAllCustomers(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const getNewCustomerAnalytics = () => {
    setIsLoadingNewCustomers(true);
    let queries = getUrlQuerysection();
    fetchNewCustomerAnalytics(queries)
      .then((response) => {
        setIsLoadingNewCustomers(false);
        setNewCustomerData(response.data.data);
      })
      .catch((error) => {
        setIsLoadingNewCustomers(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const getReturnedCustomerAnalytics = () => {
    setIsLoadingReturnedCustomers(true);
    let queries = getUrlQuerysection();
    fetchReturningCustomerAnalytics(queries)
      .then((response) => {
        setIsLoadingReturnedCustomers(false);
        setReturnedCustomerData(response.data.data);
      })
      .catch((error) => {
        setIsLoadingReturnedCustomers(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const getASPUAnalytics = () => {
    setIsLoadingASPU(true);
    let queries = getUrlQuerysection();
    fetchAVSUCustomerAnalytics(queries)
      .then((response) => {
        setIsLoadingASPU(false);
        setASPUCustomerData(response.data.data);
      })
      .catch((error) => {
        setIsLoadingASPU(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const getTopCustomers = () => {
    setIsLoadingTopCustomers(true);
    let queries = getUrlQuerysection();
    fetchTopCustomers(queries)
      .then((response) => {
        setIsLoadingTopCustomers(false);
        setTopCustomers(response.data.data);
      })
      .catch((error) => {
        setIsLoadingTopCustomers(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  return {
    isLoadingAllCustomers,
    isLoadingNewCustomers,
    isLoadingReturnedCustomers,
    isLoadingASPU,
    isLoadingTopCustomers,
    allCustomerData,
    newCustomerData,
    returnedCustomerData,
    ASPUCustomerData,
    topCustomers,
  };
};
export default CustomerAnalyticsLogic;
