import { getBusiness } from "../../../services/settings";
import { setBusinessDetails } from "../../../store/modules/profile";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const KYBVerificationResponseLogic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    getBusiness().then((response) => {
      dispatch(setBusinessDetails(response.data.data));
      navigate("/app/dashboard");
    });
  };

  return { handleClick };
};

export default KYBVerificationResponseLogic;
