import "./FundTransferWidget.scss";
import secondaryComponents from "../../secondaryComponents";
import FundTransferLogic from "./FundTransferLogic";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TransferSummary from "./TransferSummary/TransferSummary";
import TransferForm from "./TransferForm/TransferForm";
import Beneficiaries from "./Beneficiaries/Beneficiaries";
import { motion } from "framer-motion";

const FundTransferWidget = ({ closeWidget, refreshTransfers }) => {
  const fundTransferLogic = FundTransferLogic({
    closeWidget,
    refreshTransfers,
  });

  return (
    <div className="fund-transfer">
      <div
        className="fund-transfer__backdrop"
        onClick={() => closeWidget(false)}
      ></div>
      <motion.div
        initial={{ opacity: 0, marginRight: "-400px" }}
        animate={{
          opacity: 1,
          marginRight: "0px",
          transition: { duration: 0.3 },
        }}
        exit={{ opacity: 0 }}
        className="fund-transfer__content"
      >
        {fundTransferLogic.canTransferFunds ? (
          <div>
            {fundTransferLogic.index === 0 && (
              <TransferForm
                fundTransferLogic={fundTransferLogic}
                closeWidget={closeWidget}
              />
            )}
            {fundTransferLogic.index === 1 && (
              <TransferSummary fundTransferLogic={fundTransferLogic} />
            )}
            {fundTransferLogic.index === 2 && (
              <Beneficiaries fundTransferLogic={fundTransferLogic} />
            )}
          </div>
        ) : (
          <div className="mt-5">
            <secondaryComponents.UnverifiedAccountWidget
              message="It appears your account has not been verified, please verify your account to continue"
              verification_status={fundTransferLogic.verification_status}
            />
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default FundTransferWidget;
