import "./Checkbox.scss";
import { useState } from "react";

const Checkbox = ({ isChecked = false, name, id, ...props }) => {
  const [checked, setChecked] = useState(isChecked);
  const onChange = () => {
    setChecked(!checked);
    props.onChange(!checked);
  };
  return (
    <div className="checkbox mr-4">
      <input
        {...props}
        name={name}
        type="checkbox"
        id={id}
        //value={checked}
        defaultChecked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}></label>
    </div>
  );
};

export default Checkbox;
