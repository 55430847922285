import "./SalesTransactionsTable.scss";
import { Fragment } from "react";
import primaryComponents from "../../primaryComponents";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SalesTransactionsTableLogic from "./SalesTransactionsTableLogic";
import secondaryComponents from "..";
import { formatCurrency } from "../../../helpers";

const SalesTransactionsTable = () => {
  const {
    openSalesTransactionWidget,
    setOpenSalesTransactionWidget,
    instance,
    isFetchingOrders,
    selectedOrder,
    orders,
    orderDetails,
    searchValue,
    gotoPage,
    handleSearchOrders,
  } = SalesTransactionsTableLogic();
  const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
    instance;
  return (
    <div className="sales-transactions-table">
      {/* { rows.length > 0 && ( */}
      <Fragment>
        <div className="sales-transactions-table__header">
          <div style={{ width: "100%" }}>
            <primaryComponents.SearchField
              placeholder="Search for a transaction by (Order Number, Transaction Ref)"
              trigger={(value) => handleSearchOrders(value)}
              value={searchValue}
              classNames="search-section"
              triggerOnInput={true}
              showsearchBtn={true}
            />
          </div>
        </div>
        <div className="sales-transactions-table__content">
          <secondaryComponents.LoaderHelper
            isLoading={isFetchingOrders}
            classNames={"mt-3"}
          >
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, i) => (
                      <th
                        key={i}
                        className="font-weight-semibold"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr key={i} {...row.getRowProps({ key: i })}>
                      {row.cells.map((cell) => {
                        return (
                          <td key={i} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* Card */}
            <div className="sales-transactions-table__content-mobile">
              {orders.map((order) => (
                <div
                  key={order.id}
                  className="sales-transactions-table__content-mobile-card"
                >
                  <div className="sales-transactions-table__content-mobile-card__title">
                    <p>Order Number:</p>
                    <p>{order.id}</p>
                  </div>
                  <div className="sales-transactions-table__content-mobile-card__title">
                    <p>Order Date:</p>
                    <p>
                      {new Date(order.created_at).toLocaleDateString("en-GB")}
                    </p>
                  </div>
                  <div className="sales-transactions-table__content-mobile-card__title">
                    <p>Transaction Ref:</p>
                    <p>N/A</p>
                  </div>
                  <div className="sales-transactions-table__content-mobile-card__title">
                    <p>Payment Type:</p>
                    <p>{order?.payment_method}</p>
                  </div>
                  <div className="sales-transactions-table__content-mobile-card__title">
                    <p>Amount:</p>
                    <p>{formatCurrency(order.totat_amount)}</p>
                  </div>
                  <div className="sales-transactions-table__content-mobile-card__title">
                    <p>Cashier:</p>
                    <p>{order?.cashier?.first_name || "N/A"}</p>
                  </div>
                  <div className="sales-transactions-table__content-mobile-card__title">
                    <p>Store:</p>
                    <p>{order?.store?.name || "N/A"}</p>
                  </div>
                  <div className="sales-transactions-table__content-mobile-card__title">
                    <p>Status:</p>
                    <p className="status">{order?.status}</p>
                  </div>
                </div>
              ))}
            </div>
          </secondaryComponents.LoaderHelper>
        </div>
      </Fragment>
      {/* Pagination */}
      {orders.length > 0 && (
        <secondaryComponents.Pagination
          totalPageNumber={orderDetails.last_page}
          handlePageClick={(value) => gotoPage(value.selected + 1)}
          currentPage={orderDetails.current_page}
        />
      )}

      <secondaryComponents.SideModal
        isActive={openSalesTransactionWidget}
        closeModal={() => setOpenSalesTransactionWidget(false)}
      >
        <secondaryComponents.SalesTransactionWidget
          closeWidget={setOpenSalesTransactionWidget}
          selectedOrder={selectedOrder}
        />
      </secondaryComponents.SideModal>
    </div>
  );
};

export default SalesTransactionsTable;
