import { useStep } from "react-hooks-helper";
import { useState, useContext, useEffect } from "react";
import { createUserViaInvite, getInviteDetails } from "../../../services/auth";
import { validateData } from "../../../services/auth";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { useNavigate } from "react-router-dom";
import { setAccessToken } from "../../../store/modules/profile";
import { useDispatch } from "react-redux";

const InviteSignupLogic = () => {
  const params = new URLSearchParams(window.location.search);
  const refId = params.get("ref");
  const [email, setEmail] = useState(params.get("email"));
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [isValidatingData, setisValidatingData] = useState(false);
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const dispatch = useDispatch();
  const [showRegistrationFlow, setShowRegistrationFlow] = useState(false);

  useEffect(() => {
    if (refId === undefined || refId === null) return navigate("/signup");
    getInviteDetails(refId)
      .then((response) => {
        setEmail(response.data.data.email);
      })
      .then(() => {
        setShowRegistrationFlow(true);
      })
      .catch((error) => {
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  }, []);

  const { index, navigation } = useStep({
    steps: 2,
    initialStep: 0,
  });
  const handleNextStep = () => {
    validatEmailAndPhoneNumber();
  };

  const handlePreviousStep = () => {
    navigation.previous();
  };

  const validatEmailAndPhoneNumber = () => {
    setisValidatingData(true);
    setRequestLoaderProgress(60);
    validateData({
      email: email,
      phone_number: `${phoneNumber}`,
      country_code: "234",
    })
      .then((response) => {
        setisValidatingData(false);
        setRequestLoaderProgress(100);
        if (response.data.data.email.available === false) {
          window.setError(
            "email",
            {
              type: "validate",
              message: "Email already exists",
            },
            { shouldFocus: true }
          );
        }
        if (response.data.data.phone_number.available === false) {
          window.setError(
            "phoneNumber",
            {
              type: "validate",
              message: "Phone number already exists",
            },
            { shouldFocus: true }
          );
        }
        if (
          response.data.data.email.available === false ||
          response.data.data.phone_number.available === false
        )
          return;
        navigation.next();
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setisValidatingData(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const createAccount = () => {
    setRequestLoaderProgress(50);
    setIsCreatingAccount(true);
    createUserViaInvite({
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone_number: `${phoneNumber}`,
      country_code: "234",
      job_title: jobTitle,
      password: password,
      confirm_password: confirmPassword,
      agree_terms: agreeToTerms,
      invite_ref: params.get("ref"),
      is_web: true,
    })
      .then((response) => {
        setRequestLoaderProgress(100);
        localStorage.setItem("refreshToken", response.data.data.refresh);
        dispatch(setAccessToken(response.data.data.access));
        triggerToast("Registration successful", "success");
        navigate("/app/dashboard?all_business=true");
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsCreatingAccount(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  return {
    handleNextStep,
    handlePreviousStep,
    setEmail,
    email,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phoneNumber,
    setPhoneNumber,
    jobTitle,
    setJobTitle,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    index,
    agreeToTerms,
    setAgreeToTerms,
    createAccount,
    isCreatingAccount,
    isValidatingData,
    showRegistrationFlow,
  };
};

export default InviteSignupLogic;
