import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const campaignSchema = yup
  .object({
    brandName: yup
      .string()
      .max(30, "Maximum of 30 characters is allowed")
      .required("Brand name is required"),
    brandCategory: yup.string().max(30, "Maximum of 30 characters is allowed"),
    brandDescription: yup
      .string()
      .max(500, "Maximum of 500 characters is allowed"),
    brandLogo: yup.string().required("Brand logo is required"),
    brandUrl: yup
      .string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Invalid url format!"
      )
      .required("Brand url is required"),

    campaignPointRatio: yup
      .number()
      .typeError("Invalid format")
      .required("Point equivalent is required"),
    campaignSlug: yup
      .string()
      .min(3, "Minimum of 3 characters allowed")
      .max(25, "Maximum of 25 characters allowed")
      .required("slug is required"),
    termsUrl: yup
      .string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Invalid url format!"
      )
      .required("Fill in terms and conditions url"),
  })
  .required();

export const useCampaignFormValidation = ({
  brandName,
  brandCategory,
  brandDescription,
  brandLogo,
  termsUrl,
  campaignSlug,
  campaignPointRatio,
}) => {
  return useForm({
    resolver: yupResolver(campaignSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      brandName: brandName,
      brandCategory: brandCategory,
      brandDescription: brandDescription,
      brandLogo: brandLogo,
      termsUrl: termsUrl,
      campaignSlug: campaignSlug,
      campaignPointRatio: campaignPointRatio,
    },
  });
};
