import httpClient from "../axiosBase/axiosHandler";

export const createStore = (payload) => {
  return httpClient.post("/store/", payload);
};

export const getStoreDetails = (storeId) => {
  return httpClient.get(`/store/${storeId}/`);
};

export const getStoreList = () => {
  return httpClient.get("/store/");
};

export const updateStoreDetails = (id, data) => {
  return httpClient.patch(`/store/${id}/`, data);
};

export const createCategory = (payload) => {
  return httpClient.post("/product-category/", payload);
};

export const getCategoryList = () => {
  return httpClient.get("/product-category/");
};

export const getCategoryDetails = (categoryId) => {
  return httpClient.get(`/product-category/${categoryId}`);
};

export const updateCategoryDetails = (categoryId, update) => {
  return httpClient.patch(`/product-category/${categoryId}/`, update);
};

export const deleteCategory = (categoryId) => {
  return httpClient.delete(`/product-category/${categoryId}/`);
};

export const getProductList = (queries) => {
  return httpClient.get(`/product/${queries}`);
};

export const getProduct = (productId) => {
  return httpClient.get(`/product/${productId}/`);
};

export const addSingleProduct = (productDetails) => {
  return httpClient.post("/product/", productDetails);
};

export const getProductUnits = () => {
  return httpClient.get("/product/units/");
};

export const addMultipleProducts = (file) => {
  return httpClient.post("/product/bulk-create/", file, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const updateProductDetails = (productId, data) => {
  return httpClient.patch(`/product/${productId}/`, data);
};

export const uploadProductImage = (image) => {
  return httpClient.post("/media/", image, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const deleteProduct = (productId) => {
  return httpClient.delete(`/product/${productId}/`);
};

export const getOrderList = (queries) => {
  return httpClient.get(`/order/${queries}`);
};

export const getOrderDetails = (orderId) => {
  return httpClient.get(`/order/${orderId}/`);
};

export const generateOrderReceipt = (orderId) => {
  return httpClient.post(`/order/${orderId}/generate-receipt/`);
};

export const reverseOrder = (orderId) => {
  return httpClient.post(`/order/${orderId}/cancel_order/`);
};

export const getStockHistory = (queries) => {
  return httpClient.get(`/product/stock-history/${queries}`);
};

export const getProductStockHistory = (product_id) => {
  return httpClient.get(`/product/stock-history/?product_id=${product_id}`);
};

export const restockProduct = (id, payload) => {
  return httpClient.patch(`/product/${id}/restock-product/`, payload);
};
