import primaryComponents from "../../../primaryComponents";
import "./CashPayment.scss";
import CashPaymentLogic from "./CashPaymentLogic";
import { formatCurrency } from "../../../../helpers";

const CashPayment = ({ orderDetails, closePaymentSession }) => {
  const { isConfirmingPayment, amount, payViaCash } = CashPaymentLogic({
    closePaymentSession,
    orderDetails,
  });

  return (
    <div className="cash-payment mt-5">
      <primaryComponents.InputField
        label="Please confirm that you have received the cash amount below from your customer"
        placeholder="NGN"
        isRequired={true}
        isDisabled={true}
        name="amount"
        value={formatCurrency(amount)}
        classNames="dark--text font-weight-bold"
      />

      <div className="transfer-payment__bottom">
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isLoading={isConfirmingPayment}
          loadingText="Confirming..."
          isDisabled={isConfirmingPayment}
          onClick={payViaCash}
        >
          Confirm
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default CashPayment;
