import { useState, useContext } from "react";
import { createGiftCard } from "../../../services/giftcards";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { useNavigate } from "react-router-dom";

export const useGiftcardFormLogic = ({ campaigns }) => {
  const [giftcardName, setGiftcardName] = useState("");
  const [giftcardAmount, setGiftcardAmount] = useState("");
  const [giftcardPoints, setGiftcardPoints] = useState(0);
  const [giftcardValidity, setGiftcardValidity] = useState("");
  // const [showNairaEquivalent, setShowNairaEquivalent] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const handleCreateGiftCard = () => {
    if (campaigns.length <= 0)
      return triggerToast("Please create a campaign to continue.");
    setIsSubmitting(true);
    setRequestLoaderProgress(30);
    createGiftCard({
      name: giftcardName,
      amount_in_naira: giftcardAmount,
      redemption_period: giftcardValidity,
      // show_naira_value: showNairaEquivalent,
    })
      .then(() => {
        setIsSubmitting(false);
        setRequestLoaderProgress(100);
        triggerToast("Giftcard created successfully", "success");
        navigate("/app/loyalty/manage/giftcards");
      })
      .catch((error) => {
        setIsSubmitting(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
        if (error.response.status === 422) {
          const errors = error?.response?.data?.errors;
          if (errors?.name) {
            window.setGiftCardError(
              "giftcardName",
              {
                type: "validate",
                message: errors?.name[0],
              },
              { shouldFocus: true }
            );
          }
          if (errors?.amount_in_naira) {
            window.setGiftCardError(
              "giftcardAmount",
              {
                type: "validate",
                message: errors?.amount_in_naira[0],
              },
              { shouldFocus: true }
            );
          }
          if (errors?.redemption_period) {
            window.setGiftCardError(
              "giftcardValidity",
              {
                type: "validate",
                message: errors?.redemption_period[0],
              },
              { shouldFocus: true }
            );
          }
        }
      });
  };

  return {
    giftcardPoints,
    giftcardAmount,
    giftcardName,
    giftcardValidity,
    isSubmitting,
    // showNairaEquivalent,
    // setShowNairaEquivalent,
    setGiftcardAmount,
    setGiftcardName,
    setGiftcardValidity,
    setGiftcardPoints,
    handleCreateGiftCard,
  };
};
