import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const shopDetailsSchema = yup
  .object({
    storeName: yup.string().required("Store Name is required"),
    storeAddress: yup.string().required("Store address is required"),
    subAccount: yup.string().required("Please, select your settlement account"),
  })
  .required("All fields are required");

const ShopDetailsValidation = ({ storeName, storeAddress, subAccount }) => {
  return useForm({
    resolver: yupResolver(shopDetailsSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      storeName,
      storeAddress,
      subAccount,
    },
  });
};

export default ShopDetailsValidation;
