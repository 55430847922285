import { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import {
  generateNewApiKeys,
  getApiKeys,
  revealApiKeys,
} from "../../../../services/settings";

const ApiKeysLogic = () => {
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const [refetch, setRefetch] = useState(true);
  const [maskKeys, setMaskKeys] = useState({});
  const [isKeyRevealed, setIsKeyRevealed] = useState(false);

  const { isLoading: isFetchingSecretKeys } = useQuery(
    ["secretKey"],
    () => getApiKeys(),
    {
      onError: (error) => {
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      },
      onSuccess: (response) => {
        setRefetch(false);
        setMaskKeys(response?.data?.detail);
      },
      enabled: refetch,
    }
  );

  const { isLoading: isGeneratingKeys, mutate: generateNewKey } = useMutation(
    () => generateNewApiKeys()
  );
  const generateApiKey = () => {
    setRequestLoaderProgress(40);
    generateNewKey(
      {},
      {
        onSuccess: (response) => {
          setRequestLoaderProgress(100);
          triggerToast("New secret key generated succefully", "success");
          setRefetch(true);
          setMaskKeys(response?.data?.detail);
        },
        onError: (error) => {
          setRequestLoaderProgress(100);
          if (error?.response?.status === 400)
            triggerToast(error?.response?.data?.detail, "warning");
        },
      }
    );
  };

  const { isLoading: isRevealing, mutate: revealApiKey } = useMutation(() =>
    revealApiKeys()
  );
  const revealKey = () => {
    setRequestLoaderProgress(40);
    revealApiKey(
      {},
      {
        onSuccess: (response) => {
          setRequestLoaderProgress(100);
          const keys = response?.data?.detail;
          setMaskKeys({
            masked_secret_key: keys.secret_key,
          });
        },
        onError: (error) => {
          setRequestLoaderProgress(100);
          if (error?.response?.status === 400)
            triggerToast(error?.response?.data?.detail, "warning");
        },
      }
    );
  };

  return {
    maskKeys,
    isFetchingSecretKeys,
    isGeneratingKeys,
    triggerToast,
    isKeyRevealed,
    isRevealing,
    revealKey,
    setIsKeyRevealed,
    generateApiKey,
  };
};

export default ApiKeysLogic;
