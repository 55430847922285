import "./AppLayout.scss";
import { NavLink, Outlet } from "react-router-dom";
import Logo from "../../assets/logo";
import Icon from "../../assets/icons";
import AppLayoutLogic from "./AppLayoutLogic";
import { logoutHandler } from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AppLayout = () => {
  const {
    business_name,
    business_role,
    email,
    showSidebar,
    pending_transfer_request,
    subTab,
    setSubTab,
    setShowSidebar,
  } = AppLayoutLogic();

  return (
    <>
      {business_name !== null && email !== null && (
        <div className="dashboard-layout">
          {/* sidebar section */}
          <div
            className={`dashboard-layout__sidebar ${showSidebar && "active"}`}
          >
            <div className="sidebar-section">
              {/* Dashboard layout sidebar */}
              <a href="https://www.lumibusiness.io">
                <img
                  className="sidebar-section__logo"
                  src={Logo.primary}
                  alt="lumi merchant"
                />
              </a>

              <div className="sidebar-section__content">
                {/* add sidebar links here.... */}
                <div className="sidebar-section__content-links">
                  {business_role === "CUSTOMER_SUPPORT" ||
                  business_role === "CASHIER" ? null : (
                    <>
                      <NavLink
                        className="link-item"
                        to="/app/dashboard?all_business=true"
                        onClick={() => setShowSidebar(false)}
                      >
                        <img
                          width="20px"
                          height="20px"
                          className="mr-2"
                          src={Icon.home}
                          alt="dashboard"
                        />
                        <span>Dashboard</span>
                      </NavLink>
                      <div
                        className="link-item justify-content-between"
                        style={{
                          cursor: "pointer",
                          padding: "1em 3px 1em 12px",
                        }}
                        onClick={() => {
                          if (subTab === "loyalty") return setSubTab(null);
                          setSubTab("loyalty");
                        }}
                      >
                        <div>
                          <img
                            width="20px"
                            height="20px"
                            className="mr-2"
                            src={Icon.like}
                            alt="loyalty"
                          />
                          <span>Loyalty</span>
                        </div>
                        <FontAwesomeIcon
                          className={`white--text transform-icon ${
                            subTab === "loyalty" && "active"
                          }`}
                          icon="angle-down"
                          style={{ fontSize: "18px" }}
                        />
                      </div>
                      <div
                        className={`sub-tab-body ${
                          subTab === "loyalty" && "active"
                        }`}
                      >
                        <NavLink
                          to="/app/loyalty/customers"
                          onClick={() => setShowSidebar(false)}
                          className="link-item sub-tab"
                        >
                          <img
                            width="20px"
                            height="20px"
                            className="mr-2"
                            src={Icon.user_group}
                            alt="customers"
                          />
                          <span>Customers</span>
                        </NavLink>
                        {(business_role === "OWNER" ||
                          business_role === "ADMIN" ||
                          business_role === "BUSINESS_MANAGER" ||
                          business_role === "DEVELOPER") && (
                          <NavLink
                            className="link-item sub-tab"
                            to="/app/loyalty/manage"
                            onClick={() => setShowSidebar(false)}
                          >
                            <FontAwesomeIcon
                              className={`white--text mr-2 ${
                                subTab === "loyalty" && "active"
                              }`}
                              icon="fa-regular fa-pen-to-square"
                              style={{ fontSize: "17px" }}
                            />
                            <span>Manage</span>
                          </NavLink>
                        )}
                      </div>
                    </>
                  )}

                  <div
                    className="link-item justify-content-between"
                    style={{
                      cursor: "pointer",
                      padding: "1em 3px 1em 12px",
                    }}
                    onClick={() => {
                      if (subTab === "payments") return setSubTab(null);
                      setSubTab("payments");
                    }}
                  >
                    <div>
                      <img
                        width="20px"
                        height="20px"
                        className="mr-2"
                        src={Icon.paymentIcon}
                        alt="payemnts"
                      />
                      <span>Payments</span>
                      {subTab !== "payments" &&
                        "active" &&
                        pending_transfer_request > 0 && (
                          <span className="ml-4 transfer-alert">
                            {pending_transfer_request}
                          </span>
                        )}
                    </div>
                    <FontAwesomeIcon
                      className={`white--text transform-icon ${
                        subTab === "payments" && "active"
                      }`}
                      icon="angle-down"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                  {/* sub payments section */}
                  <div
                    className={`sub-tab-body ${
                      subTab === "payments" && "active"
                    }`}
                  >
                    {(business_role === "OWNER" ||
                      business_role === "ADMIN" ||
                      business_role === "BUSINESS_MANAGER") && (
                      <NavLink
                        className="link-item sub-tab"
                        to="/app/transfers?status=accepted&all_business=true"
                        onClick={() => setShowSidebar(false)}
                      >
                        <img
                          width="20px"
                          height="20px"
                          className="mr-2"
                          src={Icon.transferIcon}
                          alt="transfers"
                        />
                        <span>Transfers</span>

                        {pending_transfer_request > 0 && (
                          <span className="ml-4 transfer-alert">
                            {pending_transfer_request}
                          </span>
                        )}
                      </NavLink>
                    )}
                    <NavLink
                      className="link-item sub-tab"
                      to="/app/transactions?all_business=true"
                      onClick={() => setShowSidebar(false)}
                    >
                      <img
                        width="20px"
                        height="20px"
                        className="mr-2"
                        src={Icon.note}
                        alt="transactions"
                      />
                      <span>Transactions</span>
                    </NavLink>
                    <NavLink
                      className="link-item sub-tab"
                      to="/app/sub-accounts"
                      onClick={() => setShowSidebar(false)}
                    >
                      <img
                        width="20px"
                        height="20px"
                        className="mr-2"
                        src={Icon.walletIcon}
                        alt="sub accounts"
                      />
                      <span>Subaccounts</span>
                    </NavLink>
                    {business_role === "CUSTOMER_SUPPORT" ||
                    business_role === "CASHIER" ? null : (
                      <>
                        <NavLink
                          className="link-item sub-tab"
                          to="/app/pos?all_business=true"
                          onClick={() => setShowSidebar(false)}
                        >
                          <img
                            width="20px"
                            height="20px"
                            className="mr-2"
                            src={Icon.terminal}
                            alt="sub accounts"
                          />
                          <span>POS</span>
                        </NavLink>
                        <NavLink
                          className="link-item sub-tab"
                          to="/app/tags"
                          onClick={() => setShowSidebar(false)}
                        >
                          <img
                            width="20px"
                            height="20px"
                            className="mr-2"
                            src={Icon.tag}
                            alt="Tags"
                          />
                          <span>Tags</span>
                        </NavLink>
                      </>
                    )}
                  </div>
                  {/* inventory section */}
                  <div
                    className="link-item justify-content-between"
                    style={{
                      cursor: "pointer",
                      padding: "1em 3px 1em 12px",
                    }}
                    onClick={() => {
                      if (subTab === "inventory") return setSubTab(null);
                      setSubTab("inventory");
                    }}
                  >
                    <div>
                      <img
                        width="20px"
                        height="20px"
                        className="mr-2"
                        src={Icon.shop}
                        alt="inventory"
                      />
                      <span>Inventory</span>
                    </div>
                    <FontAwesomeIcon
                      className={`white--text transform-icon ${
                        subTab === "inventory" && "active"
                      }`}
                      icon="angle-down"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                  {/* sub inventory section */}
                  <div
                    className={`sub-tab-body ${
                      subTab === "inventory" && "active"
                    }`}
                  >
                    <NavLink
                      className="link-item sub-tab"
                      to={{ pathname: "/store" }}
                      onClick={() => setShowSidebar(false)}
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        className={`white--text mr-2 ${
                          subTab === "loyalty" && "active"
                        }`}
                        icon="cart-shopping"
                        style={{ fontSize: "18px" }}
                      />
                      <span>Sell</span>
                    </NavLink>
                    {(business_role === "OWNER" ||
                      business_role === "ADMIN" ||
                      business_role === "BUSINESS_MANAGER") && (
                      <NavLink
                        className="link-item sub-tab"
                        to="/app/products"
                        onClick={() => setShowSidebar(false)}
                      >
                        <FontAwesomeIcon
                          className={`white--text mr-2 ${
                            subTab === "loyalty" && "active"
                          }`}
                          icon="fa-regular fa-pen-to-square"
                          style={{ fontSize: "18px" }}
                        />
                        <span>Manage</span>
                      </NavLink>
                    )}
                  </div>
                  <NavLink
                    to="/app/customers?all_business=true"
                    className="link-item"
                    onClick={() => setShowSidebar(false)}
                  >
                    <img
                      width="20px"
                      height="20px"
                      className="mr-2"
                      src={Icon.user_group}
                      alt="customers"
                    />
                    <span>Customers</span>
                  </NavLink>
                  {business_role === "CUSTOMER_SUPPORT" ||
                    (business_role === "CASHIER" ? null : (
                      <NavLink
                        to="/app/settings"
                        className="link-item"
                        onClick={() => setShowSidebar(false)}
                      >
                        <img
                          width="20px"
                          height="20px"
                          className="mr-2"
                          src={Icon.settings}
                          alt="settings"
                        />
                        <span>Settings</span>
                      </NavLink>
                    ))}
                </div>
                <hr />
                <div
                  className="sidebar-section__content-links"
                  style={{ paddingBottom: "5px" }}
                >
                  <NavLink
                    className="link-item"
                    to="/app/documentation"
                    onClick={() => setShowSidebar(false)}
                  >
                    <img
                      width="20px"
                      height="20px"
                      className="mr-2"
                      src={Icon.note}
                      alt="settings"
                    />
                    <span>Documentation</span>
                  </NavLink>

                  <span
                    className="link-item"
                    style={{ cursor: "pointer" }}
                    onClick={logoutHandler}
                  >
                    <img
                      width="20px"
                      height="20px"
                      className="mr-2"
                      src={Icon.logoutIcon}
                      alt="logout icon"
                    />
                    <span>Log Out</span>
                  </span>
                </div>
              </div>

              <div className="sidebar-section__tag">
                <div className="user-profile">
                  <div className="user-profile__avatar">
                    {business_name.charAt(0)}
                  </div>
                  <div className="mx-3 business-name">
                    <span>{business_name}</span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="sidebar-overlay"
              onClick={() => setShowSidebar(false)}
            ></div>
          </div>

          <div className="dashboard-layout__content">
            {/* show sidebar button */}
            <div className="sidebar-toggler">
              <FontAwesomeIcon
                icon="bars"
                className="ml-auto my-2"
                onClick={() => setShowSidebar(true)}
              />
            </div>
            {/* Dashboard layout content */}
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};

export default AppLayout;
