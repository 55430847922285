import { useState, useContext } from "react";
import { loginUser } from "../../../services/auth";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { useNavigate, createSearchParams } from "react-router-dom";
import { getRandomIntInclusive } from "../../../helpers";

const LoginLogic = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogging, setIsLogging] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const login = () => {
    setIsLogging(true);
    setErrorMessage(null);
    setRequestLoaderProgress(getRandomIntInclusive(15, 25));

    loginUser({
      email: email,
      password: password,
      is_web: true,
    })
      .then((response) => {
        navigate({
          pathname: "/login/otp-verification",
          search: createSearchParams({
            session_id: response.data.data.id,
            email: email,
          }).toString(),
        });
      })
      .catch((error) => {
        setIsLogging(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          setErrorMessage(error.response.data.detail);
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  return {
    email,
    password,
    login,
    setPassword,
    setEmail,
    isLogging,
    errorMessage,
  };
};
export default LoginLogic;
