/* eslint-disable no-unused-vars */
import "./assets/styles/index.scss";
import { BrowserRouter } from "react-router-dom";
import "./Fontawesome";
import secondaryComponents from "./components/secondaryComponents";
import Meta from "./Meta";
import { useEffect } from "react";

// layout imports
import layouts from "./layouts/index";
import AnimatedRoute from "./AnimatedRoutes";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();
const App = () => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_ENV === "production")
      window.console.log = () => {};
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <layouts.MainLayout>
          <secondaryComponents.ApiErrorHandler>
            <secondaryComponents.ToastMessage>
              <Meta />
              <AnimatedRoute />
            </secondaryComponents.ToastMessage>
          </secondaryComponents.ApiErrorHandler>
        </layouts.MainLayout>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
