import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { getTransferDetails } from "../../../../services/transfers";
import { ToastContext, RequestLoader } from "../../../../hooks/context";
import { getRandomIntInclusive } from "../../../../helpers";

const TransferDetailsLogic = () => {
  const [transferDetails, setTransferDetails] = useState({ customer: {} });
  const [isFetchingTransferDetails, setIsFetchingTransferDetails] =
    useState(false);
  const [copiedTransferId, setCopiedtransferId] = useState(false);
  const [openSendReceiptWidget, setOpenSendReceiptWidget] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { transferId } = useParams();

  useEffect(() => {
    setIsFetchingTransferDetails(true);
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    getTransferDetails(transferId)
      .then((response) => {
        setTransferDetails(response.data.data);
        setRequestLoaderProgress(100);
        setIsFetchingTransferDetails(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsFetchingTransferDetails(false);
        if (error.response === undefined) return;
        if (error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  }, []);

  useEffect(() => {
    if (copiedTransferId === false) return;
    setTimeout(() => {
      setCopiedtransferId(false);
    }, 5000);
  }, [copiedTransferId]);

  return {
    isFetchingTransferDetails,
    transferDetails,
    setCopiedtransferId,
    copiedTransferId,
    openSendReceiptWidget,
    setOpenSendReceiptWidget,
  };
};
export default TransferDetailsLogic;
