import "./UnverifiedAccountWidget.scss";
import primaryComponents from "../../primaryComponents";
import Images from "../../../assets/images";
import { useNavigate } from "react-router-dom";

const UnverifiedAccountWidget = ({
  message,
  verification_status = "UNVERIFIED ACCOUNT",
}) => {
  const navigate = useNavigate();

  return (
    <div className="unverified-account-widget">
      <img src={Images.error_img} alt="" />
      <h4>
        {verification_status === "unverified" && "UNVERIFIED ACCOUNT"}
        {verification_status === "pending_review" && "VERIFICATION PENDING"}
      </h4>
      <p style={{ textAlign: "center" }}>{message}</p>
      <primaryComponents.Button
        onClick={() => navigate("/app/kyb-verification")}
        classNames={"btn btn--primary"}
      >
        Verify Account
      </primaryComponents.Button>
    </div>
  );
};

export default UnverifiedAccountWidget;
