import "./RestockProduct.scss";
import primaryComponents from "../../../components/primaryComponents";
import secondaryComponents from "../../../components/secondaryComponents";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { useRestockProductLogic } from "./RestockProductLogic";

const RestockProduct = () => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    instance,
    isFetchingHistory,
    stockHistory,
    stockHistoryMeta,
    gotoPage,
    formatToDateString,
  } = useRestockProductLogic({
    product_id: params.id,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      className="restock-single-product"
    >
      <primaryComponents.Button
        classNames="btn btn--outline back__btn"
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon
          icon="angle-left"
          className="mr-3"
          style={{ fontSize: "18px" }}
        />
        <span>Back</span>
      </primaryComponents.Button>
      <div className="restock-single-product__content">
        <h4 className="font-weight-semibold">History</h4>
        <secondaryComponents.LoaderHelper
          classNames={"mt-3"}
          isLoading={isFetchingHistory}
        >
          <div className="restock-single-product__content__container">
            <div className="product-details">
              <div className="product-details__container">
                <div>
                  <span>Product Name</span>
                  <p>{stockHistory[0]?.product?.name || "N/A"}</p>
                </div>
                <div>
                  <span>Date last updated</span>
                  <p>
                    {formatToDateString(stockHistory[0]?.created_at) || "N/A"}
                  </p>
                  {/* <p>1st of January, 2023</p> */}
                </div>
              </div>
              <div className="product-details__actions">
                {/* <primaryComponents.Button
                  classNames={"btn btn--outline-gray ml-auto"}
                >
                  Filter{" "}
                  <FontAwesomeIcon
                    className="ml-3"
                    icon="angle-down"
                    style={{ fontSize: "16px" }}
                  />
                </primaryComponents.Button>
                <primaryComponents.Button classNames="btn btn--outline">
                  Download
                </primaryComponents.Button> */}
              </div>
            </div>
            <div className="product-restock-table">
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, i) => (
                        <th
                          key={i}
                          className="font-weight-semibold"
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr key={i} {...row.getRowProps({ key: i })}>
                        {row.cells.map((cell, i) => {
                          return (
                            <td key={i} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </secondaryComponents.LoaderHelper>
        {stockHistory.length > 0 && (
          <secondaryComponents.Pagination
            totalPageNumber={stockHistoryMeta.last_page}
            handlePageClick={(value) => gotoPage(value.selected + 1)}
            currentPage={stockHistoryMeta.current_page}
          />
        )}
      </div>
    </motion.div>
  );
};

export default RestockProduct;
