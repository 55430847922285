import { useTable } from "react-table";
import { useMemo } from "react";
import { convertToTilteCase, formatCurrency } from "../../../helpers";
// import { Link } from "react-router-dom";

const useTopFiveCustomersTable = ({ topCustomers }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const getRouteQuery = () => {
    if (queryParams.get("all_business") !== null) return "?all_business=true";
    else if (queryParams.get("business_id") !== null)
      return `?business_id=${queryParams.get("business_id")}`;
    else return "";
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "payer",
        Cell: ({ cell: { value } }) =>
          value ? convertToTilteCase(value) : "N/A",
        // <Link className="purple--text" to={`/app/customers/${row.original["id"]}`}
        //     style={{ fontSize: "12px", textDecoration: "underline" }}
        // >
        // </Link>
      },
      {
        Header: "No of Transactions",
        accessor: "no_of_transactions",
      },
      {
        Header: "Transaction Volume",
        accessor: "transaction_volume",
        Cell: ({ cell: { value } }) => formatCurrency(value),
      },
      {
        Header: "Time Since Last Transaction",
        accessor: "time_since_last_transaction",
        Cell: ({ cell: { value } }) =>
          value === 1 ? `${parseInt(value)} day` : `${parseInt(value)} days`,
      },
    ],
    []
  );

  const instance = useTable({ columns, data: topCustomers });

  return {
    instance,
    getRouteQuery,
  };
};

export default useTopFiveCustomersTable;
