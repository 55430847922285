import { useSelector } from "react-redux";

const SettingsLayoutLogic = () => {
  const { business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  return { business_role };
};

export default SettingsLayoutLogic;
