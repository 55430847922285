import "./SubAccounts.scss";
import { Link } from "react-router-dom";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import SubAccountsLogic from "./SubAccountsLogic";
import Images from "../../../../assets/images";
import { useState, useEffect } from "react";

const AllSubAccounts = () => {
  const {
    isFetchingAccounts,
    subAccountsList,
    getSubAccountsList,
    triggerToast,
  } = SubAccountsLogic();
  const [copiedAccount, setCopiedAccount] = useState(false);

  useEffect(() => {
    if (copiedAccount === false) return;
    setTimeout(() => {
      setCopiedAccount(false);
    }, 5000);
  }, [copiedAccount]);

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Sub Accounts"
      description="View all sub accounts created"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className="sub-accounts"
        >
          <div className="sub-accounts__add-acct-wrapper">
            <h3 className="sub-accounts__title font-weight-semibold mr-5">
              Sub-accounts
            </h3>
            <Link to="create" className="ml-auto mt-2 mt-sm-0">
              <primaryComponents.Button classNames="btn btn--primary">
                + Add Sub-account
              </primaryComponents.Button>
            </Link>
          </div>
          <div>
            <secondaryComponents.LoaderHelper
              isLoading={isFetchingAccounts}
              classNames="mt-5"
            >
              {subAccountsList.length > 0 ? (
                <div className="sub-accounts__users">
                  {subAccountsList.map((account, index) => (
                    <secondaryComponents.SubAccountCard
                      account={account}
                      index={index % 4}
                      key={account.business_id + index}
                      getSubAccountsList={getSubAccountsList}
                      triggerToast={triggerToast}
                    />
                  ))}
                </div>
              ) : (
                <div className="mt-5 text-center">
                  <img
                    width="250px"
                    className="mb-4 mx-auto"
                    src={Images.subAccountEmptyState}
                    alt="empty state"
                  />
                  <h4>You don&apos;t have any sub-account yet</h4>
                </div>
              )}
            </secondaryComponents.LoaderHelper>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default AllSubAccounts;
