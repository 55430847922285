import { useContext, useState } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { verifyBusiness, verifyBvn } from "../../../services/kyb-verification";
import {
  getLocalGovernments,
  fetchStates,
} from "../../../services/nigerian-states";

const IndividualKybFormLogic = ({ verificationData, navigation }) => {
  const [business_category, setBusinessCategory] = useState(
    verificationData?.business_category || ""
  );
  const [business_description, setBusinessDescription] = useState(
    verificationData?.business_description || ""
  );
  const [user_bvn, setUserBvn] = useState(
    verificationData?.bvn_is_verified ? verificationData?.bvn : ""
  );
  const [user_fullname, setUserFullname] = useState(
    verificationData?.business_name || ""
  );
  const [business_state, setBusinesState] = useState(
    verificationData?.state || ""
  );
  const [business_lga, setBusinessLga] = useState(verificationData?.lga || "");
  const [user_address, setUserAddress] = useState(
    verificationData?.business_address || ""
  );
  const [govt_id, setGovtId] = useState(
    verificationData?.government_issued_id || ""
  );
  const [utility_bill, setUtilityBill] = useState(
    verificationData?.utility_bill || ""
  );
  const [user_selfie, setUserSelfie] = useState(verificationData?.selfie || "");
  const [agree_terms, setAgreeTerms] = useState(
    verificationData?.terms_accepted || false
  );
  const [isOpenAgreementModal, setIsOpenAgreementModal] = useState(false);

  const [showBankVerificationOtpWidget, setShowBankVerificationOtpWidget] =
    useState(false);
  const [isVerifyingAccount, setIsVerifyingAccount] = useState(false);
  const [isVerifyingBvn, setIsVerifyingBvn] = useState(false);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const business_categories = [
    "supermarket",
    "Pharmacy",
    "Restaurant",
    "Bar",
    "Grocery",
    "Hotel",
    "Spa",
    "Technology/IT",
  ];

  const handleVerifyAccount = () => {
    if (!verificationData?.bvn_is_verified)
      return triggerToast(
        "Please ensure you have verified your BVN before proceeding",
        "warning"
      );
    setIsVerifyingAccount(true);
    verifyBusiness({
      business_type: "Individual",
      business_category: business_category,
      business_description: business_description,
      business_name: user_fullname,
      address: user_address,
      terms_accepted: agree_terms,
      state: business_state,
      lga: business_lga,
    })
      .then(() => {
        setRequestLoaderProgress(100);
        setIsVerifyingAccount(false);
        navigation.next();
        triggerToast("Account successfully verified", "success");
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsVerifyingAccount(false);
        if (error?.response?.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const handleVerifyBvn = (user_bvn) => {
    setIsVerifyingBvn(true);
    setRequestLoaderProgress(40);
    verifyBvn({ bvn: user_bvn })
      .then(() => {
        setIsVerifyingBvn(false);
        setRequestLoaderProgress(100);
        setShowBankVerificationOtpWidget(true);
      })
      .catch((error) => {
        setIsVerifyingAccount(false);
        setIsVerifyingBvn(false);
        setRequestLoaderProgress(100);
        if (error?.response?.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  return {
    business_category,
    setBusinessCategory,
    business_categories,
    business_description,
    setBusinessDescription,
    user_bvn,
    setUserBvn,
    user_fullname,
    setUserFullname,
    user_address,
    setUserAddress,
    govt_id,
    setGovtId,
    utility_bill,
    setUtilityBill,
    user_selfie,
    setUserSelfie,
    agree_terms,
    setAgreeTerms,
    showBankVerificationOtpWidget,
    setShowBankVerificationOtpWidget,
    isVerifyingAccount,
    setIsVerifyingAccount,
    isVerifyingBvn,
    setIsVerifyingBvn,
    handleVerifyAccount,
    handleVerifyBvn,
    business_state,
    business_lga,
    setBusinesState,
    setBusinessLga,
    isOpenAgreementModal,
    setIsOpenAgreementModal,
    nigerian_states: fetchStates() || [],
    local_government_areas: getLocalGovernments(business_state),
    verification_status:
      verificationData?.verification_status === "pending_review" ||
      verificationData?.verification_status === "verified",
  };
};

export default IndividualKybFormLogic;
