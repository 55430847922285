import "./Users.scss";
import { useState } from "react";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";

const Users = () => {
  const [showInviteModal, setShowInviteModal] = useState(false);

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Settings - Users"
      description="Add, edit and delete users that can have access to your lumi merchant account"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          <div className="users-page">
            <div className="users-page__nav">
              <primaryComponents.Button
                onClick={() => setShowInviteModal(true)}
                classNames={"btn btn--primary ml-auto"}
                data-testId="invite-button"
              >
                <span className="mr-2" style={{ fontSize: "18px" }}>
                  +
                </span>
                <span>Invite User</span>
              </primaryComponents.Button>
            </div>
            {/* table section */}
            <div className="users-page__content">
              <secondaryComponents.UsersTable />
            </div>
          </div>

          {/* Invite modal section */}
          {showInviteModal && (
            <secondaryComponents.InviteUser
              showInviteModal={showInviteModal}
              setShowInviteModal={setShowInviteModal}
            />
          )}
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default Users;
