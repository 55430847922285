/* eslint-disable react/jsx-key */
import "./CustomersTable.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import useCustomersTable from "./CustomersTableLogic";
import Images from "../../../assets/images";

const CustomersTable = ({ isRefresh, setIsRefresh }) => {
  const {
    instance,
    isFetchingCustomers,
    customers,
    gotoPage,
    customersPageDetails,
    searchCustomer,
    searchValue,
  } = useCustomersTable({ isRefresh, setIsRefresh });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="customers-table">
      <div className="customers-table__content">
        <div className="customers-table__content__header">
          <div style={{ width: "700px" }}>
            <primaryComponents.SearchField
              placeholder="Search customer by name or email"
              trigger={(value) => searchCustomer(value)}
              value={searchValue}
            />
          </div>
        </div>
        <div className="table-section">
          <secondaryComponents.LoaderHelper
            isLoading={isFetchingCustomers}
            classNames="mt-5"
          >
            {customers.length ? (
              <>
                {/* show on desktop */}
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => {
                          return (
                            <th
                              className="font-weight-semibold"
                              {...column.getHeaderProps()}
                            >
                              {column.render("Header")}
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps({ key: i })}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                {/* display on mobile */}
                <div className="mobile-table">
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <div
                        className="mobile-table__row"
                        {...row.getRowProps({ key: i })}
                      >
                        {row.cells.map((cell, j) => (
                          <div
                            className="row-item"
                            {...cell.getCellProps({ key: j })}
                          >
                            <h6 className="row-item__title">
                              {cell.column.Header}
                            </h6>
                            <p className="row-item__value">
                              {cell.render("Cell")}
                            </p>
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div className="text-center mt-5">
                <img
                  src={Images.customersEmptyState}
                  alt="No Customers Found"
                  width="250px"
                  height="262px"
                />
                <h4>You have no customers</h4>
                <p>When you do, they would appear here.</p>
              </div>
            )}
          </secondaryComponents.LoaderHelper>
        </div>
        {/* show pagination component only when data is available */}
        {customers.length > 0 && (
          <secondaryComponents.Pagination
            totalPageNumber={customersPageDetails.last_page}
            handlePageClick={(value) => gotoPage(value.selected + 1)}
            currentPage={customersPageDetails.current_page}
          />
        )}
      </div>
    </div>
  );
};

export default CustomersTable;
