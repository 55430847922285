import "./IndividualKYBForm.scss";
import { motion } from "framer-motion";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import { Controller } from "react-hook-form";
import IndividualKybFormLogic from "./IndividualKYBFormLogic";
import useIndividualKybFormValidation from "./IndividualKYBFormValidation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DOCUMENT_UPLOAD_STATUS } from "../../../helpers/constants";

const IndividualKYBForm = ({
  verificationData,
  uploadDocument,
  navigation,
  fetchDocuments,
}) => {
  const {
    business_category,
    setBusinessCategory,
    business_categories,
    business_description,
    setBusinessDescription,
    user_bvn,
    setUserBvn,
    user_fullname,
    setUserFullname,
    user_address,
    setUserAddress,
    govt_id,
    setGovtId,
    utility_bill,
    setUtilityBill,
    user_selfie,
    setUserSelfie,
    agree_terms,
    setAgreeTerms,
    showBankVerificationOtpWidget,
    setShowBankVerificationOtpWidget,
    isVerifyingBvn,
    handleVerifyBvn,
    verification_status,
    handleVerifyAccount,
    isVerifyingAccount,
    business_state,
    setBusinesState,
    business_lga,
    setBusinessLga,
    nigerian_states,
    local_government_areas,
    isOpenAgreementModal,
    setIsOpenAgreementModal,
  } = IndividualKybFormLogic({ verificationData, navigation });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useIndividualKybFormValidation({
    business_category,
    business_description,
    user_bvn,
    user_fullname,
    user_address,
    govt_id,
    agree_terms,
    business_lga,
    business_state,
  });

  return (
    <motion.form
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.8 } }}
      exit={{ opacity: 0 }}
      className="individual-kyb-form"
      onSubmit={handleSubmit(handleVerifyAccount)}
    >
      <div className="individual-kyb-form__group">
        <h4 className="font-weight-semibold">Business Category</h4>
        <Controller
          defaultValue={business_category}
          name="business_category"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              options={business_categories}
              errorMessage={errors.business_category}
              placeholder="Select your business category"
              value={
                business_category
                  ? business_category
                  : "Select your business category"
              }
              onChange={(value) => {
                onChange(value);
                setBusinessCategory(value);
              }}
              isDisabled={verification_status}
            />
          )}
        />
      </div>
      <div className="individual-kyb-form__group">
        <h4 className="font-weight-semibold">
          Describe the nature of your business
        </h4>
        <Controller
          defaultValue={business_description}
          name="business_description"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.TextArea
              placeholder="My business is a"
              errorMessage={errors.business_description}
              value={business_description}
              onChange={({ target: { value } }) => {
                onChange(value);
                setBusinessDescription(value);
              }}
              isDisabled={business_description !== "" && verification_status}
            />
          )}
        />
      </div>
      <div
        className="individual-kyb-form__group"
        style={{ position: "relative" }}
      >
        <h4 className="font-weight-semibold">BVN</h4>
        <Controller
          defaultValue={user_bvn}
          name="user_bvn"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              placeholder="Enter your 11-digit BVN"
              errorMessage={errors.user_bvn}
              value={user_bvn}
              onChange={({ target: { value } }) => {
                if (value.length === 11) handleVerifyBvn(value);
                onChange(value);
                setUserBvn(value);
              }}
              isDisabled={verificationData?.bvn_is_verified}
            />
          )}
        />
        {isVerifyingBvn && (
          <FontAwesomeIcon
            style={{
              position: "absolute",
              right: "15px",
              top: "51%",
              transform: "translateY(-50%)",
              color: "#7647EE",
            }}
            icon={["fas", "spinner"]}
            spin
          />
        )}
        {verificationData?.bvn_is_verified && (
          <FontAwesomeIcon
            style={{
              position: "absolute",
              right: "15px",
              top: "60%",
              transform: "translateY(-50%)",
              color: "#7647EE",
            }}
            icon={["fa", "check"]}
          />
        )}
      </div>
      {showBankVerificationOtpWidget && (
        <div>
          <secondaryComponents.BankVerificationOtpWidget
            setShowBankVerificationOtpWidget={setShowBankVerificationOtpWidget}
            fetchDocuments={fetchDocuments}
          />
        </div>
      )}
      <div className="individual-kyb-form__group">
        <h4 className="font-weight-semibold">User&apos;s Full Name</h4>
        <Controller
          defaultValue={user_fullname}
          name="user_fullname"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              placeholder="Enter your name and surname"
              errorMessage={errors.user_fullname}
              value={user_fullname}
              onChange={({ target: { value } }) => {
                onChange(value);
                setUserFullname(value);
              }}
              isDisabled={user_fullname !== "" && verification_status}
            />
          )}
        />
      </div>
      <div className="individual-kyb-form__group">
        <h4 className="font-weight-semibold">State</h4>
        <Controller
          defaultValue={business_state}
          name="business_state"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              placeholder="Select State"
              value={business_state ? business_state : "Select State"}
              options={nigerian_states}
              errorMessage={errors.business_state}
              onChange={(value) => {
                onChange(value);
                setBusinesState(value);
              }}
              isDisabled={business_state !== "" && verification_status}
            />
          )}
        />
      </div>
      <div className="individual-kyb-form__group">
        <h4 className="font-weight-semibold">Local Government</h4>
        <Controller
          defaultValue={business_lga}
          name="business_lga"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              placeholder="Select Local Government"
              value={business_lga ? business_lga : "Select Local Government"}
              options={local_government_areas}
              errorMessage={errors.business_lga}
              onChange={(value) => {
                onChange(value);
                setBusinessLga(value);
              }}
              isDisabled={business_lga !== "" && verification_status}
            />
          )}
        />
      </div>
      <div className="individual-kyb-form__group">
        <h4 className="font-weight-semibold">User&apos;s Address</h4>
        <Controller
          defaultValue={user_address}
          name="user_address"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              placeholder="Enter your street address"
              errorMessage={errors.user_address}
              value={user_address}
              onChange={({ target: { value } }) => {
                onChange(value);
                setUserAddress(value);
              }}
              isDisabled={user_address !== "" && verification_status}
            />
          )}
        />
      </div>
      <div className="individual-kyb-form__group">
        <h4 className="font-weight-semibold">
          Government Issued ID (eg. NIN Slip, International Passport,
          Driver&apos;s License)
          {govt_id !== undefined && (
            <span
              className={
                "document-upload-status " +
                DOCUMENT_UPLOAD_STATUS[govt_id?.status]
              }
            >
              {govt_id?.status?.split("_")?.join(" ")}
            </span>
          )}
        </h4>
        <Controller
          defaultValue={govt_id}
          name="govt_id"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.FileInput
              name="govt_id"
              onChange={(document) => {
                onChange(document);
                setGovtId(document);
                if (document) {
                  const payload = {
                    type: "government_issued_id",
                    file: document,
                  };
                  uploadDocument(payload);
                }
              }}
              isDisabled={
                DOCUMENT_UPLOAD_STATUS[govt_id?.status] === "pending" ||
                DOCUMENT_UPLOAD_STATUS[govt_id?.status] === "approved"
              }
            />
          )}
        />
      </div>
      <div className="individual-kyb-form__group">
        <h4 className="font-weight-semibold">
          Utility Bill (eg. Power bill, receipt for household items showing your
          address)
          {utility_bill !== undefined && (
            <span
              className={
                "document-upload-status " +
                DOCUMENT_UPLOAD_STATUS[utility_bill?.status]
              }
            >
              {utility_bill?.status?.split("_")?.join(" ")}
            </span>
          )}
        </h4>
        <Controller
          defaultValue={utility_bill}
          name="utility_bill"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.FileInput
              name="utility_bill"
              onChange={(document) => {
                onChange(document);
                setUtilityBill(document);
                if (document) {
                  const payload = {
                    type: "utility_bill",
                    file: document,
                  };
                  uploadDocument(payload);
                }
              }}
              isDisabled={
                DOCUMENT_UPLOAD_STATUS[utility_bill?.status] === "pending" ||
                DOCUMENT_UPLOAD_STATUS[utility_bill?.status] === "approved"
              }
            />
          )}
        />
      </div>
      <div className="individual-kyb-form__group">
        <h4 className="font-weight-semibold">
          Your Selfie Photo (Please upload a clear picture of yourself)
          {user_selfie !== undefined && (
            <span
              className={
                "document-upload-status " +
                DOCUMENT_UPLOAD_STATUS[user_selfie?.status]
              }
            >
              {user_selfie?.status?.split("_")?.join(" ")}
            </span>
          )}
        </h4>
        <Controller
          defaultValue={user_selfie}
          name="user_selfie"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.FileInput
              name="user_selfie"
              onChange={(document) => {
                onChange(document);
                setUserSelfie(document);
                if (document) {
                  const payload = {
                    type: "selfie",
                    file: document,
                  };
                  uploadDocument(payload);
                }
              }}
              isDisabled={
                DOCUMENT_UPLOAD_STATUS[user_selfie?.status] === "pending" ||
                DOCUMENT_UPLOAD_STATUS[user_selfie?.status] === "approved"
              }
            />
          )}
        />
      </div>
      <div className="individual-kyb-form__group d-flex align-items-center">
        <primaryComponents.Checkbox
          name="agreeToTerms"
          id="agreeToTerms"
          isChecked={isOpenAgreementModal}
          onChange={(value) => {
            setIsOpenAgreementModal(value);
          }}
        />
        <label
          htmlFor="agreeToTerms"
          style={{ fontSize: "16px", marginLeft: "10px" }}
        >
          I agree to Enlumi Data&apos;s{" "}
          <a
            href="https://www.lumibusiness.io/terms-and-conditions"
            target="_blank"
            rel="noreferrer noopener"
            className="purple--text"
          >
            Service Level Agreement
          </a>
        </label>
      </div>
      <div className="individual-kyb-form__group d-flex align-items-center">
        <primaryComponents.Button
          classNames={"btn btn--primary ml-auto"}
          isDisabled={!agree_terms || verification_status}
          isLoading={isVerifyingAccount}
          loadingText="verifying account..."
        >
          Submit
        </primaryComponents.Button>
      </div>
      {isOpenAgreementModal && (
        <secondaryComponents.ServiceLevelAgreementModal
          isActive={isOpenAgreementModal}
          setIsActive={setIsOpenAgreementModal}
          setAgreeTerms={setAgreeTerms}
        />
      )}
    </motion.form>
  );
};

export default IndividualKYBForm;
