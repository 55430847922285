import { COLORS } from "../../../helpers/constants";
import { useState, useEffect } from "react";
const SpendAnalyticsLogic = ({ tagsData }) => {
  const [sumOfTags, setSumOfTags] = useState(0);
  const data = tagsData.spend_tags;

  useEffect(() => {
    calculateSumOfTags();
  }, [tagsData]);

  const calculateSumOfTags = () => {
    let sum = tagsData.spend_tags.reduce((accumulator, item) => {
      return accumulator + item.total;
    }, 0);
    setSumOfTags(sum);
  };

  return {
    data,
    COLORS,
    sumOfTags,
  };
};

export default SpendAnalyticsLogic;
