// import all logos and export them as an object
import enlumi_logo_white from "./enlumi-primary-logo.svg";
import enlumi_logo_purple from "./enlumi-secondary-logo.svg";

const Logo = {
  primary: enlumi_logo_white,
  secondary: enlumi_logo_purple,
};

export default Logo;
