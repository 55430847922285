import { useTable } from "react-table";
import { useContext, useState, useMemo, useEffect } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { getProductStockHistory } from "../../../services/inventory";
import {
  formatCurrency,
  formatDate,
  getRandomIntInclusive,
  getUrlQuerysection,
} from "../../../helpers";
import { useNavigate } from "react-router-dom";
import { MONTHS_OF_YEAR_FULL } from "../../../helpers/constants";

export const useRestockProductLogic = ({ product_id }) => {
  const [stockHistory, setStockHistory] = useState([]);
  const [stockHistoryMeta, setStockHistoryMeta] = useState(null);
  const [isFetchingHistory, setIsFetchingHistory] = useState(false);

  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: "Variant",
        accessor: "variant",
        Cell: ({ cell: { value } }) =>
          `${value?.option}-${value?.value}` || "N/A",
      },
      {
        Header: "Stock type",
        accessor: "type",
        Cell: ({ cell: { value } }) => (
          <span
            style={{ color: value === "NEW_STOCK" ? "#19AE57" : "#E02020" }}
          >
            {value === "NEW_STOCK" ? "New Stock" : "Sold Stock"}
          </span>
        ),
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Quantity Before",
        accessor: "quantity_before",
      },
      {
        Header: "Quantity After",
        accessor: "quantity_after",
      },
      {
        Header: "Cost Price (N)",
        accessor: "cost_price",
        Cell: ({ cell: { value } }) => formatCurrency(value),
      },
      {
        Header: "Selling Price",
        accessor: "selling_price",
        Cell: ({ cell: { value } }) => formatCurrency(value),
      },
      {
        Header: "Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => formatDate(value),
      },
    ],
    []
  );

  useEffect(() => {
    handleProductFetchStockHistory();
  }, []);

  const handleProductFetchStockHistory = () => {
    setRequestLoaderProgress(getRandomIntInclusive(20, 40));
    setIsFetchingHistory(true);
    getProductStockHistory(product_id)
      .then((response) => {
        setRequestLoaderProgress(100);
        setIsFetchingHistory(false);
        setStockHistory(response.data?.data);
        setStockHistoryMeta(response.data?.meta);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsFetchingHistory(false);

        if (error.response) {
          const { status } = error.response;
          const { detail } = error.response.data;
          if (status === 400) {
            triggerToast(detail, "warning");
          }
        }
      });
  };

  const gotoPage = (pageNumber) => {
    let queries = getUrlQuerysection();
    let page = queryParams.get("page");
    if (page === null) {
      let symbol = queries.includes("?") ? "&" : "?";
      navigate(`${queries}${symbol}page=${pageNumber}`);
      return handleProductFetchStockHistory();
    }

    let splitedQuery = queries.split(`page=${page}`);
    splitedQuery = splitedQuery[0] + `page=${pageNumber}` + splitedQuery[1];
    navigate(`${splitedQuery}`);
    handleProductFetchStockHistory();
  };

  const formatToDateString = (date) => {
    const _date = new Date(date);
    const day = _date.getDay();
    const month = MONTHS_OF_YEAR_FULL[_date.getMonth()];
    const year = _date.getFullYear();

    return `${day} of ${month}, ${year}`;
  };

  const instance = useTable({ columns, data: stockHistory });

  return {
    instance,
    stockHistory,
    isFetchingHistory,
    gotoPage,
    stockHistoryMeta,
    formatToDateString,
  };
};
