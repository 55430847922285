import { useState, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { redeemGiftCard } from "../../../services/giftcards";
import { useStep } from "react-hooks-helper";

export const useRedeemGiftCardLogic = () => {
  const [redemption_code, setRedemptionCode] = useState("");
  const [giftcardDetails, setGiftcardDetails] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { index, navigation } = useStep({ initialStep: 0, steps: 2 });

  const handleRedeemGiftCard = () => {
    redeemGiftCard(redemption_code)
      .then((response) => {
        const {
          points,
          giftcard_naira_value,
          customer_name,
          gender,
          phone,
          giftcard_name,
        } = response.data?.data;
        setGiftcardDetails({
          points,
          giftcard_naira_value,
          customer_name,
          gender,
          phone,
          giftcard_name,
        });
        setIsSubmitting(false);
        setRequestLoaderProgress(100);
        triggerToast("Gift card purchase successful", "success");
        // navigate("/app/customers/history");
        navigation.next();
      })
      .catch((error) => {
        setIsSubmitting(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  return {
    handleRedeemGiftCard,
    redemption_code,
    setRedemptionCode,
    isSubmitting,
    step: index,
    navigation,
    giftcardDetails,
  };
};
