import "./TransfersLayout.scss";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TransfersLayout = () => {
  const { business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const naviagte = useNavigate();

  useEffect(() => {
    if (
      business_role !== "OWNER" &&
      business_role !== "ADMIN" &&
      business_role !== "BUSINESS_MANAGER"
    )
      return naviagte("/app/dashboard");
  }, []);

  return (
    <div className="transfers-layout">
      <Outlet />
    </div>
  );
};
export default TransfersLayout;
