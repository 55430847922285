import "./AllCustomers.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import AllCustomersLogic from "./AllCustomersLogic";

const AllCustomers = () => {
  const {
    business_list,
    isRefresh,
    setIsRefresh,
    showAllBusiness,
    filterBaseOnAccount,
    newBusinessId,
  } = AllCustomersLogic();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Customers"
      description="View all customers"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          <h3 className="font-weight-semibold">Customers</h3>

          <secondaryComponents.SubAccountsList
            businessList={business_list}
            showAllBusiness={showAllBusiness}
            filterBaseOnAccount={filterBaseOnAccount}
            newBusinessId={newBusinessId}
          />

          <secondaryComponents.CustomersTable
            isRefresh={isRefresh}
            setIsRefresh={setIsRefresh}
          />
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default AllCustomers;
