import { motion } from "framer-motion";
import primaryComponents from "../../primaryComponents";
import Icon from "../../../assets/icons";
import { formatCurrency, formatDate } from "../../../helpers";
import { Controller } from "react-hook-form";
import { useEffect } from "react";

const RestockProductWidgetVariants = ({
  closeWidget,
  selectedProduct,
  control,
  variants,
  newVariants,
  setVariants,
  setNewVariants,
  handleAddVariantField,
  handleRemoveVariantField,
}) => {
  useEffect(() => {
    if (!variants.length && !newVariants.length) {
      handleAddVariantField();
    }
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0 }}
    >
      <h4 style={{ fontSize: "20px" }}>Variants</h4>
      <div className="product-restock-variant-list-section">
        {variants.map((variant, index) => (
          <div
            key={variant?.option + index}
            className="product-restock-variant-list-section__item"
          >
            <div className="flex-fill">
              <Controller
                name={`variants.${index}.option`}
                control={control}
                render={({ field: { onChange } }) => (
                  <primaryComponents.InputField
                    placeholder={
                      "Enter your option eg. size, color, flavor etc"
                    }
                    classNames="white"
                    name={`variants.${index}.option`}
                    value={variant?.option || ""}
                    onChange={({ target: { value } }) => {
                      onChange(value);
                      const _variants = [...variants];
                      _variants[index].option = value;

                      setVariants(_variants);
                    }}
                  />
                )}
              />
              <div
                style={{ gap: "32px" }}
                className="d-flex align-items-center"
              >
                <Controller
                  name={`variants.${index}.cost_price`}
                  control={control}
                  defaultValue={variant?.cost_price || ""}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.InputField
                      label="Cost Price"
                      classNames="white"
                      name={`variants.${index}.cost_price`}
                      value={variant?.cost_price || ""}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        const _variants = [...variants];
                        _variants[index].cost_price = value;

                        setVariants(_variants);
                      }}
                    />
                  )}
                />
                <Controller
                  name={`variants.${index}.selling_price`}
                  control={control}
                  defaultValue={variant?.selling_price || ""}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.InputField
                      label="Selling Price"
                      classNames="white"
                      name={`variants.${index}.selling_price`}
                      value={variant?.selling_price || ""}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        const _variants = [...variants];
                        _variants[index].selling_price = value;

                        setVariants(_variants);
                      }}
                    />
                  )}
                />
                <Controller
                  name={`variants.${index}.stock_quantity`}
                  control={control}
                  defaultValue={variant?.stock_quantity || ""}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.StockUnitInput
                      label="Quantity"
                      name={`variants.${index}.stock_quantity`}
                      value={variant?.stock_quantity || ""}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        const _variants = [...variants];
                        _variants[index].stock_quantity = value;

                        setVariants(_variants);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex-fill">
              <div className="d-flex align-items-center justify-content-between">
                <Controller
                  name={`variants.${index}.value`}
                  control={control}
                  defaultValue={variant?.value || ""}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.InputField
                      placeholder={
                        "Enter your value eg. size, color, flavor etc"
                      }
                      classNames="white"
                      name={`variants.${index}.value`}
                      value={variant?.value || ""}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        const _variants = [...variants];
                        _variants[index].value = value;

                        setVariants(_variants);
                      }}
                    />
                  )}
                />
                <button type="button" className="variant-delete-icon">
                  <img src={Icon.deleteIcon} alt="" />
                </button>
              </div>
              <div
                style={{ gap: "32px" }}
                className="d-flex align-items-center"
              >
                <div className="profit-margin-input">
                  <label htmlFor="profit_margin_container">Profit</label>
                  <div className="profit_margin_container">
                    {formatCurrency(
                      variant?.selling_price - variant?.cost_price
                    )}
                  </div>
                </div>
                <Controller
                  name={`variants.${index}.expiry_date`}
                  control={control}
                  defaultValue={variant?.expiry_date || ""}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.DateInput
                      label="Expiry Date"
                      classNames="white"
                      name={`variants.${index}.expiry_date`}
                      value={variant?.expiry_date || ""}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        const _variants = [...variants];
                        _variants[index].expiry_date = formatDate(value);

                        setVariants(_variants);
                      }}
                    />
                  )}
                />
                <Controller
                  name={`variants.${index}.minimum_stock_quantity`}
                  control={control}
                  defaultValue={variant?.minimum_stock_quantity || ""}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.InputField
                      label="Stock Level Alert"
                      classNames="white"
                      name={`variants.${index}.minimum_stock_quantity`}
                      value={variant?.minimum_stock_quantity || ""}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        const _variants = [...variants];
                        _variants[index].minimum_stock_quantity = value;

                        setVariants(_variants);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        ))}
        {newVariants.map((variant, index) => (
          <div
            key={index}
            className="product-restock-variant-list-section__item"
          >
            <div className="flex-fill">
              <Controller
                name={`new_variants.${index}.option`}
                control={control}
                render={({ field: { onChange } }) => (
                  <primaryComponents.InputField
                    placeholder={
                      "Enter your option eg. size, color, flavor etc"
                    }
                    classNames="white"
                    name={`new_variants.${index}.option`}
                    value={variant?.option || ""}
                    onChange={({ target: { value } }) => {
                      onChange(value);
                      const _variants = [...newVariants];
                      _variants[index].option = value;

                      setNewVariants(_variants);
                    }}
                  />
                )}
              />
              <div
                style={{ gap: "32px" }}
                className="d-flex align-items-center"
              >
                <Controller
                  name={`new_variants.${index}.cost_price`}
                  control={control}
                  defaultValue={variant?.cost_price || ""}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.InputField
                      label="Cost Price"
                      classNames="white"
                      name={`new_variants.${index}.cost_price`}
                      value={variant?.cost_price || ""}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        const _variants = [...newVariants];
                        _variants[index].cost_price = value;

                        setNewVariants(_variants);
                      }}
                    />
                  )}
                />
                <Controller
                  name={`new_variants.${index}.selling_price`}
                  control={control}
                  defaultValue={variant?.selling_price || ""}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.InputField
                      label="Selling Price"
                      classNames="white"
                      name={`new_variants.${index}.selling_price`}
                      value={variant?.selling_price || ""}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        const _variants = [...newVariants];
                        _variants[index].selling_price = value;

                        setNewVariants(_variants);
                      }}
                    />
                  )}
                />
                <Controller
                  name={`new_variants.${index}.stock_quantity`}
                  control={control}
                  defaultValue={variant?.stock_quantity || ""}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.StockUnitInput
                      label="Quantity"
                      name={`new_variants.${index}.stock_quantity`}
                      value={variant?.stock_quantity || ""}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        const _variants = [...newVariants];
                        _variants[index].stock_quantity = value;

                        setNewVariants(_variants);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex-fill">
              <div className="d-flex align-items-center justify-content-between">
                <Controller
                  name={`new_variants.${index}.value`}
                  control={control}
                  defaultValue={variant?.value || ""}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.InputField
                      placeholder={
                        "Enter your value eg. size, color, flavor etc"
                      }
                      classNames="white"
                      name={`new_variants.${index}.value`}
                      value={variant?.value || ""}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        const _variants = [...newVariants];
                        _variants[index].value = value;

                        setNewVariants(_variants);
                      }}
                    />
                  )}
                />
                <button
                  onClick={() => handleRemoveVariantField(index)}
                  type="button"
                  className="variant-delete-icon"
                >
                  <img src={Icon.deleteIcon} alt="" />
                </button>
              </div>
              <div
                style={{ gap: "32px" }}
                className="d-flex align-items-center"
              >
                <div className="profit-margin-input">
                  <label htmlFor="profit_margin_container">Profit</label>
                  <div className="profit_margin_container">
                    {formatCurrency(
                      variant?.selling_price - variant?.cost_price
                    )}
                  </div>
                </div>
                <Controller
                  name={`new_variants.${index}.expiry_date`}
                  control={control}
                  defaultValue={variant?.expiry_date || ""}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.DateInput
                      label="Expiry Date"
                      classNames="white"
                      name={`new_variants.${index}.expiry_date`}
                      value={variant?.expiry_date || ""}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        const _variants = [...newVariants];
                        _variants[index].expiry_date = formatDate(value);

                        setNewVariants(_variants);
                      }}
                    />
                  )}
                />
                <Controller
                  name={`new_variants.${index}.minimum_stock_quantity`}
                  control={control}
                  defaultValue={variant?.minimum_stock_quantity || ""}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.InputField
                      label="Stock Level Alert"
                      classNames="white"
                      name={`new_variants.${index}.minimum_stock_quantity`}
                      value={variant?.minimum_stock_quantity || ""}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        const _variants = [...newVariants];
                        _variants[index].minimum_stock_quantity = value;

                        setNewVariants(_variants);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        ))}
        {/* <div className="product-restock-variant-list-section__item">
          <div className="flex-fill">
            <primaryComponents.InputField
              placeholder={"Enter your option eg. size, color, flavor etc"}
              classNames="white"
              name={""}
              value={""}
              onChange={({ target: { value } }) => {}}
            />
            <div style={{ gap: "32px" }} className="d-flex align-items-center">
              <primaryComponents.InputField
                label="Cost Price"
                classNames="white"
                name={""}
                value={""}
                onChange={({ target: { value } }) => {}}
              />
              <primaryComponents.InputField
                label="Selling Price"
                classNames="white"
                name={""}
                value={""}
                onChange={({ target: { value } }) => {}}
              />
              <primaryComponents.StockUnitInput
                label="Quantity"
                name={""}
                value={""}
                onChange={({ target: { value } }) => {}}
              />
            </div>
          </div>
          <div className="flex-fill">
            <div className="d-flex align-items-center justify-content-between">
              <primaryComponents.InputField
                placeholder={"Enter your value eg. size, color, flavor etc"}
                classNames="white"
                name={""}
                value={""}
                onChange={({ target: { value } }) => {}}
              />
              <button type="button" className="variant-delete-icon">
                <img src={Icon.deleteIcon} alt="" />
              </button>
            </div>
            <div style={{ gap: "32px" }} className="d-flex align-items-center">
              <div className="profit-margin-input">
                <label htmlFor="profit_margin_container">Profit</label>
                <div className="profit_margin_container">
                  {formatCurrency(400)}
                </div>
              </div>
              <primaryComponents.DateInput
                label="Expiry Date"
                classNames="white"
                name={""}
                value={""}
                onChange={({ target: { value } }) => {}}
              />
              <primaryComponents.InputField
                label="Stock Level Alert"
                classNames="white"
                name={""}
                value={""}
                onChange={({ target: { value } }) => {}}
              />
            </div>
          </div>
        </div> */}
        <button
          type="button"
          className="d-flex align-items-center my-4 add-variant-button"
          onClick={handleAddVariantField}
        >
          <img src={Icon.add_blue} alt="" width={"10px"} height={"10px"} />
          <span>Add another option</span>
        </button>
      </div>
    </motion.div>
  );
};

export default RestockProductWidgetVariants;
