import "./ProductsPage.scss";
import secondaryComponents from "../../../components/secondaryComponents";
import ProductsPageLogic from "./ProductsPageLogic";
import Images from "../../../assets/images";
import { motion } from "framer-motion";

const ProductsPage = () => {
  const {
    products,
    isfetchingProducts,
    refetchCart,
    updatingCart,
    selectedItemId,
    queryParams,
    productPageDetails,
    cartItems,
    getCartItems,
    setRefetchCart,
    handleAddToCart,
    handleReduceCartItem,
    closeStoreCheckout,
    setRefreshProducts,
    gotoPage,
  } = ProductsPageLogic();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Store"
      description="View all store products and sell to customers"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <div className="store-products-page">
          {/* product list section */}
          <div className="products-list">
            <secondaryComponents.LoaderHelper
              isLoading={isfetchingProducts}
              classNames="mt-3"
            >
              {products.length > 0 ? (
                <>
                  {products?.map((product, index) => (
                    <div key={product?.id}>
                      {product.variants?.length > 0 ? (
                        <secondaryComponents.ProductVariantCard
                          product={product}
                          key={product?.id}
                          updatingCart={updatingCart}
                          selectedItemId={selectedItemId}
                          handleAddToCart={handleAddToCart}
                          cartItems={cartItems}
                          handleReduceCartItem={handleReduceCartItem}
                          productIndex={index}
                        />
                      ) : (
                        <secondaryComponents.ProductCard
                          product={product}
                          key={product?.id}
                          updatingCart={updatingCart}
                          selectedItemId={selectedItemId}
                          cartDetails={getCartItems(product?.id)}
                          handleAddToCart={handleAddToCart}
                          handleReduceCartItem={handleReduceCartItem}
                          productIndex={index}
                        />
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <div style={{ marginTop: "100px" }} className="text-center">
                  <img src={Images.productsEmptyState} alt="" width={"250px"} />
                  <h5 className="mt-3 text-center">No data available</h5>
                </div>
              )}

              {/* pagination section */}
              {products.length > 0 && (
                <div className="mt-auto">
                  <secondaryComponents.Pagination
                    totalPageNumber={productPageDetails.last_page}
                    handlePageClick={(value) => gotoPage(value.selected + 1)}
                    currentPage={productPageDetails.current_page}
                  />
                </div>
              )}
            </secondaryComponents.LoaderHelper>
          </div>

          {/* cart section */}
          <motion.div
            initial={{ x: 100 }}
            animate={{ x: 0 }}
            className="cart-section"
          >
            <secondaryComponents.StoreCart
              refetchCart={refetchCart}
              setRefetchCart={setRefetchCart}
              updatingCart={updatingCart}
              handleAddToCart={handleAddToCart}
              handleReduceCartItem={handleReduceCartItem}
              selectedItemId={selectedItemId}
              setRefreshProducts={setRefreshProducts}
            />
          </motion.div>

          {/* store checkout */}
          <secondaryComponents.SideModal
            isActive={queryParams.get("order_id") !== null}
            closeModal={closeStoreCheckout}
            closeViaOverlay={false}
          >
            <secondaryComponents.StoreCheckout
              closeStoreCheckout={closeStoreCheckout}
              setRefreshProducts={setRefreshProducts}
            />
          </secondaryComponents.SideModal>
        </div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default ProductsPage;
