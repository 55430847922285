import { useEffect, useState, useContext } from "react";
import { setCartDetails } from "../../../store/modules/storeCart";
import { useDispatch, useSelector } from "react-redux";
import { createOrder, getCartItems } from "../../../services/sales-point";
import { ToastContext } from "../../../hooks/context";
import { useNavigate } from "react-router-dom";

const StoreCartLogic = ({
  refetchCart,
  setRefetchCart,
  setRefreshProducts,
  resetInputField,
}) => {
  const [isFetchingCartItems, setIsfetchingCartItems] = useState(false);
  const {
    data: cartItems,
    total_items,
    total_amount,
    total_items_amount,
    vat_amount,
    vat,
  } = useSelector((state) => state.cartReducer.cartDetails);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const dispatch = useDispatch();
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const [discount, setDiscount] = useState("");
  const [deliveryFee, setDeliveryFee] = useState("");
  const { business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );

  useEffect(() => {
    handleFetchCartItems();
  }, []);

  useEffect(() => {
    if (refetchCart) handleFetchCartItems(false);
  }, [refetchCart]);

  const handleFetchCartItems = (spinner = true) => {
    if (spinner) setIsfetchingCartItems(true);
    getCartItems()
      .then((response) => {
        setIsfetchingCartItems(false);
        setRefetchCart(false);
        dispatch(setCartDetails(response?.data));
      })
      .catch((error) => {
        setRefetchCart(false);
        setIsfetchingCartItems(false);
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      });
  };

  const handleCreateOrder = () => {
    setIsCreatingOrder(true);
    let data = {};
    if (discount !== "") {
      data.discount = {};
      data.discount.amount = Number(discount);
      data.discount.type = "flat";
    }
    if (deliveryFee !== "") data.deliveryFee = Number(deliveryFee);

    createOrder(data)
      .then((response) => {
        setIsCreatingOrder(false);
        setRefetchCart(true);
        setRefreshProducts(true);
        const orderId = response?.data?.data?.id;
        triggerToast("Order Created", "success");
        navigate(`/store?order_id=${orderId}`);
        setDeliveryFee("");
        setDiscount("");
        resetInputField("discount");
        resetInputField("deliveryFee");
      })
      .catch((error) => {
        setIsCreatingOrder(false);
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      });
  };

  return {
    isFetchingCartItems,
    cartItems,
    total_items,
    totalPrice:
      total_amount -
      (discount === "" ? 0 : Number(discount)) +
      (deliveryFee === "" ? 0 : Number(deliveryFee)),
    total_price: total_items_amount,
    vat,
    vat_amount,
    isCreatingOrder,
    discount,
    deliveryFee,
    business_role,
    setDiscount,
    setDeliveryFee,
    handleCreateOrder,
  };
};
export default StoreCartLogic;
