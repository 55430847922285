import { useTable } from "react-table";
import { useMemo } from "react";
import { useState, useEffect, useContext } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { getTeamMembers } from "../../../services/settings";
import { mapUserRole } from "../../../helpers";

const useUsersTableLogic = () => {
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [subAccountList, setsubAccountList] = useState([]);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  useEffect(() => {
    fetchUsersList();
  }, []);

  const fetchUsersList = () => {
    setIsFetchingUsers(true);
    setRequestLoaderProgress(40);
    getTeamMembers()
      .then((response) => {
        setUsersList(response.data?.data);
        setRequestLoaderProgress(100);
        setIsFetchingUsers(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsFetchingUsers(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "first_name",
        Cell: ({ cell: { value, row } }) => (
          <span>
            {value} {row.original["last_name"]}
          </span>
        ),
      },
      {
        Header: "Email Address",
        accessor: "email",
      },
      {
        Header: "Role",
        accessor: "business_role",
        Cell: ({ cell: { value } }) => mapUserRole[value],
      },
      {
        Header: "Last Login",
        accessor: "last_login",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleString("en-us"),
      },
    ],
    []
  );

  const instance = useTable({ columns, data: usersList });

  return {
    instance,
    isFetchingUsers,
    usersList,
    showInviteModal,
    setShowInviteModal,
    userRole,
    userEmail,
    subAccountList,
    setsubAccountList,
    setUserRole,
    setUserEmail,
    fetchUsersList,
    showDeleteUserModal,
    setShowDeleteUserModal,
    columns,
  };
};

export default useUsersTableLogic;
