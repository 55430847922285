import { Controller } from "react-hook-form";
import Icon from "../../../assets/icons";
import primaryComponents from "../../primaryComponents";
import "./CartItemsConfirmation.scss";
import CartItemsConfirmationValidation from "./CartItemsConfirmationValidation";

const CartItemsConfirmation = ({ closeWidget }) => {
  const {
    register,
    // handleSubmit,
    // setError,
    control,
    // trigger,
    formState: { errors },
  } = CartItemsConfirmationValidation();
  return (
    <div className="cart-items-confirmation">
      <div
        className="cart-items-confirmation-close"
        onClick={() => closeWidget(false)}
      >
        <img src={Icon.close} alt="" />
      </div>
      <div className="cart-items-confirmation-details-wrapper">
        <div className="cart-items-confirmation-details">
          <h4 className="font-weight-semibold cart-items-confirmation-details__title">
            Please confirm you have received the money from customer
          </h4>
        </div>
        <div className="cart-items-confirmation-details">
          <Controller
            defaultValue={""}
            name="customerName"
            control={control}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                label={"Customer Name"}
                placeholder={"Enter customer name"}
                isRequired={false}
                type="text"
                name="customerName"
                errorMessage={errors.customerName}
                onChange={({ target: { value } }) => {
                  onChange(value);
                }}
                register={register}
              />
            )}
          />
        </div>
        <div className="cart-items-confirmation-details">
          <Controller
            defaultValue={""}
            name="phoneNumber"
            control={control}
            render={({ field: { onChange } }) => (
              <primaryComponents.PhoneNumberInputField
                label={"Customer Phone Number"}
                placeholder={"Enter your customer phone number"}
                isRequired={false}
                type="text"
                name="phoneNumber"
                errorMessage={errors.phoneNumber}
                onChange={({ target: { value } }) => {
                  onChange(value);
                }}
                register={register}
              />
            )}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between cart-items-confirmation-submission">
        <primaryComponents.Button
          classNames={"btn btn--outline"}
          onClick={() => closeWidget(false)}
        >
          Skip
        </primaryComponents.Button>
        <primaryComponents.Button classNames={"btn btn--primary"}>
          Confirm
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default CartItemsConfirmation;
