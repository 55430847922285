import "./ProductCategories.scss";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import Icon from "../../../../assets/icons";
// import Images from "../../../assets/images";
import ProductCategoryLogic from "./ProductCategoryLogic";
import { useOutletContext } from "react-router-dom";

const ProductCategories = () => {
  const { inventoryAnalytics, setInventoryAnalytics } = useOutletContext();
  const { isModalOpen, setIsModalOpen, isRefresh, setIsRefresh } =
    ProductCategoryLogic();

  return (
    <div className="product-categories-page">
      <div className="product-categories-page__header">
        <h4 className="font-weight-semibold">
          {inventoryAnalytics.categoryCount} Product Categories
        </h4>
        <primaryComponents.Button
          onClick={() => setIsModalOpen(true)}
          classNames={"btn btn--primary"}
        >
          <img className="mr-2" src={Icon.add} alt="add category" />
          Add new category
        </primaryComponents.Button>
      </div>
      <secondaryComponents.ProductCategoryTable
        isRefresh={isRefresh}
        setIsRefresh={setIsRefresh}
        setInventoryAnalytics={setInventoryAnalytics}
      />
      {isModalOpen && (
        <secondaryComponents.ProductCategoryModal
          isActive={isModalOpen}
          setIsActive={setIsModalOpen}
          setIsRefresh={setIsRefresh}
        />
      )}
    </div>
  );
};

export default ProductCategories;
