import "./EditGiftCard.scss";
import primaryComponents from "../../../components/primaryComponents";
import secondaryComponents from "../../../components/secondaryComponents";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useEditGiftCardLogic } from "./EditGiftCardLogic";

const EditGifctCard = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { isFetchingGiftCard, giftcard } = useEditGiftCardLogic({
    giftcard_id: params.giftcardId,
  });

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Loyalty - Edit giftcard"
      description="Create giftcard on lumi merchant and see an improve customer loyalty"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          {/* back btn */}
          <primaryComponents.Button
            classNames="btn btn--outline mb-4"
            onClick={() => navigate("/app/loyalty/manage")}
          >
            <FontAwesomeIcon
              icon="angle-left"
              className="mr-3"
              style={{ fontSize: "18px" }}
            />
            <span>Back</span>
          </primaryComponents.Button>

          <div className="edit-giftcard__content mt-4">
            <div className="edit-giftcard__content__header">
              <h3 className="font-weight-semibold">Edit Giftcard</h3>
            </div>
            <div className="edit-giftcard__content__body">
              <secondaryComponents.LoaderHelper
                classNames={"mt-5"}
                isLoading={isFetchingGiftCard}
              >
                <secondaryComponents.EditGiftCardForm
                  giftcardDetails={giftcard}
                />
              </secondaryComponents.LoaderHelper>
            </div>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default EditGifctCard;
