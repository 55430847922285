import { getProfile, getBusiness } from "../services/settings";
import { fetchDashboard } from "../services/dashboard";
import {
  setProfileDetails,
  setBusinessDetails,
} from "../store/modules/profile";
import { setDashboardDetails } from "../store/modules/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUrlQuerysection } from "../helpers";

const useRootProperties = () => {
  const dispatch = useDispatch();
  const { email } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const { business_name } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails
  );
  const { total_revenue } = useSelector(
    (state) => state.dashboardReducer.dashboardDetails
  );

  useEffect(() => {
    getProfileDetails();
    getBusinessDetails();
    getDashboardDetails();
  }, []);

  const getProfileDetails = () => {
    if (email !== null) return;
    getProfile().then((response) => {
      dispatch(setProfileDetails(response?.data?.data));
    });
  };

  const getBusinessDetails = () => {
    if (business_name !== null) return;
    getBusiness().then((response) => {
      dispatch(setBusinessDetails(response?.data?.data));
    });
  };

  const getDashboardDetails = () => {
    if (total_revenue !== null) return;
    let queries = getUrlQuerysection();
    if (queries === "") queries = "?all_business=true";
    fetchDashboard(queries).then((response) => {
      if (response?.data) dispatch(setDashboardDetails(response?.data?.data));
    });
  };

  return {
    email,
  };
};
export default useRootProperties;
