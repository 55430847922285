import "./ServiceLevelAgreementModal.scss";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";

const ServiceLevelAgreementModal = ({
  isActive,
  setIsActive,
  setAgreeTerms,
}) => {
  return (
    <secondaryComponents.Modal
      isActive={isActive}
      width="800px"
      closeModal={() => {
        setIsActive(false);
      }}
    >
      <div className="service-level-agreement-modal">
        <h4 className="text-center font-weight-semibold">
          Lumi POS Service Level Agreement
        </h4>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
          className="my-4"
        >
          <p className="dark--text">
            This Agreement is made today between ENLUMI INSIGHTS LTD and YOU(THE
            BUSINESS OWNER), The service level agreement reads as follows,
          </p>
          <p className="dark--text">
            1. That I agree to have a contractual agreement with ENLUMI INSIGHTS
            LTD.
          </p>
          <p className="dark--text">
            2. That all rules binding this agreement were not signed under
            duress.
          </p>
          <p className="dark--text">
            3. That I agree to pay the stipulated N30,000 (THIRTY THOUSAND NAIRA
            ONLY) which includes ACTIVATION, & ONBOARDING fee for me to be
            registered, onboarded and activated on ENLUMI INSIGHTS LTD.
            platform.(N15,000 IS REFUNDABLE TO AGENT DASHBOARD IF RETURNED OR
            RETRIEVED WITHIN 12 MONTHS)
          </p>
          <p className="dark--text">
            4. That the POS terminal was never sold to me, hence; it is the
            property of ENLUMI INSIGHTS LTD.
          </p>
          <p className="dark--text">
            5. That all rules binding my registration are totally binding on me
            as a customer of ENLUMI INSIGHTS LTD.
          </p>
          <p className="dark--text">
            6. That I am entering into this agreement willingly for me to get a
            POS terminal of ENLUMI INSIGHTS LTD.
          </p>
          <p className="dark--text">
            7. That I shall ensure I meet up with my minimum monthly transaction
            counts(Refer to Clause 8)
          </p>
          <p className="dark--text">
            8. That I shall ensure I meet up with my daily withdrawal
            transaction count of 15 (Fifteen Transaction counts daily) with
            30,000 daily withdrawal minimum cumulative total and daily deposits
            transaction count of 15(Fifteen Transaction counts daily) stipulated
            by the company ENLUMI INSIGHTS LTD.. (Totalling 105 withdrawal
            counts and 105 deposit counts weekly)
          </p>
          <p className="dark--text">
            9. ENLUMI INSIGHTS LTD. has the power and right to retrieve the POS
            from me if I fail to meet up with CLAUSE 8
          </p>
          <p className="dark--text">
            10. That if I fail to perform within 4 weeks of onboarding, ENLUMI
            INSIGHTS LTD. has the right to retrieve the terminal from me without
            any refund either partly or wholly (if the terminal is not in good
            condition when returned).
          </p>
          <p className="dark--text">
            11. That at any time in the future, if I fail to perform to meet up
            with the required daily target set on withdrawal, ENLUMI INSIGHTS
            LTD. still have the right to retrieve the terminal based on the
            performance report.
          </p>
          <p className="dark--text">
            12. That I shall ensure that the terminal is in good condition at
            all times.
          </p>
          <p className="dark--text">
            13. That I have agreed to all stipulated rules before appending my
            signature below.
          </p>
          <p className="dark--text">
            14. Erroneous transactions such as transfers to the wrong account
            are not binding on Enlumi Insights Ltd.
          </p>
          <p className="dark--text">
            15. When such is reported, the customer should be ready to bear all
            expenses.
          </p>
          <p className="dark--text">
            16. The customer must accompany such claim with a court order and a
            statement of account
          </p>
        </div>

        <div className="service-level-agreement-modal__actions">
          <primaryComponents.Button
            onClick={() => {
              setAgreeTerms(false);
              setIsActive(false);
            }}
            classNames={"btn btn--outline"}
            type="button"
          >
            Cancel
          </primaryComponents.Button>
          <primaryComponents.Button
            classNames={"btn btn--primary"}
            type="button"
            onClick={() => {
              setAgreeTerms(true);
              setIsActive(false);
            }}
          >
            Agree
          </primaryComponents.Button>
        </div>
      </div>
    </secondaryComponents.Modal>
  );
};

export default ServiceLevelAgreementModal;
