import secondaryComponents from "..";
import styles from "./StoreCart.module.scss";
import StoreCartLogic from "./StoreCartLogic";
import Images from "../../../assets/images";
import primaryComponents from "../../primaryComponents";
import { formatCurrency } from "../../../helpers";
import { DiscountValidation } from "./Validation";
import { Controller } from "react-hook-form";

const StoreCart = ({
  refetchCart = false,
  setRefetchCart = () => {},
  setRefreshProducts = () => {},
  updatingCart,
  handleAddToCart,
  handleReduceCartItem,
  selectedItemId,
}) => {
  const {
    register,
    formState: { errors },
    control,
    trigger,
    resetField: resetInputField,
  } = DiscountValidation();
  const {
    isFetchingCartItems,
    cartItems,
    total_items,
    totalPrice,
    total_price,
    isCreatingOrder,
    discount,
    deliveryFee,
    vat_amount,
    vat,
    setDeliveryFee,
    setDiscount,
    handleCreateOrder,
    business_role,
  } = StoreCartLogic({
    refetchCart,
    setRefetchCart,
    setRefreshProducts,
    resetInputField,
  });

  return (
    <div className={styles.storeCart}>
      {/* cart items section */}
      <secondaryComponents.LoaderHelper
        isLoading={isFetchingCartItems}
        classNames="mt-3"
      >
        {cartItems.length > 0 ? (
          <div className={styles.storeCart__items}>
            {cartItems.map((item, index) => (
              <secondaryComponents.ProductCarting
                key={item?.id + index}
                cartDetails={item}
                updatingCart={updatingCart}
                handleAddToCart={handleAddToCart}
                handleReduceCartItem={handleReduceCartItem}
                selectedItemId={selectedItemId}
                productIndex={index}
              />
            ))}
          </div>
        ) : (
          <div style={{ marginTop: "80px" }} className="text-center">
            <img src={Images.productsEmptyState} alt="" width={"150px"} />
            <h5 className="mt-3 text-center">
              You have no products in your cart
            </h5>
            <p>When you select products, they would appear here.</p>
          </div>
        )}
      </secondaryComponents.LoaderHelper>

      <div className={styles.priceSummary}>
        <div className="d-flex justify-content-between mb-2">
          <p className="dark--text">Subtotal</p>
          <p className="dark--text">{formatCurrency(total_price)}</p>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <p className="dark--text">Discount</p>
          <p className="dark--text">{discount === "" ? "-" : discount}</p>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <p className="dark--text">Delivery Fee</p>
          <p className="dark--text">{deliveryFee === "" ? "-" : deliveryFee}</p>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <p className="dark--text">VAT ({vat}%)</p>
          <p className="dark--text">{formatCurrency(vat_amount)}</p>
        </div>
        <div className="d-flex justify-content-between mb-4">
          <p className="purple--text font-weight-semibold">Total</p>
          <p className="purple--text font-weight-semibold">
            {formatCurrency(totalPrice)}
          </p>
        </div>
        {business_role === "ADMIN" ||
          (business_role === "OWNER" && (
            <div className={styles.priceSummary__row}>
              <Controller
                name="discount"
                control={control}
                defaultValue={discount}
                render={({ field: { onChange } }) => (
                  <primaryComponents.InputField
                    classNames="white"
                    placeholder="Enter discount amount e.g. N 1000"
                    isRequired={false}
                    type="number"
                    min="0"
                    name="discount"
                    errorMessage={errors.discount}
                    onKeyUp={() =>
                      errors.discount !== undefined && trigger("discount")
                    }
                    onChange={({ target: { value } }) => {
                      onChange(value);
                      setDiscount(value);
                    }}
                    register={register}
                  />
                )}
              />
            </div>
          ))}

        <div className={styles.priceSummary__row}>
          <Controller
            name="deliveryFee"
            control={control}
            defaultValue={deliveryFee}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                classNames="white"
                placeholder="Enter delivery fee e.g. N 1000"
                isRequired={false}
                type="number"
                min="0"
                name="deliveryFee"
                errorMessage={errors.deliveryFee}
                onKeyUp={() =>
                  errors.deliveryFee !== undefined && trigger("deliveryFee")
                }
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setDeliveryFee(value);
                }}
                register={register}
              />
            )}
          />
          {/* <primaryComponents.Button classNames="btn btn--outline">
            Apply
          </primaryComponents.Button> */}
        </div>
      </div>

      <div className={styles.storeCart__bottom}>
        <primaryComponents.Button
          isLoading={isCreatingOrder}
          isDisabled={isCreatingOrder || total_items === 0}
          loadingText="Creating order..."
          classNames="btn btn--primary cover"
          onClick={() => handleCreateOrder()}
        >
          Checkout {total_items} {total_items > 1 ? "items" : "item"} (
          {formatCurrency(totalPrice)})
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default StoreCart;
