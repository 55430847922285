import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "../../../assets/icons";
import { formatCurrency } from "../../../helpers";
import { PRODUCT_DEFAULT_ICONS } from "../../../helpers/constants";
import "./ProductCarting.scss";

const ProductCarting = ({
  cartDetails,
  updatingCart,
  handleAddToCart,
  handleReduceCartItem,
  selectedItemId,
  productIndex,
}) => {
  const { quantity, cart_item_name, total_price, product, id } = cartDetails;

  return (
    <div className="product">
      <div className="product-image">
        <img
          src={
            product?.image_url === "" || product?.image_url === null
              ? PRODUCT_DEFAULT_ICONS[productIndex % 5]
              : product?.image_url
          }
          alt=""
        />
      </div>
      <div className="product-details">
        <p className="product-name dark--text">{cart_item_name}</p>
        <p className="product-quantity">Qty: {quantity}</p>
        <div className="product-order">
          <p className="product-price font-weight-semibold dark--text">
            {formatCurrency(total_price)}
          </p>
          <div className="product-actions">
            <button
              className="decrement"
              onClick={() =>
                handleReduceCartItem(
                  product?.id,
                  quantity - 1,
                  id,
                  cartDetails?.variant?.id
                )
              }
            >
              -
            </button>
            {updatingCart &&
            (cartDetails?.variant?.id === selectedItemId ||
              cartDetails?.product.id === selectedItemId) ? (
              <FontAwesomeIcon
                icon={["fas", "spinner"]}
                className="fa-spin purple--text"
                style={{ fontSize: "15px", width: "25px" }}
              />
            ) : (
              <p className="count font-weight-semibold dark--text">
                {quantity}
              </p>
            )}
            <button
              className="increment"
              onClick={() =>
                handleAddToCart(
                  product?.id,
                  quantity + 1,
                  cartDetails?.variant?.id
                )
              }
            >
              +
            </button>
            <button
              className="delete"
              onClick={() => handleReduceCartItem(product?.id, 0, id)}
            >
              <img src={Icon.deleteIcon} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCarting;
