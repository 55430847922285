/* eslint-disable react/jsx-key */
import "./TransactionsTable.scss";
import useTransactionsTable from "./TransactionTableLogic";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import Images from "../../../assets/images";

const TransactionsTable = ({ isRefeshTnx, setRefreshTnx }) => {
  const {
    instance,
    isFetchingTransactions,
    transactions,
    gotoPage,
    searchTnx,
    filterBy,
    setDateFilter,
    filterDetails,
    searchValue,
    transactionsPageDetails,
    startDate,
    endDate,
    tags,
    isFetchingTags,
    handleTagChange,
    handleCreateTag,
  } = useTransactionsTable({ isRefeshTnx, setRefreshTnx });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="transactions__table">
      <div className="transactions__table__header">
        {/* header section */}
        <div className="search-section">
          <primaryComponents.SearchField
            trigger={(value) => searchTnx(value)}
            value={searchValue}
            placeholder="Search transaction by ( ID, phone number or email )"
            classNames="search-section"
          />
        </div>
        {/* transaction filter */}
        <secondaryComponents.TransactionsFilter
          applyFilter={filterBy}
          filterDetails={filterDetails}
        />
        {/* date filter */}
        <primaryComponents.DateRangeInput
          onChange={setDateFilter}
          startDate={startDate}
          endDate={endDate}
        />
      </div>

      {/* table section */}
      <div className="table-section">
        <secondaryComponents.LoaderHelper
          isLoading={isFetchingTransactions}
          classNames="mt-5"
        >
          {transactions.length > 0 ? (
            <>
              {/* show onn desktop */}
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          className="font-weight-semibold"
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                      <th className="font-weight-semibold">Tags</th>
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps({ key: i })}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                        {transactions[i].status === "settled" ? (
                          <td
                            style={{ maxWidth: "250px", width: "max-content" }}
                          >
                            <primaryComponents.TagsMultiSelect
                              options={tags}
                              idKey="id"
                              nameKey="name"
                              isDisabled={isFetchingTags}
                              value={row.original?.tag.map(({ id, name }) => ({
                                value: id,
                                label: name,
                              }))}
                              onChange={(value, datas) => {
                                const transfer_id = row.original?.id;
                                handleTagChange({
                                  inputValue: value,
                                  transfer_id,
                                  datas,
                                });
                              }}
                              onCreateOption={(value) => {
                                handleCreateTag({
                                  value,
                                  transaction_id: row.original.id,
                                });
                              }}
                            />
                          </td>
                        ) : (
                          <td>-</td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {/* display on mobile */}
              <div className="mobile-table">
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <div
                      className="mobile-table__row"
                      {...row.getRowProps({ key: i })}
                    >
                      {row.cells.map((cell, j) => (
                        <div
                          className="row-item"
                          {...cell.getCellProps({
                            key: j,
                          })}
                        >
                          <h6 className="row-item__title">
                            {cell.column.Header}
                          </h6>
                          <p className="row-item__value">
                            {cell.render("Cell")}
                          </p>
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            // show if transaction is empty
            <div className="text-center mt-5">
              <img
                width="250px"
                height="262px"
                src={Images.tnxEmptyState}
                alt="empty state"
              />
              <h4>You have no transactions</h4>
              <p>When you do, they would appear here.</p>
            </div>
          )}
        </secondaryComponents.LoaderHelper>
      </div>
      {/* pagination section */}
      {transactions.length > 0 && (
        <secondaryComponents.Pagination
          totalPageNumber={transactionsPageDetails.last_page}
          handlePageClick={(value) => gotoPage(value.selected + 1)}
          currentPage={transactionsPageDetails.current_page}
        />
      )}
    </div>
  );
};

export default TransactionsTable;
