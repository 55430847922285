import "./StoresTableModal.scss";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";
import StoresTableModalValidation from "./StoresTableModalValidation";
import StoresTableModalLogic from "./StoresTableModalLogic";
import { Controller } from "react-hook-form";
import { useEffect } from "react";

const StoresTableModal = ({ isActive, setIsActive, setIsRefresh }) => {
  const {
    storeName,
    storeAddress,
    settlementAccount,
    isLoading,
    vat,
    setVat,
    setStoreName,
    setStoreAddress,
    setSettlementAccount,
    handleAddStore,
    business_list,
  } = StoresTableModalLogic({ setIsActive, setIsRefresh });

  const {
    register,
    formState: { errors },
    trigger,
    control,
    handleSubmit,
    setError,
    clearErrors,
    reset,
  } = StoresTableModalValidation({
    storeName,
    storeAddress,
    settlementAccount,
  });

  useEffect(() => {
    window.setNewStoreError = setError;
    window.clearNewStoreError = clearErrors;
    window.resetNewStoreForm = reset;
  }, []);

  return (
    <secondaryComponents.Modal
      isActive={isActive}
      width="600px"
      closeModal={() => setIsActive(false)}
      style={{ marginTop: "30px" }}
    >
      <div className="stores-table-modal">
        <h4 className="text-center font-weight-semibold">Add new store</h4>
        <form
          className="stores-table-modal__form"
          style={{ marginTop: "32px" }}
          onSubmit={handleSubmit(handleAddStore)}
        >
          {/* Store Name */}
          <Controller
            name="storeName"
            control={control}
            defaultValue={storeName}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                label="Store Name"
                placeholder="Enter your store name"
                isRequired={true}
                type="text"
                name="storeName"
                errorMessage={errors.storeName}
                onKeyUp={() =>
                  errors.storeName !== undefined && trigger("storeName")
                }
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setStoreName(value);
                }}
                register={register}
              />
            )}
          />
          {/* Store Address */}
          <Controller
            name="storeAddress"
            control={control}
            defaultValue={storeAddress}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                label="Store Address"
                placeholder="Enter your store street address"
                isRequired={true}
                type="text"
                name="storeAddress"
                errorMessage={errors.storeAddress}
                onKeyUp={() =>
                  errors.storeAddress !== undefined && trigger("storeAddress")
                }
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setStoreAddress(value);
                }}
                register={register}
              />
            )}
          />
          {/* Settlement account */}
          <Controller
            name="settlementAccount"
            control={control}
            defaultValue={settlementAccount}
            render={({ field: { onChange } }) => (
              <primaryComponents.SelectField
                label="Settlement Account"
                placeholder="Select settlement account"
                isRequired={true}
                value={settlementAccount || "Select settlement account"}
                options={business_list}
                nameKey="tag"
                idKey="id"
                errorMessage={errors.settlementAccount}
                onChange={(value) => {
                  onChange(value);
                  setSettlementAccount(value);
                }}
              />
            )}
          />
          {/* vat */}
          <Controller
            name="vat"
            control={control}
            defaultValue={vat}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                name="vat"
                value={vat}
                label="VAT Rate(%)"
                placeholder="Enter VAT Rate"
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setVat(value);
                }}
                type="number"
                min="0"
                max="100"
                step="0.01"
                errorMessage={errors.vat}
                onKeyUp={() => errors.vat !== undefined && trigger("vat")}
                register={register}
              />
            )}
          />

          <div
            className="d-flex align-items-center justify-content-between"
            style={{ marginTop: "32px" }}
          >
            <primaryComponents.Button
              classNames={"btn btn--outline"}
              onClick={() => setIsActive(false)}
            >
              Cancel
            </primaryComponents.Button>
            <primaryComponents.Button
              className={"btn  btn--primary"}
              isLoading={isLoading}
              loadingText="Adding Store..."
              isDisabled={
                errors.storeAddress ||
                errors.storeName ||
                errors.settlementAccount ||
                isLoading
              }
            >
              Add new store
            </primaryComponents.Button>
          </div>
        </form>
      </div>
    </secondaryComponents.Modal>
  );
};

export default StoresTableModal;
