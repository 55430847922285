const CartItemsStepperLogic = () => {
  const steps = [
    {
      id: 0,
      title: "checkout",
      label: "checkout",
    },
    {
      id: 1,
      title: "confirm-payment",
      label: "confirm-payment",
    },
  ];

  return { steps };
};

export default CartItemsStepperLogic;
