import { useState, useContext } from "react";
import { createCategory } from "../../../services/inventory";
import { ToastContext, RequestLoader } from "../../../hooks/context";

const CategoryFormLogic = ({ handleNextStep }) => {
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryDescription, setCategoryDescripion] = useState("");

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const handleAddCategory = () => {
    setIsAddingCategory(true);
    setRequestLoaderProgress(40);
    createCategory({
      name: categoryName,
      description: categoryDescription,
    })
      .then(() => {
        setIsAddingCategory(false);
        setRequestLoaderProgress(100);
        handleNextStep();
      })
      .catch((error) => {
        setIsAddingCategory(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  return {
    categoryName,
    categoryDescription,
    isAddingCategory,
    setCategoryName,
    setCategoryDescripion,
    handleAddCategory,
  };
};

export default CategoryFormLogic;
