import "./ProductCategoryModal.scss";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";
import ProductCategoryModalLogic from "./ProductCategoryModalLogic";
import ProductCategoryModalValidation from "./ProductCategoryModalValidation";
import { Controller } from "react-hook-form";

const ProductCategoryModal = ({ isActive, setIsActive, setIsRefresh }) => {
  const {
    isAddingCategory,
    categoryDescription,
    categoryName,
    setCategoryDescripion,
    setCategoryName,
    handleAddCategory,
  } = ProductCategoryModalLogic({ setIsActive, setIsRefresh });

  const {
    register,
    formState: { errors },
    trigger,
    control,
    handleSubmit,
  } = ProductCategoryModalValidation({ categoryName, categoryDescription });
  return (
    <secondaryComponents.Modal
      isActive={isActive}
      closeModal={() => setIsActive(false)}
      width="550px"
    >
      <div className="product-category-modal-wrapper">
        <h4 className="font-weight-semibold text-center">
          Add new product category
        </h4>
        <form onSubmit={handleSubmit(handleAddCategory)} className="mt-4">
          {/* Category name */}
          <Controller
            name="categoryName"
            control={control}
            defaultValue={categoryName}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                label={"Category Name"}
                isRequired={true}
                placeholder="Enter your category name"
                type="text"
                name="categoryName"
                errorMessage={errors.categoryName}
                onKeyUp={() =>
                  errors.categoryName !== undefined && trigger("categoryName")
                }
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setCategoryName(value);
                }}
                register={register}
              />
            )}
          />
          {/* Category Description */}
          <Controller
            name="categoryDescription"
            control={control}
            defaultValue={categoryDescription}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                label={"Category Description"}
                isRequired={true}
                placeholder="Enter your category description"
                type="text"
                name="categoryDescription"
                errorMessage={errors.categoryDescription}
                onKeyUp={() =>
                  errors.categoryDescription !== undefined &&
                  trigger("categoryDescription")
                }
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setCategoryDescripion(value);
                }}
                register={register}
              />
            )}
          />

          <div className="modal-buttons">
            <primaryComponents.Button
              classNames={"btn btn--outline"}
              onClick={() => setIsActive(false)}
            >
              Cancel
            </primaryComponents.Button>

            <primaryComponents.Button
              classNames={"btn btn--primary "}
              isDisabled={
                isAddingCategory ||
                errors.categoryDescription ||
                errors.categoryName
              }
              isLoading={isAddingCategory}
              loadingText="Adding category..."
            >
              Add new product category
            </primaryComponents.Button>
          </div>
        </form>
      </div>
    </secondaryComponents.Modal>
  );
};
export default ProductCategoryModal;
