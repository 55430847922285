import { useTable } from "react-table";
import { useMemo, useContext, useState, useEffect } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { getTags } from "../../../services/tags";
import { useOutletContext } from "react-router-dom";
import primaryComponents from "../../primaryComponents";

const TagsTableLogic = ({
  setIsEditingTag,
  setIsAddingTag,
  setTagDetails,
  setIsDeletingTag,
}) => {
  const [tags, setTags] = useState([]);
  const [isFetchingTags, setIsFetchingTags] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { isRefresh, setIsRefresh } = useOutletContext();

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "name",
      },
      {
        Header: "",
        accessor: "edit",
        Cell: ({ cell: { row } }) => (
          <primaryComponents.Button
            classNames={"btn btn--outline tags__btn"}
            onClick={() => {
              setTagDetails(row.original);
              setIsEditingTag(true);
              setIsAddingTag(true);
            }}
          >
            Edit tag
          </primaryComponents.Button>
        ),
      },
      {
        Header: "",
        accessor: "delete",
        Cell: ({ cell: { row } }) => (
          <primaryComponents.Button
            classNames={"btn btn--danger tags__btn"}
            onClick={() => {
              setTagDetails(row.original);
              setIsDeletingTag(true);
            }}
          >
            Delete tag
          </primaryComponents.Button>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    handleFetchTags();
  }, []);

  useEffect(() => {
    if (isRefresh) handleFetchTags();
  }, [isRefresh]);

  const instance = useTable({ columns, data: tags });

  const handleFetchTags = () => {
    setIsFetchingTags(true);
    setRequestLoaderProgress(40);
    getTags(false)
      .then((response) => {
        setIsFetchingTags(false);
        setRequestLoaderProgress(100);
        setTags(response.data?.data);
        setIsRefresh(false);
      })
      .catch((error) => {
        setIsFetchingTags(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  return {
    instance,
    tags,
    isFetchingTags,
    isOpenDeleteDialog,
    setIsOpenDeleteDialog,
    handleFetchTags,
  };
};

export default TagsTableLogic;
