import secondaryComponents from "../../../../components/secondaryComponents";

const AllGiftCards = () => {
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Loyalty - All campaigns"
      description="Create campaign on lumi merchant and see an improve customer loyalty"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <div className="all-giftcards">
          <secondaryComponents.GiftCardList />
        </div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default AllGiftCards;
