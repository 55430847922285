import "./SettingsLayout.scss";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SettingsLayoutLogic from "./SettingsLayoutLogic";
import { useEffect } from "react";

const SettingsLayout = () => {
  const location = useLocation();
  const { business_role } = SettingsLayoutLogic();
  const navigate = useNavigate();

  useEffect(() => {
    if (business_role === "CUSTOMER_SUPPORT" || business_role === "CASHIER") {
      return navigate("/app/transactions?all_business=true");
    }
  }, []);

  return (
    <div className="settings-page">
      <h3 className="font-weight-semibold">Settings</h3>
      <div className="settings-page__tab">
        <div className="settings-page__tab__menu">
          <NavLink
            to="/app/settings/profile"
            className={`${
              location.pathname === "/app/settings" ? "active" : ""
            } settings-page__tab__menu__item`}
          >
            Profile
          </NavLink>
          <NavLink
            to="/app/settings/users"
            className="settings-page__tab__menu__item"
          >
            Users
          </NavLink>
          <NavLink
            to="/app/settings/settlements"
            className="settings-page__tab__menu__item"
          >
            Settlements
          </NavLink>
          <NavLink
            to="/app/settings/api-keys"
            className="settings-page__tab__menu__item"
          >
            API
          </NavLink>
          {/* <NavLink
            to="/app/settings/webhooks"
            className="settings-page__tab__menu__item"
          >
            Webhooks
          </NavLink>
          <NavLink
            to="/app/settings/account-settings"
            className="settings-page__tab__menu__item"
          >
            Account Settings
          </NavLink>
          <NavLink
            to="/app/settings/snap-and-earn"
            className="settings-page__tab__menu__item"
          >
            Snap & Earn
          </NavLink> */}
        </div>
        <div className="settings-page__tab__content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SettingsLayout;
