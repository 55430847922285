import { useState, useContext } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { verifyBvnOtp, resendBvnOtp } from "../../../services/kyb-verification";

const BankVerificationOtpWidgetLogic = ({
  setShowBankVerificationOtpWidget,
  fetchDocuments,
}) => {
  const [otp, setOtp] = useState("");
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [isResendingOtp, setIsResendingOtp] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const handleVerifyOtp = () => {
    setIsVerifyingOtp(true);
    setRequestLoaderProgress(40);
    verifyBvnOtp({ otp })
      .then(() => {
        setRequestLoaderProgress(100);
        setIsVerifyingOtp(false);
        setShowBankVerificationOtpWidget(false);
        fetchDocuments(false);
        triggerToast("BVN successfully verified", "success");
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsVerifyingOtp(false);
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      });
  };

  const handleResendOtp = () => {
    setIsResendingOtp(true);
    setRequestLoaderProgress(40);
    resendBvnOtp()
      .then(() => {
        setRequestLoaderProgress(100);
        setIsResendingOtp(false);
        triggerToast("Otp Sent successfully", "success");
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsResendingOtp(false);
        if (error?.response?.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  return {
    otp,
    setOtp,
    handleVerifyOtp,
    isVerifyingOtp,
    isResendingOtp,
    handleResendOtp,
  };
};

export default BankVerificationOtpWidgetLogic;
