import "./AddProductWidget.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import { Link } from "react-router-dom";
import Icon from "../../../assets/icons";

const AddProductWidget = ({ isOpen, setIsOpen }) => {
  return (
    <secondaryComponents.Modal
      isActive={isOpen}
      closeModal={() => setIsOpen(false)}
      width={"500px"}
    >
      <div className="add-product-widget">
        <div>
          <h4 className="font-weight-semibold text-center">
            Create new product
          </h4>
          <p className="text-center">
            Create a simple or multiple product at once
          </p>
        </div>
        <Link
          className="add-product-widget__item"
          to="/app/products/add/single"
          onClick={() => setIsOpen(false)}
        >
          <div className="icon orange">
            <img src={Icon.product_box_1} alt="" />
          </div>
          <div>
            <p className="font-weight-semibold dark--text">Single product</p>
            <p>Choose for a single product</p>
          </div>
          <img className="ml-auto" src={Icon.chevron_right_arrow} alt="" />
        </Link>
        <Link
          className="add-product-widget__item"
          to="/app/products/add/multiple"
          onClick={() => setIsOpen(false)}
        >
          <div className="icon green">
            <img src={Icon.product_box_2} alt="" />
          </div>
          <div>
            <p className="font-weight-semibold dark--text">Multiple product</p>
            <p>Choose for multiple products</p>
          </div>
          <img className="ml-auto" src={Icon.chevron_right_arrow} alt="" />
        </Link>
        <div className="add-product-widget__action">
          <primaryComponents.Button
            classNames={"btn btn--outline"}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </primaryComponents.Button>
        </div>
      </div>
    </secondaryComponents.Modal>
  );
};

export default AddProductWidget;
