import { useContext, useState } from "react";
import { ToastContext } from "../../../../hooks/context";
import { postStoreCustomerDetails } from "../../../../services/sales-point";

const StoreCustomerFormlogic = ({ openTransactionsession, orderDetails }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const triggerToast = useContext(ToastContext);

  const submitCustomerDetails = () => {
    setIsSubmitting(true);
    postStoreCustomerDetails({
      customer_phone_number: customerPhoneNumber,
      customer_name: customerName,
      order_id: orderDetails?.id,
    })
      .then(() => {
        setIsSubmitting(false);
        openTransactionsession();
      })
      .catch((error) => {
        setIsSubmitting(false);
        if (error?.response === undefined) return;
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      });
  };

  return {
    isSubmitting,
    customerName,
    customerPhoneNumber,
    setCustomerPhoneNumber,
    setCustomerName,
    submitCustomerDetails,
  };
};
export default StoreCustomerFormlogic;
