import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const OnboardingGuardLogic = () => {
  const [grantAccess, setGrantAcces] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("refreshToken"))
      navigate("/app/dashboard?all_business=true");
    setGrantAcces(true);
  }, []);
  return { grantAccess };
};

export default OnboardingGuardLogic;
