import "./EditCampaign.scss";
import primaryComponents from "../../../components/primaryComponents";
import secondaryComponents from "../../../components/secondaryComponents";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEditCampaignLogic } from "./EditCampaignLogic";

const EditCampaign = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { campaign, isFetchingCampaign } = useEditCampaignLogic({
    campaign_id: params.campaignId,
  });

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Loyalty - Edit campaign"
      description="Create campaign on lumi merchant and see an improve customer loyalty"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          {/* back btn */}
          <primaryComponents.Button
            classNames="btn btn--outline mb-4"
            onClick={() => navigate("/app/loyalty/manage")}
          >
            <FontAwesomeIcon
              icon="angle-left"
              className="mr-3"
              style={{ fontSize: "18px" }}
            />
            <span>Back</span>
          </primaryComponents.Button>

          <div className="edit-campaign__content mt-4">
            <div className="edit-campaign__content__header">
              <h3 className="font-weight-semibold">Edit Campaign</h3>
            </div>
            <div className="edit-campaign__content__body">
              <secondaryComponents.LoaderHelper
                classNames={"mt-5"}
                isLoading={isFetchingCampaign}
              >
                <secondaryComponents.EditCampaignForm campaign={campaign} />
              </secondaryComponents.LoaderHelper>
            </div>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default EditCampaign;
