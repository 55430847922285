/* eslint-disable react/jsx-key */
import "./TerminalRequestTable.scss";
import useTerminalRequestTable from "./TerminalRequestTableLogic";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";
import Images from "../../../assets/images";

const TerminalRequestTable = () => {
  const { instance, isLoading, terminalsRequest } = useTerminalRequestTable();
  const { rows, prepareRow } = instance;

  return (
    <div className="terminal-request-table">
      <div className="terminal-request-table__header">
        {/* header section */}
        <div style={{ minWidth: "250px", width: "700px" }}>
          <primaryComponents.SearchField
            trigger={() => {}}
            value=""
            placeholder="Search for a terminal"
          />
        </div>
      </div>
      <div className="table-section">
        <secondaryComponents.LoaderHelper
          isLoading={isLoading}
          classNames="mt-5"
        >
          {terminalsRequest.length > 0 ? (
            <>
              <table {...instance.getTableProps()}>
                <thead>
                  {instance.headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          className="font-weight-semibold"
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...instance.getTableBodyProps()}>
                  {instance.rows.map((row, i) => {
                    instance.prepareRow(row);
                    return (
                      <tr {...row.getRowProps({ key: i })}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {/* display on mobile */}
              <div className="mobile-table">
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <div
                      className="mobile-table__row"
                      {...row.getRowProps({ key: i })}
                    >
                      {row.cells.map((cell, j) => (
                        <div
                          className="row-item"
                          {...cell.getCellProps({ key: j })}
                        >
                          <h6 className="row-item__title">
                            {cell.column.Header}
                          </h6>
                          <p className="row-item__value">
                            {cell.render("Cell")}
                          </p>
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            // show if terminals is empty
            <div className="text-center mt-5 mb-5">
              <img
                width="250px"
                height="262px"
                src={Images.tnxEmptyState}
                alt="empty state"
              />
              <h4>No data available</h4>
            </div>
          )}
        </secondaryComponents.LoaderHelper>
      </div>
    </div>
  );
};

export default TerminalRequestTable;
