import { Route, Routes, Navigate } from "react-router-dom";
// page imports
import appPages from "./pages/appPages/index";
import authPages from "./pages/authPages";
import secondaryComponents from "./components/secondaryComponents";
import layouts from "./layouts";
import Error404 from "./pages/404Page/404Page";
import storePages from "./pages/storePages";

const AnimatedRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" replace />} />
      {/* onboarding routes */}
      <Route
        path="/signup"
        element={
          <secondaryComponents.OnboardingGuard>
            <authPages.Signup />
          </secondaryComponents.OnboardingGuard>
        }
      />
      <Route
        path="/signup/team-invite"
        element={
          <secondaryComponents.OnboardingGuard>
            <authPages.InviteSignup />
          </secondaryComponents.OnboardingGuard>
        }
      />
      <Route
        path="/login"
        element={
          <secondaryComponents.OnboardingGuard>
            <authPages.Login />
          </secondaryComponents.OnboardingGuard>
        }
      />
      <Route
        path="/login/otp-verification"
        element={
          <secondaryComponents.OnboardingGuard>
            <authPages.LoginOtpVerification />
          </secondaryComponents.OnboardingGuard>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <secondaryComponents.OnboardingGuard>
            <authPages.ForgotPassword />
          </secondaryComponents.OnboardingGuard>
        }
      />
      <Route
        path="/reset-password"
        element={
          <secondaryComponents.OnboardingGuard>
            <authPages.ResetPassword />
          </secondaryComponents.OnboardingGuard>
        }
      />
      <Route
        path="/email-verification"
        element={
          <secondaryComponents.OnboardingGuard>
            <authPages.EmailVerification />
          </secondaryComponents.OnboardingGuard>
        }
      />
      {/* app routes */}
      <Route
        path="/app"
        element={
          <secondaryComponents.AppGuard>
            <layouts.AppLayout />
          </secondaryComponents.AppGuard>
        }
      >
        <Route
          path="dashboard"
          element={
            <secondaryComponents.AppGuard>
              <appPages.Dashboard />
            </secondaryComponents.AppGuard>
          }
        >
          <Route
            index
            element={
              <secondaryComponents.AppGuard>
                <appPages.Overview />
              </secondaryComponents.AppGuard>
            }
          />

          <Route
            path="cashflow-analytics"
            element={
              <secondaryComponents.AppGuard>
                <appPages.CashflowAnalytics />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path="customer-analytics"
            element={
              <secondaryComponents.AppGuard>
                <appPages.CustomerAnalytics />
              </secondaryComponents.AppGuard>
            }
          />
        </Route>
        {/* loyalty */}
        <Route
          path="loyalty"
          element={
            <secondaryComponents.AppGuard>
              <layouts.LoyaltyLayout />
            </secondaryComponents.AppGuard>
          }
        >
          <Route
            index
            element={
              <secondaryComponents.AppGuard>
                <appPages.LoyaltyHome />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path="create-campaign"
            element={
              <secondaryComponents.AppGuard>
                <appPages.CreateCampaign />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path="create-giftcard"
            element={
              <secondaryComponents.AppGuard>
                <appPages.CreateGiftCard />
              </secondaryComponents.AppGuard>
            }
          />
          {/* manage */}
          <Route
            path="manage"
            element={
              <secondaryComponents.AppGuard>
                <appPages.AllCampaigns />
              </secondaryComponents.AppGuard>
            }
          >
            <Route
              index
              element={
                <secondaryComponents.AppGuard>
                  <secondaryComponents.CampaignList />
                </secondaryComponents.AppGuard>
              }
            />
            <Route
              path="giftcards"
              element={
                <secondaryComponents.AppGuard>
                  <appPages.AllGiftCards />
                </secondaryComponents.AppGuard>
              }
            />
          </Route>
          {/* customer loyalty */}
          <Route path="customers">
            <Route
              path=""
              element={
                <secondaryComponents.AppGuard>
                  <layouts.CustomerSubLayout></layouts.CustomerSubLayout>
                </secondaryComponents.AppGuard>
              }
            >
              <Route
                path=""
                element={
                  <secondaryComponents.AppGuard>
                    <appPages.LoyaltyCustomer />
                  </secondaryComponents.AppGuard>
                }
              />
              <Route
                path="history"
                element={
                  <secondaryComponents.AppGuard>
                    <appPages.LoyaltyCustomeHistory />
                  </secondaryComponents.AppGuard>
                }
              />
            </Route>
            <Route
              path=":id"
              element={
                <secondaryComponents.AppGuard>
                  <appPages.LoyaltyCustomerDetails />
                </secondaryComponents.AppGuard>
              }
            />
          </Route>

          <Route
            path="account-status"
            element={
              <secondaryComponents.AppGuard>
                <appPages.LoyaltyNotification />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path="edit-campaign/:campaignId"
            element={
              <secondaryComponents.AppGuard>
                <appPages.EditCampaign />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path="edit-giftcard/:giftcardId"
            element={
              <secondaryComponents.AppGuard>
                <appPages.EditGiftCard />
              </secondaryComponents.AppGuard>
            }
          />
        </Route>
        <Route
          path="transactions"
          element={
            <secondaryComponents.AppGuard>
              <layouts.TransactionsLayout />
            </secondaryComponents.AppGuard>
          }
        >
          <Route
            index
            element={
              <secondaryComponents.AppGuard>
                <appPages.AllTransactions />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path=":transactionId"
            element={
              <secondaryComponents.AppGuard>
                <appPages.TransactionDetails />
              </secondaryComponents.AppGuard>
            }
          />
        </Route>
        <Route
          path="transfers"
          element={
            <secondaryComponents.AppGuard>
              <layouts.TransfersLayout />
            </secondaryComponents.AppGuard>
          }
        >
          <Route
            path=""
            element={
              <secondaryComponents.AppGuard>
                <appPages.AllTransfers />
              </secondaryComponents.AppGuard>
            }
          >
            <Route
              path="pending"
              element={
                <secondaryComponents.AppGuard>
                  <appPages.PendingTransfers />
                </secondaryComponents.AppGuard>
              }
            />
            <Route
              path="approved"
              element={
                <secondaryComponents.AppGuard>
                  <appPages.ApprovedTransfers />
                </secondaryComponents.AppGuard>
              }
            />
            <Route
              path="rejected"
              element={
                <secondaryComponents.AppGuard>
                  <appPages.RejectedTransfers />
                </secondaryComponents.AppGuard>
              }
            />
            <Route
              path="beneficiaries"
              element={
                <secondaryComponents.AppGuard>
                  <appPages.SavedBeneficiaries />
                </secondaryComponents.AppGuard>
              }
            />
            <Route
              index
              element={
                <secondaryComponents.AppGuard>
                  <appPages.ApprovedTransfers />
                </secondaryComponents.AppGuard>
              }
            />
          </Route>
          <Route
            path=":transferId"
            element={
              <secondaryComponents.AppGuard>
                <appPages.TransferDetails />
              </secondaryComponents.AppGuard>
            }
          />
        </Route>

        <Route
          path="sub-accounts"
          element={
            <secondaryComponents.AppGuard>
              <layouts.SubAccountLayout />
            </secondaryComponents.AppGuard>
          }
        >
          <Route
            path="create"
            element={
              <secondaryComponents.AppGuard>
                <appPages.CreateSubAccount />
              </secondaryComponents.AppGuard>
            }
          ></Route>
          <Route
            index
            element={
              <secondaryComponents.AppGuard>
                <appPages.AllSubAccounts />
              </secondaryComponents.AppGuard>
            }
          ></Route>
        </Route>
        <Route
          path="pos"
          element={
            <secondaryComponents.AppGuard>
              <layouts.TerminalsLayout />
            </secondaryComponents.AppGuard>
          }
        >
          <Route
            path=""
            element={
              <secondaryComponents.AppGuard>
                <appPages.Terminals />
              </secondaryComponents.AppGuard>
            }
          >
            <Route
              index
              element={
                <secondaryComponents.AppGuard>
                  <appPages.AllTerminals />
                </secondaryComponents.AppGuard>
              }
            />
            <Route
              path="request"
              element={
                <secondaryComponents.AppGuard>
                  <appPages.TerminalRequests />
                </secondaryComponents.AppGuard>
              }
            />
          </Route>
          <Route
            path=":terminalId"
            element={
              <secondaryComponents.AppGuard>
                <appPages.TerminalDetails />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path=":terminalId/credentials"
            element={
              <secondaryComponents.AppGuard>
                <appPages.TerminalCredentials />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path="add"
            element={
              <secondaryComponents.AppGuard>
                <appPages.NewTerminal />
              </secondaryComponents.AppGuard>
            }
          />
        </Route>
        <Route
          path="tags"
          element={
            <secondaryComponents.AppGuard>
              <layouts.TagsLayout />
            </secondaryComponents.AppGuard>
          }
        >
          <Route
            index
            element={
              <secondaryComponents.AppGuard>
                <appPages.Tags />
              </secondaryComponents.AppGuard>
            }
          />
        </Route>
        <Route
          path="documentation"
          element={
            <secondaryComponents.AppGuard>
              <appPages.Documentation />
            </secondaryComponents.AppGuard>
          }
        />
        {/* customers */}
        <Route
          path="customers"
          element={
            <secondaryComponents.AppGuard>
              <layouts.CustomersLayout />
            </secondaryComponents.AppGuard>
          }
        >
          <Route
            path=""
            element={
              <secondaryComponents.AppGuard>
                <appPages.AllCustomers />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path=":customerId"
            element={
              <secondaryComponents.AppGuard>
                <appPages.CustomerDetails />
              </secondaryComponents.AppGuard>
            }
          />
        </Route>
        {/* kyb */}
        <Route
          path="kyb-verification"
          element={
            <secondaryComponents.AppGuard>
              <appPages.KYBVerification />
            </secondaryComponents.AppGuard>
          }
        />
        {/* settings */}
        <Route
          path="settings"
          element={
            <secondaryComponents.AppGuard>
              <layouts.SettingsLayout />
            </secondaryComponents.AppGuard>
          }
        >
          <Route
            path="users"
            element={
              <secondaryComponents.AppGuard>
                <appPages.Users />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path="settlements"
            element={
              <secondaryComponents.AppGuard>
                <appPages.Settlements />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path="webhooks"
            element={
              <secondaryComponents.AppGuard>
                <appPages.Webhooks />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path="api-keys"
            element={
              <secondaryComponents.AppGuard>
                <appPages.ApiKeys />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path="account-settings"
            element={
              <secondaryComponents.AppGuard>
                <appPages.AccountSettings />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path="snap-and-earn"
            element={
              <secondaryComponents.AppGuard>
                <appPages.SnapAndEarn />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path="profile"
            element={
              <secondaryComponents.AppGuard>
                <appPages.Profile />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            index
            element={
              <secondaryComponents.AppGuard>
                <appPages.Profile />
              </secondaryComponents.AppGuard>
            }
          />
        </Route>
        <Route
          path="products"
          element={
            <secondaryComponents.AppGuard>
              <layouts.InventoryLayout />
            </secondaryComponents.AppGuard>
          }
        >
          <Route
            path="stores"
            element={
              <secondaryComponents.AppGuard>
                <appPages.Stores />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path="stores/:id"
            element={
              <secondaryComponents.AppGuard>
                <appPages.ShopDetails />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path="categories"
            element={
              <secondaryComponents.AppGuard>
                <appPages.ProductCategories />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path="sales-transactions"
            element={
              <secondaryComponents.AppGuard>
                <appPages.SalesTransactions />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            path="stock-history"
            element={
              <secondaryComponents.AppGuard>
                <appPages.StockHistory />
              </secondaryComponents.AppGuard>
            }
          />
          <Route
            index
            element={
              <secondaryComponents.AppGuard>
                <appPages.Products />
              </secondaryComponents.AppGuard>
            }
          />
        </Route>
        <Route
          path="/app/products/:id/edit"
          element={
            <secondaryComponents.AppGuard>
              <appPages.EditProduct />
            </secondaryComponents.AppGuard>
          }
        />
        <Route
          path="/app/products/:id/stock-history"
          element={
            <secondaryComponents.AppGuard>
              <appPages.RestockProduct />
            </secondaryComponents.AppGuard>
          }
        />
        <Route
          path="/app/products/add"
          element={
            <secondaryComponents.AppGuard>
              <appPages.AddProducts />
            </secondaryComponents.AppGuard>
          }
        />
        <Route
          path="/app/products/add/single"
          element={
            <secondaryComponents.AppGuard>
              <appPages.AddSingleProduct />
            </secondaryComponents.AppGuard>
          }
        />
        <Route
          path="/app/products/add/multiple"
          element={
            <secondaryComponents.AppGuard>
              <appPages.AddMultipleProducts />
            </secondaryComponents.AppGuard>
          }
        />
      </Route>
      {/* store routes */}
      <Route
        path="/store"
        element={
          <secondaryComponents.AppGuard>
            <layouts.StoreLayout />
          </secondaryComponents.AppGuard>
        }
      >
        <Route
          index
          element={
            <secondaryComponents.AppGuard>
              <storePages.ProductsPage />
            </secondaryComponents.AppGuard>
          }
        />
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};
export default AnimatedRoute;
