import { useState, useContext } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { updateUserInvite } from "../../../services/settings";
import { getRandomIntInclusive, mapUserRole } from "../../../helpers";
import localData from "../../../localdata";
import { useSelector } from "react-redux";

const EditInviteUserLogic = ({
  setShowInviteModal,
  userEmail,
  userRole,
  fetchUsersList,
  subAccountList,
}) => {
  const [email, setEmail] = useState(userEmail);
  const [role, setRole] = useState(mapUserRole[userRole]);
  const [isInviting, setIsInviting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const [roleDescription, setRoleDescription] = useState("");
  const { business_list } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const [businessId, setBusinessId] = useState([]);
  const [defaultBusinesses] = useState(() => {
    let newArray = [];
    subAccountList.forEach((item) => {
      let businessName = business_list.find(
        (business) => Number(business?.id) === item
      );
      newArray.push({ value: businessName?.id, label: businessName?.tag });
    });
    return newArray;
  });

  const resetForm = () => {
    setEmail("");
    window.resetInviteForm({ email: "" });
    setRole("");
    window.resetInviteForm({ role: "" });
    window.clearInviteError();
  };

  const getDefaultBusinesses = () => {
    let newArray = [];
    defaultBusinesses.forEach((item) => {
      newArray.push(item.value);
    });
    return newArray;
  };

  const updateUser = () => {
    setIsInviting(true);
    setErrorMessage(null);
    setRequestLoaderProgress(getRandomIntInclusive(12, 40));
    updateUserInvite({
      email: email,
      role: role === mapUserRole[userRole] ? userRole : role,
      businesses_id:
        businessId.length !== 0 ? businessId : getDefaultBusinesses(),
    })
      .then(() => {
        setIsInviting(false);
        triggerToast("User permission updated successfully", "success");
        setRequestLoaderProgress(100);
        resetForm();
        fetchUsersList();
        setShowInviteModal(false);
      })
      .catch((error) => {
        setIsInviting(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400) {
          setErrorMessage(error.response.data.detail);
          triggerToast(error.response.data.detail, "warning");
        }
        if (error.response.status === 422) {
          if (error.response.data.errors.email) {
            window.setInviteError(
              "email",
              {
                type: "validate",
                message: error.response.data.errors.email[0],
              },
              { shouldFocus: true }
            );
          }
          triggerToast(error.response.data.errors.email[0], "warning");
        }
      });
  };

  return {
    email,
    setEmail,
    role,
    setRole,
    errorMessage,
    isInviting,
    updateUser,
    resetForm,
    userRoles: localData.usersRole,
    roleDescription,
    setRoleDescription,
    subAccounts: business_list,
    businessId,
    setBusinessId,
    defaultBusinesses,
  };
};
export default EditInviteUserLogic;
