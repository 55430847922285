import "./ProductCategoryTable.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductCategoryTableLogic from "./ProductCategoryTableLogic";
import { Fragment } from "react";
import Images from "../../../assets/images";

const ProductCategoryTable = ({
  isRefresh,
  setIsRefresh,
  setInventoryAnalytics,
}) => {
  const {
    instance,
    categories,
    isFetchingCategories,
    isOpenDeleteDialog,
    isOpenEditDialog,
    selectedCategory,
    isDeletingCategory,
    setIsOpenDeleteDialog,
    setIsOpenEditDialog,
    handleDeleteProductCategory,
  } = ProductCategoryTableLogic({
    isRefresh,
    setIsRefresh,
    setInventoryAnalytics,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="product-category-table">
      <secondaryComponents.LoaderHelper
        isLoading={isFetchingCategories}
        classNames="pt-3"
      >
        {categories.length > 0 ? (
          <Fragment>
            {/* <div className="product-category-table__header"> */}
            {/* <div className="product-category-table__header__search__section">
                <primaryComponents.SearchField placeholder="Search for a product category" />
              </div> */}
            {/* <primaryComponents.Button
                                classNames={`btn btn--outline-gray ml-auto`}
                            >
                                Filter{" "}
                                <FontAwesomeIcon
                                    className="ml-3"
                                    icon="angle-down"
                                    style={{ fontSize: "16px" }}
                                />
                            </primaryComponents.Button>
                            <primaryComponents.Button
                                classNames={"btn btn--outline"}
                            >
                                Download
                            </primaryComponents.Button> */}
            {/* </div> */}
            <div className="product-category-table__content">
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup, i) => (
                    <tr
                      key={i}
                      {...headerGroup.getHeaderGroupProps({ key: i })}
                    >
                      {headerGroup.headers.map((column, i) => (
                        <th
                          key={i}
                          className="font-weight-semibold"
                          {...column.getHeaderProps({ key: i })}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr key={i} {...row.getRowProps({ key: i })}>
                        {row.cells.map((cell, i) => {
                          return (
                            <td key={i} {...cell.getCellProps({ key: i })}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* Cards */}
              <div className="product-category-table__content-mobile">
                {categories.map((product) => (
                  <div
                    key={product.id}
                    className="product-category-table__content-mobile-card"
                  >
                    <div className="product-category-table__content-mobile-card__title">
                      <p>Category Name:</p>
                      <p>{product.name}</p>
                    </div>
                    <div className="product-category-table__content-mobile-card__title">
                      <p>Created on:</p>
                      <p>
                        {new Date(product.created_at).toLocaleDateString(
                          "en-GB"
                        )}
                      </p>
                    </div>
                    <div className="product-category-table__content-mobile-card__title">
                      <p>Last Updated on:</p>
                      <p>
                        {new Date(product.updated_at).toLocaleDateString(
                          "en-GB"
                        )}
                      </p>
                    </div>
                    <div className="product-category-table__content-mobile-card__title">
                      <p>Status:</p>
                      <p className="status">{product.status}</p>
                    </div>
                    <div className="product-category-table__content-mobile-card__next">
                      <primaryComponents.Button
                        classNames={"btn btn--primary edit-record"}
                      >
                        Edit
                      </primaryComponents.Button>
                      <primaryComponents.Button
                        classNames={"btn btn--danger delete-record"}
                      >
                        Delete
                      </primaryComponents.Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="empty__state">
            <img width="250px" src={Images.productCategoryEmptyState} alt="" />
            <h4 className="font-weight-semibold mt-4 text-center">
              You have no product categories
            </h4>
            <p className="mt-2 text-center">
              Use the button above to add your categories. <br />
              When you do, they would appear here.
            </p>
          </div>
        )}
        {isOpenEditDialog && (
          <secondaryComponents.EditProductCategory
            isOpen={isOpenEditDialog}
            setIsOpen={setIsOpenEditDialog}
            category={selectedCategory}
            setIsRefresh={setIsRefresh}
          />
        )}

        {isOpenDeleteDialog && (
          <secondaryComponents.ConfirmDialog
            title="Are you sure you want to delete this
                                category?"
            isOpen={isOpenDeleteDialog}
            setIsOpen={setIsOpenDeleteDialog}
            handler={handleDeleteProductCategory}
            isLoading={isDeletingCategory}
          >
            <div
              style={{
                backgroundColor: "#F3F1FC",
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                borderRadius: "8px",
              }}
            >
              <p
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span className="dark--text font-weight-semibold mb-1">
                  Category Name
                </span>
                <span>{selectedCategory.name}</span>
              </p>
              <p
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span className="dark--text font-weight-semibold mb-1">
                  Description
                </span>
                <span>{selectedCategory.description}</span>
              </p>
            </div>
          </secondaryComponents.ConfirmDialog>
        )}
      </secondaryComponents.LoaderHelper>
    </div>
  );
};

export default ProductCategoryTable;
