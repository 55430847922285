/* eslint-disable react/jsx-key */
import "./BeneficiariesTable.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import BeneficiariesTableLogic from "./BeneficiariesTableLogic";
import Images from "../../../assets/images";

const BeneficiariesTable = () => {
  const {
    instance,
    isFetchingBeneficiaries,
    beneficiaries,
    searchBeneficiaries,
    searchValue,
    showDeleteBeneficiaryModal,
    setShowDeleteBeneficiaryModal,
    fetchBeneficiaries,
    currentBeneficiary,
    setCurrentBeneficiary,
  } = BeneficiariesTableLogic();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="beneficiaries-table">
      <div className="beneficiaries-table__content">
        <div className="beneficiaries-table__content__header">
          <div style={{ width: "700px" }}>
            <primaryComponents.SearchField
              placeholder="Search customer by name or email"
              trigger={(value) => searchBeneficiaries(value)}
              value={searchValue}
            />
          </div>
        </div>
        <div className="table-section">
          <secondaryComponents.LoaderHelper
            isLoading={isFetchingBeneficiaries}
            classNames="mt-5"
          >
            {beneficiaries.length ? (
              <>
                {/* show on desktop */}
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => {
                          return (
                            <th
                              className="font-weight-semibold"
                              {...column.getHeaderProps()}
                            >
                              {column.render("Header")}
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps({ key: i })}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                          <td>
                            <primaryComponents.Button
                              classNames="btn btn--outline-red"
                              style={{
                                height: "30px",
                                fontSize: "12px",
                                padding: "0 15px",
                              }}
                              onClick={() => {
                                setCurrentBeneficiary(beneficiaries[i]);
                                setShowDeleteBeneficiaryModal(true);
                              }}
                            >
                              Detele
                            </primaryComponents.Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                {/* display on mobile */}
                <div className="mobile-table">
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <div
                        className="mobile-table__row"
                        {...row.getRowProps({ key: i })}
                      >
                        {row.cells.map((cell, j) => (
                          <div
                            className="row-item"
                            {...cell.getCellProps({ key: j })}
                          >
                            <h6 className="row-item__title">
                              {cell.column.Header}
                            </h6>
                            <p className="row-item__value">
                              {cell.render("Cell")}
                            </p>
                          </div>
                        ))}
                        <primaryComponents.Button
                          classNames="ml-auto btn btn--outline-red"
                          style={{
                            height: "30px",
                            fontSize: "12px",
                            padding: "0 15px",
                          }}
                          onClick={() => {
                            setCurrentBeneficiary(beneficiaries[i]);
                            setShowDeleteBeneficiaryModal(true);
                          }}
                        >
                          Detele
                        </primaryComponents.Button>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div className="text-center mt-5">
                <img
                  src={Images.customersEmptyState}
                  alt="No Customers Found"
                  width="250px"
                  height="262px"
                />
                <h4>No data available</h4>
                <p>When you have beneficiaries, they would appear here.</p>
              </div>
            )}
          </secondaryComponents.LoaderHelper>
        </div>
        {/* show pagination component only when data is available */}
        {/* {beneficiaries.length > 0 && (
          <secondaryComponents.Pagination
            totalPageNumber={customersPageDetails.last_page}
            handlePageClick={(value) => gotoPage(value.selected + 1)}
            currentPage={customersPageDetails.current_page}
          />
        )} */}
      </div>

      {showDeleteBeneficiaryModal && (
        <secondaryComponents.DeleteBeneficiary
          showDeleteBeneficiaryModal={showDeleteBeneficiaryModal}
          setShowDeleteBeneficiaryModal={setShowDeleteBeneficiaryModal}
          fetchBeneficiaries={fetchBeneficiaries}
          currentBeneficiary={currentBeneficiary}
        />
      )}
    </div>
  );
};

export default BeneficiariesTable;
