import { useMemo } from "react";
import { useTable } from "react-table";
import Icon from "../../../assets/icons";

const StoreInventoryTableLogic = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "product_name",
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "In-stock",
        accessor: "in_stock",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              color: "#19AE57",
              height: "28px",
              cursor: "pointer",
              backgroundColor: "#E5FFF0",
              padding: "8px 16px",
              borderRadius: "8px",
            }}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "",
        accessor: "action",
        Cell: () => (
          <span style={{ cursor: "pointer" }}>
            <img src={Icon.option_alt} alt="" />
          </span>
        ),
      },
    ],
    []
  );

  const rows = useMemo(
    () => [
      {
        product_name: "Jean Jacket",
        category: "Fashion",
        price: "N10500.00",
        in_stock: "33",
        status: "Active",
        action: "",
      },
    ],
    []
  );

  const instance = useTable({ columns, data: rows });

  return { instance };
};

export default StoreInventoryTableLogic;
