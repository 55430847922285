import { useState, useContext, useEffect } from "react";
import { refreshTransactions } from "../../../../services/transaction";
import { ToastContext, RequestLoader } from "../../../../hooks/context";
import { getRandomIntInclusive } from "../../../../helpers";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AllTransactionsLogic = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isRefreshingTransactions, setIsRefreshingTransactions] =
    useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const [refeshTnx, setRefreshTnx] = useState(false);
  const { business_id, business_list, business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const [showAllBusiness, setShowAllBusiness] = useState(
    queryParams.get("all_business")
  );
  const [newBusinessId, setNewBusinessId] = useState(business_id);
  const navigate = useNavigate();
  const [showStatementOfAccount, setShowStatementOfAccount] = useState(false);

  useEffect(() => {
    if (showAllBusiness === undefined) return;
    setRefreshTnx(true);
  }, [showAllBusiness, newBusinessId]);

  const RefreshTransactions = () => {
    setRequestLoaderProgress(getRandomIntInclusive(5, 20));
    setIsRefreshingTransactions(true);
    refreshTransactions()
      .then(() => {
        setRequestLoaderProgress(100);
        setIsRefreshingTransactions(false);
        setRefreshTnx(true);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsRefreshingTransactions(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const filterBaseOnAccount = (businessId) => {
    let newParams = queryParams;
    newParams.delete("business_id");
    newParams.delete("all_business");

    if (businessId === "all") {
      newParams.set("all_business", "true");
      setShowAllBusiness("true");
    } else {
      newParams.set("business_id", businessId);
      setNewBusinessId(businessId);
      setShowAllBusiness(false);
    }

    let newQueries = "?";
    newParams.forEach((item, key) => {
      newQueries =
        newQueries === "?"
          ? newQueries + `${key}=${item}`
          : newQueries + `&${key}=${item}`;
    });
    // eslint-disable-next-line no-restricted-globals
    navigate(`${location.pathname}${newQueries}`);
  };

  return {
    RefreshTransactions,
    isRefreshingTransactions,
    refeshTnx,
    setRefreshTnx,
    business_list,
    business_role,
    filterBaseOnAccount,
    newBusinessId,
    showAllBusiness,
    showStatementOfAccount,
    setShowStatementOfAccount,
  };
};
export default AllTransactionsLogic;
