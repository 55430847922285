import { Controller } from "react-hook-form";
import StoreCheckoutValidation from "./Validation";
import StoreCheckoutLogic from "./StoreCheckoutLogic";
import "./StoreCheckout.scss";
import secondaryComponents from "..";
import CardPayment from "./CardPayment";
import CashPayment from "./CashPayment";
import TransferPayment from "./TransferPayment";
import StoreCustomerForm from "./StoreCustomerForm";
import TransactionDetails from "./TransactionDetails";
import ReceiptTerminals from "./ReceiptTerminals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StoreCheckout = ({ closeStoreCheckout, setRefreshProducts }) => {
  const {
    checkoutType,
    isLoadingOrder,
    orderDetails,
    isCustomerForm,
    isPaymentDone,
    isTransactionDetails,
    isSendReceipt,
    isReceiptTerminal,
    closePaymentSession,
    cancelCurrentOrder,
    openTransactionsession,
    setCheckoutType,
    openSendReceipt,
    gotoTransactionDetails,
    gotoReceiptTerminals,
  } = StoreCheckoutLogic({ closeStoreCheckout, setRefreshProducts });
  const { control } = StoreCheckoutValidation(checkoutType);

  return (
    <secondaryComponents.LoaderHelper
      isLoading={isLoadingOrder}
      classNames="mt-5"
    >
      {!isPaymentDone ? (
        <div className="store-checkout">
          <FontAwesomeIcon
            onClick={cancelCurrentOrder}
            className="d-block ml-auto"
            icon="times"
            style={{ fontSize: "25px" }}
          />

          <div className="mt-4">
            <h5 className="mb-3 font-weight-normal">Select Payment Method</h5>
            <div className="store-checkout__purchase-options">
              {/* Cash payment */}
              <div className="store-checkout__purchase-option">
                <Controller
                  defaultValue={checkoutType}
                  name="checkout_type"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <secondaryComponents.WalletOption
                      title={"Cash"}
                      name="purchase-option"
                      value={"cash"}
                      checked={checkoutType === "cash"}
                      onChange={(option) => {
                        onChange(option);
                        setCheckoutType(option);
                      }}
                    />
                  )}
                />
              </div>
              {/* Card payment */}
              <div className="store-checkout__purchase-option">
                <Controller
                  defaultValue={checkoutType}
                  name="checkout_type"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <secondaryComponents.WalletOption
                      title={"Card"}
                      name="purchase-option"
                      value={"card"}
                      checked={checkoutType === "card"}
                      onChange={(option) => {
                        onChange(option);
                        setCheckoutType(option);
                      }}
                    />
                  )}
                />
              </div>
              {/* Transfer payment */}
              <div className="store-checkout__purchase-option">
                <Controller
                  defaultValue={checkoutType}
                  name="checkout_type"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <secondaryComponents.WalletOption
                      title={"Transfer"}
                      name="purchase-option"
                      value={"transfer"}
                      checked={checkoutType === "transfer"}
                      onChange={(option) => {
                        onChange(option);
                        setCheckoutType(option);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            {/* cash payment section */}
            {checkoutType === "cash" && (
              <CashPayment
                orderDetails={orderDetails}
                closePaymentSession={closePaymentSession}
              />
            )}
            {/* card payment section */}
            {checkoutType === "card" && (
              <CardPayment
                orderDetails={orderDetails}
                closePaymentSession={closePaymentSession}
              />
            )}
            {/* transfer payment section */}
            {checkoutType === "transfer" && (
              <TransferPayment
                orderDetails={orderDetails}
                closePaymentSession={closePaymentSession}
              />
            )}
          </div>
        </div>
      ) : (
        <>
          {/* cusstomer form */}
          {isCustomerForm && (
            <StoreCustomerForm
              openTransactionsession={openTransactionsession}
              closeStoreCheckout={closeStoreCheckout}
              orderDetails={orderDetails}
            />
          )}

          {isTransactionDetails && (
            <TransactionDetails
              orderDetails={orderDetails}
              closeStoreCheckout={closeStoreCheckout}
              openSendReceipt={openSendReceipt}
              gotoReceiptTerminals={gotoReceiptTerminals}
            />
          )}

          {isReceiptTerminal && (
            <ReceiptTerminals
              orderDetails={orderDetails}
              closeStoreCheckout={closeStoreCheckout}
              gotoTransactionDetails={gotoTransactionDetails}
            />
          )}

          {isSendReceipt && (
            <secondaryComponents.SendReceiptWidget
              closeWidget={closeStoreCheckout}
              transactionId={orderDetails?.id}
              receiptType="storeOrder"
              goBack={gotoTransactionDetails}
            />
          )}
        </>
      )}
    </secondaryComponents.LoaderHelper>
  );
};
export default StoreCheckout;
