import { useContext, useState } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { updateGiftCard } from "../../../services/giftcards";

export const useGiftCardItemLogic = ({ giftcard_id, giftcard_status }) => {
  const [status, setStatus] = useState(
    giftcard_status === "ACTIVE" ? true : false
  );

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const toggleGiftCardStatus = (data) => {
    let _status = giftcard_status;

    if (status === true && giftcard_status === "ACTIVE") {
      _status = "INACTIVE";
    } else if (status === false && giftcard_status === "INACTIVE") {
      _status = "ACTIVE";
    }

    updateGiftCard(giftcard_id, _status)
      .then((response) => {
        setRequestLoaderProgress(100);
        triggerToast(response.data?.detail, "success");
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  return { toggleGiftCardStatus, setStatus, status };
};
