import primaryComponents from "../../primaryComponents";
import ProfileBusinessInfoFormLogic from "./ProfileBusinessInfoFormLogic";
import ProfileBusinessInfoFormValidation from "./ProfileBusinessInfoFormValidation";
import localData from "../../../localdata";
import { useEffect } from "react";
import { Controller } from "react-hook-form";

const ProfileBusinessInfoForm = () => {
  const {
    businessName,
    setBusinessName,
    businessSize,
    setBusinessSize,
    businessEmail,
    setBusinessEmail,
    supportEmail,
    setSupportEmail,
    businessPhoneNumber,
    setBusinessPhoneNumber,
    supportPhoneNumber,
    setSupportPhoneNumber,
    businessAddress,
    setbusinessAddress,
    updateBusinessInfo,
    isSubmitting,
    setIsEdited,
    isEdited,
  } = ProfileBusinessInfoFormLogic();

  const {
    register,
    handleSubmit,
    setError,
    control,
    trigger,
    formState: { errors },
  } = ProfileBusinessInfoFormValidation(
    businessName,
    businessSize,
    businessEmail,
    supportEmail,
    businessPhoneNumber,
    supportPhoneNumber,
    businessAddress
  );

  useEffect(() => {
    window.setBusinessInfoError = setError;
  }, []);

  return (
    <form
      onSubmit={handleSubmit(updateBusinessInfo)}
      className="mt-4 business-info__form"
    >
      <div>
        <Controller
          name="businessName"
          control={control}
          defaultValue={businessName}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label="Business Name"
              placeholder="Enter your business name"
              isRequired={true}
              type="text"
              name="businessName"
              errorMessage={errors.businessName}
              onChange={({ target: { value } }) => {
                onChange(value);
                setBusinessName(value);
              }}
              onKeyUp={() =>
                errors.businessName !== undefined && trigger("businessName")
              }
              register={register}
            />
          )}
        />
      </div>
      <div className="mt-1">
        <Controller
          control={control}
          defaultValue={businessSize}
          name="businessSize"
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              label="Business size"
              isRequired={true}
              placeholder="Enter business size"
              value={businessSize}
              options={localData.businessSizeOptions}
              errorMessage={errors.businessSize}
              onChange={(value) => {
                onChange(value);
                setBusinessSize(value);
                setIsEdited(true);
              }}
            />
          )}
        />
      </div>
      {/* business email */}
      <div className="mt-1">
        <Controller
          name="businessEmail"
          control={control}
          defaultValue={businessEmail}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label="Business Email Address"
              type="email"
              isRequired={true}
              placeholder="Enter business email address"
              name="businessEmail"
              value={businessEmail}
              errorMessage={errors.businessEmail}
              register={register}
              onChange={({ target: { value } }) => {
                onChange(value);
                setBusinessEmail(value);
                setIsEdited(true);
              }}
              onKeyUp={() =>
                errors.businessEmail !== undefined && trigger("businessEmail")
              }
            />
          )}
        />
      </div>
      {/* support email */}
      <div className="mt-1">
        <Controller
          name="supportEmail"
          control={control}
          defaultValue={supportEmail}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label="Support/Chargeback Email Address"
              type="email"
              isRequired={false}
              placeholder="Enter support email address"
              name="supportEmail"
              value={supportEmail}
              errorMessage={errors.supportEmail}
              register={register}
              onChange={({ target: { value } }) => {
                onChange(value);
                setSupportEmail(value);
                setIsEdited(true);
              }}
              onKeyUp={() =>
                errors.supportEmail !== undefined && trigger("supportEmail")
              }
            />
          )}
        />
      </div>
      <div className="mt-1">
        <Controller
          name="businessPhoneNumber"
          control={control}
          defaultValue={businessPhoneNumber}
          render={({ field: { onChange } }) => (
            <primaryComponents.PhoneNumberInputField
              label="Business Phone Number"
              isRequired={false}
              placeholder="Enter business phone number"
              name="businessPhoneNumber"
              value={businessPhoneNumber}
              errorMessage={errors.businessPhoneNumber}
              register={register}
              onChange={({ target: { value } }) => {
                onChange(value);
                setBusinessPhoneNumber(value);
                setIsEdited(true);
              }}
              onKeyUp={() =>
                errors.businessPhoneNumber !== undefined &&
                trigger("businessPhoneNumber")
              }
            />
          )}
        />
      </div>
      <div className="mt-1">
        <Controller
          name="supportPhoneNumber"
          control={control}
          defaultValue={supportPhoneNumber}
          render={({ field: { onChange } }) => (
            <primaryComponents.PhoneNumberInputField
              label="Support Phone Number"
              isRequired={false}
              placeholder="Enter business phone number"
              name="supportPhoneNumber"
              value={supportPhoneNumber}
              errorMessage={errors.supportPhoneNumber}
              register={register}
              onChange={({ target: { value } }) => {
                onChange(value);
                setSupportPhoneNumber(value);
                setIsEdited(true);
              }}
              onKeyUp={() =>
                errors.supportPhoneNumber !== undefined &&
                trigger("supportPhoneNumber")
              }
            />
          )}
        />
      </div>
      <div className="mt-1">
        <Controller
          name="businessAddress"
          control={control}
          defaultValue={businessAddress}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label="Business Address"
              isRequired={false}
              placeholder="Enter your business address"
              name="businessAddress"
              value={businessAddress}
              errorMessage={errors.businessAddress}
              register={register}
              onChange={({ target: { value } }) => {
                onChange(value);
                setbusinessAddress(value);
                setIsEdited(true);
              }}
              onKeyUp={() =>
                errors.businessAddress !== undefined &&
                trigger("businessAddress")
              }
            />
          )}
        />
      </div>
      <div className="d-flex justify-content-end">
        <primaryComponents.Button
          isDisabled={!isEdited || isSubmitting}
          isLoading={isSubmitting}
          loadingText="Saving..."
          classNames={"btn btn--primary"}
          type="submit"
        >
          Save Changes
        </primaryComponents.Button>
      </div>
    </form>
  );
};

export default ProfileBusinessInfoForm;
