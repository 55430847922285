import "./Analytics.scss";

const Analytics = () => {
  return (
    <div className="analytics-page">
      <h3>Analytics Page</h3>
    </div>
  );
};

export default Analytics;
