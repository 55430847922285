import styles from "./ProductVariantCard.module.scss";
import Icon from "../../../assets/icons";
import { formatCurrency } from "../../../helpers";
import primaryComponents from "../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useProductVariantCard from "./useProductVariantCard";
import { motion } from "framer-motion";
import { PRODUCT_DEFAULT_ICONS } from "../../../helpers/constants";

const ProductVariantCard = ({
  product,
  updatingCart,
  cartItems = undefined,
  handleAddToCart,
  handleReduceCartItem,
  selectedItemId,
  productIndex,
}) => {
  const { variantOpened, setVariantOpened } = useProductVariantCard();
  const getCartItemsViaVariant = (variantId) => {
    const fountCartItem = cartItems.find(
      (item) =>
        item?.product?.id === product?.id && item?.variant?.id === variantId
    );
    return fountCartItem;
  };

  return (
    <div className={styles.productCard}>
      <div
        className={`${styles.productCard__top}  ${
          variantOpened ? styles.active : ""
        }`}
        onClick={() => setVariantOpened(!variantOpened)}
      >
        {/* left section */}
        <div className={styles.left}>
          <div className={styles.productImage}>
            <img
              src={
                product?.image_url === "" || product?.image_url === null
                  ? PRODUCT_DEFAULT_ICONS[productIndex % 5]
                  : product?.image_url
              }
              alt=""
            />
          </div>
          <p className="dark--text">{product?.name}</p>
        </div>
        {/* middle section */}
        <div className={styles.middle}>
          <p className="d-flex align-items-center">
            <span
              className="error mr-1"
              style={{
                width: "5px",
                height: "5px",
                borderRadius: "5px",
              }}
            ></span>
            <span className="font-weight-semibold dark--text">
              {product?.variants?.length}{" "}
              {product?.variants?.length > 1 ? "Variants" : "Variant"}
            </span>
          </p>
          <p className={`${styles.stock}`}>
            <img src={Icon.clock_time} alt="" width="14px" />
            <span>
              {product?.variants?.reduce(
                (partialSum, a) => partialSum + a.stock_quantity,
                0
              )}{" "}
              in stock
            </span>
          </p>
        </div>
        {/* right section */}
        <div className={styles.right}>
          <primaryComponents.Button
            classNames={`btn btn--outline ${styles.viewBtn}`}
            onClick={() => setVariantOpened(true)}
          >
            View Variants
          </primaryComponents.Button>
        </div>
      </div>

      {variantOpened && (
        <motion.div
          initial={{ y: -30 }}
          animate={{ y: 0 }}
          className={`${styles.productCard__bottom} ${styles.active}`}
        >
          <h6 className={styles.variantHeader}>Variants</h6>
          {product?.variants?.map((item, index) => (
            <div className={styles.productSubCard} key={index}>
              <div className={styles.left}>
                <p className="dark--text">{item?.option}:</p>
                <p>{item?.value}</p>
              </div>

              <div className={styles.middle}>
                <p className="d-flex align-items-center">
                  <span
                    className="error mr-1"
                    style={{
                      width: "5px",
                      height: "5px",
                      borderRadius: "5px",
                    }}
                  ></span>
                  <span className="font-weight-semibold dark--text">
                    {formatCurrency(item?.selling_price)}
                  </span>
                </p>
                <p
                  className={`${styles.stock} ${
                    item?.stock_quantity <= product?.minimum_stock_quantity &&
                    styles.lowQuantity
                  }`}
                >
                  <img src={Icon.clock_time} alt="" width="14px" />
                  <span>{item?.stock_quantity} in stock</span>
                </p>

                <p className="dark--text">
                  <span>Expiry: </span>{" "}
                  <span className="font-weight-semibold">
                    {item?.expiry_date || "N/A"}
                  </span>
                </p>
              </div>

              {item?.stock_quantity > 0 ? (
                <div className={styles.right}>
                  {getCartItemsViaVariant(item?.id) !== undefined ? (
                    <div className={styles.product__actions}>
                      <button
                        onClick={(event) => {
                          event.stopPropagation();
                          handleReduceCartItem(
                            product?.id,
                            getCartItemsViaVariant(item?.id)?.quantity - 1,
                            getCartItemsViaVariant(item?.id)?.id,
                            getCartItemsViaVariant(item?.id)?.variant?.id
                          );
                        }}
                        disabled={updatingCart}
                        className={styles.decrement}
                      >
                        -
                      </button>
                      {updatingCart && selectedItemId === item?.id ? (
                        <FontAwesomeIcon
                          icon={["fas", "spinner"]}
                          className="fa-spin purple--text"
                          style={{ fontSize: "15px", width: "25px" }}
                        />
                      ) : (
                        <p className={`${styles.count} dark--text`}>
                          {getCartItemsViaVariant(item?.id)?.quantity || 0}
                        </p>
                      )}
                      <button
                        onClick={(event) => {
                          event.stopPropagation();
                          handleAddToCart(
                            product?.id,
                            getCartItemsViaVariant(item?.id)?.quantity + 1,
                            item?.id
                          );
                        }}
                        disabled={updatingCart}
                        className={styles.increment}
                      >
                        +
                      </button>
                    </div>
                  ) : (
                    <primaryComponents.Button
                      classNames={`btn btn--primary ${styles.addToCartBtn}`}
                      isLoading={updatingCart && selectedItemId === item?.id}
                      isDisabled={updatingCart && selectedItemId === item?.id}
                      loadingText="Adding..."
                      showSpinner={false}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleAddToCart(product?.id, 1, item?.id);
                      }}
                    >
                      Add to cart
                    </primaryComponents.Button>
                  )}
                </div>
              ) : (
                <div className={styles.right}></div>
              )}
            </div>
          ))}
        </motion.div>
      )}
    </div>
  );
};

export default ProductVariantCard;
