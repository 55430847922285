import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { getTransactionDetails } from "../../../../services/transaction";
import { ToastContext, RequestLoader } from "../../../../hooks/context";
import { getRandomIntInclusive } from "../../../../helpers";
import { useSelector } from "react-redux";

const TransactionDetailsLogic = () => {
  const [transactionDetails, setTransactionDetails] = useState({
    customer: {},
  });
  const [isFetchingTnxDetails, setIsFetchingTnxDetails] = useState(false);
  const [copiedTnxId, setCopiedTnxId] = useState(false);
  const [openSendReceiptWidget, setOpenSendReceiptWidget] = useState(false);
  const [openLinkCustomerWidget, setOpenLinkCustomerWidget] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { transactionId } = useParams();
  const { business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );

  useEffect(() => {
    handleFetchTransactionDetails();
  }, []);

  useEffect(() => {
    if (copiedTnxId === false) return;
    setTimeout(() => {
      setCopiedTnxId(false);
    }, 5000);
  }, [copiedTnxId]);

  useEffect(() => {
    if (refreshDetails === true) {
      handleFetchTransactionDetails(false);
    }
  }, [refreshDetails]);

  const handleFetchTransactionDetails = (showSpinner = true) => {
    setIsFetchingTnxDetails(showSpinner);
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    getTransactionDetails(transactionId)
      .then((response) => {
        setTransactionDetails(response.data.data);
        setRequestLoaderProgress(100);
        setIsFetchingTnxDetails(false);
        setRefreshDetails(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsFetchingTnxDetails(false);
        if (error.response === undefined) return;
        if (error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  return {
    transactionDetails,
    isFetchingTnxDetails,
    copiedTnxId,
    setCopiedTnxId,
    openSendReceiptWidget,
    setOpenSendReceiptWidget,
    business_role,
    openLinkCustomerWidget,
    setOpenLinkCustomerWidget,
    setRefreshDetails,
  };
};
export default TransactionDetailsLogic;
