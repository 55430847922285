import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import primaryComponents from "../../../primaryComponents";
import secondaryComponents from "../..";
import { motion } from "framer-motion";
import styles from "./ReceiptTerminals.module.scss";
import useReceiptTerminals from "./useReceiptTerminals";

const ReceiptTerminals = ({
  closeStoreCheckout,
  orderDetails,
  gotoTransactionDetails,
}) => {
  const {
    isFetchingTerminals,
    terminalList,
    isPrinting,
    selectedTerminal,
    sendReceipt,
  } = useReceiptTerminals({
    orderDetails,
  });
  return (
    <div className="py-4">
      <div className="d-flex justify-content-between align-items-center">
        <primaryComponents.Button
          classNames="btn btn--outline"
          onClick={gotoTransactionDetails}
        >
          <FontAwesomeIcon
            onClick={closeStoreCheckout}
            className="mr-2"
            icon="angle-left"
          />
          Back
        </primaryComponents.Button>
        <FontAwesomeIcon
          onClick={closeStoreCheckout}
          className="d-block ml-auto"
          icon="times"
          style={{ fontSize: "25px" }}
        />
      </div>

      <secondaryComponents.LoaderHelper
        isLoading={isFetchingTerminals}
        classNames="mt-5"
      >
        <motion.div
          className={`mt-5 ${styles.terminalList}`}
          initial={{ y: 100 }}
          animate={{ y: 0 }}
        >
          {terminalList?.map((item) => (
            <div className={styles.terminalItem} key={item?.id}>
              <div className="d-flex">
                <h5>Terminal 1</h5>
                <div className={styles.terminalLocation}>{item.address}</div>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <p className="small-text-size">{item?.terminal_sn}</p>
                <primaryComponents.Button
                  classNames={`btn btn--primary ${styles.printBtn}`}
                  isDisabled={item?.status === "DEAD" || isPrinting}
                  isLoading={isPrinting && selectedTerminal === item?.id}
                  loadingText="Printing..."
                  onClick={() => sendReceipt(item?.id, item?.terminal_sn)}
                >
                  Print Receipt
                </primaryComponents.Button>
              </div>
            </div>
          ))}
        </motion.div>
      </secondaryComponents.LoaderHelper>
    </div>
  );
};
export default ReceiptTerminals;
