import { DAYS_OF_WEEK, MONTHS_OF_YEAR } from "./constants";

export const invertDateFormat = (date) => {
  if (date !== "") {
    let dArr = date.split("-");
    return dArr[2] + "-" + dArr[1] + "-" + dArr[0];
  }
  return date;
};

export const findObject = (ArrayOfObject, value) => {
  // eslint-disable-next-line array-callback-return
  let object = ArrayOfObject.find((item, index) => {
    if (item.my_route === value.replace(/\s/g, "")) {
      return ArrayOfObject[index];
    }
  });
  return object;
};

export const getUrlQuerysection = () => {
  let queries = window.location.search;
  return queries;
};

// this format numbers into kilo, Million, Billion ...
export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const generateRandomNumber = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return "_" + Math.random().toString(36).substr(2, 9);
};
export const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; // max & min both included
};

export const decodeToken = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const logoutHandler = () => {
  // handles logout
  localStorage.removeItem("refreshToken");
  window.location.replace(`${window.location.origin}/login`);
};

export const formatCurrency = (value, decimal = 2) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: decimal,
    currencyDisplay: "symbol",
  }).format(value);
};

export const last7Days = () => {
  return "06".split("").map(function (n) {
    var d = new Date();
    d.setDate(d.getDate() - n);

    return (function (day, month, year) {
      return [
        day < 10 ? "0" + day : day,
        month < 10 ? "0" + month : month,
        year,
      ].join("-");
    })(d.getFullYear(), d.getMonth() + 1, d.getDate());
  });
};

export const last30Days = () => {
  return "0,29".split(",").map(function (n) {
    var d = new Date();
    d.setDate(d.getDate() - n);

    return (function (day, month, year) {
      return [
        day < 10 ? "0" + day : day,
        month < 10 ? "0" + month : month,
        year,
      ].join("-");
    })(d.getFullYear(), d.getMonth() + 1, d.getDate());
  });
};

export const last3Months = () => {
  return "0,90".split(",").map(function (n) {
    var d = new Date();
    d.setDate(d.getDate() - n);

    return (function (day, month, year) {
      return [
        day < 10 ? "0" + day : day,
        month < 10 ? "0" + month : month,
        year,
      ].join("-");
    })(d.getFullYear(), d.getMonth() + 1, d.getDate());
  });
};

export const lastYear = () => {
  return "0,364".split(",").map(function (n) {
    var d = new Date();
    d.setDate(d.getDate() - n);

    return (function (day, month, year) {
      return [
        day < 10 ? "0" + day : day,
        month < 10 ? "0" + month : month,
        year,
      ].join("-");
    })(d.getFullYear(), d.getMonth() + 1, d.getDate());
  });
};

export const getStartAndDate = (value) => {
  if (value === "last 7 days") {
    let date = last7Days();
    return {
      startDate: date[1],
      endDate: date[0],
    };
  }

  if (value === "last 30 days") {
    let date = last30Days();
    return {
      startDate: date[1],
      endDate: date[0],
    };
  }

  if (value === "last 3 months") {
    let date = last3Months();
    return {
      startDate: date[1],
      endDate: date[0],
    };
  }

  if (value === "1 year") {
    let date = lastYear();
    return {
      startDate: date[1],
      endDate: date[0],
    };
  }

  return {
    startDate: "",
    endDate: "",
  };
};

export const getDaysBetweenDate = (startDate, endDate) => {
  let date1 = new Date(startDate.replace("-", "/"));
  let date2 = new Date(endDate.replace("-", "/"));

  // To calculate the time difference of two dates
  let differenceInTime = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  let differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return differenceInDays;
};

export const getDateMargin = (startDate, endDate) => {
  let daysBetween = getDaysBetweenDate(startDate, endDate);

  if (daysBetween === 6) return "last 7 days";

  if (daysBetween > 6 && daysBetween <= 29) return "last 30 days";

  if (daysBetween > 29 && daysBetween <= 89) return "last 3 months";

  if (daysBetween > 89 && daysBetween <= 365) return "1 year";

  return "";
};

export const convertToTilteCase = (words) => {
  return words.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const mapUserRole = {
  BUSINESS_MANAGER: "Business Manager",
  ADMIN: "Admin",
  OWNER: "Owner",
  DEVELOPER: "Developer",
  CUSTOMER_SUPPORT: "Customer Support",
  CASHIER: "Cashier",
};

export const mask = (str, maskChar, unmaskedLength, maskFromStart = true) => {
  const maskStart = maskFromStart ? 0 : Math.max(0, unmaskedLength);
  const maskEnd = maskFromStart
    ? Math.max(0, str.length - unmaskedLength)
    : str.length;
  return str
    .split("")
    .map((char, index) => {
      if (index >= maskStart && index < maskEnd) {
        return maskChar;
      } else {
        return char;
      }
    })
    .join("");
};

export const formatDateToString = (date) => {
  const dateObj = new Date(date);
  let day = dateObj.getDay();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();
  day = day - 1;
  day = day > DAYS_OF_WEEK.length ? day % DAYS_OF_WEEK : day;
  return `${DAYS_OF_WEEK[day]}, ${MONTHS_OF_YEAR[month]} ${year}`;
};

export const formatDateYYYYMMDD = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};
export const setToDateMonthYearInWords = (value) => {
  let date = new Date(value);
  let month = date.getMonth();
  let year = date.getFullYear();
  let day = date.getDate();
  day =
    day === 1 || day === 21 || day === 31
      ? `${day}st`
      : day === 2 || day === 22
      ? `${day}nd`
      : day === 3 || day === 23
      ? `${day}rd`
      : `${day}th`;

  return `${day} ${MONTHS_OF_YEAR[month]}, ${year}`;
};
export const truncateString = (str, length) => {
  if (str.length > length) {
    return str.substring(0, length) + "...";
  }
  return str;
};

export const subAccountColours = [
  { background: "#E8F7EE", color: "#19AE57" },
  { background: "#FFEEE9", color: "#FD561F" },
  { background: "#F2EDFE", color: "#7647EE" },
  { background: "#FEF7EB", color: "#F7AD3C" },
];

export const isMoreThan30DaysAgo = (date) => {
  //                   days  hours min  sec  ms
  const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
  const timestampThirtyDaysAgo = new Date().getTime() - thirtyDaysInMs;

  if (timestampThirtyDaysAgo > date) {
    return true;
  } else {
    return false;
  }
};

export const serializeToPercent = (value) => {
  const _intValue = parseFloat(value);
  return _intValue * 100 + "%";
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};
