import { useTable } from "react-table";
import { useMemo, useEffect, useState, useContext } from "react";
import { getCategoryList, deleteCategory } from "../../../services/inventory";
import { ToastContext, RequestLoader } from "../../../hooks/context";

const ProductCategoryTableLogic = ({
  isRefresh,
  setIsRefresh,
  setInventoryAnalytics,
}) => {
  const [isFetchingCategories, setIsfetchingCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isDeletingCategory, setIsDeletingCategory] = useState(false);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const columns = useMemo(
    () => [
      {
        Header: "Category Name",
        accessor: "name",
      },
      {
        Header: "Created on",
        accessor: "created_at",
        Cell: ({ cell: { value } }) =>
          new Date(value).toLocaleDateString("en-GB"),
      },
      {
        Header: "Last Updated on",
        accessor: "updated_at",
        Cell: ({ cell: { value } }) =>
          new Date(value).toLocaleDateString("en-GB"),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              color: "#19AE57",
              backgroundColor: "#E6FFF0",
              borderRadius: "8px",
              padding: "10px 16px",
              height: "28px",
              fontSize: "12px",
            }}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ cell: { row } }) => (
          <span
            onClick={() => {
              setIsOpenEditDialog(true);
              setSelectedCategory(row.original);
            }}
            style={{
              backgroundColor: "#7647EE",
              display: "inline-block",
              padding: "10px 16px",
              fontSize: "12px",
              borderRadius: "8px",
              color: "white",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            Edit
          </span>
        ),
      },

      {
        Header: "",
        accessor: "delete",
        Cell: ({ cell: { row } }) => (
          <span
            onClick={() => {
              setIsOpenDeleteDialog(true);
              setSelectedCategory(row.original);
            }}
            style={{
              backgroundColor: "#E02020",
              display: "inline-block",
              padding: "10px 16px",
              fontSize: "12px",
              borderRadius: "8px",
              color: "white",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            Delete
          </span>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    handlefetchcategories();
  }, []);

  useEffect(() => {
    if (isRefresh) {
      handlefetchcategories(false);
    }
  }, [isRefresh]);

  const handlefetchcategories = (showSpinner = true) => {
    if (showSpinner) {
      setIsfetchingCategories(true);
    }
    setRequestLoaderProgress(40);
    getCategoryList()
      .then((response) => {
        setIsfetchingCategories(false);
        setRequestLoaderProgress(100);
        setCategories(response.data?.data);
        setInventoryAnalytics((analytics) => ({
          ...analytics,
          categoryCount: response.data?.data.length,
        }));
      })
      .catch((error) => {
        setIsfetchingCategories(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
    setIsRefresh(false);
  };

  const handleDeleteProductCategory = () => {
    if (selectedCategory === null) return;
    const { id } = selectedCategory;

    setIsDeletingCategory(true);
    setRequestLoaderProgress(30);

    deleteCategory(id)
      .then(() => {
        handlefetchcategories(false);
        setIsDeletingCategory(false);
        setRequestLoaderProgress(100);
        setIsOpenDeleteDialog(false);
      })
      .catch((error) => {
        setIsDeletingCategory(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const instance = useTable({ columns, data: categories });

  return {
    instance,
    isFetchingCategories,
    categories,
    selectedCategory,
    isOpenDeleteDialog,
    isOpenEditDialog,
    isDeletingCategory,
    setIsOpenDeleteDialog,
    setIsOpenEditDialog,
    handleDeleteProductCategory,
  };
};

export default ProductCategoryTableLogic;
