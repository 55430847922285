import { useContext, useState } from "react";
import { ToastContext } from "../../../../hooks/context";
import { printOrderReceipt } from "../../../../services/sales-point";

const TransactionDetailsLogic = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("order_id");
  const [isLoading, setIsloading] = useState(false);
  const triggerToast = useContext(ToastContext);

  const printReceipt = () => {
    setIsloading(true);
    printOrderReceipt(orderId)
      .then((response) => {
        const receiptLink = response?.data?.data?.url;
        window.open(receiptLink, "_blank");
        setIsloading(false);
      })
      .catch((error) => {
        setIsloading(false);
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      });
  };

  return {
    isLoading,
    printReceipt,
  };
};
export default TransactionDetailsLogic;
