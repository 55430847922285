import {
  updateSettlementAccount,
  getSettlementBankList,
  resolveSettlementAccountNumber,
  getAvailableSubaccounts,
} from "../../../services/settlements";
import { useEffect, useContext, useState } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
// import { useSelector } from "react-redux";

const EditSettlementAccountLogic = (
  setIsOpen,
  settlementAccount,
  handleFetchSettlementAccounts
) => {
  // const {} = settlementAccount;
  const {
    id,
    bank_name,
    // decrypted_bank_code,
    decrypted_nuban,
    currency,
    is_primary: isPrimaryAcc,
    businesses,
  } = settlementAccount;
  const [account_currency, setAccountCurrency] = useState(currency || "NGN");
  const [bank, setBank] = useState(bank_name || "");
  const [bankList, setBankList] = useState([]);
  const [account_number, setAccountNumber] = useState(decrypted_nuban || "");
  const [business_id, setBusinessId] = useState(businesses || []);
  const [is_primary, setIsPrimary] = useState(isPrimaryAcc || false);
  const [isUpdatingAccount, setIsUpdatingAccount] = useState(false);
  const [isResolvingAccount, setIsResolvingAccount] = useState(false);
  const [isFetchingBankList, setIsFetchingBankList] = useState(false);
  const [isValidAccountNumber, setIsValidAccountNumber] = useState(false);
  const [accountDetails, setAccountDetails] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [business_list, setBusinessList] = useState([]);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  // const { business_list } = useSelector(
  //     (state) => state.profileDetailsReducer.profileDetails
  // );

  useEffect(() => {
    fetchBankList();
    fetchAvailableSubaccounts();
  }, []);

  const handleResolveAccountNumber = (account_number, bank_code) => {
    setIsResolvingAccount(true);
    resolveSettlementAccountNumber({
      account_number,
      bank_code: bank_code,
    })
      .then((response) => {
        setIsValidAccountNumber(true);
        setIsResolvingAccount(false);
        setAccountDetails(response.data.data);
      })
      .catch((error) => {
        setIsResolvingAccount(false);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
        if (error.response.status === 422) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  const fetchBankList = () => {
    setIsFetchingBankList(true);
    setRequestLoaderProgress(40);

    getSettlementBankList()
      .then((response) => {
        setIsFetchingBankList(false);
        setRequestLoaderProgress(100);
        setBankList(response.data?.data);
      })
      .catch((error) => {
        setIsFetchingBankList(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          return triggerToast(error.response.detail, "warning");
        }
      });
  };

  const fetchAvailableSubaccounts = () => {
    setRequestLoaderProgress(40);

    getAvailableSubaccounts()
      .then((response) => {
        setRequestLoaderProgress(100);
        setBusinessList(response.data?.data);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          return triggerToast(error.response.detail, "warning");
        }
      });
  };

  const handleUpdateSettlementAccount = () => {
    setIsUpdatingAccount(true);
    setRequestLoaderProgress(40);

    updateSettlementAccount(id, {
      currency: account_currency,
      bank_code: bank,
      nuban: account_number,
      is_primary,
      businesses_id: business_id,
    })
      .then(() => {
        triggerToast("Updated successfully", "success");
        handleFetchSettlementAccounts();
        setIsUpdatingAccount(false);
        setRequestLoaderProgress(100);
        setIsOpen(false);
      })
      .catch((error) => {
        setIsUpdatingAccount(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          return triggerToast(error.response.detail, "warning");
        }
      });
  };

  return {
    account_currency,
    bank,
    account_number,
    is_primary,
    isUpdatingAccount,
    isResolvingAccount,
    isFetchingBankList,
    bankList,
    accountDetails,
    isValidAccountNumber,
    isEdited,
    business_list,
    setBusinessList,
    business_id,
    setBusinessId,
    setIsEdited,
    setIsPrimary,
    setAccountCurrency,
    setAccountNumber,
    setBank,
    handleResolveAccountNumber,
    handleUpdateSettlementAccount,
  };
};

export default EditSettlementAccountLogic;
