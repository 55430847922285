import primaryComponents from "..";
import "./BoxedRadioInput.scss";

const BoxedRadioInput = ({
  children,
  name,
  value,
  id,
  isChecked,
  setRadioValue,
  isDisabled = false,
}) => {
  return (
    <label
      className={`boxed-radio ${isChecked && "active"} ${
        isDisabled && "disabled"
      }`}
    >
      <primaryComponents.RadioButton
        name={name}
        value={value}
        id={id}
        checked={isChecked}
        isDisabled={isDisabled}
        setRadioValue={setRadioValue}
      />
      <span>{children}</span>
    </label>
  );
};

export default BoxedRadioInput;
