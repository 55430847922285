import "./TransferDetails.scss";
import { useNavigate } from "react-router-dom";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import Icon from "../../../../assets/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import TransferDetailsLogic from "./TransferDetailsLogic";
import { formatCurrency } from "../../../../helpers";
import { CopyToClipboard } from "react-copy-to-clipboard";

const TransferDetails = () => {
  const navigate = useNavigate();
  const {
    isFetchingTransferDetails,
    transferDetails,
    setCopiedtransferId,
    copiedTransferId,
    openSendReceiptWidget,
    setOpenSendReceiptWidget,
  } = TransferDetailsLogic();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Transfer Details"
      description="View the details about a transfer"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className="transfer"
        >
          {/* back btn */}
          <primaryComponents.Button
            classNames="btn btn--outline"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon
              icon="angle-left"
              className="mr-3"
              style={{ fontSize: "18px" }}
            />
            <span>Back</span>
          </primaryComponents.Button>

          <secondaryComponents.LoaderHelper
            isLoading={isFetchingTransferDetails}
            classNames="mt-5"
          >
            <div className="transfer__content">
              <div className="transfer__content__header">
                <h3 className="font-weight-semibold mr-4">
                  {formatCurrency(transferDetails.amount)}
                </h3>

                <div className="d-flex ml-auto">
                  {/* download receipt btn */}
                  <primaryComponents.Button
                    onClick={() => {
                      window.open(transferDetails.receipt_link, "_blank");
                    }}
                    classNames="btn btn--primary mr-2"
                  >
                    Download Receipt
                  </primaryComponents.Button>
                  {/* send receipt btn */}
                  {(transferDetails.status === "accepted" ||
                    transferDetails.status === "successful") && (
                    <primaryComponents.Button
                      classNames="btn btn--primary"
                      onClick={() => setOpenSendReceiptWidget(true)}
                    >
                      Send Receipts
                    </primaryComponents.Button>
                  )}
                </div>
              </div>

              <div className="transfer__content__details">
                <div className="transfer__content__details__left">
                  {/* transfer ID */}
                  <div>
                    <p className="font-weight-semibold dark--text">
                      Transfer ID
                    </p>
                    <span className="gray--text">{transferDetails.id}</span>
                  </div>
                  {/* date */}
                  <div>
                    <p className="font-weight-semibold dark--text">Date</p>
                    <span className="gray--text">
                      {new Date(transferDetails.created_at).toLocaleString(
                        "en-us"
                      )}
                    </span>
                  </div>
                  {/* amount received */}
                  <div>
                    <p className="font-weight-semibold dark--text">
                      Amount Received
                    </p>
                    <span className="gray--text">
                      {formatCurrency(transferDetails.amount)}
                    </span>
                  </div>
                  {/* transfer fee */}
                  <div>
                    <p className="font-weight-semibold dark--text">
                      Transfer Fees
                    </p>
                    <span className="gray--text">
                      {formatCurrency(transferDetails.fee)}
                    </span>
                  </div>
                  {/* initiated By */}
                  <div>
                    <p className="font-weight-semibold dark--text">
                      Intiated By
                    </p>
                    <span className="gray--text">
                      {transferDetails.initiator_name}
                    </span>
                  </div>
                  {/* initiated By */}
                  <div>
                    <p className="font-weight-semibold dark--text">
                      Approved By
                    </p>
                    <span className="gray--text">
                      {transferDetails.approver_name}
                    </span>
                  </div>
                  {/* account credited */}
                  <div>
                    <p className="font-weight-semibold dark--text">
                      Account Debited
                    </p>
                    <span className="gray--text">
                      {transferDetails.business_tag}
                    </span>
                  </div>
                  {/* transfer description */}
                  <div>
                    <p className="font-weight-semibold dark--text">
                      Transfer Description
                    </p>
                    <span className="gray--text">
                      {transferDetails.description}
                    </span>
                  </div>
                </div>
                <div className="transfer__content__details__right">
                  <CopyToClipboard
                    onCopy={() => setCopiedtransferId(true)}
                    text={transferDetails.id}
                  >
                    <primaryComponents.Button classNames="btn btn--outline">
                      <img
                        className="mr-2"
                        width="16px"
                        height="16px"
                        src={Icon.copy}
                        alt="Copy"
                      />
                      <span>{copiedTransferId ? "Copied" : "Copy"}</span>
                    </primaryComponents.Button>
                  </CopyToClipboard>
                </div>
              </div>

              <div className="transfer__content__other-information">
                <div className="transfer__content__other-information__header mb-2">
                  <p className="font-weight-semibold dark--text">
                    Other Information
                  </p>
                </div>
                <div className="transfer__content__other-information__content">
                  <div>
                    <p className="font-weight-semibold dark--text">
                      Destination Account Number
                    </p>
                    <span className="gray--text">
                      {transferDetails.account_number}
                    </span>
                  </div>
                  <div>
                    <p className="font-weight-semibold dark--text">
                      Beneficiary
                    </p>
                    <span className="gray--text">
                      {transferDetails.account_name}
                    </span>
                  </div>
                  <div>
                    <p className="font-weight-semibold dark--text">Bank Name</p>
                    <span className="gray--text">
                      {transferDetails.bank_name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </secondaryComponents.LoaderHelper>

          {/* send receipt widget */}
          <secondaryComponents.SideModal
            isActive={openSendReceiptWidget}
            closeModal={() => setOpenSendReceiptWidget(false)}
          >
            <secondaryComponents.SendReceiptWidget
              closeWidget={() => setOpenSendReceiptWidget(false)}
              transactionId={transferDetails.id}
              receiptType="transfers"
            />
          </secondaryComponents.SideModal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default TransferDetails;
