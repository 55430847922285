import "./ProductsTable.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import ProductsTableLogic from "./ProductsTableLogic";
import { Fragment } from "react";
import Images from "../../../assets/images";
import { formatCurrency, isMoreThan30DaysAgo } from "../../../helpers";

const ProductsTable = ({ setInventoryAnalytics }) => {
  const {
    instance,
    products,
    isfetchingProducts,
    // searchParams,
    selectedProduct,
    isOpenDeleteDialog,
    isDeletingProduct,
    productsDetails,
    openRestockProductWidget,
    setOpenRestockProductWidget,
    // searchValue,
    gotoPage,
    search,
    setIsOpenDeleteDialog,
    handleDeleteProduct,
    // setSearchParams,
    handleSearchProduct,

    // showOptions,
  } = ProductsTableLogic({
    setInventoryAnalytics,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="products-table">
      <div className="products-table__header">
        <div style={{ width: "100%" }}>
          <primaryComponents.SearchField
            placeholder="Search for a product"
            trigger={(value) => handleSearchProduct(value)}
            value={search.get("query") !== null ? search.get("query") : ""}
            classNames="search-section"
            triggerOnInput={true}
            showsearchBtn={true}
          />
        </div>
      </div>
      <secondaryComponents.LoaderHelper
        classNames={"mt-3"}
        isLoading={isfetchingProducts}
      >
        {products.length > 0 ? (
          <Fragment>
            <div className="products-table__content">
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, i) => (
                        <th
                          key={i}
                          className="font-weight-semibold"
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        key={i}
                        {...row.getRowProps({ key: i })}
                        style={
                          row?.depth === 1 ? { background: "#F3F1FC" } : {}
                        }
                      >
                        {row.cells.map((cell, i) => {
                          return (
                            <td key={i} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* cards */}
              <div className="products-table__content-mobile">
                {products.map((product) => (
                  <div
                    key={product.id}
                    className="products-table__content-mobile-card"
                  >
                    <div className="products-table__content-mobile-card__title">
                      <p>Product Name:</p>
                      <p>{product.name}</p>
                    </div>
                    <div className="products-table__content-mobile-card__title">
                      <p>Cost Price:</p>
                      <p>{formatCurrency(product.cost_price)}</p>
                    </div>
                    <div className="products-table__content-mobile-card__title">
                      <p>Selling Price:</p>
                      <p>{formatCurrency(product.selling_price)}</p>
                    </div>
                    <div className="products-table__content-mobile-card__title">
                      <p>Profit Margin:</p>
                      <p>{formatCurrency(product.profit_margin)}</p>
                    </div>
                    <div className="products-table__content-mobile-card__title">
                      <p>Stock Left:</p>
                      <p>{product.stock_quantity}</p>
                    </div>
                    <div className="products-table__content-mobile-card__title">
                      <p>Expiry Date:</p>
                      <p
                        style={{
                          color: isMoreThan30DaysAgo(
                            new Date(product.expiry_date)
                          )
                            ? "red"
                            : "green",
                          backgroundColor: isMoreThan30DaysAgo(
                            new Date(product.expiry_date)
                          )
                            ? "#FFEEE9"
                            : "#E6FFF0",
                        }}
                      >
                        {new Date(product.expiry_date).toLocaleDateString(
                          "en-GB"
                        )}
                      </p>
                    </div>
                    <div className="products-table__content-mobile-card__title">
                      <p>Status:</p>
                      <p className="status">{product.status}</p>
                    </div>
                    <div className="products-table__content-mobile-card__next">
                      <primaryComponents.Button
                        classNames={"btn btn--primary edit-record"}
                      >
                        Edit
                      </primaryComponents.Button>
                      <primaryComponents.Button
                        classNames={"btn btn--danger delete-record"}
                      >
                        Delete
                      </primaryComponents.Button>
                    </div>
                  </div>
                ))}
              </div>
              {products.length >= 10 && (
                <secondaryComponents.Pagination
                  totalPageNumber={productsDetails?.last_page}
                  handlePageClick={(value) => gotoPage(value.selected + 1)}
                  currentPage={productsDetails?.current_page}
                />
              )}
            </div>
          </Fragment>
        ) : (
          <div className="empty__state">
            <img src={Images.productsEmptyState} width="250px" alt="" />
            <h4 className="font-weight-semibold text-center mt-4">
              You have no products
            </h4>
            <p className="text-center">
              Use the button above to add your products. When you do, they would
              appear here.
            </p>
          </div>
        )}

        {isOpenDeleteDialog && (
          <secondaryComponents.ConfirmDialog
            title="Are you sure you want to delete this product"
            isOpen={isOpenDeleteDialog}
            setIsOpen={setIsOpenDeleteDialog}
            isLoading={isDeletingProduct}
            handler={handleDeleteProduct}
          >
            <div
              style={{
                backgroundColor: "#F3F1FC",
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                borderRadius: "8px",
              }}
            >
              <p
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span className="dark--text font-weight-semibold mb-1">
                  Product Name
                </span>
                <span>{selectedProduct?.name}</span>
              </p>
              <p
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span className="dark--text font-weight-semibold mb-1">
                  Cost Price
                </span>
                <span>{formatCurrency(selectedProduct?.cost_price)}</span>
              </p>
              <p
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span className="dark--text font-weight-semibold mb-1">
                  Expiry Date
                </span>
                <span>{selectedProduct?.expiry_date}</span>
              </p>
            </div>
          </secondaryComponents.ConfirmDialog>
        )}
      </secondaryComponents.LoaderHelper>

      <secondaryComponents.SideModal
        width={"1200px"}
        isActive={openRestockProductWidget}
        closeModal={() => setOpenRestockProductWidget(false)}
      >
        <secondaryComponents.RestockProductWidget
          closeWidget={() => setOpenRestockProductWidget(false)}
          selectedProduct={selectedProduct}
        />
      </secondaryComponents.SideModal>
    </div>
  );
};

export default ProductsTable;
