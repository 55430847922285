import MainLayout from "./MainLayout/MainLayout";
import SignupLayout from "./SignupLayout/SignupLayout";
import LoginLayout from "./LoginLayout/LoginLayout";
import AppLayout from "./AppLayout/AppLayout";
import SettingsLayout from "./SettingsLayout/SettingsLayout";
import LoyaltyLayout from "./LoyaltyLayout/LoyaltyLayout";
import TransactionsLayout from "./TransactionsLayout/TransactionsLayout";
import CustomersLayout from "./CustomersLayout/CustomersLayout";
import TransfersLayout from "./TransfersLayout/TransfersLayout";
import SubAccountLayout from "./SubAccountLayout/SubAccountLayout";
import TerminalsLayout from "./TerminalsLayout/TerminalsLayout";
import TagsLayout from "./TagsLayout/TagsLayout";
import InventoryLayout from "./InventoryLayout/InventoryLayout";
import StoreLayout from "./StoreLayout/StoreLayout";
import CustomerSubLayout from "./CustomerSubLayout/CustomerSubLayout";

const layouts = {
  MainLayout,
  SignupLayout,
  LoginLayout,
  AppLayout,
  SettingsLayout,
  LoyaltyLayout,
  TransactionsLayout,
  CustomersLayout,
  TransfersLayout,
  SubAccountLayout,
  TerminalsLayout,
  TagsLayout,
  InventoryLayout,
  StoreLayout,
  CustomerSubLayout,
};

export default layouts;
