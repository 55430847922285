import { useState, useContext, useEffect } from "react";
import { useStep } from "react-hooks-helper";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchBankList,
  initiateTransfer,
  resolveAccountNumber,
  getTransferFee,
} from "../../../services/transfers";
import { fetchDashboard } from "../../../services/dashboard";
import { getTags } from "../../../services/tags";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { getRandomIntInclusive, formatCurrency } from "../../../helpers";
import { setDashboardDetails } from "../../../store/modules/dashboard";

const FundTransferLogic = ({ closeWidget, refreshTransfers }) => {
  const [isFetchingBankList, setIsFetchingBankList] = useState(false);
  const [isValidatingAccountNumber, setIsValidatingAccountNumber] =
    useState(false);
  const [isTranferringFunds, setIsTransferringFunds] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [amount, setAmount] = useState("");
  const [bank, setBank] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [transferDescription, setTransferDescription] = useState("");
  const [accountDetails, setAccountDetails] = useState({});
  const [isAccountNumberValid, setIsAccountNumberValid] = useState(false);
  const [accountResolveError, setAccountResolveError] = useState(null);
  const [transferCharge, setTransferCharge] = useState(0);
  const [isFetchingCharges, setIsFetchingCharges] = useState(false);
  const [subAccount, setSubAccount] = useState("");
  const [subAccountName, setSubAccountName] = useState("");
  const [saveBeneficiary, setSaveBeneficiary] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { index, navigation } = useStep({ steps: 3, initialStep: 0 });
  const { verification_status } = useSelector(
    (state) => state.dashboardReducer.dashboardDetails
  );
  const { business_role, business_list } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const [businessList, setBusinessList] = useState([]);
  const dispatch = useDispatch();

  const reArrangeBusinessList = () => {
    let newList = [];
    business_list.forEach((item) => {
      let newObject = {
        tag: `${item.tag} --- Available Balance: ${formatCurrency(
          item.balance
        )}`,
        id: item.id,
        balance: item.balance,
      };
      newList.push(newObject);
    });
    setBusinessList(newList);
  };

  useEffect(() => {
    if (
      verification_status === "unverified" ||
      verification_status === "pending_review"
    )
      return;

    reArrangeBusinessList();
    handleFetchBankList();
  }, []);

  const handleNextStep = () => {
    navigation.next();
  };

  const handlePreviousStep = () => {
    navigation.previous();
  };

  const canTransferFunds =
    verification_status !== "pending_review" &&
    verification_status !== "unverified";

  const handleFetchBankList = () => {
    setIsFetchingBankList(true);
    setRequestLoaderProgress(getRandomIntInclusive(20, 50));
    fetchBankList()
      .then((response) => {
        setBankList(response.data?.data);
        setRequestLoaderProgress(getRandomIntInclusive(50, 70));
        handleFetchTags();
      })
      .catch((error) => {
        setIsFetchingBankList(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const handleFetchTags = () => {
    getTags()
      .then((response) => {
        setIsFetchingBankList(false);
        setRequestLoaderProgress(100);
        setTagsList(response.data.data);
      })
      .catch((error) => {
        setIsFetchingBankList(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const handleResolveAccountNumber = (bankCode, accountNumber) => {
    setIsValidatingAccountNumber(true);
    resolveAccountNumber({
      bank_code: bankCode,
      account_number: accountNumber,
    })
      .then((response) => {
        setIsValidatingAccountNumber(false);
        setIsAccountNumberValid(true);
        setAccountDetails(response.data.data);
        window.clearAccountErrors("account_number");
      })
      .catch((error) => {
        setIsValidatingAccountNumber(false);
        if (error.response === undefined) return;
        if (error.response.status === 400) {
          setIsAccountNumberValid(false);
          setAccountResolveError(error.response.data.detail);
          window.setAccountError(
            "account_number",
            {
              type: "validate",
              message:
                error.response.data.detail || "Account number is invalid",
            },
            { shouldFocus: true }
          );
        }
        if (error.response.status === 422) {
          window.setAccountError(
            "account_number",
            {
              type: "validate",
              message: error.response.data.errors.account_number[0],
            },
            { shouldFocus: true }
          );
        }
      });
  };

  const handleFundTransfer = (data) => {
    const { account_number, bank, amount, password } = data;
    setIsTransferringFunds(true);
    setRequestLoaderProgress(getRandomIntInclusive(20, 40));
    initiateTransfer({
      account_number: account_number,
      bank_code: bank,
      amount: amount,
      narration: transferDescription,
      password: password,
      business_id: subAccount,
      save_beneficiary: saveBeneficiary,
      tag_ids: tags,
    })
      .then(() => {
        setIsTransferringFunds(false);
        setRequestLoaderProgress(100);
        let message =
          business_role === "BUSINESS_MANAGER"
            ? "Transfer Succesfully Scheduled, Waiting Approval"
            : "Transfer successful";
        refreshTransfers(true);
        triggerToast(message, "success");
        getDashboardDetails();
        closeWidget(false);
      })
      .catch((error) => {
        setIsTransferringFunds(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const validateBalance = () => {
    if (parseFloat(amount) < 100) {
      return window.setAccountError(
        "amount",
        {
          type: "validate",
          message: "Minimum withdrawal amount is NGN 100",
        },
        { shouldFocus: true }
      );
    }
    window.clearAccountErrors("amount");
    getTransferCharge();
  };

  const getTransferCharge = () => {
    setIsFetchingCharges(true);
    setRequestLoaderProgress(getRandomIntInclusive(10, 30));
    getTransferFee({ amount: amount })
      .then((response) => {
        setTransferCharge(response.data.data.fee);
        setIsFetchingCharges(false);
        setRequestLoaderProgress(100);
        handleNextStep();
      })
      .catch((error) => {
        setIsFetchingCharges(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const getDashboardDetails = () => {
    fetchDashboard("").then((response) => {
      dispatch(setDashboardDetails(response.data.data));
    });
  };

  const fillFormWithBeneficiary = (item) => {
    setAccountDetails(item);
    setAccountNumber(item.account_number);
    setBankName(item.bank_name);
    setBank(
      bankList.find((object) => object.bank_code === item.bank_code).bank_code
    );
    setIsAccountNumberValid(true);
    navigation.go(0);
  };

  return {
    isFetchingBankList,
    isValidatingAccountNumber,
    isTranferringFunds,
    bankList,
    amount,
    accountNumber,
    isAccountNumberValid,
    accountDetails,
    accountResolveError,
    canTransferFunds,
    verification_status,
    handleResolveAccountNumber,
    handleFundTransfer,
    setAccountNumber,
    setAmount,
    bank,
    setBank,
    bankName,
    setBankName,
    transferDescription,
    setTransferDescription,
    index,
    handleNextStep,
    handlePreviousStep,
    validateBalance,
    transferCharge,
    isFetchingCharges,
    business_role,
    subAccount,
    setSubAccount,
    businessList,
    fillFormWithBeneficiary,
    navigation,
    saveBeneficiary,
    setSaveBeneficiary,
    subAccountName,
    setSubAccountName,
    setTags,
    tags,
    tagsList,
  };
};

export default FundTransferLogic;
