import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object({
  costPrice: yup.number().typeError("Invalid format"),
  sellingPrice: yup.number().typeError("Invalid format"),
  stockQuantity: yup.number().typeError("Invalid format"),
  minStockQuantity: yup.number().typeError("Invalid format"),
  expiryDate: yup.string().typeError("Invalid format"),
});

export const useRestockProductValidation = (fields) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      costPrice: fields.costPrice,
      sellingPrice: fields.sellingPrice,
      stockQuantity: fields.stockQuantity,
      minStockQuantity: fields.minStockQuantity,
      variants: fields.variants,
      new_variants: fields.new_variants,
      expiryDate: fields.expiryDate,
    },
  });
};
