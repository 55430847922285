import "./Stores.scss";
import primaryComponents from "../../../../components/primaryComponents";
import Icon from "../../../../assets/icons";
import secondaryComponents from "../../../../components/secondaryComponents";
import StoresLogic from "./StoresLogic";
import { useOutletContext } from "react-router-dom";

const Stores = () => {
  const { inventoryAnalytics, setInventoryAnalytics } = useOutletContext();

  const { isModalOpen, isRefresh, setIsModalOpen, setIsRefresh } =
    StoresLogic();

  return (
    <div className="stores-page">
      <div className="stores-page__header">
        <h4 className="font-weight-semibold">
          {inventoryAnalytics.storeCount} Stores
        </h4>
        {inventoryAnalytics.storeCount >= 1 ? null : (
          <primaryComponents.Button
            classNames={"btn btn--primary"}
            onClick={() => setIsModalOpen(true)}
          >
            <img className="mr-1" src={Icon.add} alt="add-stores" />
            Add new store
          </primaryComponents.Button>
        )}
      </div>
      <div className="stores-page__content">
        <secondaryComponents.StoresTable
          setInventoryAnalytics={setInventoryAnalytics}
          isRefresh={isRefresh}
          setIsRefresh={setIsRefresh}
        />
      </div>
      {isModalOpen && (
        <secondaryComponents.StoresTableModal
          isActive={isModalOpen}
          setIsActive={setIsModalOpen}
          setIsRefresh={setIsRefresh}
        />
      )}
    </div>
  );
};

export default Stores;
