import { useState, useEffect, useContext } from "react";
import {
  getDocumentsStatus,
  uploadVerificationDocument,
} from "../../../services/kyb-verification";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { useStep } from "react-hooks-helper";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const KYBVerificationLogic = () => {
  const [business_type, setBusinessType] = useState("individual"); // individual | LLC | sole_propietor
  const [isFetchingDocuments, setIsFetchingDocuments] = useState(false);
  const [verificationData, setVerificationData] = useState(null);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { index, navigation } = useStep({ initialStep: 0, steps: 2 });
  const businessDetails = useSelector(
    (state) => state.profileDetailsReducer.businessDetails
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (businessDetails.has_wallet)
      return navigate("/app/dashboard?all_business=true");
    fetchDocuments(true);
  }, []);

  const fetchDocuments = (showLoader = true) => {
    if (showLoader) setIsFetchingDocuments(true);
    setRequestLoaderProgress(40);
    getDocumentsStatus()
      .then((response) => {
        setIsFetchingDocuments(false);
        setRequestLoaderProgress(100);
        setVerificationData(response?.data?.data);
      })
      .catch((error) => {
        setIsFetchingDocuments(false);
        setRequestLoaderProgress(100);
        if (error?.response?.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const handleDocumentUpload = (document) => {
    setRequestLoaderProgress(40);
    const formData = new FormData();
    formData.append("type", document.type);
    formData.append("file", document.file);
    uploadVerificationDocument(formData)
      .then(() => {
        setRequestLoaderProgress(100);
        triggerToast("Document successfully uploaded", "success");
        fetchDocuments(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
        if (error.response.status === 422)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  return {
    business_type,
    verificationData,
    isFetchingDocuments,
    setBusinessType,
    handleDocumentUpload,
    fetchDocuments,
    step: index,
    navigation,
  };
};

export default KYBVerificationLogic;
