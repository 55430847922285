import { useContext } from "react";
import { toggleCampaignStatus } from "../../../services/campaign";
import { ToastContext, RequestLoader } from "../../../hooks/context";

export const useCampaignCardLogic = ({ campaign_id }) => {
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const toggleCampaign = () => {
    toggleCampaignStatus(campaign_id)
      .then((response) => {
        setRequestLoaderProgress(100);
        triggerToast(response.data.detail, "success");
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  const handleCopyUrl = () => triggerToast("Copied url", "success");

  return {
    toggleCampaign,
    handleCopyUrl,
  };
};
