import "./EditProduct.scss";
import primaryComponents from "../../../components/primaryComponents";
import secondaryComponents from "../../../components/secondaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useEditProductLogic } from "./EditProductLogic";

const EditProduct = () => {
  const navigate = useNavigate();
  const { isFetchingProduct, productData, categories, isFetchingCategories } =
    useEditProductLogic();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      className="edit-single-product-page"
    >
      <primaryComponents.Button
        classNames="btn btn--outline back__btn"
        onClick={() => navigate(-1)}
        type="button"
      >
        <FontAwesomeIcon
          icon="angle-left"
          className="mr-3"
          style={{ fontSize: "18px" }}
        />
        <span>Back</span>
      </primaryComponents.Button>
      <div className="edit-single-product-page__content">
        <h3 className="font-weight-semibold">Edit product</h3>
        <p>Please provide the following information about your product</p>
        {!isFetchingProduct && !isFetchingCategories && (
          <secondaryComponents.EditProductForm
            productDetails={productData}
            isFetchingProduct={isFetchingProduct}
            isFetchingCategories={isFetchingCategories}
            categories={categories}
          />
        )}
      </div>
    </motion.div>
  );
};

export default EditProduct;
