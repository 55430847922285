import { useSelector } from "react-redux";
import { useState, useContext, useEffect, useRef } from "react";
import { ToastContext, RequestLoader } from "../../hooks/context";
import { getCategoryList } from "../../services/inventory";
import useRootProperties from "../../hooks/useRootProperties";
import { getRandomIntInclusive } from "../../helpers";
import { useSearchParams } from "react-router-dom";

const StoreLayoutLogic = () => {
  useRootProperties();
  const queryParams = new URLSearchParams(window.location.search);
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useSearchParams();
  const [searchValue] = useState(
    queryParams.get("query") !== null ? [queryParams.get("query")] : ""
  );
  const [refreshProducts, setRefreshProducts] = useState(false);
  const [categoryId, setCategoryId] = useState(
    queryParams.get("category_id") !== null
      ? [queryParams.get("category_id")]
      : null
  );
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const business = useSelector(
    (state) => state.profileDetailsReducer.businessDetails
  );
  const productContainer = useRef();

  useEffect(() => {
    handleFetchCategories();
  }, []);

  const handleFetchCategories = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    getCategoryList()
      .then((response) => {
        setRequestLoaderProgress(100);
        setCategories(response.data?.data);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      });
  };

  const handleSearchProducts = (value) => {
    queryParams.forEach((value, key) => {
      search.delete(key);
    });
    if (value.length !== 0) search.set("query", value);
    setCategoryId(null);
    setSearch(search, { replace: true });
    setRefreshProducts(true);
  };

  const selectCategory = (id) => {
    if (id === null) search.delete("category_id");
    else search.set("category_id", id);
    setSearch(search, { replace: true });
    setCategoryId(id);
    setRefreshProducts(true);
  };

  return {
    categoryId,
    categories,
    business_name: business?.business_name,
    searchValue,
    refreshProducts,
    productContainer,
    setRefreshProducts,
    handleSearchProducts,
    selectCategory,
  };
};

export default StoreLayoutLogic;
