import { useNavigate, NavLink, Outlet, useLocation } from "react-router-dom";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import "./AllCampaigns.scss";
import { motion } from "framer-motion";

const AllCampaigns = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Loyalty - All campaigns"
      description="Create campaign on lumi merchant and see an improve customer loyalty"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div className="all-campaigns">
          {/* campaign list */}
          <div className="d-flex align-items-center flex-wrap justify-content-between mb-sm-4 mb-3">
            <h3 className="font-weight-semibold mr-5">Manage Loyalty</h3>
            <primaryComponents.Button
              onClick={() =>
                navigate(
                  location.pathname === "/app/loyalty/manage"
                    ? "/app/loyalty/create-campaign"
                    : "/app/loyalty/create-giftcard"
                )
              }
              classNames="btn btn--primary ml-auto"
            >
              <span className="mr-2" style={{ fontSize: "24px" }}>
                +
              </span>{" "}
              {location.pathname === "/app/loyalty/manage"
                ? "Create Campaign"
                : "Create Giftcard"}
            </primaryComponents.Button>
          </div>
          <div className="all-campaigns__nav">
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "all-campaigns__nav__item active"
                  : "all-campaigns__nav__item"
              }
              to="/app/loyalty/manage"
              end
            >
              Campaigns
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "all-campaigns__nav__item active"
                  : "all-campaigns__nav__item"
              }
              to="/app/loyalty/manage/giftcards"
            >
              Giftcards
            </NavLink>
          </div>
          <div className="all-campaigns__content">
            <Outlet />
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default AllCampaigns;
