import "./SnapAndEarn.scss";
import secondaryComponents from "../../../../components/secondaryComponents";

const SnapAndEarn = () => {
  return (
    <div className="snap-and-earn-page">
      {/* <h4>Snap and Earn Page</h4> */}
      <secondaryComponents.ComingSoon />
    </div>
  );
};

export default SnapAndEarn;
