import primaryComponents from "../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./TransfersFilter.scss";
import { motion } from "framer-motion";
import TransfersFilterLogic from "./TransfersFilterLogic";

const TransfersFilter = ({ applyFilter, filterDetails }) => {
  const {
    showDropdown,
    setShowDropdown,
    // paymentStatus,
    // removeValueFromPaymentStatus,
    // addValueToPaymentStatus,
    setFilter,
    setTimeDuration,
    timeDuration,
  } = TransfersFilterLogic({ applyFilter, filterDetails });

  return (
    <div className="transfers-filter">
      <primaryComponents.Button
        classNames={`btn btn--outline-gray ${showDropdown && "active"}`}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        Filter{" "}
        <FontAwesomeIcon
          className="ml-3"
          icon="angle-down"
          style={{ fontSize: "16px" }}
        />
      </primaryComponents.Button>

      {showDropdown && (
        <motion.div className="transfers-filter__dropdown">
          <h5 className="font-weight-normal mb-4">Filter by</h5>

          {/* payment status */}
          {/* <p className="dark--text mb-3">Status</p>
          <div className="d-flex flex-wrap">
            <div className="checked-item mr-4 mb-1">
              <primaryComponents.Checkbox
                name="paymentStatus"
                isChecked={paymentStatus.includes("awaiting_settlement")}
                value="awaiting_settlement"
                onChange={(value) => {
                  if (value)
                    return addValueToPaymentStatus("awaiting_settlement");
                  removeValueFromPaymentStatus("awaiting_settlement");
                }}
                id="awaiting_settlement"
              />
              <label htmlFor="awaiting_settlement" className="ml-2 gray--text">
                Pending
              </label>
            </div>

            <div className="checked-item mr-4 mb-1">
              <primaryComponents.Checkbox
                name="paymentStatus"
                isChecked={paymentStatus.includes("settled")}
                value="settled"
                onChange={(value) => {
                  if (value) return addValueToPaymentStatus("settled");
                  removeValueFromPaymentStatus("settled");
                }}
                id="settled"
              />
              <label htmlFor="settled" className="ml-2 gray--text">
                Successful
              </label>
            </div>

            <div className="checked-item mb-1">
              <primaryComponents.Checkbox
                name="paymentStatus"
                isChecked={paymentStatus.includes("unpaid")}
                value="unpaid"
                id="unpaid"
                onChange={(value) => {
                  if (value) return addValueToPaymentStatus("unpaid");
                  removeValueFromPaymentStatus("unpaid");
                }}
              />
              <label htmlFor="unpaid" className="ml-2 gray--text">
                Failed
              </label>
            </div>
          </div> */}

          {/* date filter */}
          <p className="dark--text mt-4 mb-3">Date</p>
          <div className="mb-4 date-section">
            {/* last 7 days */}
            <primaryComponents.Button
              classNames={`btn ${
                timeDuration === "last 7 days" ? "btn--primary" : "btn--outline"
              }`}
              onClick={() =>
                setTimeDuration(
                  timeDuration !== "last 7 days" ? "last 7 days" : null
                )
              }
            >
              Last 7 days
            </primaryComponents.Button>
            {/* last 30 days */}
            <primaryComponents.Button
              classNames={`btn ${
                timeDuration === "last 30 days"
                  ? "btn--primary"
                  : "btn--outline"
              }`}
              onClick={() =>
                setTimeDuration(
                  timeDuration !== "last 30 days" ? "last 30 days" : null
                )
              }
            >
              Last 30 days
            </primaryComponents.Button>
            {/* last 3 months */}
            <primaryComponents.Button
              classNames={`btn ${
                timeDuration === "last 3 months"
                  ? "btn--primary"
                  : "btn--outline"
              }`}
              onClick={() =>
                setTimeDuration(
                  timeDuration !== "last 3 months" ? "last 3 months" : null
                )
              }
            >
              Last 3 months
            </primaryComponents.Button>
            {/* last 1 year */}
            <primaryComponents.Button
              classNames={`btn ${
                timeDuration === "1 year" ? "btn--primary" : "btn--outline"
              }`}
              onClick={() =>
                setTimeDuration(timeDuration !== "1 year" ? "1 year" : null)
              }
            >
              1 year
            </primaryComponents.Button>
          </div>

          <div className="button-section">
            <primaryComponents.Button
              classNames="btn btn--primary cover"
              onClick={setFilter}
            >
              Apply
            </primaryComponents.Button>
          </div>
        </motion.div>
      )}

      {showDropdown && (
        <div
          className="transfers-filter__overlay"
          onClick={() => setShowDropdown(false)}
        ></div>
      )}
    </div>
  );
};

export default TransfersFilter;
