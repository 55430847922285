import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import primaryComponents from "../../../primaryComponents";
import "./StoreCustomerForm.scss";
import StoreCustomerFormlogic from "./StoreCustomerFormlogic";
import StoreCustomerValidation from "./Validation";
import { Controller } from "react-hook-form";

const StoreCustomerForm = ({
  openTransactionsession,
  closeStoreCheckout,
  orderDetails,
}) => {
  const {
    isSubmitting,
    customerName,
    customerPhoneNumber,
    setCustomerPhoneNumber,
    setCustomerName,
    submitCustomerDetails,
  } = StoreCustomerFormlogic({
    openTransactionsession,
    orderDetails,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
  } = StoreCustomerValidation(customerName, customerPhoneNumber);

  return (
    <div className="storeCustomerForm">
      <FontAwesomeIcon
        onClick={closeStoreCheckout}
        className="d-block ml-auto"
        icon="times"
        style={{ fontSize: "25px" }}
      />
      <div className="mt-5">
        <p className="dark--text mb-2">
          Please collect and enter Customer Details below
        </p>
        <div className="mb-3">
          <Controller
            name="customerName"
            control={control}
            defaultValue={customerName}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                placeholder="Enter Customer Name"
                isRequired={true}
                name="customerName"
                errorMessage={errors.customerName}
                onKeyUp={() =>
                  errors.customerName !== undefined && trigger("customerName")
                }
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setCustomerName(value);
                }}
                register={register}
              />
            )}
          />
        </div>

        <Controller
          name="customerPhoneNumber"
          control={control}
          defaultValue={customerPhoneNumber}
          render={({ field: { onChange } }) => (
            <primaryComponents.PhoneNumberInputField
              placeholder="Enter Customer Phone Number"
              isRequired={true}
              name="customerPhoneNumber"
              classNames="mb-1"
              errorMessage={errors.customerPhoneNumber}
              onKeyUp={() =>
                errors.customerPhoneNumber !== undefined &&
                trigger("customerPhoneNumber")
              }
              onChange={({ target: { value } }) => {
                onChange(value);
                setCustomerPhoneNumber(value);
              }}
              register={register}
            />
          )}
        />
      </div>

      <div className="storeCustomerForm__bottom">
        <primaryComponents.Button
          classNames="btn btn--outline"
          onClick={openTransactionsession}
        >
          Skip
        </primaryComponents.Button>
        <primaryComponents.Button
          classNames="btn btn--primary"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          loadingText="Submitting"
          onClick={handleSubmit(submitCustomerDetails)}
        >
          Submit
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default StoreCustomerForm;
