import "./EmailVerification.scss";
import layouts from "../../../layouts";
import Images from "../../../assets/images";
import primaryComponents from "../../../components/primaryComponents";
import EmailVerificationLogic from "./EmailVerificationLogic";
import { Link } from "react-router-dom";
import secondaryComponents from "../../../components/secondaryComponents";
import { motion } from "framer-motion";

const EmailVerification = () => {
  const {
    handleOTPInputs,
    email,
    isVerified,
    otp,
    isVerifing,
    countDown,
    resendOTP,
    isResendingOTP,
  } = EmailVerificationLogic();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Email verification"
      description="Verify your email address"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          <layouts.LoginLayout>
            <div className="email-verification-page">
              {!isVerified ? (
                <div className="email-verification-page__container">
                  <h3 className="purple--text text-center">Verification</h3>
                  <p className="text-center">
                    Enter the 6-digit code sent to your email{" "}
                    <span className="purple--text">{email}</span>
                  </p>
                  <form className="verification__form">
                    {!isVerifing ? (
                      <primaryComponents.OtpInputField
                        onChange={handleOTPInputs}
                        numOfInputs={6}
                        value={otp}
                      />
                    ) : (
                      <h4 className="purple--text font-weight-normal">
                        Verifying...
                      </h4>
                    )}
                  </form>
                  <p className="text-center">
                    Didn&apos;t get code?
                    {isResendingOTP ? (
                      <span className="purple--text"> Resending...</span>
                    ) : (
                      <>
                        {countDown !== 0 && (
                          <span className="purple--text"> {countDown}</span>
                        )}
                        {countDown === 0 && (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a
                            onClick={resendOTP}
                            style={{ cursor: "pointer" }}
                            className="purple--text"
                          >
                            {" "}
                            Resend
                          </a>
                        )}
                      </>
                    )}
                  </p>
                </div>
              ) : (
                <div className="email-verification-page__notification">
                  <img
                    className="d-block mx-auto"
                    src={Images.email_verification_icon}
                    alt=""
                  />
                  <h3 className="text-center mb-4">
                    Email verification successful.
                  </h3>
                  <Link to="/app/dashboard" className="my-4">
                    <primaryComponents.Button classNames="btn btn--primary verify__btn">
                      Go to Dashboard
                    </primaryComponents.Button>
                  </Link>
                </div>
              )}
            </div>
          </layouts.LoginLayout>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default EmailVerification;
