import { useState } from "react";
import { useSelector } from "react-redux";

const TagsLayoutLogic = () => {
  const [isAddingTag, setIsAddingTag] = useState(false);
  const [isEditingTag, setIsEditingTag] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [tagDetails, setTagDetails] = useState(null);
  const [isDeletingTag, setIsDeletingTag] = useState(false);
  const { business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );

  return {
    isAddingTag,
    isEditingTag,
    isRefresh,
    business_role,
    tagDetails,
    setIsDeletingTag,
    isDeletingTag,
    setTagDetails,
    setIsRefresh,
    setIsEditingTag,
    setIsAddingTag,
  };
};

export default TagsLayoutLogic;
