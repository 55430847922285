// import all icons and export them as an object
import getlumiIcon from "./getlumi-icon.svg"; //sample code
import arrow_right from "./arrow-right.svg";
import arrow_left from "./arrow-left.svg";
import nigerian_flag from "./nigerian-flag.svg";
import eye_icon from "./eye.svg";
import home from "./home.svg";
import settings from "./settings-account-more.svg";
import note from "./paper-note.svg";
import message from "./send-message-dm.svg";
import user_group from "./user-group-accounts.svg";
import like from "./thumbs-up.svg";
import add from "./add-plus.svg";
import arrow_down_alt from "./arrow-down-alt.svg";
import option_alt from "./option-alt.svg";
import copy from "./copy-duplicate.svg";
import shop from "./shop-store.svg";
import user from "./user-account-profile.svg";
import search from "./search.svg";
import chevron_left_arrow from "./chevron-left-arrow.svg";
import chevron_up_arrow from "./chevron-up-arrow.svg";
import chevron_down_arrow from "./chevron-down-arrow.svg";
import chevron_right_arrow from "./chevron-right-arrow.svg";
import chevron_left_arrow_black from "./chevron-left-arrow-black.svg";
import clock_time from "./clock-time.svg";
import add_blue from "./add-blue.svg";
import filter from "./Filter.svg";
import close from "./x-close.svg";
import check from "./Check.svg";
import logoutIcon from "./logout-icon.svg";
import money from "./money-alt-cash-currency.svg";
import message_alt from "./send-message-dm-alt.svg";
import transferIcon from "./transfer-icon.svg";
import user_alt from "./user-account-profile-alt.svg";
import paymentIcon from "./payment-icon.svg";
import walletIcon from "./wallet-icon.svg";
import terminal from "./launch-link-open.svg";
import tag from "./tag-price-discount.svg";
import pie_chart from "./pie-chart.svg";
import package_icon from "./package 1.svg";
import product_box_1 from "./product-box-1.svg";
import product_box_2 from "./product-box-2.svg";
import product_box_3 from "./product-box-3.svg";
import upload from "./upload-share.svg";
import alert_icon from "./alert-cirlcle.svg";
import deleteIcon from "./delete.svg";
import switch_off from "./switch-off.svg";
import switch_on from "./switch-on.svg";
import productIcon1 from "./productIcon1.svg";
import productIcon2 from "./productIcon2.svg";
import productIcon3 from "./productIcon3.svg";
import productIcon4 from "./productIcon4.svg";
import productIcon5 from "./productIcon5.svg";

const Icon = {
  getlumiIcon: getlumiIcon,
  arrow_right: arrow_right,
  arrow_left: arrow_left,
  nigerian_flag: nigerian_flag,
  eye_icon: eye_icon,
  home,
  settings,
  note,
  message,
  user_group,
  like,
  add,
  arrow_down_alt,
  option_alt,
  copy,
  shop,
  user,
  search,
  chevron_left_arrow,
  chevron_down_arrow,
  chevron_up_arrow,
  chevron_right_arrow,
  chevron_left_arrow_black,
  clock_time,
  add_blue,
  filter,
  close,
  check,
  logoutIcon,
  money,
  message_alt,
  transferIcon,
  user_alt,
  paymentIcon,
  walletIcon,
  terminal,
  tag,
  pie_chart,
  package_icon,
  product_box_1,
  product_box_2,
  product_box_3,
  upload,
  alert: alert_icon,
  deleteIcon,
  switch_off,
  switch_on,
  productIcon1,
  productIcon2,
  productIcon3,
  productIcon4,
  productIcon5,
};

export default Icon;
