import { useState, useContext, useEffect } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getRandomIntInclusive } from "../../../helpers";
import {
  loginUserVerification,
  getProfileDetails,
} from "../../../services/auth";
import {
  setAccessToken,
  setProfileDetails,
} from "../../../store/modules/profile";

const LoginVerificationLogic = () => {
  const [isVerifing, setIsVerifing] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const dispatch = useDispatch();
  const params = useSearchParams()[0];
  const email = params.get("email");
  const sessionId = params.get("session_id");

  useEffect(() => {
    if (!sessionId && !email) return navigate("/login");
  }, []);

  const handleOTPInputs = (value) => {
    setOtp(value);
    if (value.length !== 4) return;
    setIsVerifing(true);
    setRequestLoaderProgress(getRandomIntInclusive(3, 30));
    loginUserVerification({ otp: value, sessionId: sessionId })
      .then((response) => {
        setIsVerified(true);
        const { access, refresh } = response.data.data;
        getUserProfileDetails(access, refresh);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsVerifing(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          return triggerToast(error.response.data.detail, "warning");
      });
  };

  const getUserProfileDetails = (accessToken, refreshToken) => {
    setRequestLoaderProgress(getRandomIntInclusive(41, 70));
    getProfileDetails(accessToken)
      .then((response) => {
        setRequestLoaderProgress(100);
        if (!response.data.data.email_verified) {
          triggerToast("Please verify your email", "warning");
          return navigate(
            `/email-verification?email=${email}&token=${accessToken}`
          );
        }
        localStorage.setItem("refreshToken", refreshToken);
        dispatch(setAccessToken(accessToken));
        dispatch(setProfileDetails(response.data.data));
        triggerToast("Login successful", "success");
        if (localStorage.getItem("nextRoute")) {
          let pathname = localStorage.getItem("nextRoute");
          localStorage.removeItem("nextRoute");
          return navigate(pathname);
        }
        navigate("/app/dashboard?all_business=true");
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsVerifing(false);
        setIsVerified(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  return {
    isVerifing,
    isVerified,
    otp,
    handleOTPInputs,
    email,
  };
};

export default LoginVerificationLogic;
