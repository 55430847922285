import { useTable } from "react-table";
import { useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  getTransfers,
  addTransferTag,
  removeTransferTag,
} from "../../../services/transfers";
import { addTag, getTags } from "../../../services/tags";
import { useState, useEffect, useContext } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import {
  getUrlQuerysection,
  formatCurrency,
  getRandomIntInclusive,
} from "../../../helpers";

const ApprovedTransfersTableLogic = ({
  statusType,
  isRefreshTransfers,
  setIsRefreshTransfers,
  setNumberOfPendingTransfers,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isFetchingTransfers, setIsFetchingTransfers] = useState(false);
  const [transfers, setTransfers] = useState([]);
  const [transferPageDetails, setTransferPageDetails] = useState({});
  const [startDate] = useState(queryParams.get("start_date"));
  const [endDate] = useState(queryParams.get("end_date"));
  const [searchValue, setSearchValue] = useState(
    queryParams.get("query") !== null ? [queryParams.get("query")] : ""
  );
  const [tags, setTags] = useState([]);
  const [isFetchingTags, setIsFetchingTags] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTransfers();
    handleFetchTags();
  }, []);

  useEffect(() => {
    if (isRefreshTransfers) {
      fetchTransfers();
    }
  }, [isRefreshTransfers]);

  const fetchTransfers = (showLoader = true) => {
    setIsFetchingTransfers(showLoader);
    if (showLoader) setRequestLoaderProgress(getRandomIntInclusive(5, 50));
    let queries = getUrlQuerysection();
    getTransfers(queries)
      .then((response) => {
        setTransfers(response.data.data);
        setTransferPageDetails(response.data.meta);
        setNumberOfPendingTransfers(response.data.pending_transfer_request);
        setRequestLoaderProgress(100);
        setIsFetchingTransfers(false);
        setIsRefreshTransfers(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsFetchingTransfers(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const handleFetchTags = () => {
    setIsFetchingTags(true);
    setRequestLoaderProgress(40);
    getTags()
      .then((response) => {
        setIsFetchingTags(false);
        setRequestLoaderProgress(100);
        setTags(response.data?.data);
      })
      .catch((error) => {
        setIsFetchingTags(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const handleAddTag = (data) => {
    const { id, tags } = data;
    setRequestLoaderProgress(40);
    addTransferTag(id, tags)
      .then(() => {
        setRequestLoaderProgress(100);
        triggerToast("Tag added successfully", "success");
        fetchTransfers(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const handleRemoveTag = (data) => {
    const { id, tags } = data;
    setRequestLoaderProgress(40);
    removeTransferTag(id, tags)
      .then(() => {
        setRequestLoaderProgress(100);
        triggerToast("Tag removed successfully", "success");
        fetchTransfers(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const handleCreateTag = ({ value, transfer_id }) => {
    setRequestLoaderProgress(40);
    addTag({ name: value })
      .then((response) => {
        setRequestLoaderProgress(100);
        handleAddTag({ id: transfer_id, tags: [response.data?.data.id] });
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          return triggerToast(error.response.data.detail, "warning");
      });
  };

  const handleTagChange = (data) => {
    const { transfer_id, datas } = data;
    if (datas.action === "select-option") {
      handleAddTag({ id: transfer_id, tags: [datas.option.value] });
    } else if (datas.action === "remove-value") {
      const { removedValue } = datas;
      handleRemoveTag({ id: transfer_id, tags: [removedValue.value] });
    } else if (datas.action === "clear") {
      const { removedValues } = datas;
      const tags = removedValues.map((removedValue) => removedValue.value);
      handleRemoveTag({ id: transfer_id, tags: tags });
    }
  };

  const gotoPage = (pageNumber) => {
    let queries = getUrlQuerysection();
    let page = queryParams.get("page");
    if (page === null) {
      let symbol = queries.includes("?") ? "&" : "?";
      navigate(`${queries}${symbol}page=${pageNumber}`);
      return fetchTransfers();
    }

    let splitedQuery = queries.split(`page=${page}`);
    splitedQuery = splitedQuery[0] + `page=${pageNumber}` + splitedQuery[1];
    navigate(`${splitedQuery}`);
    fetchTransfers();
  };

  const searchTnx = (value) => {
    let pathName = statusType === "accepted" ? "approved" : "rejected";
    let searchQuery = "";
    if (queryParams.get("business_id") !== null)
      searchQuery = `&business_id=${queryParams.get("business_id")}`;
    else searchQuery = "&all_business=true";
    searchQuery = value === "" ? searchQuery : `${searchQuery}&query=${value}`;
    let route = `/app/transfers/${pathName}?status=${statusType}${searchQuery}`;
    navigate(route);
    fetchTransfers();
  };

  const setDateFilter = (data) => {
    let queries = getUrlQuerysection();
    let prevStartDate = queryParams.get("start_date");
    let prevEndDate = queryParams.get("end_date");
    let newQueries = queries;

    if (!queries.includes("?")) {
      navigate(`?start_date=${data.startDate}&end_date=${data.endDate}`);
    } else {
      if (prevStartDate === null) {
        newQueries = `${newQueries}&start_date=${data.startDate}&end_date=${data.endDate}`;
      } else {
        let splitedQuery = newQueries.split(`start_date=${prevStartDate}`);
        splitedQuery =
          splitedQuery[0] + `start_date=${data.startDate}` + splitedQuery[1];
        newQueries = `${splitedQuery}`;
        splitedQuery = newQueries.split(`end_date=${prevEndDate}`);
        splitedQuery =
          splitedQuery[0] + `end_date=${data.endDate}` + splitedQuery[1];
        newQueries = `${splitedQuery}`;
      }
      navigate(newQueries);
    }
    setIsRefreshTransfers(true);
  };

  const filterBy = () => {
    fetchTransfers();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleString("en-us"),
      },
      {
        Header: "Beneficiary",
        accessor: "account_name",
        Cell: ({ cell: { value, row } }) => (
          <Link
            className="purple--text"
            to={`/app/transfers/${row.original["id"]}`}
            style={{
              fontSize: "12px",
              textDecoration: "underline",
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: "Account No.",
        accessor: "account_number",
      },
      {
        Header: "Bank",
        accessor: "bank_name",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell: { value } }) => formatCurrency(value),
      },
      // {
      //     Header: "Transfer Fee",
      //     accessor: "fee",
      //     Cell: ({ cell: { value } }) => formatCurrency(value),
      // },
      // {
      //     Header: "Account",
      //     accessor: "business_tag"
      // },
      {
        Header: "Initiated By",
        accessor: "initiator_name",
        Cell: ({ cell: { value } }) => value !== null && value.split(" ")[0],
      },
      {
        Header: "Approved By",
        accessor: "approver_name",
        Cell: ({ cell: { value } }) => value !== null && value.split(" ")[0],
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            className={`${
              value === "successful" || value === "accepted"
                ? "success--text"
                : value === "pending_approval" || value === "pending"
                ? "pending--text"
                : "failed--text"
            }`}
          >
            {value === "accepted" ? "successful" : value}
          </span>
        ),
      },
    ],
    []
  );

  const instance = useTable({ columns, data: transfers });
  return {
    instance,
    isFetchingTransfers,
    transfers,
    transferPageDetails,
    gotoPage,
    searchTnx,
    filterBy,
    searchValue,
    setSearchValue,
    fetchTransfers,
    setDateFilter,
    startDate,
    endDate,
    tags,
    isFetchingTags,
    handleAddTag,
    handleTagChange,
    handleCreateTag,
  };
};

export default ApprovedTransfersTableLogic;
