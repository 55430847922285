import { getBeneficiaries } from "../../../../services/transfers";
import { useEffect, useState, useContext } from "react";
import { ToastContext } from "../../../../hooks/context";

const BeneficiariesLogic = () => {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [currentBeneficiaries, setCurrentBeneficiaries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const triggerToast = useContext(ToastContext);
  const [searchValue] = useState("");

  useEffect(() => {
    fetchBeneficiaries();
  }, []);

  const fetchBeneficiaries = () => {
    setIsLoading(true);
    getBeneficiaries()
      .then((response) => {
        setBeneficiaries(response.data.data);
        setCurrentBeneficiaries(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const searchBeneficary = (value) => {
    let newBeneficiaries = beneficiaries.filter((item) =>
      item.account_name.toLowerCase().includes(value.toLowerCase())
    );
    setCurrentBeneficiaries(newBeneficiaries);
  };

  return {
    fetchBeneficiaries,
    isLoading,
    searchBeneficary,
    searchValue,
    currentBeneficiaries,
  };
};
export default BeneficiariesLogic;
