import { motion } from "framer-motion";
import "./CartItemsWidget.scss";
import secondaryComponents from "..";
import CartItemsWidgetLogic from "./CartItemsWidgetLogic";

const CartItemsWidget = ({ closeWidget, checkoutType, order }) => {
  const carting = CartItemsWidgetLogic();

  return (
    <div className="cart-items-widget">
      <div
        className="cart-items-widget__backdrop"
        onClick={() => closeWidget(false)}
      ></div>
      <motion.div
        initial={{ opacity: 0, marginRight: "-400px" }}
        animate={{
          opacity: 1,
          marginRight: "0px",
          transition: { duration: 0.3 },
        }}
        exit={{ opacity: 0 }}
        className="cart-items-widget__content"
      >
        {/* Checkout by cash */}
        {checkoutType === "cash" && (
          <secondaryComponents.CashCheckoutWidget
            closeWidget={closeWidget}
            carting={carting}
            order={order}
          />
        )}
        {/* Checkout by card */}
        {checkoutType === "card" && (
          <secondaryComponents.CardCheckoutWidget
            closeWidget={closeWidget}
            carting={carting}
            order={order}
          />
        )}
        {/* Checkout by transfer */}
        {checkoutType === "transfer" && (
          <secondaryComponents.CheckoutByTransfer
            closeWidget={closeWidget}
            order={order}
          />
        )}
      </motion.div>
    </div>
  );
};

export default CartItemsWidget;
