import { useState, useContext } from "react";
import { updateCategoryDetails } from "../../../services/inventory";
import { ToastContext, RequestLoader } from "../../../hooks/context";

const EditProductCategoryLogic = ({ category, setIsOpen, setIsRefresh }) => {
  const [isUpdatingCategory, setIsUpdatingCategory] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [categoryName, setCategoryName] = useState(category.name || "");
  const [categoryDescription, setCategoryDescripion] = useState(
    category.description || ""
  );

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const handleUpdateCategory = () => {
    setIsUpdatingCategory(true);
    setRequestLoaderProgress(40);
    updateCategoryDetails(category.id, {
      name: categoryName,
      description: categoryDescription,
    })
      .then(() => {
        setIsUpdatingCategory(false);
        setRequestLoaderProgress(100);
        setIsRefresh(true);
        setIsOpen(false);
        triggerToast("Product category updated successfully", "success");
      })
      .catch((error) => {
        setIsUpdatingCategory(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  return {
    categoryDescription,
    categoryName,
    isUpdatingCategory,
    isEdited,
    setIsEdited,
    setIsUpdatingCategory,
    setCategoryDescripion,
    setCategoryName,
    handleUpdateCategory,
  };
};

export default EditProductCategoryLogic;
