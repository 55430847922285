import "./SideModal.scss";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";

const SideModal = ({
  children,
  width,
  isActive = false,
  closeModal,
  position = "right",
  closeViaOverlay = true,
}) => {
  if (isActive === true) {
    return ReactDOM.createPortal(
      <div
        className={`side-modal ${
          position === "right" ? "positionRight" : "positionLeft"
        }`}
      >
        {/* back layer */}
        <div
          onClick={() => {
            if (closeViaOverlay) closeModal();
          }}
          className="side-modal__backdrop"
        ></div>
        {/* modal section */}
        <motion.div
          initial={{
            opacity: 0,
            marginRight: `${position === "right" ? "-400px" : "0px"}`,
            marginLeft: `${position === "left" ? "-400px" : "0px"}`,
          }}
          animate={{
            opacity: 1,
            marginRight: "0px",
            marginLeft: "0px",
            transition: { duration: 0.3 },
          }}
          exit={{ opacity: 0 }}
          className="side-modal__container"
          style={{ width: width }}
        >
          {children}
        </motion.div>
      </div>,
      document.getElementById("sidemodal-root")
    );
  }
};

export default SideModal;
