import "./TerminalRequestForm.scss";
import primaryComponents from "../../primaryComponents";
import TerminalRequestFormValidation from "./TerminalRequestFormValidation";
import { Controller } from "react-hook-form";
import TerminalRequestFormLogic from "./TerminalRequestLogic";

const TerminalRequestForm = () => {
  const {
    terminal_type,
    payment_option,
    number_of_terminals,
    lumi_account,
    delivery_address,
    contact_phone_number,
    setTerminalType,
    setPaymentOption,
    setNumberOfTerminals,
    setLumiAccount,
    setDeliveryAddress,
    setContactPhoneNumber,
    handleRequestTerminal,
    isRequesting,
    business_list,
    subAccount,
    setSubAccount,
  } = TerminalRequestFormLogic();

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = TerminalRequestFormValidation({
    terminal_type,
    payment_option,
    number_of_terminals,
    lumi_account,
    delivery_address,
    contact_phone_number,
    subAccount,
  });
  return (
    <form
      onSubmit={handleSubmit(handleRequestTerminal)}
      className="terminal-request-form"
    >
      <div className="terminal-request-form__field">
        <Controller
          name="terminal_type"
          control={control}
          defaultValue={terminal_type}
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              label="Terminal Type"
              isRequired={true}
              placeholder="Choose Terminal Type"
              value={terminal_type || "Choose Terminal Type"}
              options={[{ label: "Linux", value: "LINUX" }]}
              idKey="value"
              nameKey="label"
              errorMessage={errors.terminal_type}
              onChange={(value) => {
                onChange(value);
                setTerminalType(value);
              }}
            />
          )}
        />
      </div>
      <div className="terminal-request-form__field">
        <Controller
          name="payment_option"
          control={control}
          defaultValue={payment_option}
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              label="Payment Option"
              isRequired={true}
              placeholder="Choose Payment Option"
              value={payment_option || "Choose Payment Option"}
              options={[
                {
                  label: "Outright Payment",
                  value: "OUTRIGHT_PAYMENT",
                },
              ]}
              idKey="value"
              nameKey="label"
              errorMessage={errors.payment_option}
              onChange={(value) => {
                onChange(value);
                setPaymentOption(value);
              }}
            />
          )}
        />
      </div>
      <div className="terminal-request-form__field">
        <Controller
          name="number_of_terminals"
          control={control}
          defaultValue={number_of_terminals}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label="Number of Terminals"
              isRequired={true}
              placeholder="Enter number of terminals"
              name="number_of_terminals"
              type="number"
              errorMessage={errors.number_of_terminals}
              register={register}
              value={number_of_terminals}
              onChange={({ target: { value } }) => {
                onChange(value);
                setNumberOfTerminals(value);
              }}
            />
          )}
        />
      </div>
      <div className="terminal-request-form__field">
        <Controller
          name="lumi_account"
          control={control}
          defaultValue={lumi_account}
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              label="Select account for card transaction"
              isRequired={true}
              placeholder="Choose Lumi Account"
              value={lumi_account || "Select Account"}
              options={business_list}
              nameKey="tag"
              idKey="id"
              errorMessage={errors.lumi_account}
              onChange={(value) => {
                onChange(value);
                setLumiAccount(value);
              }}
            />
          )}
        />
      </div>
      <div className="terminal-request-form__field">
        <Controller
          name="subAccount"
          control={control}
          defaultValue={subAccount}
          render={({ field: { onChange } }) => (
            <primaryComponents.MultipleSelectField
              label="Select account(s) for transfer transactions"
              isRequired={true}
              placeholder="Select account"
              name="subAccount"
              errorMessage={errors.subAccount}
              options={business_list}
              idKey="id"
              nameKey="tag"
              onChange={(values) => {
                onChange(values);
                let newValues = [];
                values.forEach((item) => {
                  newValues.push(item.value);
                });
                setSubAccount(newValues);
              }}
            />
          )}
        />
      </div>
      <div className="terminal-request-form__field">
        <Controller
          name="delivery_address"
          control={control}
          defaultValue={delivery_address}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label="Delivery Address"
              isRequired={true}
              placeholder="Enter delivery address"
              name="delivery_address"
              errorMessage={errors.delivery_address}
              register={register}
              value={delivery_address}
              onChange={({ target: { value } }) => {
                onChange(value);
                setDeliveryAddress(value);
              }}
            />
          )}
        />
      </div>
      <div className="terminal-request-form__field">
        <Controller
          name="contact_phone_number"
          control={control}
          defaultValue={contact_phone_number}
          render={({ field: { onChange } }) => (
            <primaryComponents.PhoneNumberInputField
              label="Contact Phone Number"
              isRequired={true}
              placeholder="Enter contact phone number"
              name="contact_phone_number"
              errorMessage={errors.contact_phone_number}
              register={register}
              value={contact_phone_number}
              onChange={({ target: { value } }) => {
                onChange(value);
                setContactPhoneNumber(value);
              }}
            />
          )}
        />
      </div>
      <div>
        <primaryComponents.Button
          classNames="btn btn--primary ml-auto mt-3"
          isDisabled={isRequesting}
          isLoading={isRequesting}
          loadingText="Requesting..."
        >
          Request Terminal
        </primaryComponents.Button>
      </div>
    </form>
  );
};

export default TerminalRequestForm;
