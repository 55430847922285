import "./CartItemsCheckout.scss";
import Icon from "../../../assets/icons";
import primaryComponents from "../../primaryComponents";
import CartItemsCheckoutValidation from "./CartItemsCheckoutValidation";
import { Controller } from "react-hook-form";

const CartItemsCheckout = ({ closeWidget, carting }) => {
  const { handleNextStep } = carting;

  const {
    register,
    // handleSubmit,
    // setError,
    control,
    // trigger,
    formState: { errors },
  } = CartItemsCheckoutValidation();

  return (
    <div className="cart-items-checkout">
      <div
        className="cart-items-checkout-close"
        onClick={() => closeWidget(false)}
      >
        <img src={Icon.close} alt="" />
      </div>
      <div className="cart-items-checkout-details-wrapper">
        <div className="cart-items-checkout-details">
          <h4 className="font-weight-semibold cart-items-checkout-details__title">
            Total Amount to Pay:
          </h4>
          <h4 className="font-weight-semibold carting-amount cart-items-checkout-details__value">
            N54,000
          </h4>
        </div>
        <div className="cart-items-checkout-details">
          <Controller
            defaultValue={""}
            name="amount"
            control={control}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                label={"Enter amount collected from customer"}
                placeholder={"Enter NAmount"}
                isRequired={true}
                type="text"
                name="amount"
                errorMessage={errors.amount}
                onChange={({ target: { value } }) => {
                  onChange(value);
                }}
                register={register}
              />
            )}
          />
        </div>
        <div className="cart-items-checkout-details">
          <p className="cart-items-checkout-details__title change big-text-size font-weight-semibold">
            Customer’s Change:
          </p>
          <p className="cart-items-checkout-details__value change-amount big-text-size font-weight-semibold">
            N0.00
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-between cart-items-checkout-submission">
        <primaryComponents.Button
          classNames={"btn btn--outline"}
          onClick={() => closeWidget(false)}
        >
          Skip
        </primaryComponents.Button>
        <primaryComponents.Button
          classNames={"btn btn--primary"}
          onClick={handleNextStep}
        >
          Record sale
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default CartItemsCheckout;
