import "./AllTransfers.scss";
import { Outlet } from "react-router-dom";
import secondaryComponents from "../../../../components/secondaryComponents";
import primaryComponents from "../../../../components/primaryComponents";
import { NavLink } from "react-router-dom";
import AllTransferLogic from "./AllTransfer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AllTransfers = () => {
  const {
    openTransferWidget,
    setOpenTransferWidget,
    business_role,
    newBusinessId,
    business_list,
    isRefresh,
    setIsRefresh,
    showAllBusiness,
    filterBaseOnAccount,
    appliedBusinessFilter,
    setNumberOfPendingTransfers,
    numberOfPendingTransfers,
    showStatementOfAccount,
    setShowStatementOfAccount,
  } = AllTransferLogic();

  return (
    <div className="all-transfers">
      <div className="all-transfers__header">
        <h3 className="font-weight-semibold">Transfer</h3>

        <div className="d-flex ml-auto">
          <primaryComponents.Button
            classNames="btn btn--primary"
            onClick={() => setOpenTransferWidget(true)}
          >
            {business_role === "BUSINESS_MANAGER"
              ? "Request Transfer"
              : "Transfer Funds"}
          </primaryComponents.Button>

          <primaryComponents.Button
            classNames="ml-2 btn btn--primary"
            onClick={() => setShowStatementOfAccount(true)}
          >
            Statement
            <FontAwesomeIcon icon="download" className="ml-3" />
          </primaryComponents.Button>
        </div>
      </div>

      <secondaryComponents.SubAccountsList
        businessList={business_list}
        showAllBusiness={showAllBusiness}
        filterBaseOnAccount={filterBaseOnAccount}
        newBusinessId={newBusinessId}
      />

      {/* tab navigation section */}
      <div className="all-transfers__tab">
        <NavLink
          to={`/app/transfers/approved${appliedBusinessFilter()}status=accepted`}
          className={`${
            // eslint-disable-next-line no-restricted-globals
            location.pathname === "/app/transfers" ? "active" : ""
          } all-transfers__tab__item`}
        >
          Approved Transfers
        </NavLink>
        <NavLink
          to={`/app/transfers/pending${appliedBusinessFilter()}status=pending_approval`}
          className="all-transfers__tab__item"
        >
          Pending Transfers
          {numberOfPendingTransfers > 0 && (
            <span className="ml-3 transfer-alert">
              {numberOfPendingTransfers}
            </span>
          )}
        </NavLink>
        <NavLink
          to={`/app/transfers/rejected${appliedBusinessFilter()}status=rejected`}
          className="all-transfers__tab__item"
        >
          Rejected Transfers
        </NavLink>
        <NavLink
          to="/app/transfers/beneficiaries"
          className="all-transfers__tab__item"
        >
          Saved Beneficiaries
        </NavLink>
      </div>

      {/* outlet section */}
      <Outlet
        context={{ isRefresh, setIsRefresh, setNumberOfPendingTransfers }}
      />

      {/* transfer widget */}
      {openTransferWidget && (
        <secondaryComponents.FundTransferWidget
          closeWidget={setOpenTransferWidget}
          refreshTransfers={setIsRefresh}
        />
      )}

      {/* statement of account widget */}
      {showStatementOfAccount && (
        <secondaryComponents.StatementOfAccount
          closeWidget={setShowStatementOfAccount}
        />
      )}
    </div>
  );
};
export default AllTransfers;
