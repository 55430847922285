import "./Switch.scss";

const Switch = ({ isOn, name, onChange }) => {
  return (
    <span className="custom-switch custom-switch--rounded">
      <input
        type="checkbox"
        defaultChecked={isOn}
        id={name}
        onChange={(e) => onChange(e.target.value)}
      />
      <label className="custom-switch__control" htmlFor={name}></label>
    </span>
  );
};
export default Switch;
