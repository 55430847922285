import { useState, useContext } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { sendUserInvite } from "../../../services/settings";
import { getRandomIntInclusive } from "../../../helpers";
import localData from "../../../localdata";
import { useSelector } from "react-redux";

const InviteUserLogic = (setShowInviteModal) => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [businessId, setBusinessId] = useState("");
  const [isInviting, setIsInviting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const [roleDescription, setRoleDescription] = useState("");
  const { business_list } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );

  const resetForm = () => {
    setEmail("");
    window.resetInviteForm({ email: "" });
    setRole("");
    window.resetInviteForm({ role: "" });
    window.clearInviteError();
  };

  const inviteUser = () => {
    setIsInviting(true);
    setErrorMessage(null);
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    sendUserInvite({
      email: email,
      role: role,
      businesses_id: businessId,
    })
      .then(() => {
        setIsInviting(false);
        triggerToast("Invitation sent successful", "success");
        setRequestLoaderProgress(100);
        resetForm();
        setShowInviteModal(false);
      })
      .catch((error) => {
        setIsInviting(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400) {
          setErrorMessage(error.response.data.detail);
          triggerToast(error.response.data.detail, "warning");
        }
        if (error.response.status === 422) {
          if (error.response.data.errors.email) {
            window.setInviteError(
              "email",
              {
                type: "validate",
                message: error.response.data.errors.email[0],
              },
              { shouldFocus: true }
            );
          }
          triggerToast(error.response.data.errors.email[0], "warning");
        }
      });
  };

  return {
    email,
    setEmail,
    role,
    setRole,
    errorMessage,
    isInviting,
    inviteUser,
    resetForm,
    userRoles: localData.usersRole,
    roleDescription,
    setRoleDescription,
    subAccounts: business_list,
    businessId,
    setBusinessId,
  };
};
export default InviteUserLogic;
