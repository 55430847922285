import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardDetails: {
    five_most_recent_transactions: [],
    revenue_summary: [],
    business: {},
    total_revenue: null,
  },
};

export const dashboardSlice = createSlice({
  name: "dasboard",
  initialState,
  reducers: {
    setDashboardDetails: (state, action) => {
      state.dashboardDetails = action.payload;
    },
  },
});

export const { setDashboardDetails } = dashboardSlice.actions;
