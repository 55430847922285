import "./Profile.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import ProfileLogic from "./ProfileLogic";
import { motion } from "framer-motion";

const Profile = () => {
  const { business_name, first_name, last_name } = ProfileLogic();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Settings - Profile"
      description="Edit your profile details on lumi merchant"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className="profile-page"
        >
          {/* personal information */}
          <h4 className="font-weight-normal mb-3">Personal Information</h4>
          <div className="profile-page__section">
            <div className="profile-page__section__left">
              <div className="profile-page__section__left__avatar">
                {first_name.charAt(0)}
                {last_name.charAt(0)}
              </div>
              <p className="mt-4 dark--text">
                {first_name} {last_name}
              </p>
            </div>
            <div className="profile-page__section__right">
              <secondaryComponents.ProfilePersonalInfoForm />
            </div>
          </div>
          {/* business info form */}
          <h4 className="font-weight-normal mb-3 mt-4">Business Information</h4>
          <div className="profile-page__section">
            <div className="profile-page__section__left">
              <div className="profile-page__section__left__avatar">
                {business_name.charAt(0)}
              </div>
              <p className="mt-4 dark--text">{business_name}</p>
            </div>
            <div className="profile-page__section__right">
              <secondaryComponents.ProfileBusinessInfoForm />
            </div>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default Profile;
