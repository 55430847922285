import "./OfferCard.scss";
import Icon from "../../../assets/icons";
import primaryComponents from "../../primaryComponents";
import OfferCardLogic from "./OfferCardLogic";
import { useNavigate } from "react-router-dom";
import { convertToTilteCase } from "../../../helpers";

const OfferCard = ({ offerDetails, campaignId }) => {
  const { toggleOffer } = OfferCardLogic({ campaignId, offerDetails });
  const navigate = useNavigate();

  return (
    <div className="offer__card">
      <div className="offer__card__details">
        <div className="offer__card__details__image">
          <img src={offerDetails.offer_image_url} alt="offer" />
        </div>
        <div className="offer__card__details__description mt-2">
          <div className="duration">
            <img className="mr-1" src={Icon.clock_time} alt="" />
            <span className="">
              {offerDetails.days_left}{" "}
              {parseInt(offerDetails.days_left, 10) >= 1 ? "DAYS" : "DAY"} TO GO
            </span>
          </div>
          <div className="d-flex mt-3">
            <p className=" dark--text small-text-size">
              {convertToTilteCase(offerDetails.name)}
            </p>
            <p className="dark--text ml-4 dark--text small-text-size">
              {offerDetails.reward_per_transaction}% per transaction
            </p>
          </div>
        </div>
      </div>
      <div className="offer__card__actions">
        <primaryComponents.Button
          className="btn btn--outline"
          onClick={() => {
            navigate(`/app/loyalty/edit/${campaignId}?section=offer`);
          }}
        >
          Edit offer
        </primaryComponents.Button>
        <div className="d-flex">
          <primaryComponents.Switch
            isOn={offerDetails.status === "ACTIVE"}
            name={offerDetails.id}
            onChange={toggleOffer}
          />
          <p className="ml-2 d-sm-none">Pause offer</p>
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
