import "./MainLayout.scss";
import MainLayoutLogic from "./MainLayoutLogic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MainLayout = ({ children }) => {
  const {
    RequestLoader,
    setRequestLoaderProgress,
    requestLoaderProgress,
    MetaHandler,
    setMetaDetails,
    metaDetails,
  } = MainLayoutLogic();
  return (
    <RequestLoader.Provider
      value={{ setRequestLoaderProgress, requestLoaderProgress }}
      className="main-layout"
    >
      {/* this loader shows a progress on top of the page and can be triggered from any child components */}
      <div className="request-loader">
        <div className="request-loader__bar">
          <div
            className="request-loader__bar__progress"
            style={{ width: `${requestLoaderProgress}%` }}
          ></div>
        </div>
        {requestLoaderProgress !== 0 && (
          <div className="request-loader__spinner">
            <FontAwesomeIcon
              icon={["fas", "spinner"]}
              className="fa-spin me-3"
              style={{ fontSize: "17px", color: "#F7AD3C" }}
            />
          </div>
        )}
      </div>
      {/* main children component */}
      <MetaHandler.Provider value={{ setMetaDetails, metaDetails }}>
        {children}
      </MetaHandler.Provider>
    </RequestLoader.Provider>
  );
};

export default MainLayout;
