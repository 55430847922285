import { useState, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { useNavigate } from "react-router-dom";
import { updateCampaign } from "../../../services/campaign";

export const useEditCampaignFormLogic = ({ campaign_id, campaign }) => {
  const [isUpdatingCampaign, setIsUpdatingCampaign] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  const [brandName, setBrandName] = useState(campaign?.brand_name || "");
  const [brandDescription, setBrandDescription] = useState(
    campaign?.brand_description || ""
  );
  const [brandCategory, setBrandCategory] = useState(
    campaign?.brand_category || ""
  );
  const [brandUrl, setBrandUrl] = useState(campaign?.brand_url || "");
  const [brandLogo, setBrandLogo] = useState(campaign?.brand_logo_url || "");

  const [campaignSlug, setCampaignSlug] = useState(
    campaign?.business_slug || ""
  );
  const [termsUrl, setTermsUrl] = useState(
    campaign?.term_and_condition_url || ""
  );
  const [campaignPointRatio, setCampaignPointRatio] = useState(
    Number(campaign?.campaign_point_ratio)?.toFixed(2) || 0
  );

  const [offers, setOffers] = useState(
    () =>
      campaign?.offers.map((offer) => ({ ...offer, isDisabled: true })) || []
  );

  const navigate = useNavigate();
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const serializeOffers = (offers = []) => {
    return offers.map((offer) => ({
      business_id: offer.id,
      reward_per_transaction: offer.reward_per_transaction,
    }));
  };

  const handleUpdateCampaign = () => {
    setIsUpdatingCampaign(true);
    setRequestLoaderProgress(30);
    updateCampaign(campaign_id, {
      brand_description: brandDescription,
      brand_category: brandCategory,
      campaign_point_ratio: campaignPointRatio,
      brand_url: brandUrl,
      brand_name: brandName,
      brand_logo_url: brandLogo,
      term_and_condition_url: termsUrl,
      offers: serializeOffers(offers),
    })
      .then(() => {
        setIsUpdatingCampaign(false);
        setRequestLoaderProgress(100);
        triggerToast("Campaign updated successfully", "success");
        navigate("/app/loyalty/manage");
      })
      .catch((error) => {
        setIsUpdatingCampaign(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
        if (error.response.status === 422) {
          const errors = error?.response?.data?.errors;
          if (errors?.business_slug) {
            window.setEditCampaignError(
              "campaignSlug",
              {
                type: "validate",
                message: errors?.business_slug[0],
              },
              { shouldFocus: true }
            );
          }
          if (errors?.brand_name || errors?.campaign_name) {
            window.setEditCampaignError(
              "brandName",
              {
                type: "validate",
                message: errors?.brand_name[0],
              },
              { shouldFocus: true }
            );
          }
          if (errors?.brand_description) {
            window.setEditCampaignError(
              "brandDescription",
              {
                type: "validate",
                message: errors?.brand_description[0],
              },
              { shouldFocus: true }
            );
          }
          if (errors?.brand_url) {
            window.setEditCampaignError(
              "brandUrl",
              {
                type: "validate",
                message: errors?.brand_url[0],
              },
              { shouldFocus: true }
            );
          }
          if (errors?.reward_per_transaction) {
            window.setEditCampaignError(
              "rewardPercentage",
              {
                type: "validate",
                message: errors?.reward_per_transaction[0],
              },
              { shouldFocus: true }
            );
          }
          if (errors?.brand_category) {
            window.setEditCampaignError(
              "brandCategory",
              {
                type: "validate",
                message: errors?.brand_category[0],
              },
              { shouldFocus: true }
            );
          }
          if (errors?.term_and_condition_url) {
            window.setEditCampaignError(
              "brandCategory",
              {
                type: "validate",
                message: errors?.term_and_condition_url[0],
              },
              { shouldFocus: true }
            );
          }
          if (errors?.campaign_point_ratio) {
            window.setEditCampaignError(
              "campaignPointRatio",
              {
                type: "validate",
                message: errors?.campaign_point_ratio[0],
              },
              { shouldFocus: true }
            );
          }
        }
      });
  };

  return {
    showImageModal,
    isUpdatingCampaign,
    brandCategory,
    brandName,
    brandDescription,
    brandLogo,
    brandUrl,
    termsUrl,
    campaignSlug,
    offers,
    campaignPointRatio,
    setCampaignPointRatio,
    setOffers,
    setCampaignSlug,
    setTermsUrl,
    setBrandCategory,
    setBrandDescription,
    setBrandName,
    setBrandUrl,
    setBrandLogo,
    setShowImageModal,
    handleUpdateCampaign,
  };
};
