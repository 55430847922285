import httpClient from "../axiosBase/axiosHandler";

export const getTransfers = (queries) => {
  const response = httpClient.get(`/transfers/${queries}`);
  return response;
};

export const getTransferDetails = (id) => {
  const response = httpClient.get(`/transfers/${id}/`);
  return response;
};

export const fetchBankList = () => {
  const response = httpClient.get("/transfers/bank_list/");
  return response;
};

export const initiateTransfer = (payload) => {
  const response = httpClient.post("/transfers/", payload);
  return response;
};

export const resolveAccountNumber = (payload) => {
  const response = httpClient.post("/transfers/resolve_account/", payload);
  return response;
};

export const getTransferFee = (payload) => {
  const response = httpClient.post("/transfers/calculate_fee/", payload);
  return response;
};

export const updateTransfer = (payload, id) => {
  const response = httpClient.patch(`/transfers/${id}/`, payload);
  return response;
};

export const getBeneficiaries = () => {
  const response = httpClient.get("/beneficiaries/");
  return response;
};

export const deleteBeneficiary = (id) => {
  const response = httpClient.delete(`/beneficiaries/${id}/`);
  return response;
};

export const addTransferTag = (id, tags = []) => {
  const response = httpClient.patch(`/transfers/${id}/add-tags/`, {
    tag_ids: tags,
  });
  return response;
};

export const removeTransferTag = (id, tags = []) => {
  const response = httpClient.patch(`/transfers/${id}/remove-tags/`, {
    tag_ids: tags,
  });
  return response;
};
