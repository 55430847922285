import { useTable } from "react-table";
import { useMemo, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import {
  getRandomIntInclusive,
  getUrlQuerysection,
  formatCurrency,
} from "../../../helpers";
import { getCustomersHistory } from "../../../services/customer";

const useCustomerHistoryTable = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [customerHistory, setCustomerHistory] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState({});
  const [searchValue] = useState(
    queryParams.get("q") !== null ? [queryParams.get("q")] : ""
  );
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCustomersHistory();
  }, []);

  const fetchCustomersHistory = () => {
    setIsLoading(true);
    setRequestLoaderProgress(getRandomIntInclusive(20, 50));
    let queries = getUrlQuerysection();
    getCustomersHistory(queries)
      .then((response) => {
        setCustomerHistory(response?.data?.data);
        setPaginationDetails({
          last_page: response?.data?.last_page,
          current_page: response?.data?.current_page,
        });
        setRequestLoaderProgress(100);
        setIsLoading(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsLoading(false);
        if (error?.response?.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const searchCustomer = (value) => {
    let searchQuery = `?query=${value}`;
    navigate(`/app/customers/history${searchQuery}`);
    fetchCustomersHistory();
  };

  const gotoPage = (pageNumber) => {
    let queries = getUrlQuerysection();
    let page = queryParams.get("page");
    if (page === null) {
      let symbol = queries.includes("?") ? "&" : "?";
      navigate(`${queries}${symbol}page=${pageNumber}`);
      return fetchCustomersHistory();
    }

    let splitedQuery = queries.split(`page=${page}`);
    splitedQuery = splitedQuery[0] + `page=${pageNumber}` + splitedQuery[1];
    navigate(`${splitedQuery}`);
    fetchCustomersHistory();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) =>
          value ? new Date(value).toLocaleString("en-us") : "-",
      },
      {
        Header: "Customer name",
        accessor: "customer_name",
      },
      {
        Header: "Phone Number",
        accessor: "customer_phone",
      },
      {
        Header: "Amount",
        accessor: "naira_equiv",
        Cell: ({ cell: { value } }) => formatCurrency(value),
      },
      {
        Header: "Points",
        accessor: "points",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Status",
        accessor: "giftcard_status",
        Cell: ({ cell: { value, row } }) => (
          <span
            className={`${
              row.original["type"] === "credit"
                ? "success--text"
                : value === "UNUSED"
                ? "pending--text"
                : "failed--text"
            }`}
          >
            {row.original["type"] === "credit"
              ? "Earned"
              : value === "UNUSED"
              ? "Purchased"
              : "Redeemed"}
          </span>
        ),
      },
    ],
    []
  );

  const instance = useTable({ columns, data: customerHistory });

  return {
    instance,
    isLoading,
    customerHistory,
    paginationDetails,
    searchCustomer,
    searchValue,
    gotoPage,
  };
};

export default useCustomerHistoryTable;
