import "./Settlements.scss";
// import Icon from "../../../../assets/icons";
import secondaryComponents from "../../../../components/secondaryComponents";
import SettlementsLogic from "./SettlementsLogic";
import SettlementsValidation from "./SettlementsValidation";
import { Controller } from "react-hook-form";

const Settlements = () => {
  const {
    settlementOption,
    isOpenSettlementForm,
    hasAccount,
    verification_status,
    setHasAccount,
    setIsOpenSettlementForm,
    setSettlementOption,
    handleSettlementType,
  } = SettlementsLogic();

  const { control } = SettlementsValidation();

  if (verification_status !== "verified") {
    return (
      <div className="settlements-page">
        <secondaryComponents.UnverifiedAccountWidget
          message="It appears your account has not been verified, please verify your account to continue"
          verification_status={verification_status}
        />
      </div>
    );
  }

  return (
    <div className="settlements-page">
      <h4 style={{ fontSize: "20px" }} className="font-weight-semibold">
        Please select settlement option
      </h4>
      <div className="settlement-options">
        <div className="settlement-options__item">
          <Controller
            name="settlement_option"
            control={control}
            defaultValue={settlementOption}
            render={({ field: { onChange } }) => (
              <secondaryComponents.WalletOption
                title="Lumi Wallet"
                subtitle="Funds will be settled instantly to your lumi wallet"
                onChange={(option) => {
                  onChange(option);
                  setSettlementOption(option);
                  handleSettlementType(option);
                }}
                name="settlement_option"
                checked={settlementOption === "wallet"}
                value="wallet"
              />
            )}
          />
        </div>
        <div className="settlement-options__item">
          <Controller
            name="settlement_option"
            control={control}
            defaultValue={settlementOption}
            render={({ field: { onChange } }) => (
              <secondaryComponents.WalletOption
                title="Bank Account"
                subtitle="Add your bank account and funds will be settled daily"
                onChange={(option) => {
                  onChange(option);
                  setSettlementOption(option);
                  if (hasAccount) {
                    handleSettlementType(option);
                  }
                }}
                name="settlement_option"
                checked={settlementOption === "bank_account"}
                value="bank_account"
              />
            )}
          />
        </div>
      </div>
      <div className="settlements-page__content">
        {settlementOption === "bank_account" && (
          <secondaryComponents.SettlementsTable
            isOpenSettlementForm={isOpenSettlementForm}
            setIsOpenSettlementForm={setIsOpenSettlementForm}
            setHasAccount={setHasAccount}
          />
        )}
      </div>
    </div>
  );
};

export default Settlements;
