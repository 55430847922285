import { useState, useEffect, useContext } from "react";
import {
  fetchCashFlowAnalytics,
  fetchTagsFlowAnalytics,
} from "../../../../services/dashboard";
import { getUrlQuerysection, getRandomIntInclusive } from "../../../../helpers";
import { ToastContext, RequestLoader } from "../../../../hooks/context";

const CashflowAnalyticsLogic = ({ isRefresh, setIsRefresh }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [cashFlowData, setCashFlowData] = useState({
    cash_inflow_trend: [],
    cash_outflow_trend: [],
    cash_inflow_count: [],
    cash_outflow_count: [],
  });
  const [cashflowTrend, setCasflowTrend] = useState([]);
  const [transactionCount, setTransactionCount] = useState([]);
  const [tagsData, setTagsData] = useState({
    revenue_tags: [],
    spend_tags: [],
  });
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  useEffect(() => {
    setRequestLoaderProgress(getRandomIntInclusive(5, 30));
    getCashFlowAnalytics();
    getTagsAnalytics();
    setRequestLoaderProgress(80);
  }, []);

  useEffect(() => {
    if (isRefresh) {
      setRequestLoaderProgress(getRandomIntInclusive(5, 30));
      getCashFlowAnalytics();
      getTagsAnalytics();
      setIsRefresh(false);
      setRequestLoaderProgress(80);
    }
  }, [isRefresh]);

  useEffect(() => {
    arrangeCasflowData();
    arrangeTransactionCountData();
  }, [cashFlowData]);

  const getCashFlowAnalytics = () => {
    setIsLoading(true);
    let queries = getUrlQuerysection();
    fetchCashFlowAnalytics(queries)
      .then((response) => {
        setIsLoading(false);
        setCashFlowData(response.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const getTagsAnalytics = () => {
    setIsLoadingTags(true);
    let queries = getUrlQuerysection();
    fetchTagsFlowAnalytics(queries)
      .then((response) => {
        setIsLoadingTags(false);
        setTagsData(response.data.data);
      })
      .catch((error) => {
        setIsLoadingTags(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const arrangeCasflowData = () => {
    let newData = [];
    for (
      let index = 0;
      index < cashFlowData.cash_inflow_trend.length;
      index++
    ) {
      newData.push({
        inflowTrend: cashFlowData.cash_inflow_trend[index].total_inflow,
        outflowTrend: cashFlowData.cash_outflow_trend[index].total_inflow,
        date: cashFlowData.cash_inflow_trend[index].date,
      });
    }
    setCasflowTrend(newData);
  };

  const arrangeTransactionCountData = () => {
    let newData = [];
    for (
      let index = 0;
      index < cashFlowData.cash_inflow_count.length;
      index++
    ) {
      newData.push({
        inflowCount: cashFlowData.cash_inflow_count[index].count,
        outflowCount: cashFlowData.cash_outflow_count[index].count,
        date: cashFlowData.cash_inflow_count[index].date,
      });
    }
    setTransactionCount(newData);
  };

  return {
    isLoading,
    cashFlowData,
    cashflowTrend,
    transactionCount,
    isLoadingTags,
    tagsData,
  };
};
export default CashflowAnalyticsLogic;
