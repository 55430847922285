import "./SpendAnalytics.scss";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import SpendAnalyticsLogic from "./SpendAnalyticsLogic";
import Images from "../../../assets/images";
import { formatCurrency } from "../../../helpers";
import { Link } from "react-router-dom";

const SpeedTooltip = ({ active, payload, sumOfTags }) => {
  if (active) {
    return (
      <div className="revenue-chart-tooltip">
        <p className="dark--text" style={{ fontSize: "13px" }}>
          {payload[0].name}
        </p>
        <p className="purple--text" style={{ fontSize: "12px" }}>
          {formatCurrency(payload[0].value)}
        </p>
        <p style={{ fontSize: "12px", color: "#FD561F" }}>
          {((payload[0].value / sumOfTags) * 100).toFixed(2)}%
        </p>
      </div>
    );
  }
  return null;
};

const SpendAnalytics = ({ tagsData }) => {
  const { data, COLORS, sumOfTags } = SpendAnalyticsLogic({ tagsData });
  return (
    <div className="spend-analytics">
      <h4 style={{ fontSize: "20px" }} className="font-weight-semibold mb-0">
        Spend
      </h4>
      <p style={{ fontSize: "13px" }}>
        <span>Vist </span>
        <Link
          style={{ fontSize: "13px" }}
          to="/app/transfers?all_business=true"
          className="purple--text"
        >
          Transfers page
        </Link>
        <span> to label your transfers</span>
      </p>
      {data.length > 0 ? (
        <>
          <div style={{ position: "relative" }}>
            <ResponsiveContainer
              height={"370px"}
              width={"100%"}
              aspect={1}
              className="spend-analytics__chart"
            >
              <PieChart>
                <Pie
                  data={data}
                  outerRadius={130}
                  innerRadius={100}
                  dataKey="total"
                  nameKey="tag_name"
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip
                  wrapperStyle={{ border: "none", outline: "none" }}
                  content={<SpeedTooltip sumOfTags={sumOfTags} />}
                />
              </PieChart>
            </ResponsiveContainer>
            <div className="spend-analytics__total">
              <p className="mb-1" style={{ fontSize: "14px" }}>
                Total
              </p>
              <h5>{formatCurrency(sumOfTags)}</h5>
            </div>
          </div>
          {/* legend section */}
          <div className="spend-analytics__legend">
            {data.map(({ total, tag_name }, index) => (
              <div className="spend-analytics__legend-item" key={index}>
                <span
                  style={{ background: COLORS[index % COLORS.length] }}
                  className="indicator mr-2"
                ></span>
                <span className="title">{tag_name}</span>
                <span className="value font-weight-semibold">
                  {((total / sumOfTags) * 100).toFixed(2)}%
                </span>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="mt-5 text-center">
          <img width={"150px"} src={Images.tagEmptyState} alt="spend tags" />
          <p className="font-weight-semibold mt-3 dark--text">
            No data available
          </p>
        </div>
      )}
    </div>
  );
};

export default SpendAnalytics;
