import { useState, useContext, useEffect } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { getGiftCardList } from "../../../services/giftcards";

export const useGiftCardListLogic = () => {
  const [isFetchingGiftCards, setIsFetchingGiftCards] = useState(false);
  const [giftcards, setGiftCards] = useState([]);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  useEffect(() => {
    handleFetchGiftCards();
  }, []);

  const handleFetchGiftCards = () => {
    setIsFetchingGiftCards(true);
    setRequestLoaderProgress(30);
    getGiftCardList()
      .then((response) => {
        setIsFetchingGiftCards(false);
        setRequestLoaderProgress(100);
        setGiftCards(response.data?.results);
      })
      .catch((error) => {
        setIsFetchingGiftCards(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  return { giftcards, isFetchingGiftCards };
};
