import "./SoleProprietorKYBForm.scss";

import { motion } from "framer-motion";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import { Controller } from "react-hook-form";
import useSolePropretorKyb from "./SoleProprietorKYBFormLogic";
import useSolePropretorKybFormValidation from "./SoleProprietorKYBFormValidation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DOCUMENT_UPLOAD_STATUS } from "../../../helpers/constants";

const SoleProprietorKYBForm = ({
  verificationData,
  uploadDocument,
  navigation,
  fetchDocuments,
}) => {
  const {
    business_category,
    business_description,
    brand_name,
    business_state,
    business_lga,
    business_address,
    cac_reg_number,
    tax_id_number,
    directors_bvn,
    // business_logo,
    cac_reg_form,
    business_name_cert,
    agree_terms,
    inc_date,
    nigerian_states,
    local_government_areas,
    business_categories,
    showBankVerificationOtpWidget,
    isVerifyingAccount,
    isVerifyingBvn,
    verification_status,
    govt_id,
    isOpenAgreementModal,
    setIsOpenAgreementModal,
    setGovtId,
    setIncDate,
    setBusinessCategory,
    setBusinessDescription,
    setBrandName,
    setBusinesState,
    setBusinessLga,
    setBusinessAddress,
    setCacRegNumber,
    setTaxIdNumber,
    setDirectorsBvn,
    setShowBankVerificationOtpWidget,
    setCacRegForm,
    setBusinessNameCert,
    setAgreeTerms,
    handleVerifyAccount,
    handleVerifyBvn,
    // uploadBusinessLogo,
  } = useSolePropretorKyb({ verificationData, navigation });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useSolePropretorKybFormValidation({
    business_category,
    business_description,
    brand_name,
    business_state,
    business_lga,
    business_address,
    cac_reg_number,
    tax_id_number,
    directors_bvn,
    agree_terms,
    inc_date,
  });

  return (
    <motion.form
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.8 } }}
      exit={{ opacity: 0 }}
      className="soleproprietor-kyb-form"
      onSubmit={handleSubmit(handleVerifyAccount)}
    >
      <div className="soleproprietor-kyb-form__group">
        <h4 className="font-weight-semibold">Business Category</h4>
        <Controller
          defaultValue={business_category}
          name="business_category"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              options={business_categories}
              placeholder="Select your business category"
              value={
                business_category
                  ? business_category
                  : "Select your business category"
              }
              onChange={(value) => {
                onChange(value);
                setBusinessCategory(value);
              }}
              errorMessage={errors.business_category}
              isDisabled={
                (business_category !== "" &&
                  verification_status === "pending_review") ||
                verification_status === "verified"
              }
            />
          )}
        />
      </div>
      <div className="soleproprietor-kyb-form__group">
        <h4 className="font-weight-semibold">
          Describe the nature of your business
        </h4>
        <Controller
          defaultValue={business_description}
          control={control}
          name="business_description"
          render={({ field: { onChange } }) => (
            <primaryComponents.TextArea
              placeholder="My business is a"
              errorMessage={errors.business_description}
              value={business_description}
              onChange={({ target: { value } }) => {
                onChange(value);
                setBusinessDescription(value);
              }}
              isDisabled={verification_status}
            />
          )}
        />
      </div>
      <div className="soleproprietor-kyb-form__group">
        <h4 className="font-weight-semibold">CAC Registered Business Name</h4>
        <Controller
          defaultValue={brand_name}
          control={control}
          name="brand_name"
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              placeholder="Enter your CAC registered business name"
              errorMessage={errors.brand_name}
              value={brand_name}
              onChange={({ target: { value } }) => {
                onChange(value);
                setBrandName(value);
              }}
              isDisabled={verification_status}
            />
          )}
        />
      </div>
      <div className="soleproprietor-kyb-form__group">
        <h4 className="font-weight-semibold">State</h4>
        <Controller
          defaultValue={business_state}
          name="business_state"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              placeholder="Select State"
              value={business_state ? business_state : "Select State"}
              options={nigerian_states}
              errorMessage={errors.business_state}
              onChange={(value) => {
                onChange(value);
                setBusinesState(value);
              }}
              isDisabled={verification_status}
            />
          )}
        />
      </div>
      <div className="soleproprietor-kyb-form__group">
        <h4 className="font-weight-semibold">Local Government</h4>
        <Controller
          defaultValue={business_lga}
          name="business_lga"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              placeholder="Select Local Government"
              value={business_lga ? business_lga : "Select Local Government"}
              options={local_government_areas}
              errorMessage={errors.business_lga}
              onChange={(value) => {
                onChange(value);
                setBusinessLga(value);
              }}
              isDisabled={verification_status}
            />
          )}
        />
      </div>
      <div className="soleproprietor-kyb-form__group">
        <h4 className="font-weight-semibold">Address</h4>
        <Controller
          defaultValue={business_address}
          name="business_address"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              placeholder="Enter your street address"
              errorMessage={errors.business_address}
              value={business_address}
              onChange={({ target: { value } }) => {
                onChange(value);
                setBusinessAddress(value);
              }}
              isDisabled={verification_status}
            />
          )}
        />
      </div>
      <div className="soleproprietor-kyb-form__group">
        <h4 className="font-weight-semibold">CAC Registration Number</h4>
        <Controller
          defaultValue={cac_reg_number}
          name="cac_reg_number"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              placeholder="Enter your CAC RC-Number"
              errorMessage={errors.cac_reg_number}
              value={cac_reg_number}
              onChange={({ target: { value } }) => {
                onChange(value);
                setCacRegNumber(value);
              }}
              isDisabled={verification_status}
            />
          )}
        />
      </div>
      <div className="soleproprietor-kyb-form__group">
        <h4 className="font-weight-semibold">Incorporation Date</h4>
        <Controller
          defaultValue={inc_date}
          name="inc_date"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.DateInput
              placeholder="Enter your incorporation date"
              errorMessage={errors.inc_date}
              value={inc_date}
              onChange={({ target: { value } }) => {
                onChange(value);
                setIncDate(value);
              }}
              isDisabled={verification_status}
            />
          )}
        />
      </div>
      <div className="soleproprietor-kyb-form__group">
        <h4 className="font-weight-semibold">Tax Identification Number</h4>
        <Controller
          defaultValue={tax_id_number}
          name="tax_id_number"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              placeholder="Enter your 15 digits Tax Identification Number e.g (1231122-0909143)"
              errorMessage={errors.tax_id_number}
              value={tax_id_number}
              onChange={({ target: { value } }) => {
                onChange(value);
                setTaxIdNumber(value);
              }}
              isDisabled={verification_status}
            />
          )}
        />
      </div>
      <div
        style={{ position: "relative" }}
        className="soleproprietor-kyb-form__group"
      >
        <h4 className="font-weight-semibold">Director&apos;s BVN</h4>
        <Controller
          defaultValue={directors_bvn}
          name="directors_bvn"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              placeholder="Enter your Director's 11-digit BVN"
              errorMessage={errors.directors_bvn}
              value={directors_bvn}
              onChange={({ target: { value } }) => {
                if (value.length === 11) handleVerifyBvn(value);
                onChange(value);
                setDirectorsBvn(value);
              }}
              isDisabled={verificationData?.bvn_is_verified}
            />
          )}
        />
        {isVerifyingBvn && (
          <FontAwesomeIcon
            style={{
              position: "absolute",
              right: "15px",
              // bottom: "50%",
              top: "51%",
              transform: "translateY(-50%)",
              color: "#7647EE",
            }}
            icon={["fas", "spinner"]}
            spin
          />
        )}
        {verificationData?.bvn_is_verified && (
          <FontAwesomeIcon
            style={{
              position: "absolute",
              right: "15px",
              // bottom: "50%",
              top: "60%",
              transform: "translateY(-50%)",
              color: "#7647EE",
            }}
            icon={["fa", "check"]}
          />
        )}
      </div>
      {showBankVerificationOtpWidget && (
        <div>
          <secondaryComponents.BankVerificationOtpWidget
            setShowBankVerificationOtpWidget={setShowBankVerificationOtpWidget}
            fetchDocuments={fetchDocuments}
          />
        </div>
      )}
      <div className="soleproprietor-kyb-form__group">
        <h4 className="font-weight-semibold">
          Government Issued ID (eg. NIN Slip, International Passport,
          Driver&apos;s License)
          {govt_id !== undefined && (
            <span
              className={
                "document-upload-status " +
                DOCUMENT_UPLOAD_STATUS[govt_id?.status]
              }
            >
              {govt_id?.status?.split("_")?.join(" ")}
            </span>
          )}
        </h4>
        <Controller
          defaultValue={govt_id}
          name="govt_id"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.FileInput
              name="govt_id"
              onChange={(document) => {
                onChange(document);
                setGovtId(document);
                if (document) {
                  const payload = {
                    type: "government_issued_id",
                    file: document,
                  };
                  uploadDocument(payload);
                }
              }}
              isDisabled={
                DOCUMENT_UPLOAD_STATUS[govt_id?.status] === "pending" ||
                DOCUMENT_UPLOAD_STATUS[govt_id?.status] === "approved"
              }
            />
          )}
        />
      </div>
      <div className="soleproprietor-kyb-form__group">
        <h4 className="font-weight-semibold">
          Upload CAC Registration Form
          {cac_reg_form !== undefined && (
            <span
              className={
                "document-upload-status " +
                DOCUMENT_UPLOAD_STATUS[cac_reg_form?.status]
              }
            >
              {cac_reg_form?.status?.split("_")?.join(" ")}
            </span>
          )}
        </h4>
        <Controller
          name="cac_reg_form"
          defaultValue={cac_reg_form}
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.FileInput
              name="cac_reg_form"
              onChange={(document) => {
                onChange(document);
                setCacRegForm(document);
                if (document) {
                  const payload = {
                    type: "cac_registration_form",
                    file: document,
                  };
                  uploadDocument(payload);
                }
              }}
              isDisabled={
                DOCUMENT_UPLOAD_STATUS[cac_reg_form?.status] === "pending" ||
                DOCUMENT_UPLOAD_STATUS[cac_reg_form?.status] === "approved"
              }
            />
          )}
        />
      </div>
      <div className="soleproprietor-kyb-form__group">
        <h4 className="font-weight-semibold">
          Upload your certificate of Incorporation
          {business_name_cert !== undefined && (
            <span
              className={
                "document-upload-status " +
                DOCUMENT_UPLOAD_STATUS[business_name_cert?.status]
              }
            >
              {business_name_cert?.status?.split("_")?.join(" ")}
            </span>
          )}
        </h4>
        <Controller
          name="business_name_cert"
          defaultValue={business_name_cert}
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.FileInput
              name="business_name_cert"
              onChange={(document) => {
                onChange(document);
                setBusinessNameCert(document);
                if (document) {
                  const payload = {
                    type: "certificate_of_registration_business_name",
                    file: document,
                  };
                  uploadDocument(payload);
                }
              }}
              isDisabled={
                DOCUMENT_UPLOAD_STATUS[business_name_cert?.status] ===
                  "pending" ||
                DOCUMENT_UPLOAD_STATUS[business_name_cert?.status] ===
                  "approved"
              }
            />
          )}
        />
      </div>
      <div className="soleproprietor-kyb-form__group d-flex align-items-center">
        <primaryComponents.Checkbox
          name="agreeToTerms"
          id="agreeToTerms"
          isChecked={isOpenAgreementModal}
          onChange={(value) => {
            setIsOpenAgreementModal(value);
          }}
        />
        <label
          htmlFor="agreeToTerms"
          style={{ fontSize: "16px", marginLeft: "10px" }}
        >
          I agree to Enlumi Data&apos;s{" "}
          <a
            href="https://www.lumibusiness.io/terms-and-conditions"
            target="_blank"
            rel="noreferrer noopener"
            className="purple--text"
          >
            Service Level Agreement
          </a>
        </label>
      </div>
      <div className="soleproprietor-kyb-form__group d-flex align-items-center">
        <primaryComponents.Button
          classNames={"btn btn--primary ml-auto"}
          isDisabled={!agree_terms || verification_status}
          isLoading={isVerifyingAccount}
          loadingText="verifying account..."
        >
          Submit
        </primaryComponents.Button>
      </div>
      {isOpenAgreementModal && (
        <secondaryComponents.ServiceLevelAgreementModal
          isActive={isOpenAgreementModal}
          setIsActive={setIsOpenAgreementModal}
          setAgreeTerms={setAgreeTerms}
        />
      )}
    </motion.form>
  );
};

export default SoleProprietorKYBForm;
