import { useState, useContext } from "react";
import { forgetPassword } from "../../../services/auth";
import { ToastContext, RequestLoader } from "../../../hooks/context";

const ForgetPasswordLogic = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const submitEmail = () => {
    setErrorMessage(null);
    setRequestLoaderProgress(50);
    setIsLoading(true);
    forgetPassword({
      email: email,
    })
      .then(() => {
        setRequestLoaderProgress(100);
        setIsLoading(false);
        triggerToast("A reset link has been sent to your email", "success");
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsLoading(false);
        if (error.response === undefined) return;
        if (error.response.status === 400) {
          setErrorMessage(error.response.data.detail);
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };
  return { email, setEmail, submitEmail, isLoading, errorMessage };
};
export default ForgetPasswordLogic;
