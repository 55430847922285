import { useState, useRef, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import { addMultipleProducts } from "../../../../services/inventory";
import { useNavigate } from "react-router-dom";

const AddMultipleProductsLogic = () => {
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const inputRef = useRef();
  const navigate = useNavigate();

  const handleProductUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return triggerToast("Please select a file", "warning");

    if (event.target.files[0].size > 10000000) {
      return triggerToast("File size should be less than 10mb", "warning");
    }

    setIsUploadingFile(true);
    setRequestLoaderProgress(40);

    const formData = new FormData();
    formData.append("file", file);

    addMultipleProducts(formData)
      .then(() => {
        setIsUploadingFile(false);
        setRequestLoaderProgress(100);
        navigate("/app/products");
        inputRef.current.value = null;
        triggerToast("File uploaded successfully", "success");
      })
      .catch((error) => {
        setIsUploadingFile(false);
        setRequestLoaderProgress(100);
        inputRef.current.value = null;
        if (error.response === undefined) return;
        if (error.response.status === 400 || error.response.status === 401)
          triggerToast(error.response.data.detail, "warning");
        if (error.response.status === 400 && error.response.data?.data) {
          const reasons = error.response.data?.data?.reasons;
          const errors = Object.keys(reasons).map((reason) => reasons[reason]);
          errors.map((error) => triggerToast(error, "warning"));
        }
      });
  };

  return {
    isUploadingFile,
    inputRef,
    handleProductUpload,
  };
};

export default AddMultipleProductsLogic;
