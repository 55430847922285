import "./Webhooks.scss";

import secondaryComponents from "../../../../components/secondaryComponents";

const Webhooks = () => {
  return (
    <div className="webhooks-page">
      <secondaryComponents.ComingSoon />
      {/* <div className="webhooks-page__nav">
                <h4 className="font-weight-semibold">Live Webhooks</h4>
                <primaryComponents.Button
                    classNames={"btn btn--primary"}
                    isDisabled={true}
                >
                    Save
                </primaryComponents.Button>
            </div>
            <div className="webhooks-page__content">
                <div className="webhooks-page__content__section">
                    <h5 className="webhooks-page__content__section__title">
                        URL
                    </h5>
                    <div className="webhooks-page__content__section__key">
                        <span className="webhooks-page__content__section__key__label">
                            https://www.figma.com/file/ydAYSwP5yAIWjDcnpscyu3/TTFCX?node-id=3344%3A30292
                        </span>
                    </div>
                </div>
                <div className="webhooks-page__content__section">
                    <h5 className="webhooks-page__content__section__title">
                        Secret Hash
                    </h5>
                    <div className="webhooks-page__content__section__key">
                        <span className="webhooks-page__content__section__key__label">
                            https://www.figma.com/file/ydAYSwP5yAIWjDcnpscyu3/TTFCX?node-id=3344%3A30292
                        </span>
                    </div>
                </div>
            </div> */}
    </div>
  );
};

export default Webhooks;
