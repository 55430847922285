import { useTable } from "react-table";
import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getTransactions,
  addTransactionTag,
  removeTransactionTag,
} from "../../../services/transaction";
import { getTags, addTag } from "../../../services/tags";
import { useState, useEffect, useContext } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import {
  getUrlQuerysection,
  formatCurrency,
  getDateMargin,
  convertToTilteCase,
  getRandomIntInclusive,
} from "../../../helpers";
import { useSelector } from "react-redux";

const useTransactionsTable = ({ isRefeshTnx, setRefreshTnx }) => {
  const { business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const queryParams = new URLSearchParams(window.location.search);
  const [isFetchingTransactions, setIsFetchingTransactions] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [transactionsPageDetails, setTransactionsPageDetails] = useState({});
  const [startDate] = useState(queryParams.get("start_date"));
  const [endDate] = useState(queryParams.get("end_date"));
  const [searchValue] = useState(
    queryParams.get("query") !== null ? [queryParams.get("query")] : ""
  );
  const [openTagWidget] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(
    queryParams.get("status") !== null
      ? queryParams.get("status").split("|")
      : []
  );
  const [paymentMethod, setPaymentMethod] = useState(
    queryParams.get("payment_method") !== null
      ? queryParams.get("payment_method").split("|")
      : []
  );
  const [timeDuration, setTimeDuration] = useState(
    queryParams.get("start_date") !== null
      ? getDateMargin(
          queryParams.get("start_date"),
          queryParams.get("end_date")
        )
      : ""
  );

  const [tags, setTags] = useState([]);
  const [isFetchingTags, setIsFetchingTags] = useState(false);

  const filterDetails = {
    paymentStatus,
    setPaymentStatus,
    paymentMethod,
    setPaymentMethod,
    timeDuration,
    setTimeDuration,
  };
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTransactions();
    handleFetchTags();
  }, []);

  useEffect(() => {
    if (isRefeshTnx) fetchTransactions();
  }, [isRefeshTnx]);

  const fetchTransactions = (showLoader = true) => {
    setIsFetchingTransactions(showLoader);
    if (showLoader) setRequestLoaderProgress(getRandomIntInclusive(20, 50));
    let queries = getUrlQuerysection();
    getTransactions(queries)
      .then((response) => {
        setTransactions(response.data.data);
        setTransactionsPageDetails(response.data.meta);
        setRequestLoaderProgress(100);
        setIsFetchingTransactions(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsFetchingTransactions(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
    setRefreshTnx(false);
  };

  const handleFetchTags = () => {
    setIsFetchingTags(true);
    setRequestLoaderProgress(40);
    getTags()
      .then((response) => {
        setIsFetchingTags(false);
        setRequestLoaderProgress(100);
        setTags(response.data?.data);
      })
      .catch((error) => {
        setIsFetchingTags(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const handleAddTag = (data) => {
    const { id, tags } = data;
    setRequestLoaderProgress(40);
    addTransactionTag(id, tags)
      .then(() => {
        setRequestLoaderProgress(100);
        triggerToast("Tag added successfully", "success");
        fetchTransactions(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const handleRemoveTag = (data) => {
    const { id, tags } = data;
    setRequestLoaderProgress(40);
    removeTransactionTag(id, tags)
      .then(() => {
        setRequestLoaderProgress(100);
        triggerToast("Tag removed successfully", "success");
        fetchTransactions(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const handleCreateTag = ({ value, transaction_id }) => {
    setRequestLoaderProgress(40);
    addTag({ name: value })
      .then((response) => {
        const tag = response.data?.data;
        handleAddTag({ id: transaction_id, tags: [tag.id] });
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          return triggerToast(error.response.data.detail, "warning");
      });
  };

  const handleTagChange = (data) => {
    const { transfer_id, datas } = data;
    if (datas.action === "select-option") {
      handleAddTag({ id: transfer_id, tags: [datas.option.value] });
    } else if (datas.action === "remove-value") {
      const { removedValue } = datas;
      handleRemoveTag({ id: transfer_id, tags: [removedValue.value] });
    } else if (datas.action === "clear") {
      const { removedValues } = datas;
      const tags = removedValues.map((removedValue) => removedValue.value);
      handleRemoveTag({ id: transfer_id, tags: tags });
    }
  };

  const gotoPage = (pageNumber) => {
    let queries = getUrlQuerysection();
    let page = queryParams.get("page");
    if (page === null) {
      let symbol = queries.includes("?") ? "&" : "?";
      navigate(`${queries}${symbol}page=${pageNumber}`);
      return fetchTransactions();
    }

    let splitedQuery = queries.split(`page=${page}`);
    splitedQuery = splitedQuery[0] + `page=${pageNumber}` + splitedQuery[1];
    navigate(`${splitedQuery}`);
    fetchTransactions();
  };

  const searchTnx = (value) => {
    let searchQuery = "";
    if (queryParams.get("business_id") !== null)
      searchQuery = `?business_id=${queryParams.get("business_id")}`;
    else searchQuery = "?all_business=true";
    searchQuery = value === "" ? searchQuery : `${searchQuery}&query=${value}`;
    navigate(`/app/transactions${searchQuery}`);
    fetchTransactions();
  };

  const setDateFilter = (data) => {
    let queries = getUrlQuerysection();
    let prevStartDate = queryParams.get("start_date");
    let prevEndDate = queryParams.get("end_date");
    let newQueries = queries;

    if (!queries.includes("?")) {
      navigate(`?start_date=${data.startDate}&end_date=${data.endDate}`);
    } else {
      if (prevStartDate === null) {
        newQueries = `${newQueries}&start_date=${data.startDate}&end_date=${data.endDate}`;
      } else {
        let splitedQuery = newQueries.split(`start_date=${prevStartDate}`);
        splitedQuery =
          splitedQuery[0] + `start_date=${data.startDate}` + splitedQuery[1];
        newQueries = `${splitedQuery}`;
        splitedQuery = newQueries.split(`end_date=${prevEndDate}`);
        splitedQuery =
          splitedQuery[0] + `end_date=${data.endDate}` + splitedQuery[1];
        newQueries = `${splitedQuery}`;
      }
      navigate(newQueries);
    }
    setRefreshTnx(true);
  };

  const filterBy = () => {
    fetchTransactions();
  };

  const columns = useMemo(() => {
    let headers = [
      {
        Header: "Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) =>
          value ? new Date(value).toLocaleString("en-us") : "-",
      },
      {
        Header: "Customer Name",
        accessor: "payer",
        Cell: ({ cell: { value, row } }) => (
          <Link
            className="purple--text"
            to={`${row.original["id"]}`}
            style={{
              fontSize: "12px",
              textDecoration: "underline",
            }}
          >
            {value ? convertToTilteCase(value) : "N/A"}
          </Link>
        ),
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell: { value } }) => (value ? formatCurrency(value) : "-"),
      },
      // {
      //     Header: "Cashback Paid",
      //     accessor: "cash_earned",
      //     Cell: ({ cell: { value } }) => formatCurrency(value),
      // },
      {
        Header: "Account",
        accessor: "business_tag",
      },
    ];

    if (business_role !== "CUSTOMER_SUPPORT") {
      headers.push({
        Header: "Transaction Fee",
        accessor: "settlement_fee",
        Cell: ({ cell: { value } }) => (value ? formatCurrency(value) : "-"),
      });
      headers.push({
        Header: "Amount Settled",
        accessor: "amount_settled",
        Cell: ({ cell: { value } }) => (value ? formatCurrency(value) : "-"),
      });
    }
    headers.push({
      Header: "Status",
      accessor: "status",
      Cell: ({ cell: { value } }) => (
        <span
          className={`${
            value === "settled"
              ? "success--text"
              : value === "unpaid"
              ? "pending--text"
              : "failed--text"
          }`}
        >
          {value}
        </span>
      ),
    });

    return headers;
  }, []);

  const instance = useTable({ columns, data: transactions });
  return {
    instance,
    isFetchingTransactions,
    transactions,
    transactionsPageDetails,
    gotoPage,
    searchTnx,
    filterBy,
    filterDetails,
    searchValue,
    business_role,
    setDateFilter,
    startDate,
    endDate,
    openTagWidget,
    tags,
    isFetchingTags,
    handleTagChange,
    handleCreateTag,
  };
};

export default useTransactionsTable;
