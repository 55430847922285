import { useState, useEffect, useContext } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { getGiftCardDetails } from "../../../services/giftcards";
import { getRandomIntInclusive } from "../../../helpers";

export const useEditGiftCardLogic = ({ giftcard_id }) => {
  const [isFetchingGiftCard, setIsFetchingGiftCard] = useState(false);
  const [giftcard, setGiftCard] = useState(null);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  useEffect(() => {
    handleFetchGiftCard();
  }, []);

  const handleFetchGiftCard = () => {
    setIsFetchingGiftCard(true);
    setRequestLoaderProgress(getRandomIntInclusive(30, 60));
    getGiftCardDetails(giftcard_id)
      .then((response) => {
        setIsFetchingGiftCard(false);
        setRequestLoaderProgress(100);
        setGiftCard(response.data?.data);
      })
      .catch((error) => {
        setIsFetchingGiftCard(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  return { isFetchingGiftCard, giftcard };
};
