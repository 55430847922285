import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency } from "../../../../helpers";
import primaryComponents from "../../../primaryComponents";
import "./TransactionDetails.scss";
import TransactionDetailsLogic from "./TransactionDetailsLogic";
import { motion } from "framer-motion";

const TransactionDetails = ({
  orderDetails,
  closeStoreCheckout,
  openSendReceipt,
  gotoReceiptTerminals,
}) => {
  const { isLoading, printReceipt } = TransactionDetailsLogic();

  return (
    <div className="storeTransactiondetails">
      <FontAwesomeIcon
        onClick={closeStoreCheckout}
        className="d-block ml-auto"
        icon="times"
        style={{ fontSize: "25px" }}
      />

      <motion.div className="mt-5" initial={{ y: 100 }} animate={{ y: 0 }}>
        <h5 className="mb-3">Transaction Details</h5>
        <div className="d-flex justify-content-between mb-2">
          <p>Order Date:</p>
          <p className="text-right dark--text">
            {new Date(orderDetails?.created_at).toLocaleString("en-us")}
          </p>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <p>Transaction Reference:</p>
          <p className="text-right dark--text">
            {orderDetails?.trx_reference || "N/A"}
          </p>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <p>Payment Mode:</p>
          <p className="text-right dark--text">
            {orderDetails?.payment_method || "N/A"}
          </p>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <p>Cashier:</p>
          <p className="text-right dark--text">N/A</p>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <p>Customer:</p>
          <p className="text-right dark--text">
            {orderDetails?.customer?.full_name || "N/A"}
          </p>
        </div>
        <h5 className="mt-4 mb-3">Ordered Items</h5>
        <div className="orderDetails">
          <p>ITEM</p>
          <p>QUANTITY</p>
          <p>UNIT(S)</p>
          <p>AMOUNT</p>
        </div>
        {orderDetails?.order_items?.map((item) => (
          <div className="orderDetails" key={item?.id}>
            <p>{item?.product?.name}</p>
            <p className="text-left">{item?.quantity}</p>
            <p className="text-left">
              {item?.product?.unit_value + item?.product?.unit?.unit || "N/A"}
            </p>
            <p className="text-left">{formatCurrency(item?.total_price)}</p>
          </div>
        ))}
        <div className="mt-2 d-flex justify-content-between">
          <p>Subtotal:</p>
          <h6> {formatCurrency(orderDetails?.total_item_amount)}</h6>
        </div>
        <h5 className="mt-4 mb-3">Price Summary</h5>
        <div className="mt-3 d-flex justify-content-between">
          <p>Subtotal:</p>
          <h6> {formatCurrency(orderDetails?.total_item_amount)}</h6>
        </div>
        <div className="d-flex justify-content-between">
          <p>Delivery Fee:</p>
          <h6>{formatCurrency(orderDetails?.delivery_fee || 0)}</h6>
        </div>
        <div className="mt-1 d-flex justify-content-between">
          <p>Discount:</p>
          <h6>{formatCurrency(orderDetails?.discount_amount || 0)}</h6>
        </div>
        <div className="mt-1 d-flex justify-content-between">
          <p>VAT:</p>
          <h6>{formatCurrency(orderDetails?.vat || 0)}</h6>
        </div>
        <div className="mt-1 d-flex justify-content-between">
          <p>Total:</p>
          <h6 className="purple--text">
            {formatCurrency(orderDetails?.total_amount)}
          </h6>
        </div>
      </motion.div>

      <div className="storeTransactiondetails__bottom">
        <primaryComponents.Button
          classNames="btn btn--outline cover"
          isLoading={isLoading}
          isDisabled={isLoading}
          loadingText="Loading..."
          onClick={printReceipt}
        >
          Download
        </primaryComponents.Button>
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          onClick={gotoReceiptTerminals}
        >
          Print
        </primaryComponents.Button>
        <primaryComponents.Button
          classNames="btn btn--outline cover"
          onClick={openSendReceipt}
        >
          Send
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default TransactionDetails;
