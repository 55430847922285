import { useState, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { addLoyaltyCustomer } from "../../../services/customer";
import { useNavigate } from "react-router-dom";

export const useAddCustomerWidgetLogic = ({ closeWidget }) => {
  const [customerName, setCustomerName] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerGender, setCustomerGender] = useState("MALE");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const handleAddCustomer = () => {
    setIsSubmitting(true);
    setRequestLoaderProgress(30);
    addLoyaltyCustomer({
      first_name: customerName,
      phone: customerPhoneNumber,
      email: customerEmail,
      gender: customerGender,
      date_of_birth: dateOfBirth,
    })
      .then(() => {
        setIsSubmitting(false);
        setRequestLoaderProgress(100);
        triggerToast("Customer added successfully", "success");
        navigate("/app/customers");
        closeWidget(true);
      })
      .catch((error) => {
        setIsSubmitting(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  return {
    customerName,
    customerEmail,
    customerGender,
    customerPhoneNumber,
    dateOfBirth,
    isSubmitting,
    setDateOfBirth,
    setCustomerEmail,
    setCustomerGender,
    setCustomerName,
    setCustomerPhoneNumber,
    handleAddCustomer,
  };
};
