const usersRole = [
  {
    label: "Owner",
    value: "OWNER",
    description:
      "Users with this role can carry out all actions on the platform",
  },
  {
    value: "ADMIN",
    label: "Admin",
    description:
      "Users with this role can perform all tasks except delete owner",
  },
  {
    label: "Business Manager",
    value: "BUSINESS_MANAGER",
    description:
      "Users with this role can perform business related tasks i.e view access to dashboard, view only access on transactions, customer details, and schedule transfers",
  },
  {
    value: "DEVELOPER",
    label: "Developer",
    description:
      "Users with this role can perform developer integrations i.e view all access on test mode, view only access on transactions, settings, customer details",
  },
  {
    label: "Customer support",
    value: "CUSTOMER_SUPPORT",
    description:
      "Users with this role can perform support related tasks i.e view only access on transactions , customer details and transfers",
  },
];

export default usersRole;
