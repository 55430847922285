import primaryComponents from "../../primaryComponents";
import { Controller } from "react-hook-form";
import { motion } from "framer-motion";
import { formatCurrency } from "../../../helpers";

const RestockProductWidgetHome = ({
  selectedProduct,
  hasVariants,
  setHasVariants,
  control,
  costPrice,
  sellingPrice,
  stockQuantity,
  minStockQuantity,
  expiryDate,
  setCostPrice,
  setSellingPrice,
  setStockQuantity,
  setMinStockQuantity,
  setExpiryDate,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0 }}
    >
      <div className="product-restock-form">
        <Controller
          control={control}
          name="costPrice"
          defaultValue={costPrice}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              classNames="white"
              name="costPrice"
              value={costPrice}
              label="Cost Price"
              onChange={({ target: { value } }) => {
                onChange(value);
                setCostPrice(value);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="sellingPrice"
          defaultValue={sellingPrice}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              classNames="white"
              name="sellingPrice"
              value={sellingPrice}
              label="Selling Price"
              onChange={({ target: { value } }) => {
                onChange(value);
                setSellingPrice(value);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="quantity"
          defaultValue={stockQuantity}
          render={({ field: { onChange } }) => (
            <primaryComponents.StockUnitInput
              name="quantity"
              value={stockQuantity}
              label="Quantity"
              onChange={({ target: { value } }) => {
                onChange(value);
                setStockQuantity(value);
              }}
            />
          )}
        />
        <div className="profit-margin-input">
          <label htmlFor="profit_margin_container">Profit</label>
          <div className="profit_margin_container">
            {formatCurrency(sellingPrice - costPrice)}
          </div>
        </div>
        <Controller
          control={control}
          name="expiryDate"
          defaultValue={expiryDate}
          render={({ field: { onChange } }) => (
            <primaryComponents.DateInput
              classNames="white"
              name="costPrice"
              value={expiryDate}
              label="Expiry Date"
              onChange={({ target: { value } }) => {
                onChange(value);
                setExpiryDate(value);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="stockLevelAlert"
          defaultValue={minStockQuantity}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              classNames="white"
              name="stockLevelAlert"
              value={minStockQuantity}
              label="Stock Level Alert"
              onChange={({ target: { value } }) => {
                onChange(value);
                setMinStockQuantity(value);
              }}
            />
          )}
        />
      </div>
      <div style={{ marginTop: "24px" }}>
        <label className="d-flex align-items-center" htmlFor="hasVariants">
          <primaryComponents.Checkbox
            isChecked={hasVariants === true}
            name="hasVariants"
            id="hasVariants"
            onChange={(value) => setHasVariants(value)}
          />
          <p className="ml-2 dark--text">Has Variants?</p>
        </label>
      </div>
    </motion.div>
  );
};

export default RestockProductWidgetHome;
