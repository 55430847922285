import "./CampaignCard.scss";
import primaryComponents from "../../primaryComponents";
import Icon from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { truncateString } from "../../../helpers";
import { useCampaignCardLogic } from "./CampaignCardLogic";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CampaignCard = ({ campaignDetails }) => {
  const navigate = useNavigate();
  const { toggleCampaign, handleCopyUrl } = useCampaignCardLogic({
    campaign_id: campaignDetails?.id,
  });

  return (
    <div className="campaign-card">
      <div className="campaign-card__container">
        <div className="campaign-card__container-left">
          <h5 className="font-weight-semibold">
            <span className="mobile">Campaign Name:</span>
            {campaignDetails?.campaign_name || "N/A"}
          </h5>
          <h5 className="font-weight-semibold">
            <span className="mobile">Reward Percentage Per Transaction:</span>
            {`${parseInt(
              campaignDetails?.offers[0]?.reward_per_transaction
            )}%` || "N/A"}
          </h5>
        </div>
        <div className="campaign-card__container-middle">
          <h5 className="font-weight-semibold mobile">Campaign Url</h5>
          <p className="brand-url__heading">
            {(campaignDetails?.business_campaign_url !== null &&
              truncateString(
                campaignDetails?.business_campaign_url || "",
                40
              )) ||
              "N/A"}
          </p>
          <CopyToClipboard
            onCopy={() => handleCopyUrl()}
            text={
              campaignDetails?.business_campaign_url !== null
                ? campaignDetails?.business_campaign_url
                : ""
            }
          >
            <primaryComponents.Button classNames={"btn btn--outline url-btn"}>
              <img className="mr-2" src={Icon.copy} alt="" />
              <span>Copy Url</span>
            </primaryComponents.Button>
          </CopyToClipboard>
        </div>
        <div className="campaign-card__container-right">
          <primaryComponents.Button
            onClick={() =>
              navigate(`/app/loyalty/edit-campaign/${campaignDetails?.id}`)
            }
            classNames={"btn btn--primary"}
          >
            Edit
          </primaryComponents.Button>
          <div className="d-flex align-items-center">
            <span className="mr-3 mobile">Toggle Campaign Status</span>
            <primaryComponents.Switch
              isOn={campaignDetails.status === "ACTIVE"}
              name={campaignDetails.id}
              onChange={toggleCampaign}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignCard;
