import "./StatementOfAccount.scss";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller } from "react-hook-form";
import primaryComponents from "../../primaryComponents";
import StatementOfAccountLogic from "./StatementOfAccountLogic";
import StatementOfAccountValidation from "./StatementOfAccountValidation";

const StatementOfAccount = ({ closeWidget }) => {
  const {
    statementFormat,
    setStatementFormat,
    downloadStatement,
    startDate,
    endDate,
    business_list,
    account,
    setAccount,
    sendToEmail,
    setSendToEmail,
    setStartDate,
    setEndDate,
    isLoading,
  } = StatementOfAccountLogic({ closeWidget });
  const {
    control,
    register,
    formState: { errors },
    trigger,
    handleSubmit,
  } = StatementOfAccountValidation(
    statementFormat,
    account,
    startDate,
    endDate
  );

  return (
    <div className="statement-of-account">
      <div
        className="statement-of-account__backdrop"
        onClick={() => closeWidget(false)}
        data-testid="close-statement"
      ></div>

      <motion.div
        initial={{ opacity: 0, marginRight: "-400px" }}
        animate={{
          opacity: 1,
          marginRight: "0px",
          transition: { duration: 0.3 },
        }}
        exit={{ opacity: 0 }}
        className="statement-of-account__content"
      >
        <FontAwesomeIcon
          onClick={() => closeWidget(false)}
          className="close-icon"
          icon="times"
        />

        <div className="mt-5">
          <h4>Statement of Account</h4>
          <p>
            You can customize how you wish to receive your statement of account
            with the options below:
          </p>

          <form
            className="mt-4 statement-form"
            onSubmit={handleSubmit(downloadStatement)}
          >
            <div>
              <label className="mb-1">
                <span className="error--text">*</span>File Format
              </label>
              <div className="mb-3">
                <Controller
                  name="statementFormat"
                  control={control}
                  defaultValue={statementFormat}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.BoxedRadioInput
                      name="statementFormat"
                      value={statementFormat}
                      id="PDF"
                      isChecked={statementFormat === "PDF"}
                      setRadioValue={() => {
                        setStatementFormat("PDF");
                        onChange("PDF");
                      }}
                    >
                      <span className="font-weight-semibold">PDF</span>
                    </primaryComponents.BoxedRadioInput>
                  )}
                />
              </div>
              <div>
                <Controller
                  name="statementFormat"
                  control={control}
                  defaultValue={statementFormat}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.BoxedRadioInput
                      name="statementFormat"
                      value={statementFormat}
                      id="CSV"
                      isChecked={statementFormat === "CSV"}
                      setRadioValue={() => {
                        setStatementFormat("CSV");
                        onChange("CSV");
                      }}
                    >
                      <span className="font-weight-semibold">Excel</span>
                    </primaryComponents.BoxedRadioInput>
                  )}
                />
              </div>
            </div>

            <div>
              <label className="mb-1">
                <span className="error--text">*</span>Date Range
              </label>
              <div className="daterange-section">
                <div style={{ width: "max-content" }}>
                  <Controller
                    name="startDate"
                    control={control}
                    defaultValue={startDate}
                    render={({ field: { onChange } }) => (
                      <primaryComponents.DateInput
                        name="startDate"
                        value={startDate}
                        classNames="white"
                        isRequired={true}
                        errorMessage={errors.startDate}
                        onChange={({ target: { value } }) => {
                          onChange(value);
                          setStartDate(value);
                        }}
                        onKeyUp={() =>
                          errors.startDate !== undefined && trigger("startDate")
                        }
                        register={register}
                      />
                    )}
                  />
                </div>
                <span>To</span>
                <div style={{ width: "max-content" }}>
                  <Controller
                    name="endDate"
                    control={control}
                    defaultValue={endDate}
                    render={({ field: { onChange } }) => (
                      <primaryComponents.DateInput
                        name="endDate"
                        value={endDate}
                        classNames="white"
                        isRequired={true}
                        errorMessage={errors.endDate}
                        onChange={({ target: { value } }) => {
                          onChange(value);
                          setEndDate(value);
                        }}
                        onKeyUp={() =>
                          errors.endDate !== undefined && trigger("endDate")
                        }
                        register={register}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div>
              <Controller
                name="account"
                control={control}
                defaultValue={account}
                render={({ field: { onChange } }) => (
                  <primaryComponents.SelectField
                    label="Select Account"
                    isRequired={true}
                    placeholder="Choose Account"
                    value={account || "Choose Account"}
                    options={business_list}
                    nameKey="tag"
                    idKey="id"
                    errorMessage={errors.account}
                    onChange={(value) => {
                      onChange(value);
                      setAccount(value);
                    }}
                  />
                )}
              />
            </div>

            <div className="d-flex">
              <primaryComponents.Checkbox
                isChecked={sendToEmail}
                id="sendToEmail"
                name="sendToEmail"
                onChange={(value) => setSendToEmail(value)}
              />
              <label
                style={{ fontSize: "16px", marginLeft: "10px" }}
                htmlFor="sendToEmail"
              >
                Send to my email address
              </label>
            </div>

            <primaryComponents.Button
              classNames="btn btn--primary"
              isLoading={isLoading}
              loadingText="Processing..."
              isDisabled={isLoading}
            >
              Download
            </primaryComponents.Button>
          </form>
        </div>
      </motion.div>
    </div>
  );
};
export default StatementOfAccount;
