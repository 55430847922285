import { useState } from "react";
import StoresTableLogic from "../../../../components/secondaryComponents/StoresTable/StoresTableLogic";

const ProductsLogic = ({ setInventoryAnalytics }) => {
  const [openWidget, setOpenWidget] = useState(false);
  const [productCount, setProductCount] = useState(0);
  const { isFetchingStores, storeList } = StoresTableLogic({
    setInventoryAnalytics,
  });

  return {
    openWidget,
    setOpenWidget,
    productCount,
    setProductCount,
    isFetchingStores,
    storeList,
  };
};

export default ProductsLogic;
