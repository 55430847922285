import styles from "./ProductCard.module.scss";
import Icon from "../../../assets/icons";
import { formatCurrency } from "../../../helpers";
import primaryComponents from "../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PRODUCT_DEFAULT_ICONS } from "../../../helpers/constants";

const ProductCard = ({
  product,
  updatingCart,
  cartDetails = undefined,
  handleAddToCart,
  handleReduceCartItem,
  selectedItemId,
  productIndex,
}) => {
  return (
    <div className={styles.productCard}>
      <div
        className={`${styles.productCard__top}  ${
          cartDetails !== undefined ? "active" : ""
        }`}
        onClick={() => {
          if (product?.stock_quantity === 0) return;
          if (cartDetails !== undefined)
            handleReduceCartItem(product?.id, 0, cartDetails?.id);
          else handleAddToCart(product?.id, 1);
        }}
      >
        <div className={styles.left}>
          <div className={styles.productImage}>
            <img
              src={
                product?.image_url === "" || product?.image_url === null
                  ? PRODUCT_DEFAULT_ICONS[productIndex % 5]
                  : product?.image_url
              }
              alt=""
            />
          </div>
          <p className="dark--text">{product?.name}</p>
        </div>

        <div className={styles.middle}>
          <p className="d-flex align-items-center">
            <span
              className="error mr-1"
              style={{
                width: "5px",
                height: "5px",
                borderRadius: "5px",
              }}
            ></span>
            <span className="font-weight-semibold dark--text">
              {formatCurrency(product?.selling_price)}
            </span>
          </p>
          <p
            className={`${styles.stock} ${
              product?.stock_quantity <= product?.minimum_stock_quantity &&
              styles.lowQuantity
            }`}
          >
            <img src={Icon.clock_time} alt="" width="14px" />
            <span>{product?.stock_quantity} in stock</span>
          </p>

          <p className="dark--text">
            <span>Expiry: </span>{" "}
            <span className="font-weight-semibold">
              {product?.expiry_date || "N/A"}
            </span>
          </p>
        </div>

        {product?.stock_quantity > 0 ? (
          <div className={styles.right}>
            {cartDetails !== undefined ? (
              <div className={styles.product__actions}>
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleReduceCartItem(
                      product?.id,
                      cartDetails?.quantity - 1,
                      cartDetails?.id
                    );
                  }}
                  disabled={updatingCart}
                  className={styles.decrement}
                >
                  -
                </button>
                {updatingCart && selectedItemId === product?.id ? (
                  <FontAwesomeIcon
                    icon={["fas", "spinner"]}
                    className="fa-spin purple--text"
                    style={{ fontSize: "15px", width: "25px" }}
                  />
                ) : (
                  <p className={`${styles.count} dark--text`}>
                    {cartDetails?.quantity || 0}
                  </p>
                )}
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleAddToCart(product?.id, cartDetails?.quantity + 1);
                  }}
                  disabled={updatingCart}
                  className={styles.increment}
                >
                  +
                </button>
              </div>
            ) : (
              <primaryComponents.Button
                classNames={`btn btn--primary ${styles.addToCartBtn}`}
                isLoading={updatingCart && selectedItemId === product?.id}
                isDisabled={updatingCart && selectedItemId === product?.id}
                loadingText="Adding..."
                showSpinner={false}
                onClick={(event) => {
                  event.stopPropagation();
                  handleAddToCart(product?.id, 1);
                }}
              >
                Add to cart
              </primaryComponents.Button>
            )}
          </div>
        ) : (
          <div className={styles.right}></div>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
