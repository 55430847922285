/* eslint-disable react/jsx-key */
import "./CustomerHistoryTable.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import useCustomerHistoryTable from "./CustomerHistoryTableLogic";
import Images from "../../../assets/images";

const CustomerHistoryTable = () => {
  const {
    instance,
    customerHistory,
    isLoading,
    searchCustomer,
    searchValue,
    paginationDetails,
    gotoPage,
  } = useCustomerHistoryTable();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="history-table">
      <div className="history-table__content">
        <div className="history-table__content__header">
          <div style={{ width: "700px" }}>
            <primaryComponents.SearchField
              placeholder="Search for a customer"
              trigger={(value) => searchCustomer(value)}
              value={searchValue}
            />
          </div>
        </div>
        <div className="table-section">
          <secondaryComponents.LoaderHelper
            isLoading={isLoading}
            classNames="mt-5"
          >
            {customerHistory.length > 0 ? (
              <>
                {/* show on desktop */}
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => {
                          return (
                            <th
                              className="font-weight-semibold"
                              {...column.getHeaderProps()}
                            >
                              {column.render("Header")}
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps({ key: i })}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                {/* display on mobile */}
                <div className="mobile-table">
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <div
                        className="mobile-table__row"
                        {...row.getRowProps({ key: i })}
                      >
                        {row.cells.map((cell, j) => (
                          <div
                            className="row-item"
                            {...cell.getCellProps({ key: j })}
                          >
                            <h6 className="row-item__title">
                              {cell.column.Header}
                            </h6>
                            <p className="row-item__value">
                              {cell.render("Cell")}
                            </p>
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div className="text-center mt-5">
                <img
                  src={Images.customersEmptyState}
                  alt="No history Found"
                  width="250px"
                  height="262px"
                />
                <h4>You have no history</h4>
                <p>When you do, they would appear here.</p>
              </div>
            )}

            {/* pagination section */}
            {customerHistory.length > 0 && (
              <div className="mt-auto">
                <secondaryComponents.Pagination
                  totalPageNumber={paginationDetails.last_page}
                  handlePageClick={(value) => gotoPage(value.selected + 1)}
                  currentPage={paginationDetails.current_page}
                />
              </div>
            )}
          </secondaryComponents.LoaderHelper>
        </div>
      </div>
    </div>
  );
};

export default CustomerHistoryTable;
