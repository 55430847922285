import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { getRandomIntInclusive } from "../../../helpers";
import { downloadStatementOfAcc } from "../../../services/transaction";

const StatementOfAccountLogic = ({ closeWidget }) => {
  const [statementFormat, setStatementFormat] = useState("PDF");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [account, setAccount] = useState("");
  const [sendToEmail, setSendToEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { business_list } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const downloadStatement = () => {
    setIsLoading(true);
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    downloadStatementOfAcc({
      type: statementFormat.toLocaleLowerCase(),
      start_date: startDate,
      end_date: endDate,
      send_to_mail: sendToEmail,
      business_id: account,
    })
      .then((response) => {
        setRequestLoaderProgress(100);
        setIsLoading(false);
        closeWidget(false);
        window.open(response.data.data.file_url, "_blank");
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsLoading(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  return {
    statementFormat,
    setStatementFormat,
    downloadStatement,
    startDate,
    endDate,
    account,
    setAccount,
    business_list,
    sendToEmail,
    setSendToEmail,
    setStartDate,
    setEndDate,
    isLoading,
  };
};
export default StatementOfAccountLogic;
