import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { createCampaign } from "../../../services/campaign";
import { getRandomIntInclusive } from "../../../helpers";
import { useSelector } from "react-redux";

export const useCampaignFormLogic = () => {
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { business_list } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );

  const navigate = useNavigate();
  const triggerToast = useContext(ToastContext);

  const [brandName, setBrandName] = useState("");
  const [brandDescription, setBrandDescription] = useState("");
  const [brandCategory, setBrandCategory] = useState("");
  const [brandUrl, setBrandUrl] = useState("");
  const [brandLogo, setBrandLogo] = useState("");
  const [campaignSlug, setCampaignSlug] = useState("");
  const [termsUrl, setTermsUrl] = useState("");
  const [campaignPointRatio, setCampaignPointRatio] = useState(0);
  const [showImageModal, setShowImageModal] = useState(false);
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
  const [offers, setOffers] = useState(() =>
    business_list.map((business) => ({
      ...business,
      reward_per_transaction: 0,
      isDisabled: true,
    }))
  );

  const serializeOffers = (offers = []) => {
    return offers
      .filter((offer) => offer.isDisabled !== true)
      .map((offer) => ({
        business_id: offer.id,
        reward_per_transaction: offer.reward_per_transaction,
      }));
  };

  const handleCreateCampaign = () => {
    setIsCreatingCampaign(true);
    setRequestLoaderProgress(getRandomIntInclusive(30, 70));
    createCampaign({
      campaign_name: brandName,
      brand_name: brandName,
      brand_description: brandDescription,
      brand_logo_url: brandLogo,
      brand_url: brandUrl.includes("https://")
        ? brandUrl.replace(/(^\w+:|^)\/\//, "")
        : "https://" + brandUrl,
      brand_category: brandCategory,
      term_and_condition_url: termsUrl.includes("https://")
        ? termsUrl.replace(/(^\w+:|^)\/\//, "")
        : "https://" + termsUrl,
      business_slug: campaignSlug,
      campaign_point_ratio: campaignPointRatio,
      offers: serializeOffers(offers),
    })
      .then(() => {
        setIsCreatingCampaign(false);
        setRequestLoaderProgress(100);
        triggerToast("Campaign created successfully", "success");
        navigate("/app/loyalty/manage");
      })
      .catch((error) => {
        setIsCreatingCampaign(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
        if (error.response.status === 422) {
          const errors = error?.response?.data?.errors;
          if (errors?.business_slug) {
            window.setCampaignError(
              "campaignSlug",
              {
                type: "validate",
                message: errors?.business_slug[0],
              },
              { shouldFocus: true }
            );
          }
          if (errors?.brand_name || errors?.campaign_name) {
            window.setCampaignError(
              "brandName",
              {
                type: "validate",
                message: errors?.brand_name[0],
              },
              { shouldFocus: true }
            );
          }
          if (errors?.brand_description) {
            window.setCampaignError(
              "brandDescription",
              {
                type: "validate",
                message: errors?.brand_description[0],
              },
              { shouldFocus: true }
            );
          }
          if (errors?.brand_url) {
            window.setCampaignError(
              "brandUrl",
              {
                type: "validate",
                message: errors?.brand_url[0],
              },
              { shouldFocus: true }
            );
          }
          if (errors?.reward_per_transaction) {
            window.setCampaignError(
              "rewardPercentage",
              {
                type: "validate",
                message: errors?.reward_per_transaction[0],
              },
              { shouldFocus: true }
            );
          }
          if (errors?.brand_category) {
            window.setCampaignError(
              "brandCategory",
              {
                type: "validate",
                message: errors?.brand_category[0],
              },
              { shouldFocus: true }
            );
          }
          if (errors?.term_and_condition_url) {
            window.setCampaignError(
              "brandCategory",
              {
                type: "validate",
                message: errors?.term_and_condition_url[0],
              },
              { shouldFocus: true }
            );
          }
          if (errors?.campaign_point_ratio) {
            window.setCampaignError(
              "campaignPointRatio",
              {
                type: "validate",
                message: errors?.campaign_point_ratio[0],
              },
              { shouldFocus: true }
            );
          }
        }
      });
  };

  return {
    showImageModal,
    brandName,
    brandDescription,
    brandCategory,
    brandUrl,
    brandLogo,
    isCreatingCampaign,
    termsUrl,
    campaignSlug,
    offers,
    campaignPointRatio,
    setCampaignPointRatio,
    setOffers,
    setCampaignSlug,
    setTermsUrl,
    setShowImageModal,
    setBrandCategory,
    setBrandDescription,
    setBrandName,
    setBrandUrl,
    setBrandLogo,
    handleCreateCampaign,
  };
};
