import httpClient from "../axiosBase/axiosHandler";

export const getProfile = () => {
  const response = httpClient.get("/profile/detail/");
  return response;
};

export const getBusiness = () => {
  const response = httpClient.get("/business/profile/");
  return response;
};

export const updatePersonalInfo = (payload) => {
  const response = httpClient.post("/profile/update/", payload);
  return response;
};

export const updateBusinessInfo = (payload) => {
  const response = httpClient.post(
    "/business/update-business-profile/",
    payload
  );
  return response;
};

export const sendUserInvite = (payload) => {
  const response = httpClient.post("/business/invite-team-member/", payload);
  return response;
};

export const getTeamMembers = () => {
  const response = httpClient.get("/business/team-members/");
  return response;
};

export const updateUserInvite = (payload) => {
  const response = httpClient.post("/business/update-team-member/", payload);
  return response;
};

export const deleteInvitedUser = (payload) => {
  const response = httpClient.post("/business/delete-team-member/", payload);
  return response;
};

export const getApiKeys = () => {
  const response = httpClient.get("/settings/api-token/");
  return response;
};

export const generateNewApiKeys = () => {
  const response = httpClient.post("/settings/api-token/regenerate/");
  return response;
};

export const revealApiKeys = () => {
  const response = httpClient.post("/settings/api-token/reveal/");
  return response;
};
