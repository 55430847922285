import { useContext, useEffect, useState } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { getRandomIntInclusive } from "../../../helpers";
import { getCampaignDetails } from "../../../services/campaign";

export const useEditCampaignLogic = ({ campaign_id }) => {
  const [isFetchingCampaign, setIsFetchingCampaign] = useState(false);
  const [campaign, setCampaign] = useState(null);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  useEffect(() => {
    handleFetchCampaign();
  }, []);

  const handleFetchCampaign = () => {
    setIsFetchingCampaign(true);
    setRequestLoaderProgress(getRandomIntInclusive(30, 60));
    getCampaignDetails(campaign_id)
      .then((response) => {
        setIsFetchingCampaign(false);
        setRequestLoaderProgress(100);
        setCampaign(response.data?.data);
      })
      .catch((error) => {
        setIsFetchingCampaign(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  return { campaign, isFetchingCampaign };
};
