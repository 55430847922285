import { useTable } from "react-table";
import { useMemo, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getBeneficiaries } from "../../../services/transfers";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { getUrlQuerysection, convertToTilteCase } from "../../../helpers";
import { getRandomIntInclusive } from "../../../helpers";

const BeneficiariesTableLogic = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isFetchingBeneficiaries, setIsFetchingBeneficiaries] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [showDeleteBeneficiaryModal, setShowDeleteBeneficiaryModal] =
    useState(false);
  const [currentBeneficiary, setCurrentBeneficiary] = useState({});
  const [searchValue] = useState(
    queryParams.get("query") !== null ? [queryParams.get("query")] : ""
  );
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBeneficiaries();
  }, []);

  const fetchBeneficiaries = () => {
    setIsFetchingBeneficiaries(true);
    setRequestLoaderProgress(getRandomIntInclusive(5, 30));
    let queries = getUrlQuerysection();
    getBeneficiaries(queries)
      .then((response) => {
        setBeneficiaries(response.data.data);
        setRequestLoaderProgress(100);
        setIsFetchingBeneficiaries(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsFetchingBeneficiaries(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const searchBeneficiaries = (value) => {
    let searchQuery = "";
    searchQuery = value === "" ? searchQuery : `?query=${value}`;
    navigate(`/app/transfers/Beneficiaries${searchQuery}`);
    fetchBeneficiaries();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Account Name",
        accessor: "account_name",
        Cell: ({ cell: { value } }) => (
          <span className="purple--text">
            {value ? convertToTilteCase(value) : "N/A"}
          </span>
        ),
      },
      {
        Header: "Account Number",
        accessor: "account_number",
        Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      },
      {
        Header: "Bank Name",
        accessor: "bank_name",
        Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      },
    ],
    []
  );

  const instance = useTable({ columns, data: beneficiaries });
  return {
    instance,
    isFetchingBeneficiaries,
    beneficiaries,
    searchBeneficiaries,
    searchValue,
    showDeleteBeneficiaryModal,
    setShowDeleteBeneficiaryModal,
    fetchBeneficiaries,
    currentBeneficiary,
    setCurrentBeneficiary,
  };
};

export default BeneficiariesTableLogic;
