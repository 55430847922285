import "./StockHistory.scss";
import { useNavigate, useOutletContext } from "react-router-dom";
import primaryComponents from "../../../components/primaryComponents";
import secondaryComponents from "../../../components/secondaryComponents";
import Icon from "../../../assets/icons";

const StockHistory = () => {
  const navigate = useNavigate();
  const { inventoryAnalytics } = useOutletContext();

  return (
    <div className="stock-history">
      <div className="stock-history__header">
        <h4 className="font-weight-semibold">
          {inventoryAnalytics.productCount} Products
        </h4>
        <primaryComponents.Button
          classNames={"btn btn--primary"}
          onClick={() => navigate("/app/products/add")}
        >
          <img
            className="mr-2"
            width={"15px"}
            height={"15px"}
            src={Icon.add}
            alt="add product"
          />
          Add new product
        </primaryComponents.Button>
      </div>
      <div className="stock-history__content">
        <secondaryComponents.StockHistoryTable />
      </div>
    </div>
  );
};

export default StockHistory;
