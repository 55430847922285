/* eslint-disable react/jsx-key */
import "./TagsTable.scss";
import TagsTableLogic from "./TagsTableLogic";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "../../secondaryComponents";
import { useOutletContext } from "react-router-dom";
import Images from "../../../assets/images";

const TagsTable = ({ setIsEditingTag, setIsAddingTag }) => {
  const { setTagDetails, setIsDeletingTag } = useOutletContext();
  const { instance, isFetchingTags, tags } = TagsTableLogic({
    setIsEditingTag,
    setIsAddingTag,
    setTagDetails,
    setIsDeletingTag,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="tags-table">
      <div className="tags-table__header">
        <div style={{ minWidth: "250px", width: "700px" }}>
          <primaryComponents.SearchField
            placeholder="Search for a tag"
            classNames="search-section"
          />
        </div>
      </div>
      <secondaryComponents.LoaderHelper
        classNames="mt-5"
        isLoading={isFetchingTags}
      >
        {tags.length > 0 ? (
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps({ key: i })}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="text-center mt-5 mb-5">
            <img
              width="250px"
              height="262px"
              src={Images.tagEmptyState}
              alt="empty state"
            />
            <h4>No data available</h4>
          </div>
        )}
      </secondaryComponents.LoaderHelper>
    </div>
  );
};

export default TagsTable;
