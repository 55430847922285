import httpClient from "../axiosBase/axiosHandler";

export const getCartItems = () => {
  return httpClient.get("/order/cart-items/");
};

export const addToCart = (data) => {
  return httpClient.post("/order/add-to-cart/", data);
};

export const removeFromCart = (id) => {
  return httpClient.delete(`/order/${id}/remove-cart-item/`);
};

export const createOrder = (data) => {
  return httpClient.post("/order/", data);
};

export const checkoutWithCash = (data) => {
  return httpClient.post("/order/pay-with-cash/", data);
};

export const checkoutwithCard = (data) => {
  return httpClient.post("/order/pay-with-card/", data);
};

export const checkoutByTransfer = (data) => {
  return httpClient.post("/order/pay-with-transfer/", data);
};

export const getTransactionViaTransfer = () => {
  return httpClient.get(
    "/transactions/?all_business=true&status=settled&payment_method=lumi_naira_wallet|lumi_rewards_wallet|bank_transfer|in-app_transfer|offline_transfer&per_page=20"
  );
};

export const getTransactionViaCard = () => {
  return httpClient.get(
    "/transactions/pulled-pos-transaction-list/?per_page=20"
  );
};

export const postStoreCustomerDetails = (data) => {
  return httpClient.post("/order/confirm_customer/", data);
};

export const printOrderReceipt = (id) => {
  return httpClient.post(`/order/${id}/generate-receipt/`, {});
};

export const applyDiscount = (data) => {
  return httpClient.post("/order/apply-discount/", data);
};

export const sendOrderReceiptToPrint = (data) => {
  return httpClient.post("/order/send_to_terminal/", data);
};
