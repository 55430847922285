import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const DiscountValidationSchema = yup.object({
  discount: yup.string(),
});

const DeliveryFeeValidationSchema = yup.object({
  deliveryFee: yup.string(),
});

export const DiscountValidation = () => {
  return useForm({
    resolver: yupResolver(DiscountValidationSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      discount: "",
    },
  });
};

export const DeliveryFeeValidation = () => {
  return useForm({
    resolver: yupResolver(DeliveryFeeValidationSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      deliveryFee: "",
    },
  });
};
