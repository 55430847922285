import axios from "axios";
import store from "../store";
import { setApiErrorMessage } from "../store/modules/apiErrorHandler";

// base url
// eslint-disable-next-line no-undef
let baseUrl = process.env.REACT_APP_LUMI_MERCHANT_APP_BASE_URL;

const authHttpClient = axios.create({
  baseURL: baseUrl,
});

// setting authorization header
const httpRequest = (config) => {
  //config.headers["X-platform"] = "web";
  return config;
};

// Response interceptors
const onFulfilled = (response) => {
  return Promise.resolve(response);
};

const onRejected = async (error) => {
  if (error.response) {
    if (error.response.status) {
      if (error.response.status === 403) {
        // Do something
        store.dispatch(
          setApiErrorMessage(
            "You don't have the permission to perform this action"
          )
        );
      }
      if (error.response.status === 500 || error.response.status === 503) {
        // Do something
        store.dispatch(
          setApiErrorMessage("Something went wrong, please try again")
        );
      }
      if (error.response.status === 404) {
        // Do something
        store.dispatch(setApiErrorMessage("This resource was not found"));
      }
      return Promise.reject(error);
    }
    return;
  }
  if (!navigator.onLine) {
    // Do something
    store.dispatch(
      setApiErrorMessage(
        "No internet connection, please check your internet connectivity"
      )
    );
  }
};

/** Adding the request interceptors */
authHttpClient.interceptors.request.use(httpRequest);

/** Adding the response interceptors */
authHttpClient.interceptors.response.use(onFulfilled, onRejected);

export default authHttpClient;
