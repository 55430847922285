import { useState, useEffect, useContext } from "react";
import { useStep } from "react-hooks-helper";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { restockProduct } from "../../../services/inventory";
import { getRandomIntInclusive } from "../../../helpers";

export const useRestockProduct = ({ closeWidget, selectedProduct }) => {
  const [hasVariants, setHasVariants] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { index, navigation } = useStep({ initialStep: 0, steps: 2 });

  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);

  const [costPrice, setCostPrice] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [stockQuantity, setStockQuantity] = useState(0);
  const [minStockQuantity, setMinStockQuantity] = useState(0);
  const [expiryDate, setExpiryDate] = useState("");
  const [variants, setVariants] = useState(selectedProduct?.variants || []);
  const [newVariants, setNewVariants] = useState([]);

  useEffect(() => {
    if (selectedProduct?.variants.length) {
      setHasVariants(true);
    }
  }, []);

  const handleAddVariantField = () => {
    const _variant = {
      option: "",
      value: "",
      sku: "",
      selling_price: 0,
      stock_quantity: 1,
      minimum_stock_quantity: 1,
      expiry_date: "",
      cost_price: 0,
    };

    setNewVariants((prev) => [...prev, { ..._variant }]);
  };

  const handleRemoveVariantField = (fieldIndex) => {
    const _variants = [...newVariants];
    const variants = _variants.filter((_, index) => fieldIndex !== index);
    setNewVariants(variants);
  };

  const handleRestockProduct = (data) => {
    setIsSubmitting(true);
    setRequestLoaderProgress(getRandomIntInclusive(20, 40));
    const payload = {
      cost_price: costPrice,
      selling_price: sellingPrice,
      stock_quantity: stockQuantity,
      minimum_stock_quantity: minStockQuantity,
      expiry_date: expiryDate,
      variants: variants.map((variant) => {
        // remove expiry date field if it is empty
        if (variant.expiry_date === "") {
          delete variant["expiry_date"];
        }
        return variant;
      }),
      new_variants: newVariants.map((variant) => {
        // remove expiry date field if it is empty
        if (variant.expiry_date === "") {
          delete variant["expiry_date"];
        }
        return variant;
      }),
    };

    if (payload.expiry_date === "") delete payload["expiry_date"];

    restockProduct(selectedProduct?.id, payload)
      .then(() => {
        setIsSubmitting(false);
        setRequestLoaderProgress(100);
        closeWidget(true);
        triggerToast("Product Restock successfull", "success");
      })
      .catch((error) => {
        setIsSubmitting(false);
        setRequestLoaderProgress(100);
        if (error.response) {
          const { status } = error.response;
          const { detail } = error.response.data;
          if (status === 400) {
            triggerToast(detail, "warning");
          }
          if (status === 422) {
            triggerToast(detail, "warning");
          }
        }
      });
  };

  return {
    hasVariants,
    step: index,
    navigation,
    setHasVariants,
    selectedProduct,
    closeWidget,

    sellingPrice,
    costPrice,
    stockQuantity,
    minStockQuantity,
    expiryDate,
    variants,
    newVariants,
    isSubmitting,

    setSellingPrice,
    setCostPrice,
    setStockQuantity,
    setMinStockQuantity,
    setExpiryDate,
    setVariants,
    setNewVariants,

    handleAddVariantField,
    handleRemoveVariantField,
    handleRestockProduct,
  };
};
