import "./LoyaltyHome.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import AllCampaigns from "../AllCampaigns/AllCampaigns";

const LoyaltyHome = () => {
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Loyalty"
      description="Create campaign on lumi merchant and see an improve customer loyalty"
    >
      <AllCampaigns />
    </secondaryComponents.MetaSetter>
  );
};

export default LoyaltyHome;
