import "./EditCampaignForm.scss";
import localData from "../../../localdata";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import { motion } from "framer-motion";
import { useEditCampaignFormLogic } from "./EditCampaignFormLogic";
import { Controller } from "react-hook-form";
import { useCampaignFormValidation } from "../CampaignForm/CampaignFormValidation";
import { useEffect } from "react";

const EditCampaignForm = ({ campaign }) => {
  const {
    brandCategory,
    brandDescription,
    brandLogo,
    brandName,
    brandUrl,
    isUpdatingCampaign,
    showImageModal,
    campaignSlug,
    termsUrl,
    offers,
    setOffers,
    campaignPointRatio,
    setCampaignPointRatio,
    setTermsUrl,
    setCampaignSlug,
    setBrandCategory,
    setBrandDescription,
    setBrandLogo,
    setBrandName,
    setBrandUrl,
    setShowImageModal,
    handleUpdateCampaign,
  } = useEditCampaignFormLogic({ campaign_id: campaign?.id, campaign });

  const {
    control,
    formState: { errors },
    trigger,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
  } = useCampaignFormValidation({
    brandCategory,
    brandDescription,
    brandLogo,
    brandName,
    brandUrl,
    campaignPointRatio,
    termsUrl,
  });

  useEffect(() => {
    if (brandLogo === "" || brandLogo === null || brandLogo === undefined)
      return;
    clearErrors("brandLogo");
    setValue("brandLogo", brandLogo);
  }, [brandLogo]);

  useEffect(() => {
    window.setEditCampaignError = setError;
    window.clearEditCampaignError = clearErrors;
  }, []);

  return (
    <motion.form
      onSubmit={handleSubmit(handleUpdateCampaign)}
      className="edit-campaign-form"
      initial={{ opacity: 0, marginTop: "50px" }}
      animate={{ opacity: 1, marginTop: "0px", transition: { duration: 0.3 } }}
      exit={{ opacity: 0 }}
    >
      <div className="edit-campaign-form__fields">
        <div className="edit-campaign-form__fields-group">
          {/* brand name */}
          <div>
            <label className="default-font-size mb-1" htmlFor="brandName">
              Brand Name
            </label>
            <Controller
              name="brandName"
              control={control}
              defaultValue={brandName}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  name="brandName"
                  type="text"
                  placeholder="Enter your brand name"
                  value={brandName}
                  classNames="white"
                  isRequired={true}
                  errorMessage={errors.brandName}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setBrandName(value);
                  }}
                  onKeyUp={() =>
                    errors.brandName !== undefined && trigger("brandName")
                  }
                />
              )}
            />
          </div>
          {/* brand category */}
          <div>
            <label
              className="default-font-size mb-1"
              htmlFor="campaignObjectives"
            >
              Brand Category
            </label>
            <Controller
              control={control}
              defaultValue={brandCategory}
              name="brandCategory"
              render={({ field: { onChange } }) => (
                <primaryComponents.SelectField
                  options={localData.brandCategories}
                  name="brandCategory"
                  value={
                    brandCategory !== ""
                      ? brandCategory
                      : "Select brand category..."
                  }
                  idKey="value"
                  nameKey="label"
                  isRequired={true}
                  errorMessage={errors.brandCategory}
                  onChange={(value) => {
                    onChange(value);
                    setBrandCategory(value);
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="edit-campaign-form__fields-group">
          {/* brand descrpition */}
          <div>
            <label className="default-font-size mb-1" htmlFor="brandName">
              Brand Description
            </label>
            <Controller
              name="brandDescription"
              control={control}
              defaultValue={brandDescription}
              render={({ field: { onChange } }) => (
                <primaryComponents.TextArea
                  name="brandDescription"
                  type="text"
                  placeholder="Enter your brand description"
                  value={brandDescription}
                  classNames="white"
                  isRequired={true}
                  errorMessage={errors.brandDescription}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setBrandDescription(value);
                  }}
                  onKeyUp={() =>
                    errors.brandDescription !== undefined &&
                    trigger("brandDescription")
                  }
                />
              )}
            />
          </div>
          {/* upload image input */}
          <div>
            <label className="default-font-size mb-1" htmlFor="brandName">
              Upload your brand logo &nbsp;
              <span className="gray--text small-text-size">
                Recommended Size (3kb)
              </span>
            </label>
            <Controller
              control={control}
              defaultValue={brandLogo}
              name="brandLogo"
              render={() => (
                <primaryComponents.ImageInputField
                  setShowImageModal={setShowImageModal}
                  errorMessage={errors.brandLogo}
                  imgValue={brandLogo}
                  altValue="brand logo"
                />
              )}
            />
          </div>
        </div>
        <div className="edit-campaign-form__fields-group mt-3">
          <div>
            <label className="default-font-size mb-1" htmlFor="brand-url">
              Brand URL
            </label>
            <Controller
              name="brandUrl"
              control={control}
              defaultValue={brandUrl}
              render={({ field: { onChange } }) => (
                <primaryComponents.UrlInputField
                  type="text"
                  placeholder="example.com"
                  value={brandUrl}
                  isRequired={true}
                  errorMessage={errors.brandUrl}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setBrandUrl(value);
                  }}
                  onKeyUp={() =>
                    errors.brandUrl !== undefined && trigger("brandUrl")
                  }
                />
              )}
            />
          </div>
          <div>
            <label className="default-font-size mb-1" htmlFor="brand-url">
              Terms & Conditions URL
            </label>
            <Controller
              name="termsUrl"
              control={control}
              defaultValue={termsUrl}
              render={({ field: { onChange } }) => (
                <primaryComponents.UrlInputField
                  type="text"
                  placeholder="example.com"
                  value={termsUrl}
                  isRequired={true}
                  errorMessage={errors.termsUrl}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setTermsUrl(value);
                  }}
                  onKeyUp={() =>
                    errors.termsUrl !== undefined && trigger("termsUrl")
                  }
                />
              )}
            />
          </div>
        </div>
        <div className="edit-campaign-form__fields-group mt-3">
          <div>
            <label className="default-font-size mb-1" htmlFor="campaign-slug">
              Slug
            </label>
            <Controller
              name="campaignSlug"
              defaultValue={campaignSlug}
              control={control}
              render={({ field: { onChange } }) => (
                <primaryComponents.SlugInputField
                  name="campaignSlug"
                  type="text"
                  placeholder="your-business-name"
                  isRequired={true}
                  isDisabled={true}
                  value={campaignSlug}
                  errorMessage={errors.campaignSlug}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setCampaignSlug(value);
                  }}
                  onKeyUp={() =>
                    errors.campaignSlug !== undefined && trigger("campaignSlug")
                  }
                />
              )}
            />
          </div>
          <div>
            <label
              className="default-font-size mb-1"
              htmlFor="reward-percentage"
            >
              Points Equivalence
            </label>
            <Controller
              name="campaignPointRatio"
              defaultValue={campaignPointRatio}
              control={control}
              render={({ field: { onChange } }) => (
                <primaryComponents.PointsInputField
                  name="campaignPointRatio"
                  type="number"
                  placeholder="Enter your reward percentage per transaction"
                  isRequired={true}
                  value={campaignPointRatio}
                  errorMessage={errors.campaignPointRatio}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setCampaignPointRatio(value);
                  }}
                  onKeyUp={() =>
                    errors.campaignPointRatio !== undefined &&
                    trigger("campaignPointRatio")
                  }
                />
              )}
            />
          </div>
        </div>
        <div className="edit-campaign-form__fields-group mt-3">
          <div>
            <label className="default-font-size mb-1" htmlFor="campaign-slug">
              Select Business and Add Reward Percentage
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginTop: "24px",
              }}
            >
              {offers.map((offer, index) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  key={offer.id}
                >
                  <label
                    className="d-flex align-items-center"
                    htmlFor={offer?.business?.tag}
                  >
                    <primaryComponents.Checkbox
                      name={"offer"}
                      id={offer?.business?.tag}
                      isChecked={offer?.isDisabled === false}
                      onChange={(value) => {
                        let _offers = [...offers];
                        _offers[index].isDisabled = !value;
                        setOffers(_offers);
                      }}
                    />
                    <p className="ml-2 dark--text">{offer?.business?.tag}</p>
                  </label>
                  <div style={{ width: "277px" }}>
                    <Controller
                      name={`offers[${offers[index]}].reward_per_transaction`}
                      control={control}
                      defaultValue={offers[index].reward_per_transaction}
                      render={({ field: { onChange } }) => {
                        return (
                          <primaryComponents.PercentageInputField
                            isDisabled={offer?.isDisabled}
                            placeholder="Enter Percentage"
                            name={`offers[${offers[index]}].reward_per_transaction`}
                            value={offers[index].reward_per_transaction}
                            onChange={({ target: { value } }) => {
                              onChange(value);
                              let _offers = [...offers];
                              _offers[index].reward_per_transaction = value;
                              setOffers(_offers);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div></div>
        </div>
        <div className="edit-campaign-form__buttons">
          <primaryComponents.Button
            classNames="btn btn--primary ml-auto"
            type="submit"
            isLoading={isUpdatingCampaign}
            isDisabled={isUpdatingCampaign}
            loadingText="Updating campaign..."
          >
            Update
          </primaryComponents.Button>
        </div>
      </div>

      {/* image modal */}
      {showImageModal && (
        <secondaryComponents.ImageUploadModal
          showImageModal={showImageModal}
          setShowImageModal={setShowImageModal}
          setImage={setBrandLogo}
        />
      )}
    </motion.form>
  );
};

export default EditCampaignForm;
