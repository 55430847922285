import { useTable } from "react-table";
import { useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import { getTransfers } from "../../../services/transfers";
import { useState, useEffect, useContext } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import {
  getUrlQuerysection,
  formatCurrency,
  getRandomIntInclusive,
} from "../../../helpers";
import { useSelector } from "react-redux";

const PendingTransfersTableLogic = ({
  isRefreshTransfers,
  setIsRefreshTransfers,
  setNumberOfPendingTransfers,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isFetchingTransfers, setIsFetchingTransfers] = useState(false);
  const [transfers, setTransfers] = useState([]);
  const [transferPageDetails, setTransferPageDetails] = useState({});
  const [searchValue, setSearchValue] = useState(
    queryParams.get("query") !== null ? [queryParams.get("query")] : ""
  );
  const [startDate] = useState(queryParams.get("start_date"));
  const [endDate] = useState(queryParams.get("end_date"));
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const navigate = useNavigate();
  const [showTransferApprovalModal, setShowTransferApprovalModal] =
    useState(false);
  const [transferOnReview, setTransferOnReview] = useState({});
  const { business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );

  useEffect(() => {
    fetchTransfers();
  }, []);

  useEffect(() => {
    if (isRefreshTransfers) {
      fetchTransfers();
    }
  }, [isRefreshTransfers]);

  const fetchTransfers = () => {
    setIsFetchingTransfers(true);
    setRequestLoaderProgress(getRandomIntInclusive(5, 50));
    let queries = getUrlQuerysection();
    getTransfers(queries)
      .then((response) => {
        setTransfers(response.data.data);
        setTransferPageDetails(response.data.meta);
        setNumberOfPendingTransfers(response.data.pending_transfer_request);
        setRequestLoaderProgress(100);
        setIsFetchingTransfers(false);
        setIsRefreshTransfers(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsFetchingTransfers(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const gotoPage = (pageNumber) => {
    let queries = getUrlQuerysection();
    let page = queryParams.get("page");
    if (page === null) {
      let symbol = queries.includes("?") ? "&" : "?";
      navigate(`${queries}${symbol}page=${pageNumber}`);
      return fetchTransfers();
    }

    let splitedQuery = queries.split(`page=${page}`);
    splitedQuery = splitedQuery[0] + `page=${pageNumber}` + splitedQuery[1];
    navigate(`${splitedQuery}`);
    fetchTransfers();
  };

  const searchTnx = (value) => {
    let searchQuery = "";
    if (queryParams.get("business_id") !== null)
      searchQuery = `&business_id=${queryParams.get("business_id")}`;
    else searchQuery = "&all_business=true";
    searchQuery = value === "" ? searchQuery : `${searchQuery}&query=${value}`;
    navigate(`/app/transfers/pending?status=pending_approval${searchQuery}`);
    fetchTransfers();
  };

  const setDateFilter = (data) => {
    let queries = getUrlQuerysection();
    let prevStartDate = queryParams.get("start_date");
    let prevEndDate = queryParams.get("end_date");
    let newQueries = queries;

    if (!queries.includes("?")) {
      navigate(`?start_date=${data.startDate}&end_date=${data.endDate}`);
    } else {
      if (prevStartDate === null) {
        newQueries = `${newQueries}&start_date=${data.startDate}&end_date=${data.endDate}`;
      } else {
        let splitedQuery = newQueries.split(`start_date=${prevStartDate}`);
        splitedQuery =
          splitedQuery[0] + `start_date=${data.startDate}` + splitedQuery[1];
        newQueries = `${splitedQuery}`;
        splitedQuery = newQueries.split(`end_date=${prevEndDate}`);
        splitedQuery =
          splitedQuery[0] + `end_date=${data.endDate}` + splitedQuery[1];
        newQueries = `${splitedQuery}`;
      }
      navigate(newQueries);
    }
    setIsRefreshTransfers(true);
  };

  const filterBy = () => {
    fetchTransfers();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleString("en-us"),
      },
      {
        Header: "Beneficiary",
        accessor: "account_name",
        Cell: ({ cell: { value, row } }) => (
          <Link
            className="purple--text"
            to={`/app/transfers/${row.original["id"]}`}
            style={{
              fontSize: "12px",
              textDecoration: "underline",
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: "Account No.",
        accessor: "account_number",
      },
      {
        Header: "Bank",
        accessor: "bank_name",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell: { value } }) => formatCurrency(value),
      },
      {
        Header: "Transfer Fee",
        accessor: "fee",
        Cell: ({ cell: { value } }) => formatCurrency(value),
      },
      {
        Header: "Account",
        accessor: "business_tag",
      },
      {
        Header: "Initiated By",
        accessor: "initiator_name",
      },
    ],
    []
  );

  const instance = useTable({ columns, data: transfers });
  return {
    instance,
    isFetchingTransfers,
    transfers,
    transferPageDetails,
    gotoPage,
    searchTnx,
    filterBy,
    searchValue,
    setSearchValue,
    setShowTransferApprovalModal,
    showTransferApprovalModal,
    transferOnReview,
    setTransferOnReview,
    business_role,
    fetchTransfers,
    setDateFilter,
    startDate,
    endDate,
  };
};

export default PendingTransfersTableLogic;
