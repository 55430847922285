import "./Products.scss";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import Icon from "../../../../assets/icons";
import { useNavigate, useOutletContext } from "react-router-dom";
import ProductsLogic from "./ProductsLogic";

const Products = () => {
  const navigate = useNavigate();
  const { inventoryAnalytics, setInventoryAnalytics } = useOutletContext();
  const { openWidget, setOpenWidget, isFetchingStores, storeList } =
    ProductsLogic({ setInventoryAnalytics });

  return (
    <div className="products-page">
      <div className="products-page__header">
        <h4 className="font-weight-semibold">
          {inventoryAnalytics.productCount} Products
        </h4>
        <primaryComponents.Button
          classNames={"btn btn--primary"}
          onClick={() =>
            storeList.length > 0
              ? setOpenWidget(true)
              : navigate("/app/products/add")
          }
          isDisabled={isFetchingStores}
        >
          <img className="mr-2" src={Icon.add} alt="add product" />
          Add new product
        </primaryComponents.Button>
      </div>
      <div className="products-page__content">
        <secondaryComponents.ProductsTable
          setInventoryAnalytics={setInventoryAnalytics}
        />
      </div>
      {openWidget && (
        <secondaryComponents.AddProductWidget
          isOpen={openWidget}
          setIsOpen={setOpenWidget}
        />
      )}
    </div>
  );
};

export default Products;
