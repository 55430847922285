import { useState, useContext } from "react";
import { resetPassword } from "../../../services/auth";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { useNavigate } from "react-router-dom";

const ResetPasswordLogic = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isResetting, setIsResetting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const submitPassword = () => {
    setIsResetting(true);
    setRequestLoaderProgress(60);
    resetPassword({
      password: password,
      confirm_password: confirmPassword,
      token: params.get("token") === null ? "null" : params.get("token"),
    })
      .then(() => {
        setRequestLoaderProgress(100);
        setIsResetting(false);
        navigate("/login");
        triggerToast("Password reset succesful", "success");
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsResetting(false);
        if (error.response === undefined) return;
        if (error.response.status === 400) {
          setErrorMessage(error.response.data.detail);
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  return {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    submitPassword,
    isResetting,
    errorMessage,
  };
};
export default ResetPasswordLogic;
