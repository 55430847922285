import { useState, useContext } from "react";
import { useStep } from "react-hooks-helper";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { sendReceipt } from "../../../services/transaction";
import { getRandomIntInclusive } from "../../../helpers";

const SendReceiptLogic = ({ closeWidget, transactionId, receiptType }) => {
  const [isSendingReceipt, setIsSendingReceipt] = useState(false);
  const [sendReceiptOption, setSendReceiptOption] = useState("email");
  const { index, navigation } = useStep({ initialStep: 0, steps: 2 });
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const handleSendReceipt = (data) => {
    const { email, phone_number } = data;
    const recipient = sendReceiptOption === "email" ? email : phone_number;
    setIsSendingReceipt(true);
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const receiptData = {
      medium: sendReceiptOption,
      send_to: recipient,
    };
    if (receiptType === "storeOrder") receiptData.order_id = transactionId;
    else receiptData.trx_id = transactionId;

    sendReceipt(receiptData, receiptType)
      .then(() => {
        setIsSendingReceipt(false);
        setRequestLoaderProgress(100);
        triggerToast("Receipt sent successfully", "success");
        closeWidget();
      })
      .catch((error) => {
        setIsSendingReceipt(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
        if (error.response.status === 422)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  return {
    isSendingReceipt,
    step: index,
    navigation,
    handleSendReceipt,
    sendReceiptOption,
    setSendReceiptOption,
  };
};

export default SendReceiptLogic;
