import httpClient from "../axiosBase/axiosHandler";

export const getTransactions = (queries) => {
  const response = httpClient.get(`/transactions/${queries}`);
  return response;
};

export const getTransactionDetails = (id) => {
  const response = httpClient.get(`/transactions/${id}/`);
  return response;
};

export const refreshTransactions = () => {
  const response = httpClient.post("/transactions/refresh/", null);
  return response;
};

export const sendReceipt = (payload, receiptType) => {
  const endpoint =
    receiptType === "storeOrder"
      ? "/order/send_receipt/"
      : `/${receiptType}/send_receipt/`;
  const response = httpClient.post(endpoint, payload);
  return response;
};

export const downloadStatementOfAcc = (payload) => {
  const response = httpClient.post("/transactions/account_statement/", payload);
  return response;
};

export const addTransactionTag = (trxId, tags = []) => {
  const response = httpClient.patch(`/transactions/${trxId}/add-tags/`, {
    tag_ids: tags,
  });
  return response;
};

export const removeTransactionTag = (trxId, tags = []) => {
  const response = httpClient.patch(`/transactions/${trxId}/remove-tags/`, {
    tag_ids: tags,
  });
  return response;
};

export const linkCustomerTransaction = (trxId, customer) => {
  return httpClient.post("/transactions/link-customer-transaction/", {
    ...customer,
    trx_id: trxId,
  });
};
