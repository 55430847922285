import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const TransactionsFilterLogic = ({ applyFilter, filterDetails }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const {
    paymentStatus,
    setPaymentStatus,
    paymentMethod,
    setPaymentMethod,
    setTimeDuration,
    timeDuration,
  } = filterDetails;
  const navigate = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target))
        setShowDropdown(false);
    };
    document.addEventListener("click", handleClickOutside);
  }, [inputRef]);

  const setFilter = () => {
    let paymentStatusValues = "";
    let paymentMethodValues = "";

    paymentStatus.forEach((item, index) => {
      let comma = index + 1 === paymentStatus.length ? "" : "|";
      paymentStatusValues = paymentStatusValues + item + comma;
    });
    paymentMethod.forEach((item, index) => {
      let comma = index + 1 === paymentMethod.length ? "" : "|";
      paymentMethodValues = paymentMethodValues + item + comma;
    });

    const queryParams = new URLSearchParams(window.location.search);
    let newParams = queryParams;
    newParams.delete("payment_method");
    newParams.delete("status");

    if (paymentStatusValues !== "")
      newParams.set("status", paymentStatusValues);
    if (paymentMethodValues !== "")
      newParams.set("payment_method", paymentMethodValues);

    let newQueries = "?";
    newParams.forEach((item, key) => {
      newQueries =
        newQueries === "?"
          ? newQueries + `${key}=${item}`
          : newQueries + `&${key}=${item}`;
    });

    navigate(newQueries);
    setShowDropdown(false);
    applyFilter();
  };

  const addValueToPaymentStatus = (value) => {
    let newPaymentStatus = [...paymentStatus];
    newPaymentStatus.push(value);
    setPaymentStatus(newPaymentStatus);
  };

  const removeValueFromPaymentStatus = (value) => {
    let newPaymentStatus = [...paymentStatus];
    setPaymentStatus(newPaymentStatus.filter((item) => item !== value));
  };

  const addValueToPaymentMethod = (value) => {
    let newPaymentMethod = [...paymentMethod];
    newPaymentMethod.push(value);
    setPaymentMethod(newPaymentMethod);
  };

  const removeValueFromPaymentMethod = (value) => {
    let newPaymentMethod = [...paymentMethod];
    setPaymentMethod(newPaymentMethod.filter((item) => item !== value));
  };

  return {
    showDropdown,
    setShowDropdown,
    paymentStatus,
    paymentMethod,
    setPaymentMethod,
    setTimeDuration,
    timeDuration,
    setFilter,
    removeValueFromPaymentStatus,
    addValueToPaymentStatus,
    addValueToPaymentMethod,
    removeValueFromPaymentMethod,
    inputRef,
  };
};
export default TransactionsFilterLogic;
