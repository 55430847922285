import "./AddTransactionsTag.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import AddTransactionsTagLogic from "./AddTransactionsTagLogic";
import { Fragment } from "react";
import { Controller } from "react-hook-form";
import AddTransactionsTagValidation from "./AddTransactionsTagValidation";

const AddTransactionsTag = ({
  isOpen,
  setIsOpen,
  isEditMode,
  setIsRefresh,
  tagDetails,
  setIsEditMode,
}) => {
  const { tag_name, setTagName, handleAddTagName, isAddingTag } =
    AddTransactionsTagLogic({
      setIsRefresh,
      setIsOpen,
      tagDetails,
      isEditMode,
      setIsEditMode,
    });
  const {
    register,
    control,
    formState: { errors },
    trigger,
    handleSubmit,
  } = AddTransactionsTagValidation(tag_name);

  return (
    <secondaryComponents.Modal
      isActive={isOpen}
      closeModal={() => setIsOpen(false)}
      width="400px"
    >
      <div className="transaction-tag">
        <Fragment>
          <h3 style={{ fontSize: "20px" }} className="font-weight-semibold">
            {isEditMode ? "Edit tag" : "Add tag"}
          </h3>
          <form
            onSubmit={handleSubmit(handleAddTagName)}
            className="transaction-tag__form"
          >
            <div className="transaction-tag__form__field">
              <Controller
                name="tag_name"
                control={control}
                defaultValue={tag_name}
                render={({ field: { onChange } }) => (
                  <primaryComponents.InputField
                    label="Tag Name"
                    placeholder="Enter your chosen tag name"
                    isRequired={true}
                    type="text"
                    name="tag_name"
                    errorMessage={errors.tag_name}
                    onKeyUp={() =>
                      errors.tag_name !== undefined && trigger("tag_name")
                    }
                    onChange={({ target: { value } }) => {
                      onChange(value);
                      setTagName(value);
                    }}
                    register={register}
                  />
                )}
              />
            </div>
            <div className="transaction-tag__form__actions">
              <primaryComponents.Button
                type="button"
                classNames={"btn btn--outline"}
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </primaryComponents.Button>
              <primaryComponents.Button
                classNames={"btn btn--primary"}
                isLoading={isAddingTag}
                loadingText="Saving..."
              >
                Save and return
              </primaryComponents.Button>
            </div>
          </form>
        </Fragment>
      </div>
    </secondaryComponents.Modal>
  );
};

export default AddTransactionsTag;
