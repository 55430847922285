import { profileDetailsSlice } from "./profile";
import { apiErrorSlice } from "./apiErrorHandler";
import { dashboardSlice } from "./dashboard";
import { cartSlice } from "./storeCart";

export const rootReducer = {
  profileDetailsReducer: profileDetailsSlice.reducer,
  apiErrorReducer: apiErrorSlice.reducer,
  dashboardReducer: dashboardSlice.reducer,
  cartReducer: cartSlice.reducer,
};
