import "./RestockProductWidget.scss";
import Icon from "../../../assets/icons";
import { useRestockProduct } from "./useRestockProduct";
import { useRestockProductValidation } from "./useRestockProductValidation";
import primaryComponents from "../../primaryComponents";

import RestockProductWidgetHome from "./RestockProductWidgetHome";
import RestockProductWidgetVariants from "./RestockProductWidgetVariants";

const RestockProductWidget = ({ closeWidget, selectedProduct }) => {
  const {
    hasVariants,
    setHasVariants,
    isSubmitting,
    handleRestockProduct,
    variants,
    newVariants,
    stockQuantity,
    minStockQuantity,
    expiryDate,
    costPrice,
    sellingPrice,
    setVariants,
    setNewVariants,
    setCostPrice,
    setExpiryDate,
    setMinStockQuantity,
    setSellingPrice,
    setStockQuantity,
    handleAddVariantField,
    handleRemoveVariantField,
  } = useRestockProduct({
    closeWidget,
    selectedProduct,
  });

  const { handleSubmit, control } = useRestockProductValidation({
    variants,
    new_variants: newVariants,
    stockQuantity,
    minStockQuantity,
    expiryDate,
    costPrice,
    sellingPrice,
  });

  return (
    <div className="restock-product-widget">
      <div className="restock-product-widget__header">
        <button onClick={() => closeWidget(true)}>
          <img src={Icon.chevron_left_arrow_black} alt="" />
        </button>
        <h3>Restock {selectedProduct?.name}</h3>
        <div />
      </div>
      <form
        onSubmit={handleSubmit(handleRestockProduct)}
        id="restock-form"
        className="restock-product-widget__content"
      >
        {hasVariants ? (
          <RestockProductWidgetVariants
            selectedProduct={selectedProduct}
            variants={variants}
            newVariants={newVariants}
            setVariants={setVariants}
            setNewVariants={setNewVariants}
            control={control}
            handleAddVariantField={handleAddVariantField}
            handleRemoveVariantField={handleRemoveVariantField}
          />
        ) : (
          <RestockProductWidgetHome
            hasVariants={hasVariants}
            selectedProduct={selectedProduct}
            setHasVariants={setHasVariants}
            control={control}
            costPrice={costPrice}
            sellingPrice={sellingPrice}
            stockQuantity={stockQuantity}
            minStockQuantity={minStockQuantity}
            expiryDate={expiryDate}
            setExpiryDate={setExpiryDate}
            setCostPrice={setCostPrice}
            setSellingPrice={setSellingPrice}
            setStockQuantity={setStockQuantity}
            setMinStockQuantity={setMinStockQuantity}
          />
        )}
      </form>
      <div
        style={{ width: "240px", display: "flex" }}
        className="mt-auto ml-auto"
      >
        <primaryComponents.Button
          type="submit"
          form="restock-form"
          classNames={"btn btn--primary w-100 my-4"}
          isLoading={isSubmitting}
          loadingText={"Restocking...."}
        >
          Restock
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default RestockProductWidget;
