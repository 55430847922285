import { useState, useContext } from "react";
import { updateTransfer } from "../../../services/transfers";
import { getRandomIntInclusive } from "../../../helpers";
import { ToastContext, RequestLoader } from "../../../hooks/context";

const TransferApprovalModalLogic = ({
  setShowApprovalModal,
  transferDetails,
  refreshTable,
}) => {
  const [isRejecting, setIsRejecting] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [password, setPassword] = useState("");
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const updateTransferStatus = (status) => {
    setRequestLoaderProgress(getRandomIntInclusive(5, 50));
    if (status === "accepted") {
      setIsApproving(true);
    } else setIsRejecting(true);
    updateTransfer({ status: status, password: password }, transferDetails.id)
      .then(() => {
        let toastType = status === "accepted" ? "success" : "warning";
        let toastMessage =
          status === "accepted"
            ? "Transfer approved successfully"
            : "Transfer rejected succesfully";
        triggerToast(toastMessage, toastType);
        if (status === "accepted") {
          setIsApproving(false);
        } else setIsRejecting(false);
        setRequestLoaderProgress(100);
        refreshTable();
        setShowApprovalModal(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        if (status === "accepted") {
          setIsApproving(false);
        } else setIsRejecting(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };
  return {
    updateTransferStatus,
    isRejecting,
    isApproving,
    password,
    setPassword,
  };
};
export default TransferApprovalModalLogic;
