import { useContext, useState } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { deletTag } from "../../../services/tags";

const DeleteTagLogic = ({ tagDetails, setIsRefresh, setIsOpen }) => {
  const [isDeletingTag, setIsDeletingTag] = useState(false);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const handleDeleteTag = () => {
    setIsDeletingTag(true);
    setRequestLoaderProgress(40);

    deletTag(tagDetails.id)
      .then(() => {
        setIsDeletingTag(false);
        setRequestLoaderProgress(100);
        setIsRefresh(true);
        setIsOpen(false);
        triggerToast("Tag deleted successfully", "success");
      })
      .catch((error) => {
        setIsDeletingTag(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  return {
    handleDeleteTag,
    isDeletingTag,
  };
};

export default DeleteTagLogic;
