import "./CashflowAnalytics.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import CashflowAnalyticsLogic from "./CashflowAnalyticsLogic";
import { formatCurrency } from "../../../../helpers";
import { useOutletContext } from "react-router-dom";

const CashflowAnalytics = () => {
  const { isRefresh, setIsRefresh } = useOutletContext();
  const { isLoading, cashFlowData, cashflowTrend, transactionCount, tagsData } =
    CashflowAnalyticsLogic({ isRefresh, setIsRefresh });

  return (
    <secondaryComponents.RequestLoaderTrigger>
      <secondaryComponents.MetaSetter
        title="Lumi Merchant | Dashboard - Cashflow Analytics"
        description="Cashflow analytics"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          <div className="cashflow-analytics">
            <div className="cashflow-analytics__cards">
              <div className="cashflow-analytics__cards__card">
                <div className="cashflow-analytics__cards__card__details">
                  <span className="cashflow-analytics__cards__card__details-title">
                    Inflow
                  </span>
                  <secondaryComponents.LoaderHelper
                    isLoading={isLoading}
                    classNames="mt-1 mb-2"
                    fontSize="15px"
                    alignItem="text-left"
                  >
                    <h4 className="font-weight-semibold mt-1">
                      {formatCurrency(cashFlowData.cash_inflow)}
                    </h4>
                  </secondaryComponents.LoaderHelper>
                  {/* <span className="mt-3 d-block cashflow-analytics__cards__card__details-subtitle">
                                        +14% from yesterday
                                    </span> */}
                </div>
              </div>
              <div className="cashflow-analytics__cards__card">
                <div className="cashflow-analytics__cards__card__details">
                  <span className="cashflow-analytics__cards__card__details-title">
                    Outflow
                  </span>
                  <secondaryComponents.LoaderHelper
                    isLoading={isLoading}
                    classNames="mt-1 mb-2"
                    fontSize="15px"
                    alignItem="text-left"
                  >
                    <h4 className="font-weight-semibold mt-1">
                      {formatCurrency(cashFlowData.cash_outflow)}
                    </h4>
                  </secondaryComponents.LoaderHelper>
                  {/* <span className="mt-3 d-block cashflow-analytics__cards__card__details-subtitle">
                                        +14% from yesterday
                                    </span> */}
                </div>
              </div>
              <div className="cashflow-analytics__cards__card">
                <div className="cashflow-analytics__cards__card__details">
                  <span className="cashflow-analytics__cards__card__details-title">
                    Inflow Transacion Count
                  </span>

                  <secondaryComponents.LoaderHelper
                    isLoading={isLoading}
                    classNames="mt-1 mb-2"
                    fontSize="15px"
                    alignItem="text-left"
                  >
                    <h4 className="font-weight-semibold mt-1">
                      {cashFlowData.inflow_count}
                    </h4>
                  </secondaryComponents.LoaderHelper>
                  {/* <span className="mt-3 d-block cashflow-analytics__cards__card__details-subtitle">
                                        +14% from yesterday
                                    </span> */}
                </div>
              </div>
              <div className="cashflow-analytics__cards__card">
                <div className="cashflow-analytics__cards__card__details">
                  <span className="cashflow-analytics__cards__card__details-title">
                    Outflow Transaction Count
                  </span>
                  <secondaryComponents.LoaderHelper
                    isLoading={isLoading}
                    classNames="mt-1 mb-2"
                    fontSize="15px"
                    alignItem="text-left"
                  >
                    <h4 className="font-weight-semibold mt-1">
                      {cashFlowData.outflow_count}
                    </h4>
                  </secondaryComponents.LoaderHelper>
                  {/* <span className="mt-3 d-block cashflow-analytics__cards__card__details-subtitle">
                                        +14% from yesterday
                                    </span> */}
                </div>
              </div>
            </div>
            <div className="cashflow-analytics__summary">
              <secondaryComponents.CashflowTrend
                cashflowTrend={cashflowTrend}
                cashFlowData={cashFlowData}
              />
              <secondaryComponents.TransactionCount
                transactionCount={transactionCount}
                cashFlowData={cashFlowData}
              />
            </div>
            <div className="cashflow-analytics__summary">
              <secondaryComponents.RevenueAnalytics tagsData={tagsData} />
              <secondaryComponents.SpendAnalytics tagsData={tagsData} />
            </div>
          </div>
        </motion.div>
      </secondaryComponents.MetaSetter>
    </secondaryComponents.RequestLoaderTrigger>
  );
};

export default CashflowAnalytics;
