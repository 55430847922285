import Dashboard from "./Dashboard/Dashboard";
import KYBVerification from "./KYBVerification/KYBVerification";
import Profile from "./Settings/Profile/Profile";
import Users from "./Settings/Users/Users";
import Settlements from "./Settings/Settlements/Settlements";
import Webhooks from "./Settings/Webhooks/Webhooks";
import ApiKeys from "./Settings/APIKeys/ApiKeys";
import AccountSettings from "./Settings/AccountSettings/AccountSettings";
import SnapAndEarn from "./Settings/SnapAndEarn/SnapAndEarn";
import Documentation from "./Documentation/Documentation";
import TransactionDetails from "./Transactions/TransactionDetails/TransactionDetails";
import CustomerDetails from "./Customers/CustomerDetails/CustomerDetails";
import AllCustomers from "./Customers/AllCustomers/AllCustomers";
import AllTransactions from "./Transactions/AllTransactions/AllTransactions";
import AllCampaigns from "./Loyalty/AllCampaigns/AllCampaigns";
import CreateCampaign from "./Loyalty/CreateCampaign/CreateCampaign";
import LoyaltyNotification from "./Loyalty/LoyaltyNotification/LoyaltyNotification";
import Overview from "./Dashboard/Overview/Overview";
import ApprovedTransfers from "./Transfers/ApprovedTransfers/ApprovedTransfers";
import PendingTransfers from "./Transfers/PendingTransfers/PendingTransfers";
import RejectedTransfers from "./Transfers/RejectedTransfers/RejectedTransfers";
import TransferDetails from "./Transfers/TransferDetails/TransferDetails";
import AllTransfers from "./Transfers/AllTransfers/AllTransfers";
import AllSubAccounts from "./SubAccounts/AllSubAccounts/SubAccounts";
import CreateSubAccount from "./SubAccounts/CreateSubAccount/CreateSubAccount";
import AllTerminals from "./Terminals/AllTerminals/AllTerminals";
import TerminalRequests from "./Terminals/TerminalRequests/TerminalRequests";
import NewTerminal from "./Terminals/NewTerminal/NewTerminal";
import Terminals from "./Terminals/Terminals";
import TerminalDetails from "./Terminals/TerminalDetails/TerminalDetails";
import TerminalCredentials from "./Terminals/TerminalCredentials/TerminalCredentials";
import CustomerAnalytics from "./Dashboard/CustomerAnalytics/CustomerAnalytics";
import CashflowAnalytics from "./Dashboard/CashflowAnalytics/CashflowAnalytics";
import SavedBeneficiaries from "./Transfers/SavedBeneficiaries/SavedBeneficiaries";
import Tags from "./Tags/Tags";
import Inventory from "./Inventory/Inventory";
import Analytics from "./Analytics/Analytics";
import Products from "./Inventory/Products/Products";
import ProductCategories from "./Inventory/ProductCategories/ProductCategories";
import AddProducts from "./Inventory/AddProducts/AddProducts";
import Stores from "./Inventory/Stores/Stores";
import AddSingleProduct from "./Inventory/AddSingleProduct/AddSingleProduct";
import AddMultipleProducts from "./Inventory/AddMultipleProducts/AddMultipleProducts";
import StoresTransaction from "./StoresTransaction/StoresTransaction";
import SalesTransactions from "./Inventory/SalesTransactions/SalesTransactions";
import ShopDetails from "./ShopDetails/ShopDetails";
import StoreDevices from "./StoreDevices/StoreDevices";
import StoreInventory from "./StoreInventory/StoreInventory";
import EditProduct from "./EditProduct/EditProduct";
import AllGiftCards from "./Loyalty/AllGiftCards/AllGiftCards";
import LoyaltyHome from "./Loyalty/LoyaltyHome/LoyaltyHome";
import CreateGiftCard from "./Loyalty/CreateGiftCard/CreateGiftCard";
import EditCampaign from "./EditCampaign/EditCampaign";
import EditGiftCard from "./EditGiftCard/EditGiftCard";
import LoyaltyCustomeHistory from "./Loyalty/LoyaltyCustomeHistory";
import LoyaltyCustomer from "./Loyalty/LoyaltyCustomer";
import LoyaltyCustomerDetails from "./Loyalty/LoyaltyCustomerDetails";
import StockHistory from "./StockHistory/StockHistory";
import RestockProduct from "./RestockProduct/RestockProduct";

const appPages = {
  Dashboard,
  AllGiftCards,
  CreateGiftCard,
  LoyaltyHome,
  KYBVerification,
  Profile,
  Users,
  Settlements,
  Webhooks,
  ApiKeys,
  AccountSettings,
  SnapAndEarn,
  Documentation,
  TransactionDetails,
  CustomerDetails,
  AllCustomers,
  AllTransactions,
  AllCampaigns,
  CreateCampaign,
  LoyaltyNotification,
  Overview,
  ApprovedTransfers,
  PendingTransfers,
  RejectedTransfers,
  TransferDetails,
  AllTransfers,
  AllSubAccounts,
  CreateSubAccount,
  AllTerminals,
  TerminalRequests,
  NewTerminal,
  Terminals,
  TerminalDetails,
  TerminalCredentials,
  CustomerAnalytics,
  CashflowAnalytics,
  SavedBeneficiaries,
  Tags,
  Inventory,
  Analytics,
  Products,
  ProductCategories,
  AddProducts,
  Stores,
  AddSingleProduct,
  AddMultipleProducts,
  StoresTransaction,
  SalesTransactions,
  ShopDetails,
  StoreDevices,
  StoreInventory,
  EditProduct,
  EditCampaign,
  EditGiftCard,
  LoyaltyCustomeHistory,
  LoyaltyCustomer,
  LoyaltyCustomerDetails,
  StockHistory,
  RestockProduct,
};

export default appPages;
