import "./AddSingleProduct.scss";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const AddSingleProduct = () => {
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      className="add-single-product-page"
    >
      <primaryComponents.Button
        classNames="btn btn--outline back__btn"
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon
          icon="angle-left"
          className="mr-3"
          style={{ fontSize: "18px" }}
        />
        <span>Back</span>
      </primaryComponents.Button>
      <div className="add-single-product-page__content">
        <h3 className="font-weight-semibold">Create single product</h3>
        <p>Please provide the following information about your product</p>
        <secondaryComponents.AddSingleProductForm />
      </div>
    </motion.div>
  );
};

export default AddSingleProduct;
