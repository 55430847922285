import { useSelector } from "react-redux";
import { useState } from "react";
import useRootProperties from "../../hooks/useRootProperties";

const AppLayoutLogic = () => {
  useRootProperties();
  const { email, business_role, business_id, business_list } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const { business_name } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails
  );
  const { total_revenue, pending_transfer_request } = useSelector(
    (state) => state.dashboardReducer.dashboardDetails
  );
  const [showSidebar, setShowSidebar] = useState(false);
  const [subTab, setSubTab] = useState(null);
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);

  return {
    business_name,
    business_role,
    email,
    business_id,
    business_list,
    showSidebar,
    total_revenue,
    showAccountDropdown,
    pending_transfer_request,
    subTab,
    setSubTab,
    setShowSidebar,
    setShowAccountDropdown,
  };
};
export default AppLayoutLogic;
