import { useState } from "react";

const StoresLogic = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [storeCount, setStoreCount] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false);

  return {
    isModalOpen,
    storeCount,
    isRefresh,
    setIsRefresh,
    setStoreCount,
    setIsModalOpen,
  };
};

export default StoresLogic;
