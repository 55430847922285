import primaryComponents from "../../../../components/primaryComponents";
import "./CreateSubAccount.scss";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateSubAccountLogic from "./CreateSubAccountLogic";
import secondaryComponents from "../../../../components/secondaryComponents";
import CreateSubAccountValidation from "./CreateSubAccountValidation";
import { Controller } from "react-hook-form";
import { Fragment } from "react";
import { useEffect } from "react";

const CreateSubAccount = () => {
  const navigate = useNavigate();
  const {
    subAccountName,
    subAccountDescription,
    subAccountPhoneNumber,
    subAccountEmail,
    setSubAccountName,
    setSubAccountDescription,
    setSubAccountPhoneNumber,
    setSubAccountEmail,
    submission,
    isSubmitting,
    verification_status,
  } = CreateSubAccountLogic();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    setError,
  } = CreateSubAccountValidation(
    subAccountName,
    subAccountDescription,
    subAccountPhoneNumber,
    subAccountEmail
  );

  useEffect(() => {
    window.setAccountError = setError;
  }, [setError]);

  return (
    <div className="create-sub-account">
      {verification_status === "unverified" ||
      verification_status === "pending_review" ? (
        <div className="create-sub-account__acct-widget">
          <secondaryComponents.UnverifiedAccountWidget
            message={
              "It appears your account has not been verified, please verify your account to continue"
            }
            verification_status={verification_status}
          />
        </div>
      ) : (
        <Fragment>
          <primaryComponents.Button
            className="btn btn--outline mb-4"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon
              icon="chevron-left"
              style={{ marginRight: "8px" }}
            />
            Back
          </primaryComponents.Button>
          <form
            onSubmit={handleSubmit(submission)}
            className="create-sub-account__form-details"
          >
            <div className="create-sub-account__form-field">
              <div className="mb-2">
                <Controller
                  name="subAccountName"
                  control={control}
                  defaultValue={subAccountName}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.InputField
                      label="Enter your sub account tag"
                      isRequired={true}
                      type="text"
                      name="subAccountName"
                      placeholder="Sub-account name eg. operations, yaba branch"
                      errorMessage={errors.subAccountName}
                      onKeyUp={() => {
                        errors.subAccountName !== undefined &&
                          trigger("subAccountName");
                      }}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setSubAccountName(value);
                      }}
                      register={register}
                    />
                  )}
                />
              </div>
              <div className="mb-2">
                <Controller
                  name="subAccountDescription"
                  control={control}
                  defaultValue={subAccountDescription}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.InputField
                      label="Enter your account description"
                      isRequired={false}
                      type="text"
                      name="subAccountDescription"
                      placeholder="Description"
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setSubAccountDescription(value);
                      }}
                      errorMessage={errors.subAccountDescription}
                      register={register}
                      onKeyUp={() => {
                        errors.subAccountDescription !== undefined &&
                          trigger("subAccountDescription");
                      }}
                    />
                  )}
                />
              </div>

              <div className="mb-2">
                <Controller
                  name="subAccountPhoneNumber"
                  control={control}
                  defaultValue={subAccountPhoneNumber}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.PhoneNumberInputField
                      label="Enter your sub-account phone number"
                      isRequired={true}
                      type="number"
                      name="subAccountPhoneNumber"
                      placeholder="Phone number, this could be your store or customer support number"
                      errorMessage={errors.subAccountPhoneNumber}
                      onKeyUp={() => {
                        errors.subAccountPhoneNumber !== undefined &&
                          trigger("subAccountPhoneNumber");
                      }}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setSubAccountPhoneNumber(value);
                      }}
                      register={register}
                    />
                  )}
                />
              </div>
              <div className="mb-2">
                <Controller
                  name="subAccountEmail"
                  control={control}
                  defaultValue={subAccountEmail}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.InputField
                      label="Enter your sub-account email address"
                      isRequired={true}
                      type="email"
                      name="subAccountEmail"
                      placeholder="Email, this could be your store or customer support email"
                      errorMessage={errors.subAccountEmail}
                      onKeyUp={() => {
                        errors.subAccountEmail !== undefined &&
                          trigger("subAccountEmail");
                      }}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setSubAccountEmail(value);
                      }}
                      register={register}
                    />
                  )}
                />
              </div>
            </div>
            <primaryComponents.Button
              className="btn btn--primary mt-2 ml-auto"
              type="submit"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              loadingText="Submitting..."
            >
              Submit
            </primaryComponents.Button>
          </form>
        </Fragment>
      )}
    </div>
  );
};

export default CreateSubAccount;
