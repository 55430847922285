import { useEffect, useMemo, useState, useContext } from "react";
import { useTable } from "react-table";
import { getStockHistory } from "../../../services/inventory";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import {
  formatCurrency,
  formatDate,
  getRandomIntInclusive,
  getUrlQuerysection,
} from "../../../helpers";
import { useNavigate } from "react-router-dom";

export const useStockHistoryTable = () => {
  const [isFetchingHistory, setIsFetchingHistory] = useState(false);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const [products, setProducts] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [paginationDetails, setPaginationDetails] = useState({});

  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "product",
        Cell: ({ cell: { value } }) => value?.name,
      },
      {
        Header: "Initiator",
        accessor: "initiator",
        Cell: ({ cell: { value } }) => (value !== null ? value : "N/A"),
      },
      {
        Header: "Variant",
        accessor: "variant",
        Cell: ({ cell: { value } }) =>
          value?.option !== undefined
            ? `${value?.option}-${value?.value}`
            : "N/A",
      },
      {
        Header: "Stock type",
        accessor: "type",
        Cell: ({ cell: { value } }) => (
          <span
            style={{ color: value === "NEW_STOCK" ? "#19AE57" : "#E02020" }}
          >
            {value === "NEW_STOCK" ? "New Stock" : "Sold Stock"}
          </span>
        ),
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Quantity Before",
        accessor: "quantity_before",
      },
      {
        Header: "Quantity After",
        accessor: "quantity_after",
      },
      {
        Header: "Cost Price (N)",
        accessor: "cost_price",
        Cell: ({ cell: { value } }) => formatCurrency(value),
      },
      {
        Header: "Selling Price",
        accessor: "selling_price",
        Cell: ({ cell: { value } }) => formatCurrency(value),
      },
      {
        Header: "Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => formatDate(value),
      },
    ],
    []
  );

  const handleFetchStockHistory = () => {
    setIsFetchingHistory(true);
    setRequestLoaderProgress(getRandomIntInclusive(20, 40));
    const queries = getUrlQuerysection();
    getStockHistory(queries)
      .then((response) => {
        setIsFetchingHistory(false);
        setRequestLoaderProgress(100);
        setProducts(response.data?.data);
        setPaginationDetails(response.data?.meta);
      })
      .catch((error) => {
        setIsFetchingHistory(false);
        setRequestLoaderProgress(100);
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      });
  };

  const gotoPage = (pageNumber) => {
    let queries = getUrlQuerysection();
    let page = queryParams.get("page");
    if (page === null) {
      let symbol = queries.includes("?") ? "&" : "?";
      navigate(`${queries}${symbol}page=${pageNumber}`);
      return handleFetchStockHistory();
    }

    let splitedQuery = queries.split(`page=${page}`);
    splitedQuery = splitedQuery[0] + `page=${pageNumber}` + splitedQuery[1];
    navigate(`${splitedQuery}`);
    handleFetchStockHistory();
  };

  useEffect(() => {
    handleFetchStockHistory();
  }, []);

  const instance = useTable({ columns, data: products });

  return {
    instance,
    products,
    isFetchingHistory,
    paginationDetails,
    setProducts,
    gotoPage,
  };
};
