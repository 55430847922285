import { useEffect, useState, useContext } from "react";
import { getCampaigns } from "../../../services/campaign";
import { ToastContext, RequestLoader } from "../../../hooks/context";

const CampaignListLogic = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState({});
  const [isFetchingCampaigns, setIsFetchingCampaigns] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  useEffect(() => {
    setIsFetchingCampaigns(true);
    setRequestLoaderProgress(20);
    getCampaigns()
      .then((response) => {
        setCampaigns(response.data.data);
        setPaginationDetails(response.data?.meta);
        setIsFetchingCampaigns(false);
        setRequestLoaderProgress(100);
      })
      .catch((error) => {
        setIsFetchingCampaigns(false);
        setRequestLoaderProgress(100);
        if (error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  }, []);

  return {
    campaigns,
    paginationDetails,
    isFetchingCampaigns,
  };
};

export default CampaignListLogic;
