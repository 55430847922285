import "./TerminalRequests.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";

const TerminalRequests = () => {
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Terminal"
      description="View pending terminal to be approved"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.3 },
          }}
          exit={{ opacity: 0 }}
        >
          <secondaryComponents.TerminalRequestTable />
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default TerminalRequests;
