// import all images and export them as an object

import enlumi_arrows from "./enlumi-arrows.svg";
import email_verification_icon from "./email-verification.svg";
import enlumi_arrows_2 from "./enlumi-arrows-2.svg";
import transaction_empty_state from "./transactions-empty-state.svg";
import offer_img_1 from "./offer-1.png";
import offer_img_2 from "./offer-2.png";
import error_img from "./error.svg";
import campaignEmptyState from "./campaign-empty-state.svg";
import tnxEmptyState from "./tnx-empty-state.svg";
import customersEmptyState from "./customers-empty-state.svg";
import purple_bg from "./purple-bg.svg";
import terminal_img from "./pos-terminal.png";
import subAccountEmptyState from "./subAccountEmptyState.svg";
import notFoundImage from "./404Img.svg";
import tagEmptyState from "./tagEmptyState.svg";
import productsEmptyState from "./products-empty.svg";
import productCategoryEmptyState from "./product-category-empty.svg";
import storeProductEmpty from "./store-product-none.svg";
import product_image from "./product-image.png";
import product_image_2 from "./product-image-2.png";
import success_image from "./success.svg";
import giftcard_image from "./giftcard-image.png";
import product_avatar from "./product-avatar.png";

const Images = {
  giftcard_image,
  enlumi_arrows,
  email_verification_icon,
  enlumi_arrows_2,
  transaction_empty_state,
  offer_img_1,
  offer_img_2,
  error_img,
  campaignEmptyState,
  tnxEmptyState,
  customersEmptyState,
  purple_bg,
  terminal_img,
  subAccountEmptyState,
  notFoundImage,
  tagEmptyState,
  productsEmptyState,
  productCategoryEmptyState,
  storeProductEmpty,
  product_image,
  product_image_2,
  success_image,
  product_avatar,
};

export default Images;
