import "./Dashboard.scss";
import secondaryComponents from "../../../components/secondaryComponents";
import { NavLink, Outlet, Link, useNavigate } from "react-router-dom";
import DashboardLogic from "./DashboardLogic";
import { getUrlQuerysection } from "../../../helpers";
import primaryComponents from "../../../components/primaryComponents";
import { useEffect } from "react";

const Dashboard = () => {
  const {
    verification_status,
    first_name,
    business_list,
    isRefresh,
    setIsRefresh,
    showAllBusiness,
    newBusinessId,
    filterBaseOnAccount,
    setDateFilter,
    openTransferWidget,
    setOpenTransferWidget,
    business_role,
    startDate,
    endDate,
  } = DashboardLogic();

  const navigate = useNavigate();

  useEffect(() => {
    if (business_role === "CUSTOMER_SUPPORT" || business_role === "CASHIER") {
      return navigate("/app/transactions?all_business=true");
    }
  }, []);

  return (
    <div>
      <div className="dashboard">
        <div className="dashboard__nav">
          {/* <div>
                        <h3 className="font-weight-semibold">
                            Welcome {first_name}
                        </h3>
                    </div> */}
          {verification_status !== "" && verification_status !== "verified" && (
            <Link
              to="/app/kyb-verification"
              className={"dashboard__nav__verify__btn " + verification_status}
            >
              <span>
                {verification_status === "unverified" && "Verify business"}
                {verification_status === "" && "Verify business"}
                {verification_status === "verified" && "Account verified"}
                {verification_status === "pending_review" &&
                  "Verification pending"}
              </span>
            </Link>
          )}
        </div>
        <div className="dashboard__content">
          <div className="dashboard__content__actions">
            <h4 className="font-weight-semibold title mb-2">
              Welcome {first_name},
            </h4>
            <div className="dashboard__content__actions__items">
              <primaryComponents.DateRangeInput
                startDate={startDate}
                endDate={endDate}
                onChange={setDateFilter}
                classNames="mr-2 mb-2"
              />
              {(business_role === "OWNER" ||
                business_role === "ADMIN" ||
                business_role === "BUSINESS_MANAGER") && (
                <primaryComponents.Button
                  onClick={() => setOpenTransferWidget(true)}
                  classNames="btn btn--primary mb-2"
                >
                  {business_role === "BUSINESS_MANAGER"
                    ? "Request Transfer"
                    : "Transfer Funds"}
                </primaryComponents.Button>
              )}
            </div>
          </div>

          <secondaryComponents.SubAccountsList
            businessList={business_list}
            showAllBusiness={showAllBusiness}
            filterBaseOnAccount={filterBaseOnAccount}
            newBusinessId={newBusinessId}
            classNames="sub-accounts"
          />

          <div className="dashboard__content__tab">
            <NavLink
              className="dashboard__content__tab__item"
              to={`/app/dashboard${getUrlQuerysection()}`}
              end
            >
              General Overview
            </NavLink>
            <NavLink
              className="dashboard__content__tab__item"
              to={`/app/dashboard/cashflow-analytics${getUrlQuerysection()}`}
              end
            >
              Cash Flow Analytics
            </NavLink>
            <NavLink
              className="dashboard__content__tab__item"
              to={`/app/dashboard/customer-analytics${getUrlQuerysection()}`}
              end
            >
              Customer Analytics
            </NavLink>
          </div>
          <Outlet context={{ isRefresh, setIsRefresh }} />
        </div>
      </div>

      {/* transfer widget */}
      {openTransferWidget && (
        <secondaryComponents.FundTransferWidget
          closeWidget={setOpenTransferWidget}
          refreshTransfers={setIsRefresh}
        />
      )}
    </div>
  );
};

export default Dashboard;
