import "./AddCustomerWidget.scss";
// import { motion } from "framer-motion";
import React from "react";
import Icon from "../../../assets/icons";
import primaryComponents from "../../primaryComponents";
import { Controller } from "react-hook-form";
import { useAddCustomerWidgetLogic } from "./AddCustomerWidgetLogic";
import { useCustomerWidgetValidation } from "./AddCustomerWidgetValidation";

const AddCustomerWidget = ({ closeWidget }) => {
  const {
    customerName,
    customerEmail,
    customerGender,
    customerPhoneNumber,
    dateOfBirth,
    isSubmitting,
    setDateOfBirth,
    setCustomerEmail,
    setCustomerGender,
    setCustomerName,
    setCustomerPhoneNumber,
    handleAddCustomer,
  } = useAddCustomerWidgetLogic({ closeWidget });

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useCustomerWidgetValidation({
    customerName,
    customerEmail,
    customerGender,
    customerPhoneNumber,
    dateOfBirth,
  });

  console.log(errors);

  return (
    <div className="add-customers">
      <div className="add-customers__close" onClick={() => closeWidget(false)}>
        <img src={Icon.close} alt="" />
      </div>

      <div className="add-customers__desc">
        <h4 className="font-weight-semibold">Add Customer</h4>
        <p>Provide the following details about your customer.</p>
      </div>
      <form
        onSubmit={handleSubmit(handleAddCustomer)}
        className="add-customers__form"
        id="add-customer-form"
      >
        <Controller
          control={control}
          name="customerName"
          defaultValue={customerName}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label="Name"
              isRequired={true}
              name="customerName"
              value={customerName}
              placeholder="Enter your customer’s name"
              onChange={({ target: { value } }) => {
                onChange(value);
                setCustomerName(value);
              }}
              onKeyUp={() => {
                errors.customerName !== undefined && trigger("customerName");
              }}
              errorMessage={errors.customerName}
            />
          )}
        />
        <Controller
          control={control}
          name="customerPhoneNumber"
          defaultValue={customerPhoneNumber}
          render={({ field: { onChange } }) => (
            <primaryComponents.PhoneNumberInputField
              label="Phone Number"
              isRequired={true}
              name="customerPhoneNumber"
              value={customerPhoneNumber}
              placeholder="Enter your customer’s phone number"
              onChange={({ target: { value } }) => {
                onChange(value);
                setCustomerPhoneNumber(value);
              }}
              onKeyUp={() => {
                errors.customerPhoneNumber !== undefined &&
                  trigger("customerPhoneNumber");
              }}
              errorMessage={errors.customerPhoneNumber}
            />
          )}
        />
        <Controller
          control={control}
          name="customerEmail"
          defaultValue={customerEmail}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label="Email"
              name="customerEmail"
              value={customerEmail}
              isRequired={true}
              placeholder="Enter your customer’s email address"
              onChange={({ target: { value } }) => {
                onChange(value);
                setCustomerEmail(value);
              }}
              onKeyUp={() => {
                errors.customerEmail !== undefined && trigger("customerEmail");
              }}
              errorMessage={errors.customerEmail}
            />
          )}
        />

        <div>
          <label>Gender</label>
          <div className="form-gender__checkbox  add-customers__form--gender d-flex">
            <Controller
              control={control}
              name={"customerGender"}
              defaultValue={customerGender}
              render={({ field: { onChange } }) => (
                <primaryComponents.BoxedRadioInput
                  value={"MALE"}
                  name={"customerGender"}
                  setRadioValue={(value) => {
                    onChange(value);
                    setCustomerGender(value);
                  }}
                  isChecked={customerGender === "MALE"}
                  className="add-customers__form--gender-item"
                >
                  Male
                </primaryComponents.BoxedRadioInput>
              )}
            />
            <Controller
              control={control}
              name={"customerGender"}
              defaultValue={customerGender}
              render={({ field: { onChange } }) => (
                <primaryComponents.BoxedRadioInput
                  value={"FEMALE"}
                  name={"customerGender"}
                  setRadioValue={(value) => {
                    onChange(value);
                    setCustomerGender(value);
                  }}
                  isChecked={customerGender === "FEMALE"}
                  className="add-customers__form--gender-item"
                >
                  Female
                </primaryComponents.BoxedRadioInput>
              )}
            />
          </div>
        </div>
        <Controller
          control={control}
          name="dateOfBirth"
          defaultValue={dateOfBirth}
          render={({ field: { onChange } }) => (
            <primaryComponents.DateInput
              label="Date of birth"
              isRequired={true}
              name="dateOfBirth"
              value={dateOfBirth}
              placeholder="Enter your customer’s date of birth"
              onChange={({ target: { value } }) => {
                onChange(value);
                setDateOfBirth(value);
              }}
              onKeyUp={() =>
                errors.dateOfBirth !== undefined && trigger("dateOfBirth")
              }
              errorMessage={errors.dateOfBirth}
            />
          )}
        />
      </form>
      <primaryComponents.Button
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        loadingText={"Adding customer..."}
        className="btn btn--primary"
        form="add-customer-form"
        type="submit"
      >
        Add Customer
      </primaryComponents.Button>
    </div>
  );
};

export default AddCustomerWidget;
