import "./SubAccountsList.scss";

const SubAccountsList = ({
  classNames = "",
  newBusinessId,
  businessList = [],
  filterBaseOnAccount = () => {},
  showAllBusiness,
}) => {
  return (
    <>
      {businessList.length > 1 && (
        <div className={`all-subaccounts-list ${classNames}`}>
          <div
            className={`account ${showAllBusiness === "true" && "active"}`}
            onClick={() => filterBaseOnAccount("all")}
          >
            <span>All accounts</span>
          </div>
          {businessList.map((item) => (
            <div
              key={item.id}
              className={`account ${
                newBusinessId === item.id &&
                showAllBusiness !== "true" &&
                "active"
              }`}
              onClick={() => filterBaseOnAccount(item.id)}
            >
              <span>{item.tag}</span>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
export default SubAccountsList;
