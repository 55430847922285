import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const productSchema = yup.object({
  productName: yup.string().required("Product name is required"),
  productSku: yup.string().nullable(true),
  productCategory: yup.string().required("Category is required"),
  productPrice: yup.string().nullable(true),
  sellingPrice: yup.string().nullable(true),
  stockQuantity: yup
    .number()
    .typeError("quantity must be a number")
    .nullable(true),
  minStockQuantity: yup
    .number()
    .typeError("quantity must be a number")
    .nullable(true),
  expiryDate: yup
    .string()
    .test(
      "Invalid date",
      "Expiry date must be greater than today's date",
      (value) => {
        if (value) {
          let today = new Date();
          let date = new Date(value);
          return date >= today;
        }
        return true;
      }
    )
    .nullable(true),
  variants: yup.array().of(
    yup.object().shape({
      option: yup.string(),
      value: yup.string(),
      cost_price: yup.string(),
      selling_price: yup.string(),
      stock_quantity: yup.number().typeError("Invalid format"),
      expiry_date: yup
        .string()
        .test(
          "Invalid date",
          "Expiry date must be greater than today's date",
          (value) => {
            if (value) {
              let today = new Date();
              let date = new Date(value);
              return date >= today;
            }
            return true;
          }
        )
        .nullable(true),
    })
  ),
});

export const useAddSingleProductFormValidation = ({
  productName,
  productCategory,
  productPrice,
  sellingPrice,
  productSku,
  expiryDate,
  stockQuantity,
  minStockQuantity,
  variants,
}) => {
  return useForm({
    resolver: yupResolver(productSchema),
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
      productName,
      productCategory,
      productPrice,
      sellingPrice,
      productSku,
      expiryDate,
      stockQuantity,
      minStockQuantity,
      variants,
    },
  });
};
