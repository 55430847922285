import { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { ToastContext } from "../../../../hooks/context";
import { fetchAllTerminals } from "../../../../services/pos-terminals";
import { sendOrderReceiptToPrint } from "../../../../services/sales-point";

const useReceiptTerminals = ({ orderDetails }) => {
  const triggerToast = useContext(ToastContext);
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const { data: terminalList, isLoading: isFetchingTerminals } = useQuery(
    "fetchTerminals",
    () => fetchAllTerminals("?all_business=true").then((res) => res?.data.data),
    {
      onError: (error) => {
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      },
    }
  );

  const { isLoading: isPrinting, mutate: printReceipt } = useMutation((data) =>
    sendOrderReceiptToPrint(data)
  );

  const sendReceipt = (terminalId, terminalSn) => {
    setSelectedTerminal(terminalId);
    printReceipt(
      { order_id: orderDetails?.id, terminal_id: terminalId },
      {
        onSuccess: () => {
          triggerToast(
            `The receipt has been sent to terminal with SN: ${terminalSn}`,
            "success"
          );
        },
        onError: (error) => {
          if (error?.response?.status === 400)
            triggerToast(error?.response?.data?.detail, "warning");
        },
      }
    );
  };

  return {
    isFetchingTerminals,
    isPrinting,
    selectedTerminal,
    terminalList: terminalList || [],
    sendReceipt,
  };
};
export default useReceiptTerminals;
