import { useState, useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { getOrderDetails, reverseOrder } from "../../../services/inventory";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { getRandomIntInclusive } from "../../../helpers";

const StoreCheckoutLogic = ({ closeStoreCheckout, setRefreshProducts }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("order_id");
  const [checkoutType, setCheckoutType] = useState("cash");
  const triggerToast = useContext(ToastContext);
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [isCustomerForm, setIsCustomerForm] = useState(false);
  const [isTransactionDetails, setIsTransactionDetails] = useState(false);
  const [reloadOrder, setReloadOrder] = useState(true);
  const [isSendReceipt, setIsSendReceipt] = useState(false);
  const [isReceiptTerminal, setIsReceiptTerminal] = useState(false);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const fetchOrderDetails = async (orderId) => {
    return await getOrderDetails(orderId).then(
      (response) => response.data.data
    );
  };
  const { data: orderDetails, isLoading: isLoadingOrder } = useQuery(
    ["orderDetails", orderId],
    () => fetchOrderDetails(orderId),
    {
      onError: (error) => {
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      },
      onSuccess: () => {
        setReloadOrder(false);
      },
      enabled: reloadOrder,
    }
  );

  const { mutate: cancelOrder } = useMutation((data) => reverseOrder(data));
  const cancelCurrentOrder = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    cancelOrder(orderId, {
      onSuccess: () => {
        setRequestLoaderProgress(100);
        closeStoreCheckout();
        setRefreshProducts(true);
      },
      onError: (error) => {
        setRequestLoaderProgress(100);
        if (error?.response?.status === 400) {
          if (
            error?.response?.data?.detail ===
            "Order with status: COMPLETED can not be cancel"
          )
            return closeStoreCheckout();
          triggerToast(error?.response?.data?.detail, "warning");
        }
      },
    });
  };

  const closePaymentSession = () => {
    setIsPaymentDone(true);
    setIsCustomerForm(true);
  };

  const openTransactionsession = () => {
    setReloadOrder(true);
    setIsCustomerForm(false);
    setIsTransactionDetails(true);
  };

  const openSendReceipt = () => {
    setIsTransactionDetails(false);
    setIsSendReceipt(true);
  };

  const gotoTransactionDetails = () => {
    setIsTransactionDetails(true);
    setIsSendReceipt(false);
    setIsReceiptTerminal(false);
  };

  const gotoReceiptTerminals = () => {
    setIsTransactionDetails(false);
    setIsReceiptTerminal(true);
  };

  return {
    isLoadingOrder,
    checkoutType,
    orderDetails,
    isCustomerForm,
    isPaymentDone,
    isTransactionDetails,
    isSendReceipt,
    isReceiptTerminal,
    setIsReceiptTerminal,
    setIsPaymentDone,
    setCheckoutType,
    setIsCustomerForm,
    closePaymentSession,
    setIsTransactionDetails,
    openTransactionsession,
    setIsSendReceipt,
    openSendReceipt,
    gotoTransactionDetails,
    cancelCurrentOrder,
    gotoReceiptTerminals,
  };
};

export default StoreCheckoutLogic;
