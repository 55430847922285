import "./GiftCardList.scss";
import { useGiftCardListLogic } from "./GiftCardLogic";
import LoaderHelper from "../LoaderHelper/LoaderHelper";
import GiftCardItem from "../GiftCardItem/GiftCardItem";
import Images from "../../../assets/images";

const GiftCardList = () => {
  const { isFetchingGiftCards, giftcards } = useGiftCardListLogic();

  return (
    <div>
      <LoaderHelper classNames={"mt-4 mb-5"} isLoading={isFetchingGiftCards}>
        {giftcards.length > 0 ? (
          <div className="giftcard-list">
            {giftcards.map((giftcard) => (
              <GiftCardItem key={giftcard.id} giftcardDetails={giftcard} />
            ))}
          </div>
        ) : (
          <div className="text-center mt-5">
            <img
              width="250px"
              height="262px"
              src={Images.campaignEmptyState}
              alt="empty state"
            />
            <h4>You have no giftcards</h4>
            <p>Create your giftcards using the button above.</p>
          </div>
        )}
      </LoaderHelper>
    </div>
  );
};

export default GiftCardList;
