import secondaryComponents from "../../../components/secondaryComponents";
import { useOutletContext } from "react-router-dom";

const Tags = () => {
  const { setIsEditingTag, setIsAddingTag } = useOutletContext();

  return (
    <div className="tags-page">
      <secondaryComponents.TagsTable
        setIsEditingTag={setIsEditingTag}
        setIsAddingTag={setIsAddingTag}
      />
    </div>
  );
};

export default Tags;
