import { useStep } from "react-hooks-helper";

const AddProductsLogic = () => {
  const { index: currentStep, navigation } = useStep({
    steps: 3,
    initialStep: 0,
  });

  const handleNextStep = () => {
    navigation.next();
  };

  const handlePreviousStep = () => {
    navigation.previous();
  };

  const navigatetToStep = (step) => {
    navigation.go(step);
  };

  return {
    handleNextStep,
    handlePreviousStep,
    currentStep,
    navigatetToStep,
  };
};

export default AddProductsLogic;
