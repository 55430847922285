import "./AddSettlementAccount.scss";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";
import { Controller } from "react-hook-form";
import AddSettlementAccountValidation from "./AddSettlementAccountValidation";
import AddSettlementAccountLogic from "./AddSettlementAccountLogic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddSettlementAccount = ({
  isOpen,
  setIsOpen,
  handleFetchSettlementAccounts,
}) => {
  const {
    account_currency,
    setAccountCurrency,
    account_number,
    setAccountNumber,
    bank,
    setBank,
    handleAddSettlementAccount,
    is_primary,
    setIsPrimary,
    bankList,
    isFetchingBankList,
    isResolvingAccount,
    handleResolveAccountNumber,
    accountDetails,
    isValidAccountNumber,
    isAddingAccount,
    businessId,
    setBusinessId,
    business_list,
  } = AddSettlementAccountLogic(setIsOpen, handleFetchSettlementAccounts);
  const {
    register,
    control,
    formState: { errors },
    trigger,
    handleSubmit,
  } = AddSettlementAccountValidation({
    bank,
    account_currency,
    account_number,
    is_primary,
    business_id: businessId,
  });

  return (
    <secondaryComponents.Modal
      isActive={isOpen}
      width="500px"
      closeModal={() => setIsOpen(false)}
    >
      <div className="add-settlement-account">
        <h4 className="font-weight-semibold text-center">
          Add new bank account
        </h4>
        <secondaryComponents.LoaderHelper
          classNames={"mt-3"}
          isLoading={isFetchingBankList}
        >
          <form
            onSubmit={handleSubmit(handleAddSettlementAccount)}
            className="add-settlement-account__form"
          >
            <div>
              <Controller
                name="account_currency"
                control={control}
                defaultValue={account_currency}
                render={({ field: { onChange } }) => (
                  <primaryComponents.SelectField
                    label="Account Currency"
                    isRequired={true}
                    placeholder="Select currency"
                    name="account_currency"
                    options={["NGN"]}
                    value={
                      account_currency === ""
                        ? "Select currency"
                        : account_currency
                    }
                    errorMessage={errors.account_currency}
                    onChange={(value) => {
                      onChange(value);
                      setAccountCurrency(value);
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="bank"
                control={control}
                defaultValue={bank}
                render={({ field: { onChange } }) => (
                  <primaryComponents.SelectField
                    label="Bank"
                    isRequired={true}
                    placeholder="Select bank"
                    name="bank"
                    nameKey={"bank_name"}
                    idKey={"bank_code"}
                    options={bankList}
                    value={bank === "" ? "Select bank" : bank}
                    errorMessage={errors.bank}
                    onChange={(value) => {
                      onChange(value);
                      setBank(value);
                    }}
                  />
                )}
              />
            </div>
            <div style={{ position: "relative" }}>
              <Controller
                name="account_number"
                control={control}
                defaultValue={account_number}
                render={({ field: { onChange } }) => (
                  <primaryComponents.InputField
                    label="Account Number"
                    isRequired={true}
                    placeholder="Enter your account number"
                    name="account_number"
                    errorMessage={errors.account_number}
                    register={register}
                    onKeyUp={() =>
                      errors.account_number !== undefined &&
                      trigger("account_number")
                    }
                    onChange={({ target: { value } }) => {
                      onChange(value);
                      setAccountNumber(value);
                      if (value.length === 10) {
                        handleResolveAccountNumber(value, bank);
                      }
                    }}
                  />
                )}
              />
              {accountDetails !== null && (
                <p className="purple--text mb-3">
                  {accountDetails.account_name}
                </p>
              )}
              {isResolvingAccount && (
                <FontAwesomeIcon
                  style={{
                    position: "absolute",
                    right: "10px",
                    // bottom: "50%",
                    top: "45%",
                    transform: "translateY(-50%)",
                  }}
                  icon={["fas", "spinner"]}
                  spin
                />
              )}
            </div>
            <div>
              <Controller
                control={control}
                defaultValue={businessId}
                name="business_id"
                render={({ field: { onChange } }) => (
                  <primaryComponents.MultipleSelectField
                    label={"Select Sub Account"}
                    isRequired={true}
                    placeholder="Select account"
                    options={business_list}
                    idKey="id"
                    nameKey="tag"
                    name="business_id"
                    onChange={(values) => {
                      onChange(values);
                      let newValues = [];
                      values.forEach((item) => {
                        newValues.push(item.value);
                      });
                      setBusinessId(newValues);
                    }}
                  />
                )}
              />
            </div>
            <div className="d-flex">
              <Controller
                name="is_primary"
                control={control}
                defaultValue={is_primary}
                render={({ field: { onChange } }) => (
                  <primaryComponents.Checkbox
                    name="is_primary"
                    id="is_primary"
                    isChecked={is_primary}
                    onChange={(value) => {
                      onChange(value);
                      setIsPrimary(value);
                    }}
                  />
                )}
              />
              <p className="ml-2">Make this my primary bank account</p>
            </div>
            <div className="add-settlement-account__form__actions">
              <primaryComponents.Button
                classNames={"btn btn--outline"}
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </primaryComponents.Button>
              <primaryComponents.Button
                classNames={"btn btn--primary"}
                isDisabled={!isValidAccountNumber || isResolvingAccount}
                isLoading={isAddingAccount}
                loadingText="Adding account..."
              >
                Add new bank
              </primaryComponents.Button>
            </div>
          </form>
        </secondaryComponents.LoaderHelper>
      </div>
    </secondaryComponents.Modal>
  );
};

export default AddSettlementAccount;
