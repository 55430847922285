import { useContext, useState } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { useSelector } from "react-redux";
import { updateSubAccount } from "../../../services/business";
import { getRandomIntInclusive } from "../../../helpers";

const EditSubAccountLogic = (
  account,
  getSubAccountsList,
  setOpenEditWidget
) => {
  const { business_tag, business_id } = account;
  const [sub_account_description, setSubAccountDescription] = useState("");
  const [sub_account_tag, setSubAccountTag] = useState(business_tag || "");
  const [isEdited, setIsEdited] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const handleEditSubAccount = async () => {
    if (business_role === "OWNER" || business_role === "ADMIN") {
      setIsUpdating(true);
      setRequestLoaderProgress(getRandomIntInclusive(10, 40));

      updateSubAccount({
        sub_account_id: business_id,
        sub_account_tag,
        sub_account_description,
      })
        .then(() => {
          setIsUpdating(false);
          triggerToast("Account updated successfully", "success");
          setOpenEditWidget(false);
          getSubAccountsList();
        })
        .catch((error) => {
          setIsUpdating(false);
          setRequestLoaderProgress(100);
          if (error.response === undefined) return;
          if (error.response.status === 400) {
            triggerToast(error.response.data.detail, "warning");
          }
        });
    } else {
      return triggerToast(
        "You are not authorized to perform this action.",
        "warn"
      );
    }
  };

  return {
    sub_account_tag,
    isEdited,
    isUpdating,
    sub_account_description,

    setSubAccountTag,
    setIsEdited,
    setIsUpdating,
    handleEditSubAccount,
    setSubAccountDescription,
  };
};

export default EditSubAccountLogic;
