import "./CampaignList.scss";
import CampaignCard from "../CampaignCard/CampaignCard";
// import primaryComponents from "../../primaryComponents";
import CampaignListLogic from "./CampaignListLogic";
import secondaryComponents from "..";
import Images from "../../../assets/images";

const CampaignList = () => {
  const { campaigns, isFetchingCampaigns } = CampaignListLogic();

  return (
    <div>
      {/* search section */}
      {/* <div className="mt-4 mb-3">
        <primaryComponents.CampaignSearchField placeholder="Search for a campaign" />
      </div> */}

      {/* campaign list section */}
      <secondaryComponents.LoaderHelper
        classNames="mt-5"
        isLoading={isFetchingCampaigns}
      >
        {campaigns.length > 0 ? (
          <div className="campaign-list">
            {campaigns.map((campaign) => (
              <CampaignCard key={campaign.id} campaignDetails={campaign} />
            ))}
          </div>
        ) : (
          <div className="text-center mt-5">
            <img
              width="250px"
              height="262px"
              src={Images.campaignEmptyState}
              alt="empty state"
            />
            <h4>You have no campaigns</h4>
            <p>Create your campaigns using the button above.</p>
          </div>
        )}
      </secondaryComponents.LoaderHelper>
    </div>
  );
};

export default CampaignList;
