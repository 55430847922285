import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const storeSchema = yup
  .object({
    storeName: yup.string().required("Store name is required"),
    storeAddress: yup.string().required("Store address is required"),
    settlementAccount: yup.string().required("Select settlement account"),
  })
  .required("All fields are required");

const StoresTableModalValidation = ({
  storeName,
  storeAddress,
  settlementAccount,
}) => {
  return useForm({
    resolver: yupResolver(storeSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: { storeName, storeAddress, settlementAccount },
  });
};

export default StoresTableModalValidation;
