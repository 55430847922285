import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = { message: null };

export const apiErrorSlice = createSlice({
  name: "apiError",
  initialState,
  reducers: {
    setApiErrorMessage: (state, action) => {
      state.message = action.payload;
    },
  },
});

export const { setApiErrorMessage } = apiErrorSlice.actions;
