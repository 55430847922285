import "./SavedBeneficiaries.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";

const SavedBeneficiaries = () => {
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Transfers - Beneficiaries"
      description="View all beneficiaries you choose to save on transfers"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className="beneficiaries-page"
        >
          <secondaryComponents.BeneficiariesTable />
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default SavedBeneficiaries;
