import { useState, useContext, useEffect, useRef } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import {
  getCategoryList,
  addSingleProduct,
  uploadProductImage,
} from "../../../services/inventory";
import { useNavigate } from "react-router-dom";
import { getRandomIntInclusive } from "../../../helpers";

export const useAddSingleProductFormLogic = () => {
  //Single Form field state
  const [productName, setProductName] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productSku, setProductSku] = useState("");
  const [productCostPrice, setProductCostPrice] = useState(0);
  const [productSellingPrice, setProductSellingPrice] = useState(0);
  const [productStockQuantity, setProductStockQuantity] = useState(1);
  const [productProfit, setProductProfit] = useState(0);
  const [productExpiryDate, setProductExpiryDate] = useState("");
  const [productMinStockQuantity, setProductStockMinStockQuantity] =
    useState(0);
  const [productVariants, setProductVariants] = useState([
    {
      option: "",
      value: "",
      sku: "",
      selling_price: 0,
      stock_quantity: 1,
      minimum_stock_quantity: 1,
      expiry_date: "",
      cost_price: 0,
      profit: 0,
    },
  ]);
  const [productImage, setProductImage] = useState("");

  const [categories, setCategories] = useState([]);

  const [isFetchingCategories, setIsfetchingCategories] = useState(false);
  const [hasVariants, setHasVariants] = useState(false);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const navigate = useNavigate();

  const inputRef = useRef();

  useEffect(() => {
    handleFetchCategories();
  }, []);

  const handleAddVariantField = () => {
    const _variant = {
      option: "",
      value: "",
      sku: "",
      selling_price: 0,
      stock_quantity: 1,
      minimum_stock_quantity: 1,
      expiry_date: "",
      cost_price: 0,
    };

    setProductVariants((prev) => [...prev, { ..._variant }]);
  };

  const handleRemoveVariantField = (fieldIndex) => {
    const _variants = [...productVariants];
    const variants = _variants.filter((_, index) => fieldIndex !== index);
    setProductVariants(variants);
  };

  const handleAddProduct = () => {
    setIsAddingProduct(true);
    setRequestLoaderProgress(getRandomIntInclusive(20, 40));
    const payload = {
      name: productName,
      category: productCategory,
      variants: hasVariants
        ? productVariants.map((variant) => {
            // remove expiry date field if it is empty
            if (variant.expiry_date === "") {
              delete variant["expiry_date"];
            }
            return variant;
          })
        : [],
      sku: productSku,
      selling_price: productSellingPrice,
      cost_price: productCostPrice,
      image_url: productImage,
      stock_quantity: productStockQuantity,
      minimum_stock_quantity: productMinStockQuantity,
      expiry_date: productExpiryDate,
    };

    if (payload.expiry_date === "") delete payload["expiry_date"];

    addSingleProduct(payload)
      .then(() => {
        setIsAddingProduct(false);
        setRequestLoaderProgress(100);
        navigate("/app/products");
      })
      .catch((error) => {
        if (error.response) {
          const { status } = error.response;
          const { detail } = error.response.data;
          if (status === 401 || status === 400) {
            triggerToast(detail, "warning");
          }
        }
      });
  };

  const handleFetchCategories = () => {
    setIsfetchingCategories(true);
    setRequestLoaderProgress(40);
    getCategoryList()
      .then((response) => {
        setIsfetchingCategories(false);
        setRequestLoaderProgress(100);
        setCategories(response.data?.data);
      })
      .catch((error) => {
        setIsfetchingCategories(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  const handleProductImageUpload = (event) => {
    setIsUploadingImage(true);
    setRequestLoaderProgress(40);

    if (event.target.files[0].size > 10000000) {
      return new Error("File size should be less than 10mb");
    }
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    uploadProductImage(formData)
      .then((response) => {
        setIsUploadingImage(false);
        setRequestLoaderProgress(100);
        const url = response.data?.data.url;
        setProductImage(url);
        triggerToast("Image uploaded successfully", "success");
      })
      .catch((error) => {
        setIsUploadingImage(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
        if (error.response.status === 422)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  return {
    productName,
    productCategory,
    productSku,
    productStockQuantity,
    productCostPrice,
    productSellingPrice,
    productProfit,
    productMinStockQuantity,
    productExpiryDate,
    productImage,
    productVariants,

    categories,

    isFetchingCategories,
    hasVariants,
    isAddingProduct,
    isUploadingImage,
    inputRef,

    setHasVariants,

    setProductName,
    setProductCategory,
    setProductSku,
    setProductStockQuantity,
    setProductCostPrice,
    setProductSellingPrice,
    setProductProfit,
    setProductStockMinStockQuantity,
    setProductExpiryDate,
    setProductVariants,

    handleAddVariantField,
    handleRemoveVariantField,

    handleAddProduct,
    handleProductImageUpload,
  };
};
