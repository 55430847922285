import { useEffect, useState, useContext } from "react";
import { getProductUnits } from "../../../services/inventory";
import { ToastContext, RequestLoader } from "../../../hooks/context";

const StockUnitInputLogic = ({ onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFetchinUnits, setIsFetchingUnits] = useState(false);
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(
    null || {
      id: 1,
      name: "Kilogram",
      unit: "kg",
    }
  );

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  useEffect(() => {
    handleFetchUnits();
  }, []);

  const handleFetchUnits = () => {
    setIsFetchingUnits(true);
    setRequestLoaderProgress(40);
    getProductUnits()
      .then((response) => {
        setIsFetchingUnits(false);
        setRequestLoaderProgress(100);
        setUnits(response.data?.data);
      })
      .catch((error) => {
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  const handleChange = (event) => {
    onChange({ ...event, selectedUnit });
  };

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelectUnit = (unit) => {
    setSelectedUnit({ ...unit });
  };

  return {
    isOpen,
    units,
    isFetchinUnits,
    selectedUnit,
    handleToggle,
    setIsOpen,
    handleSelectUnit,
    handleChange,
  };
};

export default StockUnitInputLogic;
