import "./Terminals.scss";
import primaryComponents from "../../../components/primaryComponents";
import { NavLink, Outlet, useNavigate } from "react-router-dom";

const Terminals = () => {
  const navigate = useNavigate();

  return (
    <div className="terminals">
      <div className="terminals__header">
        <h3 className="font-weight-semibold mr-5">Terminals</h3>
        <primaryComponents.Button
          onClick={() => navigate("/app/pos/add")}
          classNames={"btn btn--primary ml-auto"}
        >
          Request a Terminal
        </primaryComponents.Button>
      </div>
      <div className="terminals__tab">
        <NavLink
          className="terminals__tab__item"
          to="/app/pos?all_business=true"
          end
        >
          All Terminals
        </NavLink>
        <NavLink className="terminals__tab__item" to="request">
          Terminal Requests
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default Terminals;
