import "./TagsMultiSelect.scss";
import CreateableSelect from "react-select/creatable";
import TagsMultiSelectLogic from "./TagsMultiSelectLogic";

const styles = {
  control: (base) => ({
    ...base,
    border: "1px solid #7647EE",
    color: "#7647EE",
    borderRadius: "8px",
    textAlign: "center",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #7647ee !important",
      boxShadow: "none",
    },
    fontSize: "12px",
    alignItems: "center",
    flexWrap: "nowrap",
    paddingRight: "8px",
  }),
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#7647ee" : null,
      color: isFocused ? "#fff" : null,
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: "nowrap",
  }),
  multiValue: (styles) => ({
    ...styles,
    maxWidth: "70%",
    minWidth: "fit-content",
  }),
};

const TagsMultiSelect = ({
  value = null,
  onChange = () => {},
  options = [],
  idKey,
  nameKey,
  isDisabled = false,
  //onCreateOption = () => {},
}) => {
  const { items, selectedItem } = TagsMultiSelectLogic({
    options,
    valueKey: idKey,
    labelKey: nameKey,
    value,
  });

  return (
    <div className="multi-select-dropdown">
      {value.length > 0 && (
        <div className="tool-tip">
          {value.map(({ label }) => (
            <span key={label}>{label}</span>
          ))}
        </div>
      )}
      <CreateableSelect
        isMulti
        styles={styles}
        placeholder="Add new tag"
        components={{ DropdownIndicator: null, ClearIndicator: null }}
        options={items}
        onChange={(value, datas) => {
          onChange(value, datas);
        }}
        defaultValue={value === null ? null : selectedItem}
        isDisabled={isDisabled}
        isSearchable={false}
      />
    </div>
  );
};

export default TagsMultiSelect;
