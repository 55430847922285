import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AllCustomersLogic = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const { business_id, business_list } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const [isRefresh, setIsRefresh] = useState(false);
  const [showAllBusiness, setShowAllBusiness] = useState(
    queryParams.get("all_business")
  );
  const [newBusinessId, setNewBusinessId] = useState(business_id);
  const navigate = useNavigate();

  useEffect(() => {
    if (showAllBusiness === undefined) return;
    setIsRefresh(true);
  }, [showAllBusiness, newBusinessId]);

  const filterBaseOnAccount = (businessId) => {
    if (businessId === "all") {
      setShowAllBusiness("true");
      navigate("/app/customers?all_business=true");
    } else {
      setNewBusinessId(businessId);
      setShowAllBusiness(false);
      navigate(`/app/customers?business_id=${businessId}`);
    }
  };

  return {
    business_list,
    isRefresh,
    setIsRefresh,
    showAllBusiness,
    filterBaseOnAccount,
    newBusinessId,
  };
};
export default AllCustomersLogic;
