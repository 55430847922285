import "./ShopDetails.scss";
import { Controller } from "react-hook-form";
import primaryComponents from "../../../components/primaryComponents";
import secondaryComponents from "../../../components/secondaryComponents";
import ShopDetailsValidation from "./ShopDetailsValidation";
import ShopDetailsLogic from "./ShopDetailsLogic";

const ShopDetails = () => {
  const {
    storeAddress,
    storeName,
    subAccount,
    isFetchingStoreDetails,
    business_list,
    storeDetails,
    isUpdatingStore,
    vat,
    setVat,
    setStoreAddress,
    setStoreName,
    setSubAccount,
    onSubmit,
  } = ShopDetailsLogic();

  const {
    handleSubmit,
    control,
    register,
    trigger,
    formState: { errors },
  } = ShopDetailsValidation({ storeAddress, storeName, subAccount });

  return (
    <secondaryComponents.LoaderHelper
      isLoading={isFetchingStoreDetails}
      classNames={"mt-3"}
    >
      <form className="shop-details" onSubmit={handleSubmit(onSubmit)}>
        <h4 className="font-weight-semibold mb-3">Store Details</h4>
        <Controller
          name="storeName"
          control={control}
          defaultValue={storeName}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              name="storeName"
              label="Store Name"
              placeholder={storeDetails.name}
              isRequired={true}
              value={storeDetails.name || storeName}
              onChange={({ target: { value } }) => {
                onChange(value);
                setStoreName(value);
              }}
              errorMessage={errors.storeName}
              onKeyUp={() =>
                errors.storeName !== undefined && trigger("storeName")
              }
              register={register}
            />
          )}
        />
        <Controller
          name="storeAddress"
          control={control}
          defaultValue={storeDetails.address}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              placeholder={storeDetails.address}
              name="storeAddress"
              label="Store Address"
              isRequired={true}
              value={storeAddress}
              onChange={({ target: { value } }) => {
                onChange(value);
                setStoreAddress(value);
              }}
              errorMessage={errors.storeAddress}
              onKeyUp={() =>
                errors.storeAddress !== undefined && trigger("storeAddress")
              }
              register={register}
            />
          )}
        />
        <Controller
          name="subAccount"
          control={control}
          defaultValue={subAccount}
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              name="subAccount"
              label="Settlement Account"
              isRequired={true}
              value={storeDetails.subaccount || subAccount}
              onChange={(value) => {
                onChange(value);
                setSubAccount(value);
              }}
              options={business_list}
              nameKey="tag"
              idKey="id"
              errorMessage={errors.subAccount}
            />
          )}
        />
        <Controller
          name="vat"
          control={control}
          defaultValue={vat}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              name="vat"
              label="VAT Rate(%)"
              value={vat}
              onChange={({ target: { value } }) => {
                onChange(value);
                setVat(value);
              }}
              type="number"
              min="0"
              max="100"
              step="0.01"
              errorMessage={errors.vat}
              onKeyUp={() => errors.vat !== undefined && trigger("vta")}
              register={register}
            />
          )}
        />
        <div className="mt-3">
          <primaryComponents.Button
            classNames={"btn btn--primary"}
            isLoading={isUpdatingStore}
            loadingText="Saving..."
          >
            Update
          </primaryComponents.Button>
        </div>
      </form>
    </secondaryComponents.LoaderHelper>
  );
};

export default ShopDetails;
