import { useState, useEffect, useContext } from "react";
import { getSubAccounts } from "../../../../services/business";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import { getRandomIntInclusive } from "../../../../helpers";

const SubAccountsLogic = () => {
  const [isFetchingAccounts, setIsFetchingAccounts] = useState(false);
  const [subAccountsList, setSubAccountsList] = useState([]);
  const [openEditWidget, setOpenEditWidget] = useState(false);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);

  useEffect(() => {
    getSubAccountsList();
  }, []);

  const getSubAccountsList = () => {
    setIsFetchingAccounts(true);
    setRequestLoaderProgress(getRandomIntInclusive(20, 40));
    getSubAccounts()
      .then((response) => {
        setIsFetchingAccounts(false);
        setRequestLoaderProgress(100);
        setSubAccountsList(response.data.data);
      })
      .catch((error) => {
        setIsFetchingAccounts(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  return {
    isFetchingAccounts,
    subAccountsList,
    openEditWidget,
    setOpenEditWidget,
    getSubAccountsList,
    triggerToast,
  };
};
export default SubAccountsLogic;
