import "./GiftcardForm.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import { Controller } from "react-hook-form";
import { useGiftcardFormValidation } from "./GiftcardFormValidation";
import { useGiftcardFormLogic } from "./GiftcardFormLogic";
import { useEffect } from "react";
import CampaignListLogic from "../CampaignList/CampaignListLogic";

const GiftcardForm = () => {
  const { campaigns, isFetchingCampaigns } = CampaignListLogic();
  const {
    giftcardPoints,
    giftcardAmount,
    giftcardName,
    giftcardValidity,
    isSubmitting,
    // showNairaEquivalent,
    // setShowNairaEquivalent,
    setGiftcardAmount,
    setGiftcardName,
    setGiftcardPoints,
    setGiftcardValidity,
    handleCreateGiftCard,
  } = useGiftcardFormLogic({ campaigns });

  const {
    control,
    formState: { errors },
    trigger,
    handleSubmit,
    setError,
    clearErrors,
  } = useGiftcardFormValidation({
    giftcardName,
    amount: giftcardAmount,
    validity_preiod: giftcardValidity,
    // showNairaEquivalent: showNairaEquivalent,
  });

  useEffect(() => {
    window.setGiftCardError = setError;
    window.clearGiftCardErrors = clearErrors;
  }, []);

  return (
    <secondaryComponents.LoaderHelper
      isLoading={isFetchingCampaigns}
      classNames={"mt-3"}
    >
      <form
        onSubmit={handleSubmit(handleCreateGiftCard)}
        className="giftcard-form"
      >
        <div className="giftcard-form__fields-group">
          <div>
            <label
              className="default-font-size font-weight-semibold mb-1"
              htmlFor="giftcardName"
            >
              Giftcard Name
            </label>
            <Controller
              control={control}
              name="giftcardName"
              defaultValue={giftcardName}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  name="giftcardName"
                  type="text"
                  placeholder="Enter your chosen giftcard name"
                  classNames="white"
                  isRequired={true}
                  value={giftcardName}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setGiftcardName(value);
                  }}
                  errorMessage={errors.giftcardName}
                  onKeyUp={() =>
                    errors.giftcardName !== undefined && trigger("giftcardName")
                  }
                />
              )}
            />
          </div>
          <div>
            <label
              className="default-font-size font-weight-semibold mb-1"
              htmlFor="gitfcardAmount"
            >
              Amount
            </label>
            <Controller
              control={control}
              defaultValue={giftcardAmount}
              name="amount"
              render={({ field: { onChange } }) => (
                <primaryComponents.CurrencyInputField
                  name="amount"
                  type="text"
                  classNames="white"
                  placeholder="Enter amount"
                  isRequired={true}
                  errorMessage={errors.amount}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setGiftcardAmount(value);
                    setGiftcardPoints(
                      Math.ceil(
                        Number(value * campaigns[0]?.campaign_point_ratio)
                      ).toFixed(2)
                    );
                  }}
                  onKeyUp={() =>
                    errors.amount !== undefined && trigger("amount")
                  }
                />
              )}
            />
          </div>
        </div>
        <div className="giftcard-form__fields-group">
          <div>
            <label
              className="default-font-size font-weight-semibold mb-1"
              htmlFor="giftcardPoints"
            >
              Required Number of Points
            </label>
            <Controller
              control={control}
              name="points"
              defaultValue={giftcardPoints}
              render={({ field: { onChange } }) => (
                <primaryComponents.PointsInputField
                  naira_value={giftcardAmount}
                  name="points"
                  type="number"
                  placeholder="Enter number"
                  classNames="white"
                  isRequired={true}
                  isDisabled={true}
                  value={giftcardPoints}
                  errorMessage={errors.points}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setGiftcardPoints(value);
                  }}
                  onKeyUp={() =>
                    errors.points !== undefined && trigger("points")
                  }
                />
              )}
            />
          </div>
          <div>
            <label
              className="default-font-size font-weight-semibold mb-1"
              htmlFor="giftcardValidity"
            >
              Validity Period
            </label>
            <Controller
              control={control}
              name="validity_preiod"
              defaultValue={giftcardValidity}
              render={({ field: { onChange } }) => (
                <primaryComponents.DaysInputField
                  name="validity_period"
                  type="text"
                  placeholder="Enter number"
                  classNames="white"
                  isRequired={true}
                  errorMessage={errors.validity_preiod}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setGiftcardValidity(value);
                  }}
                  onKeyUp={() =>
                    errors.validity_preiod !== undefined &&
                    trigger("validity_preiod")
                  }
                />
              )}
            />
          </div>
        </div>
        {/* <div className="mt-3">
          <label
            className="default-font-size font-weight-normal mb-1 d-flex align-items-center"
            htmlFor="showNairaEquivalent"
          >
            <div className="mr-2">
              <Controller
                name="showNairaEquivalent"
                control={control}
                defaultValue={showNairaEquivalent}
                render={({ field: { onChange } }) => (
                  <primaryComponents.Checkbox
                    name="showNairaEquivalent"
                    id="showNairaEquivalent"
                    isChecked={showNairaEquivalent}
                    onChange={(value) => {
                      onChange(value);
                      setShowNairaEquivalent(value);
                    }}
                  />
                )}
              />
            </div>
            Display giftcard naira equivalent
          </label>
        </div> */}
        <div className="giftcard-form__action">
          <primaryComponents.Button
            type="submit"
            isLoading={isSubmitting}
            loadingText="Creating giftcard"
            isDisabled={isSubmitting}
            classNames={"btn btn--primary"}
          >
            Create Giftcard
          </primaryComponents.Button>
        </div>
      </form>
    </secondaryComponents.LoaderHelper>
  );
};

export default GiftcardForm;
