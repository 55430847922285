import "./WalletDetailWidget.scss";
import { useSelector } from "react-redux";
import primaryComponents from "../../primaryComponents";
import Icon from "../../../assets/icons";

const WalletDetailWidget = () => {
  const { account_number, account_name, bank_name } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails
  );

  return (
    <div className="wallet-details__widget">
      <div className="wallet-details__widget-top">
        <p className="dark--text">
          {bank_name} - <span className="font-weight-bold">{account_name}</span>
        </p>
      </div>
      <div className="wallet-details__widget-bottom">
        <p className="font-weight-bold dark--text">{account_number}</p>
        <primaryComponents.Button classNames={"btn btn--outline btn__copy"}>
          <img className="mr-2" src={Icon.copy} alt="" />
          Copy
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default WalletDetailWidget;
