import "./CustomerSubLayout.scss";
import { NavLink, Outlet } from "react-router-dom";
import primaryComponents from "../../components/primaryComponents";
import { useState } from "react";
import secondaryComponents from "../../components/secondaryComponents";

const CustomerSubLayout = () => {
  const [openAddCustomerWidget, setOpenAddCustomerWidget] = useState(false);
  const [openGiftCardWidget, setOpenGiftCardWidget] = useState(false);

  return (
    <div className="customers-layout">
      <div className="customers-layout__header">
        <h3 className="font-weight-semibold">Customers</h3>
        <div className="customers-layout__buttons d-flex ml-auto">
          <primaryComponents.Button
            classNames={"btn btn--outline"}
            onClick={() => setOpenGiftCardWidget(true)}
          >
            Gift card redemption
          </primaryComponents.Button>
          <primaryComponents.Button
            classNames={"btn btn--primary"}
            onClick={() => setOpenAddCustomerWidget(true)}
          >
            <span className="mr-1 mb-1" style={{ fontSize: "22px" }}>
              +
            </span>
            <span>Add Customer</span>
          </primaryComponents.Button>
        </div>
      </div>

      <div className="customers-layout__links d-flex">
        <NavLink
          className={"customers-layout__links__item"}
          to="/app/loyalty/customers"
          end
        >
          All customers
        </NavLink>
        <NavLink
          className={"customers-layout__links__item"}
          to="/app/loyalty/customers/history"
        >
          History
        </NavLink>
      </div>
      {}
      <div>
        <Outlet />
      </div>
      <secondaryComponents.SideModal
        isActive={openAddCustomerWidget}
        closeModal={() => setOpenAddCustomerWidget(false)}
      >
        <secondaryComponents.AddCustomerWidget
          closeWidget={() => setOpenAddCustomerWidget(false)}
        />
      </secondaryComponents.SideModal>
      <secondaryComponents.SideModal
        isActive={openGiftCardWidget}
        closeModal={() => setOpenGiftCardWidget(false)}
      >
        <secondaryComponents.GiftCardWidget
          closeWidget={() => setOpenGiftCardWidget(false)}
        />
      </secondaryComponents.SideModal>
    </div>
  );
};

export default CustomerSubLayout;
