import { useQuery, useMutation } from "react-query";
import { useContext, useState } from "react";
import { ToastContext, RequestLoader } from "../../../../hooks/context";
import {
  getLoyaltyCustomerDetails,
  toggleCustomerLoyalty,
} from "../../../../services/customer";
import { useParams } from "react-router-dom";
import { getRandomIntInclusive } from "../../../../helpers";

const useLoyaltyCustomerDetails = () => {
  const param = useParams();
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const [isEnabled, setIsEnabled] = useState(true);
  const { data: customerDetails, isLoading } = useQuery(
    ["getLoyaltyCustomerDetails", param.id],
    () => getLoyaltyCustomerDetails(param.id).then((res) => res?.data.data),
    {
      isEnabled: isEnabled,
      onSuccess: () => setIsEnabled(false),
      onError: (error) => {
        setIsEnabled(false);
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      },
    }
  );
  const { mutate: switchLoyalty } = useMutation((id) =>
    toggleCustomerLoyalty(id)
  );

  const toggleLoyalty = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 30));
    switchLoyalty(param.id, {
      onSuccess: () => {
        setRequestLoaderProgress(100);
        setIsEnabled(true);
      },
      onError: (error) => {
        setRequestLoaderProgress(100);
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      },
    });
  };

  return { isLoading, customerDetails, toggleLoyalty };
};
export default useLoyaltyCustomerDetails;
