import { useState, useEffect, useContext } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import {
  getOrderDetails,
  generateOrderReceipt,
} from "../../../services/inventory";

const SalesTransactionWidgetLogic = ({ selectedOrder, closeWidget }) => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [isFetchingOrder, setIsFetchingOrder] = useState(false);
  const [isGeneratingReceipt, setIsGeneratingReceipt] = useState(false);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  useEffect(() => {
    handleFetchOrder(selectedOrder);
  }, []);

  const handleFetchOrder = (orderId) => {
    setIsFetchingOrder(true);
    setRequestLoaderProgress(30);
    getOrderDetails(orderId)
      .then((response) => {
        setIsFetchingOrder(false);
        setRequestLoaderProgress(100);
        setOrderDetails(response.data?.data);
      })
      .catch((error) => {
        setIsFetchingOrder(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  const handleGenerateReceipt = () => {
    setIsGeneratingReceipt(true);
    setRequestLoaderProgress(40);
    generateOrderReceipt(orderDetails.id)
      .then((response) => {
        const url = response.data?.data?.url;
        setIsGeneratingReceipt(false);
        setRequestLoaderProgress(100);
        triggerToast("Receipt generated successfully", "success");
        window.open(url, "_blank");
        closeWidget(false);
      })
      .catch((error) => {
        setIsGeneratingReceipt(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  return {
    isFetchingOrder,
    orderDetails,
    isGeneratingReceipt,
    handleGenerateReceipt,
  };
};

export default SalesTransactionWidgetLogic;
