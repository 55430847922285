import { useSelector } from "react-redux";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUrlQuerysection } from "../../../helpers";

const DashboardLogic = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const { verification_status } = useSelector(
    (state) => state.dashboardReducer.dashboardDetails
  );
  const { first_name, business_id, business_list, business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const [isRefresh, setIsRefresh] = useState(false);
  const [showAllBusiness, setShowAllBusiness] = useState(
    queryParams.get("all_business")
  );
  const [newBusinessId, setNewBusinessId] = useState(
    queryParams.get("business_id") || business_id
  );
  const [openTransferWidget, setOpenTransferWidget] = useState(false);
  const [startDate] = useState(queryParams.get("start_date"));
  const [endDate] = useState(queryParams.get("end_date"));
  const location = useLocation();
  const navigate = useNavigate();

  const filterBaseOnAccount = (businessId) => {
    let newParams = queryParams;
    newParams.delete("business_id");
    newParams.delete("all_business");

    if (businessId === "all") {
      newParams.set("all_business", "true");
      setShowAllBusiness("true");
    } else {
      newParams.set("business_id", businessId);
      setNewBusinessId(businessId);
      setShowAllBusiness(false);
    }

    let newQueries = "?";
    newParams.forEach((item, key) => {
      newQueries =
        newQueries === "?"
          ? newQueries + `${key}=${item}`
          : newQueries + `&${key}=${item}`;
    });
    navigate(`${location.pathname}${newQueries}`);
    setIsRefresh(true);
  };

  const setDateFilter = (data) => {
    let queries = getUrlQuerysection();
    let prevStartDate = queryParams.get("start_date");
    let prevEndDate = queryParams.get("end_date");
    let newQueries = queries;

    if (!queries.includes("?")) {
      navigate(`?start_date=${data.startDate}&end_date=${data.endDate}`);
    } else {
      if (prevStartDate === null) {
        newQueries = `${newQueries}&start_date=${data.startDate}&end_date=${data.endDate}`;
      } else {
        let splitedQuery = newQueries.split(`start_date=${prevStartDate}`);
        splitedQuery =
          splitedQuery[0] + `start_date=${data.startDate}` + splitedQuery[1];
        newQueries = `${splitedQuery}`;
        splitedQuery = newQueries.split(`end_date=${prevEndDate}`);
        splitedQuery =
          splitedQuery[0] + `end_date=${data.endDate}` + splitedQuery[1];
        newQueries = `${splitedQuery}`;
      }
      navigate(newQueries);
    }
    setIsRefresh(true);
  };

  return {
    verification_status,
    first_name,
    showAllBusiness,
    setShowAllBusiness,
    newBusinessId,
    setNewBusinessId,
    setIsRefresh,
    isRefresh,
    business_list,
    filterBaseOnAccount,
    setDateFilter,
    openTransferWidget,
    setOpenTransferWidget,
    business_role,
    startDate,
    endDate,
  };
};

export default DashboardLogic;
