import { useState } from "react";

const ProductCategoryLogic = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [categoryCount, setCategoryCount] = useState(0);

  return {
    isModalOpen,
    setIsModalOpen,
    isRefresh,
    setIsRefresh,
    categoryCount,
    setCategoryCount,
  };
};

export default ProductCategoryLogic;
