import { useContext, useRef, useState } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import {
  updateProductDetails,
  uploadProductImage,
} from "../../../services/inventory";
import { useNavigate } from "react-router-dom";

export const useEditSingleProductLogic = ({ productDetails, categories }) => {
  const navigate = useNavigate();

  const [isUpdatingProduct, setIsUpdatingProduct] = useState(false);

  const serializeProductCategory = (category_id) => {
    if (category_id && categories.length) {
      const category = categories.find(({ id }) => id === category_id);
      return { value: category?.id, label: category?.name };
    }
  };

  const [productName, setProductName] = useState(
    productDetails?.name !== undefined ? productDetails?.name : ""
  );
  const [productSku, setProductSku] = useState(
    productDetails?.sku !== undefined ? productDetails?.sku : ""
  );
  const [productCategory, setProductCategory] = useState(
    productDetails?.category !== undefined
      ? serializeProductCategory(productDetails?.category)?.label
      : ""
  );
  const [productCostPrice, setProductCostPrice] = useState(
    productDetails?.cost_price !== undefined ? productDetails?.cost_price : ""
  );
  const [productSellingPrice, setProductSellingPrice] = useState(
    productDetails?.selling_price !== undefined
      ? productDetails?.selling_price
      : ""
  );
  const [productProfit, setProductProfit] = useState("");
  const [productStockQuantity, setProductStockQuantity] = useState(
    productDetails?.stock_quantity !== undefined
      ? productDetails?.stock_quantity
      : ""
  );
  const [productMinStockQuantity, setProductStockMinStockQuantity] = useState(
    productDetails?.minimum_stock_quantity !== undefined
      ? productDetails?.minimum_stock_quantity
      : ""
  );
  const [productExpiryDate, setProductExpiryDate] = useState(
    productDetails?.expiry_date !== undefined ? productDetails?.expiry_date : ""
  );
  const [productVariants, setProductVariants] = useState(
    productDetails?.variants !== undefined ? productDetails?.variants : []
  );
  const [hasVariants, setHasVariants] = useState(
    productDetails?.variants.length ? true : false
  );
  const [status, setStatus] = useState(productDetails?.status);
  const [productImage, setProductImage] = useState(
    productDetails?.image_url || ""
  );
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const inputRef = useRef();

  const handleUpdateProduct = () => {
    setIsUpdatingProduct(true);
    setRequestLoaderProgress(30);
    let payload = {
      name: productName,
      cost_price: productCostPrice,
      selling_price: productSellingPrice,
      stock_quantity: productStockQuantity,
      minimum_stock_quantity: productMinStockQuantity,
      status: status,
      image_url: productImage,
      expiry_date: productExpiryDate,
      variants: productVariants,
    };

    if (payload.expiry_date === "" || payload.expiry_date === null)
      delete payload["expiry_date"];

    updateProductDetails(productDetails.id, payload)
      .then(() => {
        setIsUpdatingProduct(false);
        setRequestLoaderProgress(100);
        triggerToast("Product updated successfully", "success");
        navigate("/app/products");
      })
      .catch((error) => {
        setIsUpdatingProduct(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  const handleProductImageUpload = (event) => {
    setIsUploadingImage(true);
    setRequestLoaderProgress(40);

    if (!event.target.files[0]) return;

    if (event.target.files[0].size > 10000000) {
      return new Error("File size should be less than 10mb");
    }
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    uploadProductImage(formData)
      .then((response) => {
        setIsUploadingImage(false);
        setRequestLoaderProgress(100);
        const url = response.data?.data.url;
        setProductImage(url);
        triggerToast("Image uploaded successfully", "success");
      })
      .catch((error) => {
        setIsUploadingImage(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
        if (error.response.status === 422)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const handleAddVariantField = () => {
    const _variant = {
      option: "",
      value: "",
      sku: "",
      selling_price: 0,
      stock_quantity: 1,
      minimum_stock_quantity: 1,
      expiry_date: "",
      cost_price: 0,
    };

    setProductVariants((prev) => [...prev, { ..._variant }]);
  };

  const handleRemoveVariantField = (fieldIndex) => {
    const _variants = [...productVariants];
    const variants = _variants.filter((_, index) => fieldIndex !== index);
    setProductVariants(variants);
  };

  return {
    productName,
    productSku,
    productCategory,
    productCostPrice,
    productSellingPrice,
    productStockQuantity,
    productMinStockQuantity,
    productExpiryDate,
    status,
    isUpdatingProduct,
    productImage,
    isUploadingImage,
    inputRef,
    productProfit,
    isEdited,
    hasVariants,
    productVariants,
    productDetails,
    setProductVariants,
    setHasVariants,
    setIsEdited,
    setProductProfit,
    setStatus,
    setProductName,
    setProductSku,
    setProductCategory,
    setProductCostPrice,
    setProductSellingPrice,
    setProductStockQuantity,
    setProductStockMinStockQuantity,
    setProductExpiryDate,
    handleUpdateProduct,
    handleProductImageUpload,
    handleAddVariantField,
    handleRemoveVariantField,
  };
};
