import secondaryComponents from "../../../components/secondaryComponents";
import "./StoreInventory.scss";

const StoreInventory = () => {
  return (
    <div className="store-inventory">
      <div className="store-inventory__content">
        <secondaryComponents.StoreInventoryTable />
      </div>
    </div>
  );
};

export default StoreInventory;
