// import { Route } from "react-router-dom";
import OnboardingGuardLogic from "./OnboardingGuardLogic";

const OnboardingGuard = ({ children }) => {
  const { grantAccess } = OnboardingGuardLogic();

  return <>{grantAccess && children}</>;
};

export default OnboardingGuard;
