/* eslint-disable react/jsx-key */
import "./StoreInventoryTable.scss";
import { Fragment } from "react";
import primaryComponents from "../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StoreInventoryTableLogic from "./StoreInventoryTableLogic";

const StoreInventoryTable = () => {
  const { instance } = StoreInventoryTableLogic();
  const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="store-inventory-table">
      <Fragment>
        <div className="store-inventory-table__header">
          <div className="store-inventory-table__header__search-section">
            <primaryComponents.SearchField
              placeholder={"Search for a transaction"}
            />
          </div>
          <primaryComponents.Button classNames="btn btn--outline-gray ml-auto">
            Filter{" "}
            <FontAwesomeIcon
              className="ml-3"
              icon="angle-down"
              style={{ fontSize: "16px" }}
            />
          </primaryComponents.Button>
          <primaryComponents.Button classNames={"btn btn--outline"}>
            Download
          </primaryComponents.Button>
        </div>
        <div className="store-inventory-table__content">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="font-weight-semibold"
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps({ key: i })}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Fragment>
    </div>
  );
};

export default StoreInventoryTable;
