import "./WalletOption.scss";
import useWalletOptionLogic from "./WalletOptionLogic";

const WalletOption = ({
  title,
  subtitle,
  name,
  onChange,
  value,
  checked,
  isDisabled,
}) => {
  const walletOption = useWalletOptionLogic({ onChange });
  return (
    <label
      htmlFor={title}
      className={"wallet__option " + (checked ? "checked" : "")}
    >
      <input
        type="radio"
        name={name}
        id={title}
        value={value}
        onChange={walletOption.handleWalletOptionChange}
        checked={checked}
        disabled={isDisabled}
      />
      <div className="wallet__option__indicator"></div>
      <div className="wallet__option__placeholder">
        <p className="dark--text">{title}</p>
        <span className="gray--text">{subtitle}</span>
      </div>
    </label>
  );
};

export default WalletOption;
