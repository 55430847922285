import "./PendingTransfersTable.scss";
import PendingTransfersTableLogic from "./PendingTransfersTableLogic";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import Images from "../../../assets/images";

const PendingTransfersTable = ({
  isRefreshTransfers,
  setIsRefreshTransfers,
  setNumberOfPendingTransfers,
}) => {
  const {
    instance,
    isFetchingTransfers,
    transfers,
    gotoPage,
    searchTnx,
    searchValue,
    transferPageDetails,
    setShowTransferApprovalModal,
    showTransferApprovalModal,
    transferOnReview,
    setTransferOnReview,
    business_role,
    fetchTransfers,
    setDateFilter,
    startDate,
    endDate,
  } = PendingTransfersTableLogic({
    isRefreshTransfers,
    setIsRefreshTransfers,
    setNumberOfPendingTransfers,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="pending-transfers-table">
      <div className="pending-transfers-table__header">
        {/* header section */}
        <div className="search-section">
          <primaryComponents.SearchField
            trigger={(value) => searchTnx(value)}
            value={searchValue}
            placeholder="Search transfers by ( beneficiary name, account number or bank name)"
          />
        </div>

        {/* date filter */}
        <primaryComponents.DateRangeInput
          onChange={setDateFilter}
          startDate={startDate}
          endDate={endDate}
        />
      </div>

      {/* table section */}
      <div className="table-section">
        <secondaryComponents.LoaderHelper
          isLoading={isFetchingTransfers}
          classNames="mt-5"
        >
          {transfers.length > 0 ? (
            <>
              {/* show on desktop */}
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <th
                            className="font-weight-semibold"
                            {...column.getHeaderProps()}
                          >
                            {column.render("Header")}
                          </th>
                        );
                      })}
                      {business_role !== "BUSINESS_MANAGER" && (
                        <th className="font-weight-semibold">Action</th>
                      )}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...row.getRowProps({ key: i })}>
                        {row.cells.map((cell) => (
                          // eslint-disable-next-line react/jsx-key
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                        {business_role !== "BUSINESS_MANAGER" && (
                          <td>
                            <primaryComponents.Button
                              classNames="btn btn--outline"
                              style={{
                                height: "30px",
                                fontSize: "12px",
                                padding: "0 15px",
                              }}
                              onClick={() => {
                                setTransferOnReview(transfers[i]);
                                setShowTransferApprovalModal(true);
                              }}
                            >
                              Review
                            </primaryComponents.Button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {/* display on mobile */}
              <div className="mobile-table">
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <div
                      className="mobile-table__row"
                      {...row.getRowProps({ key: i })}
                    >
                      {row.cells.map((cell, j) => (
                        // eslint-disable-next-line react/jsx-key
                        <div
                          className="row-item"
                          {...cell.getCellProps({ key: j })}
                        >
                          <h6 className="row-item__title">
                            {cell.column.Header}
                          </h6>
                          <p className="row-item__value">
                            {cell.render("Cell")}
                          </p>
                        </div>
                      ))}
                      <div className="row-item">
                        <h6 className="row-item__title">Action</h6>
                        <p className="row-item__value">
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            className="purple--text"
                            onClick={() => {
                              setTransferOnReview(transfers[i]);
                              setShowTransferApprovalModal(true);
                            }}
                          >
                            Review
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            // show if transaction is empty
            <div className="text-center mt-5">
              <img
                width="250px"
                height="262px"
                src={Images.tnxEmptyState}
                alt="empty state"
              />
              <h4>You have no transfers</h4>
              <p>When you do, they would appear here.</p>
            </div>
          )}
        </secondaryComponents.LoaderHelper>
      </div>

      {/* pagination section */}
      {transfers.length > 0 && (
        <secondaryComponents.Pagination
          totalPageNumber={transferPageDetails.last_page}
          handlePageClick={(value) => gotoPage(value.selected + 1)}
          currentPage={transferPageDetails.current_page}
        />
      )}

      {/* transfer approval modal */}
      {showTransferApprovalModal && (
        <secondaryComponents.TransferApprovalModal
          showApprovalModal={showTransferApprovalModal}
          setShowApprovalModal={setShowTransferApprovalModal}
          transferDetails={transferOnReview}
          refreshTable={fetchTransfers}
        />
      )}
    </div>
  );
};
export default PendingTransfersTable;
