import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import {
  addSettlementAccount,
  resolveSettlementAccountNumber,
  getSettlementBankList,
  getAvailableSubaccounts,
} from "../../../services/settlements";
import SettlementsLogic from "../../../pages/appPages/Settings/Settlements/SettlementsLogic";

const AddSettlementAccountLogic = (
  setIsOpen,
  handleFetchSettlementAccounts
) => {
  const [account_currency, setAccountCurrency] = useState("NGN");
  const [bank, setBank] = useState("");
  const [bankList, setBankList] = useState([]);
  const [account_number, setAccountNumber] = useState("");
  const [is_primary, setIsPrimary] = useState(false);
  const [isAddingAccount, setIsAddingAccount] = useState(false);
  const [isResolvingAccount, setIsResolvingAccount] = useState(false);
  const [isFetchingBankList, setIsFetchingBankList] = useState(false);
  const [isValidAccountNumber, setIsValidAccountNumber] = useState(false);
  const [accountDetails, setAccountDetails] = useState(null);
  const [businessId, setBusinessId] = useState([]);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { handleSettlementType } = SettlementsLogic();
  const [business_list, setBusinessList] = useState([]);

  // const { business_list } = useSelector(
  //     (state) => state.profileDetailsReducer.profileDetails
  // );

  useEffect(() => {
    fetchBankList();
    fetchAvailableSubaccounts();
  }, []);

  const handleResolveAccountNumber = (account_number, bank_code) => {
    setIsResolvingAccount(true);
    resolveSettlementAccountNumber({ account_number, bank_code })
      .then((response) => {
        setIsValidAccountNumber(true);
        setIsResolvingAccount(false);
        setAccountDetails(response.data.data);
        window.clearAccountErrors("account_number");
      })
      .catch((error) => {
        setIsResolvingAccount(false);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
        if (error.response.status === 422) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  const handleAddSettlementAccount = () => {
    setIsAddingAccount(true);
    setRequestLoaderProgress(40);

    addSettlementAccount({
      currency: account_currency,
      bank_code: bank,
      nuban: account_number,
      is_primary,
      businesses_id: businessId,
    })
      .then(() => {
        handleSettlementType("bank_account");
        setIsAddingAccount(false);
        setRequestLoaderProgress(100);
        handleFetchSettlementAccounts();
        setIsOpen(false);
        triggerToast("Account added successfully", "success");
      })
      .catch((error) => {
        setIsAddingAccount(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  const fetchBankList = () => {
    setIsFetchingBankList(true);
    setRequestLoaderProgress(40);

    getSettlementBankList()
      .then((response) => {
        setIsFetchingBankList(false);
        setRequestLoaderProgress(100);
        setBankList(response.data?.data);
      })
      .catch((error) => {
        setIsFetchingBankList(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          return triggerToast(error.response.detail, "warning");
        }
      });
  };

  const fetchAvailableSubaccounts = () => {
    setRequestLoaderProgress(40);

    getAvailableSubaccounts()
      .then((response) => {
        setRequestLoaderProgress(100);
        setBusinessList(response.data?.data);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          return triggerToast(error.response.detail, "warning");
        }
      });
  };

  return {
    account_currency,
    bank,
    account_number,
    is_primary,
    isAddingAccount,
    isResolvingAccount,
    isFetchingBankList,
    bankList,
    accountDetails,
    isValidAccountNumber,
    businessId,
    business_list,
    setBusinessId,
    setIsPrimary,
    setAccountCurrency,
    setAccountNumber,
    setBank,
    handleAddSettlementAccount,
    handleResolveAccountNumber,
  };
};

export default AddSettlementAccountLogic;
