import { toggleOfferStatus } from "../../../services/campaign";
import { useContext } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";

const OfferCardLogic = ({ offerDetails }) => {
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const toggleOffer = () => {
    setRequestLoaderProgress(0);
    toggleOfferStatus(offerDetails.id)
      .then((response) => {
        setRequestLoaderProgress(100);
        triggerToast(response.data.detail, "success");
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  return { toggleOffer };
};

export default OfferCardLogic;
