import { useContext, useEffect, useMemo, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useTable } from "react-table";
import { getOrderList } from "../../../services/inventory";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import {
  formatCurrency,
  getRandomIntInclusive,
  getUrlQuerysection,
} from "../../../helpers";
import { useNavigate, useSearchParams } from "react-router-dom";

const SalesTransactionsTableLogic = () => {
  const [openSalesTransactionWidget, setOpenSalesTransactionWidget] =
    useState(false);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const [isFetchingOrders, setIsFetchingOrders] = useState(false);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [orderDetails, setOrderDetails] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const [search, setSearch] = useSearchParams();
  const [searchValue] = useState(
    queryParams.get("query") !== null ? [queryParams.get("query")] : ""
  );
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: "Order Number",
        accessor: "id",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              color: "#7647EE",
              height: "28px",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedOrder(value);
              setOpenSalesTransactionWidget(true);
            }}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "Order Date",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => (
          <span>
            {value === null || value === undefined
              ? "N/A"
              : new Date(value).toLocaleDateString("en-GB")}
          </span>
        ),
      },
      {
        Header: "Payment Type",
        accessor: "payment_method",
        Cell: ({ cell: { value } }) => (
          <span>{value === null ? "N/A" : value}</span>
        ),
      },
      {
        Header: "Amount",
        accessor: "total_amount",
        Cell: ({ cell: { value } }) => <span>{formatCurrency(value)}</span>,
      },
      {
        Header: "Customer",
        accessor: "customer",
        Cell: ({ cell: { value } }) =>
          value === null ? "N/A" : value?.full_name,
      },
      {
        Header: "Cashier",
        accessor: "cashier",
        Cell: ({ cell: { value } }) => (
          <span>
            {value === null || value === undefined ? "N/A" : value?.first_name}
          </span>
        ),
      },
      {
        Header: "Store",
        accessor: "store",
        Cell: ({ cell: { value } }) => <span>{value?.name || "N/A"}</span>,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              display: "block",
              ...handleRenderStatusIndicator(value),
              borderRadius: "8px",
              padding: "10px 16px",
              height: "28px",
              width: "100px",
              maxWidth: "100px",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            {value}
          </span>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    handleFetchOrders();
  }, []);

  const handleRenderStatusIndicator = (value) => {
    if (value === "COMPLETED") {
      return { color: "#19AE57", backgroundColor: "#E5FFF0" };
    } else if (value === "IN_PROGRESS") {
      return { color: "#F7AD3C", backgroundColor: "#FEF7EB" };
    } else if (value === "PLACED") {
      return { color: "#F7AD3C", backgroundColor: "#FEF7EB" };
    } else {
      return { color: "#E02020", backgroundColor: "#FFEEE9" };
    }
  };

  const handleFetchOrders = (showSpinner = true) => {
    if (showSpinner !== false) setIsFetchingOrders(true);
    if (showSpinner) setRequestLoaderProgress(getRandomIntInclusive(20, 50));
    const queries = getUrlQuerysection();
    getOrderList(queries)
      .then((response) => {
        setOrders(response.data?.data);
        setOrderDetails(response.data?.meta);
        setIsFetchingOrders(false);
        setRequestLoaderProgress(100);
      })
      .catch((error) => {
        setIsFetchingOrders(false);
        setRequestLoaderProgress(100);
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      });
  };

  const gotoPage = (pageNumber) => {
    let queries = getUrlQuerysection();
    let page = queryParams.get("page");
    if (page === null) {
      let symbol = queries.includes("?") ? "&" : "?";
      navigate(`${queries}${symbol}page=${pageNumber}`);
      return handleFetchOrders();
    }

    let splitedQuery = queries.split(`page=${page}`);
    splitedQuery = splitedQuery[0] + `page=${pageNumber}` + splitedQuery[1];
    navigate(`${splitedQuery}`);
    handleFetchOrders();
  };

  const handleSearchOrders = (value) => {
    queryParams.forEach((value, key) => {
      search.delete(key);
    });
    if (value.length !== 0) search.set("query", value);
    setSearch(search, { replace: true });
    handleFetchOrders();
  };

  const instance = useTable({ columns, data: orders });

  return {
    searchValue,
    openSalesTransactionWidget,
    setOpenSalesTransactionWidget,
    gotoPage,
    instance,
    isFetchingOrders,
    orders,
    selectedOrder,
    orderDetails,
    handleSearchOrders,
  };
};

export default SalesTransactionsTableLogic;
