import { useMemo, useState, useEffect, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { useTable } from "react-table";
import { useNavigate } from "react-router-dom";
import { fetchPendingRequest } from "../../../services/pos-terminals";
import { getRandomIntInclusive, getUrlQuerysection } from "../../../helpers";

const useTerminalRequestTable = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [terminalsRequest, setTerminalsRequest] = useState([]);
  const [searchValue] = useState(
    queryParams.get("q") !== null ? [queryParams.get("q")] : ""
  );
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const navigate = useNavigate();

  useEffect(() => {
    getPendingTerminalsRequest();
  }, []);

  const getPendingTerminalsRequest = () => {
    setIsLoading(true);
    setRequestLoaderProgress(getRandomIntInclusive(20, 50));
    let queries = getUrlQuerysection();
    fetchPendingRequest(queries)
      .then((response) => {
        setTerminalsRequest(response.data.data);
        setRequestLoaderProgress(100);
        setIsLoading(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsLoading(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const searchTerminal = (value) => {
    navigate(`/app/pos/request?q=${value}`);
    getPendingTerminalsRequest();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Request Date",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleString("en-us"),
      },
      {
        Header: "No of Requested Devices",
        accessor: "quantity",
      },
      {
        Header: "Requested By",
        accessor: "requested_by",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            className={`${
              value === "APPROVED"
                ? "success--text"
                : value === "PENDING"
                ? "pending--text"
                : "failed--text"
            }`}
          >
            {value}
          </span>
        ),
      },
    ],
    []
  );

  const instance = useTable({ columns, data: terminalsRequest });

  return {
    instance,
    isLoading,
    searchTerminal,
    searchValue,
    terminalsRequest,
  };
};

export default useTerminalRequestTable;
