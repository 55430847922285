import "./PercentageInputField.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PercentageInputField = ({
  placeholder = "",
  name = "",
  isRequired,
  label = null,
  isDisabled = false,
  errorMessage = undefined,
  onChange = () => {},
  value = "",
  register = () => {},
  onKeyUp = () => {},
  ...props
}) => {
  return (
    <div className="percentage-input-field-container">
      {label !== null && (
        <label htmlFor={name}>
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}
      <div
        className={`percentage-input-field-container__input-section ${
          isDisabled === true && "disabled"
        } ${errorMessage !== undefined && "error-border"}`}
      >
        <input
          type="text"
          name={name}
          id={name}
          placeholder={placeholder}
          disabled={isDisabled}
          {...register(name, { required: isRequired })}
          onChange={onChange}
          onKeyUp={onKeyUp}
          value={value}
          {...props}
        />
        <span>%</span>
      </div>
      <p
        className={`error-message text-danger ${
          errorMessage !== undefined ? "show" : ""
        }`}
      >
        <FontAwesomeIcon
          icon={["fas", "exclamation-circle"]}
          className="mr-2"
          style={{ fontSize: "13px" }}
        />
        {errorMessage !== undefined && <span>{errorMessage.message}</span>}
      </p>
    </div>
  );
};

export default PercentageInputField;
