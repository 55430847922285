import { useContext, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import {
  getStoreDetails,
  updateStoreDetails,
} from "../../../services/inventory";
import { useSelector } from "react-redux";

const ShopDetailsLogic = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const store = location.state !== null && location.state.store;
  const { business_list } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const serializeSubAccount = (account) => {
    const _account = business_list.find(({ name }) => name === account);
    return _account?.id;
  };

  const [storeDetails, setStoreDetails] = useState({
    name: "",
    address: "",
    subaccount: "",
    vat: "",
  });
  const [storeName, setStoreName] = useState(store.name || "");
  const [storeAddress, setStoreAddress] = useState(store.address || "");
  const [subAccount, setSubAccount] = useState(
    serializeSubAccount(store?.subaccount) || ""
  );
  const [vat, setVat] = useState(store.vat || 0);
  const [isFetchingStoreDetails, setIsFetchingStoreDetails] = useState(false);
  const [isUpdatingStore, setIsUpdatingStore] = useState(false);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  useEffect(() => {
    if (store === null) return navigate("/app/products/stores");
  }, []);

  useEffect(() => {
    handlefetchStoreDetails(id);
  }, []);

  const handlefetchStoreDetails = (storeId) => {
    setIsFetchingStoreDetails(true);
    setRequestLoaderProgress(40);
    getStoreDetails(storeId)
      .then((response) => {
        setIsFetchingStoreDetails(false);
        setRequestLoaderProgress(100);
        if (response.data.data) {
          const { name, address, subaccount, vat } = response.data.data;
          setStoreDetails({ name, address, subaccount, vat: vat });
        }
      })
      .catch((error) => {
        setIsFetchingStoreDetails(false);
        setRequestLoaderProgress(100);
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      });
  };

  const onSubmit = (data) => {
    setIsUpdatingStore(true);
    setRequestLoaderProgress(30);
    updateStoreDetails(id, {
      name: data.storeName,
      address: data.storeAddress,
      subaccount: data.subAccount,
      vat: vat === "" ? 0 : vat,
    })
      .then(() => {
        setIsUpdatingStore(false);
        setRequestLoaderProgress(100);
        navigate("/app/products/stores");
        triggerToast("Store successfully updated", "success");
      })
      .catch((error) => {
        setIsUpdatingStore(false);
        setRequestLoaderProgress(100);
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      });
  };

  return {
    storeName,
    storeAddress,
    subAccount,
    isFetchingStoreDetails,
    business_list,
    storeDetails,
    isUpdatingStore,
    vat,
    setVat,
    setStoreName,
    setStoreAddress,
    setSubAccount,
    onSubmit,
  };
};

export default ShopDetailsLogic;
