import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";
import { formatCurrency } from "../../../helpers";
import TransferApprovalModalLogic from "./TransferApprovalModalLogic";
import TransferApprovalValidation from "./TransferApprovalValidation";
import { Controller } from "react-hook-form";

const TransferApprovalModal = ({
  showApprovalModal,
  setShowApprovalModal,
  transferDetails,
  refreshTable,
}) => {
  const {
    updateTransferStatus,
    isRejecting,
    isApproving,
    password,
    setPassword,
  } = TransferApprovalModalLogic({
    setShowApprovalModal,
    transferDetails,
    refreshTable,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
  } = TransferApprovalValidation(password);

  return (
    <secondaryComponents.Modal
      width="500px"
      isActive={showApprovalModal}
      closeModal={() => {
        setShowApprovalModal(false);
      }}
    >
      <div className="px-3 py-3">
        <h4 className="font-weight-normal">Review Transfer</h4>
        <p className="mb-2">
          Verify and approve the following withdrawal details
        </p>

        <div className="mt-4">
          <div className="d-flex justify-content-between mb-2">
            <p className="mb-0 dark--text font-weight-bold">Amount:</p>
            <h5 className="mb-0 purple--text">
              {formatCurrency(transferDetails.amount)}
            </h5>
          </div>

          <p className="dark--text font-weight-bold mb-2">To:</p>

          {/* bank name */}
          <div className="d-flex justify-content-between mb-2">
            <p>Bank:</p>
            <p className="dark--text">{transferDetails.bank_name}</p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Account number:</p>
            <p className="dark--text">{transferDetails.account_number}</p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Beneficiary:</p>
            <p className="dark--text">{transferDetails.account_name}</p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Initiated by:</p>
            <p className="dark--text">{transferDetails.initiator}</p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Description:</p>
            <p className="dark--text">{transferDetails.description}</p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Charges:</p>
            <p className="dark--text">{formatCurrency(transferDetails.fee)}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Total Amount:</p>
            <p className="dark--text">
              {formatCurrency(
                parseFloat(transferDetails.amount) +
                  parseFloat(transferDetails.fee)
              )}
            </p>
          </div>
        </div>

        <div className="mt-4">
          <Controller
            name="password"
            control={control}
            defaultValue={password}
            render={({ field: { onChange } }) => (
              <primaryComponents.PasswordInputField
                label="Password"
                name="password"
                placeholder="Enter your password"
                errorMessage={errors.password}
                isRequired={true}
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setPassword(value);
                }}
                onKeyUp={() =>
                  errors.password !== undefined && trigger("password")
                }
                register={register}
              />
            )}
          />
        </div>

        <div className="mt-3 d-flex justify-content-between">
          <primaryComponents.Button
            onClick={handleSubmit(() => updateTransferStatus("rejected"))}
            style={{ width: "47%" }}
            classNames="btn btn--outline"
            isLoading={isRejecting}
            isDisabled={isRejecting}
            loadingText="Rejecting..."
          >
            Reject
          </primaryComponents.Button>

          <primaryComponents.Button
            onClick={handleSubmit(() => updateTransferStatus("accepted"))}
            style={{ width: "47%" }}
            classNames="btn btn--primary"
            isLoading={isApproving}
            isDisabled={isApproving}
            loadingText="Approving..."
          >
            Approve
          </primaryComponents.Button>
        </div>
      </div>
    </secondaryComponents.Modal>
  );
};
export default TransferApprovalModal;
