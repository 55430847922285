import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const AllTransferLogic = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [openTransferWidget, setOpenTransferWidget] = useState(false);
  const { business_role, business_id, business_list } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const [isRefresh, setIsRefresh] = useState(false);
  const [showAllBusiness, setShowAllBusiness] = useState(
    queryParams.get("all_business")
  );
  const [newBusinessId, setNewBusinessId] = useState(
    queryParams.get("business_id") || business_id
  );
  const [numberOfPendingTransfers, setNumberOfPendingTransfers] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [showStatementOfAccount, setShowStatementOfAccount] = useState(false);

  useEffect(() => {
    if (showAllBusiness === undefined) return;
    setIsRefresh(true);
  }, [showAllBusiness, newBusinessId]);

  const filterBaseOnAccount = (businessId) => {
    let newParams = queryParams;
    newParams.delete("business_id");
    newParams.delete("all_business");

    if (businessId === "all") {
      newParams.set("all_business", "true");
      setShowAllBusiness("true");
    } else {
      newParams.set("business_id", businessId);
      setNewBusinessId(businessId);
      setShowAllBusiness(false);
    }

    let newQueries = "?";
    newParams.forEach((item, key) => {
      newQueries =
        newQueries === "?"
          ? newQueries + `${key}=${item}`
          : newQueries + `&${key}=${item}`;
    });
    navigate(`${location.pathname}${newQueries}`);
    setIsRefresh(true);
  };

  const appliedBusinessFilter = () => {
    let businessFilter = "?";
    if (queryParams.get("all_business") === "true")
      businessFilter =
        businessFilter + `all_business=${queryParams.get("all_business")}&`;
    else if (queryParams.get("business_id")) {
      businessFilter =
        businessFilter + `business_id=${queryParams.get("business_id")}&`;
    }
    return businessFilter;
  };

  return {
    openTransferWidget,
    setOpenTransferWidget,
    business_role,
    business_id,
    business_list,
    isRefresh,
    setIsRefresh,
    showAllBusiness,
    filterBaseOnAccount,
    appliedBusinessFilter,
    newBusinessId,
    numberOfPendingTransfers,
    setNumberOfPendingTransfers,
    showStatementOfAccount,
    setShowStatementOfAccount,
  };
};
export default AllTransferLogic;
