import { useContext, useEffect, useState } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { getProduct, getCategoryList } from "../../../services/inventory";
import { useParams } from "react-router-dom";
import { getRandomIntInclusive } from "../../../helpers";

export const useEditProductLogic = () => {
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const params = useParams();

  const [productData, setProductData] = useState(null);
  const [categories, setCategories] = useState([]);

  const [isFetchingProduct, setIsFetchingProduct] = useState(false);
  const [isFetchingCategories, setIsFetchingCategories] = useState(false);

  useEffect(() => {
    handleFetchProduct();
    handleFetchCategories();
  }, []);

  const handleFetchProduct = () => {
    setIsFetchingProduct(true);
    setRequestLoaderProgress(getRandomIntInclusive(20, 40));
    getProduct(params.id)
      .then((response) => {
        setIsFetchingProduct(false);
        setRequestLoaderProgress(100);
        setProductData({ ...response.data?.data });
      })
      .catch((error) => {
        setIsFetchingProduct(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  const handleFetchCategories = () => {
    setIsFetchingCategories(true);
    setRequestLoaderProgress(40);
    getCategoryList()
      .then((response) => {
        setIsFetchingCategories(false);
        setRequestLoaderProgress(100);
        setCategories(response.data?.data);
      })
      .catch((error) => {
        setIsFetchingCategories(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  return { isFetchingProduct, productData, categories, isFetchingCategories };
};
