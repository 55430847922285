import "./AddMultipleProducts.scss";
import primaryComponents from "../../../../components/primaryComponents";
import Icon from "../../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import AddMultipleProductsLogic from "./AddMultipleProductsLogic";

const AddMultipleProducts = () => {
  const navigate = useNavigate();
  const { isUploadingFile, inputRef, handleProductUpload } =
    AddMultipleProductsLogic();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      className="add-multiple-products-page"
    >
      <h3 className="font-weight-semibold">Add Multiple Products</h3>
      <p>Upload your bulk product file here:</p>
      <input
        style={{ display: "none" }}
        type="file"
        name="products"
        id="products"
        onChange={handleProductUpload}
        ref={inputRef}
        accept=".csv"
      />
      <label htmlFor="products">
        <primaryComponents.Button
          isLoading={isUploadingFile}
          classNames={"btn btn--primary mt-4"}
          loadingText="Uploading..."
          onClick={() => inputRef.current.click()}
        >
          <img className="mr-2" src={Icon.upload} alt="" />
          Upload bulk product CSV
        </primaryComponents.Button>
      </label>
      <p className="mt-2 dark--text font-weight-semibold">
        Download bulk creation template
        <a
          href="https://enlumi-research-app.s3.eu-west-2.amazonaws.com/develop/business/templates/bulk-product-upload/template.csv"
          className="ml-2 purple--text"
        >
          here
        </a>
      </p>
      <primaryComponents.Button
        onClick={() => navigate(-1)}
        classNames={"btn btn--outline back__btn"}
      >
        Back
      </primaryComponents.Button>
    </motion.div>
  );
};

export default AddMultipleProducts;
