import "./CustomerAnalytics.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import CustomerAnalyticsLogic from "./CustomerAnalyticsLogic";
import { formatCurrency } from "../../../../helpers";
import { useOutletContext } from "react-router-dom";

const CustomerAnalytics = () => {
  const { isRefresh, setIsRefresh } = useOutletContext();
  const {
    isLoadingAllCustomers,
    isLoadingNewCustomers,
    isLoadingReturnedCustomers,
    isLoadingASPU,
    isLoadingTopCustomers,
    allCustomerData,
    newCustomerData,
    returnedCustomerData,
    ASPUCustomerData,
    topCustomers,
  } = CustomerAnalyticsLogic({ isRefresh, setIsRefresh });

  return (
    <secondaryComponents.RequestLoaderTrigger>
      <secondaryComponents.MetaSetter
        title="Lumi Merchant | Dashboard - Customer Analytics"
        description="Customer Anaytics"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          <div className="customer-analytics">
            <div className="customer-analytics__cards">
              <div className="customer-analytics__cards__card">
                <div className="customer-analytics__cards__card__details">
                  <span className="customer-analytics__cards__card__details-title">
                    Total No of Customers
                  </span>
                  <secondaryComponents.LoaderHelper
                    isLoading={isLoadingAllCustomers}
                    classNames="mt-1 mb-2"
                    fontSize="15px"
                    alignItem="text-left"
                  >
                    <h4 className="font-weight-semibold mt-1">
                      {allCustomerData.count}
                    </h4>
                  </secondaryComponents.LoaderHelper>
                  {/* <span className="mt-3 d-block customer-analytics__cards__card__details-subtitle">
                                        +14% from yesterday
                                    </span> */}
                </div>
              </div>
              <div className="customer-analytics__cards__card">
                <div className="customer-analytics__cards__card__details">
                  <span className="customer-analytics__cards__card__details-title">
                    New Customers
                  </span>
                  <secondaryComponents.LoaderHelper
                    isLoading={isLoadingNewCustomers}
                    classNames="mt-1 mb-2"
                    fontSize="15px"
                    alignItem="text-left"
                  >
                    <h4 className="font-weight-semibold mt-1">
                      {newCustomerData.count}
                    </h4>
                  </secondaryComponents.LoaderHelper>
                  {/* <span className="mt-3 d-block customer-analytics__cards__card__details-subtitle">
                                        +14% from yesterday
                                    </span> */}
                </div>
              </div>
              <div className="customer-analytics__cards__card">
                <div className="customer-analytics__cards__card__details">
                  <span className="customer-analytics__cards__card__details-title">
                    Returning Customers
                  </span>
                  <secondaryComponents.LoaderHelper
                    isLoading={isLoadingReturnedCustomers}
                    classNames="mt-1 mb-2"
                    fontSize="15px"
                    alignItem="text-left"
                  >
                    <h4 className="font-weight-semibold mt-1">
                      {returnedCustomerData.count}
                    </h4>
                  </secondaryComponents.LoaderHelper>
                  {/* <span className="mt-3 d-block customer-analytics__cards__card__details-subtitle">
                                        +14% from yesterday
                                    </span> */}
                </div>
              </div>
              <div className="customer-analytics__cards__card">
                <div className="customer-analytics__cards__card__details">
                  <span className="customer-analytics__cards__card__details-title">
                    Average Spend Per Customer
                  </span>
                  <secondaryComponents.LoaderHelper
                    isLoading={isLoadingASPU}
                    classNames="mt-1 mb-2"
                    fontSize="15px"
                    alignItem="text-left"
                  >
                    <h4 className="font-weight-semibold mt-1">
                      {formatCurrency(ASPUCustomerData.count)}
                    </h4>
                  </secondaryComponents.LoaderHelper>
                  {/* <span className="mt-3 d-block customer-analytics__cards__card__details-subtitle">
                                        +14% from yesterday
                                    </span> */}
                </div>
              </div>
            </div>
            <div className="d-none d-md-block">
              <secondaryComponents.TopFiveCustomersTable
                topCustomers={topCustomers}
                isLoadingTopCustomers={isLoadingTopCustomers}
              />
            </div>
            <div className="customer-analytics__charts">
              <secondaryComponents.TotalCustomersChart
                allCustomerData={allCustomerData}
              />
              <secondaryComponents.NewCustomersChart
                newCustomerData={newCustomerData}
              />
              <secondaryComponents.ReturningCustomersChart
                returnedCustomerData={returnedCustomerData}
              />
              <secondaryComponents.AverageSpendChart
                ASPUCustomerData={ASPUCustomerData}
              />
            </div>
          </div>
        </motion.div>
      </secondaryComponents.MetaSetter>
    </secondaryComponents.RequestLoaderTrigger>
  );
};

export default CustomerAnalytics;
