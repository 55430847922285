import { useState, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { addTag, updateTag } from "../../../services/tags";

const AddTransactionsTagLogic = ({
  setIsRefresh,
  setIsOpen,
  tagDetails,
  isEditMode,
  setIsEditMode,
}) => {
  const [tag_name, setTagName] = useState(tagDetails ? tagDetails.name : "");
  const [isAddingTag, setIsAddingTag] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const handleAddTagName = () => {
    setIsAddingTag(true);
    setRequestLoaderProgress(40);

    if (isEditMode) {
      return updateTag(tagDetails.id, { name: tag_name })
        .then(() => {
          setIsEditMode(false);
          setRequestLoaderProgress(100);
          setIsAddingTag(false);
          setIsRefresh(true);
          setIsOpen(false);
          triggerToast("Tag updated successfully", "success");
        })
        .catch((error) => {
          setIsAddingTag(false);
          setRequestLoaderProgress(100);
          if (error.response === undefined) return;
          if (error.response.status === 400)
            return triggerToast(error.response.data.detail, "warning");
        });
    }

    return addTag({ name: tag_name })
      .then(() => {
        setRequestLoaderProgress(100);
        setIsAddingTag(false);
        setIsRefresh(true);
        setIsOpen(false);
        triggerToast("Tag created successfully", "success");
      })
      .catch((error) => {
        setIsAddingTag(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          return triggerToast(error.response.data.detail, "warning");
      });
  };

  return {
    tag_name,
    isAddingTag,
    setTagName,
    handleAddTagName,
  };
};

export default AddTransactionsTagLogic;
