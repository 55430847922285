import { useState, useContext } from "react";
import { deleteBeneficiary } from "../../../services/transfers";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { getRandomIntInclusive } from "../../../helpers";

const DeleteBeneficiaryLogic = ({
  setShowDeleteBeneficiaryModal,
  fetchBeneficiaries,
  currentBeneficiary,
}) => {
  const [isRemovingBeneficiary, setIsRemovingBeneficiary] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const removeBenficiary = () => {
    setIsRemovingBeneficiary(true);
    setRequestLoaderProgress(getRandomIntInclusive(20, 50));
    deleteBeneficiary(currentBeneficiary.id)
      .then(() => {
        fetchBeneficiaries();
        setRequestLoaderProgress(100);
        triggerToast("Beneficiary removed successfully", "success");
        setShowDeleteBeneficiaryModal(false);
      })
      .catch((error) => {
        setIsRemovingBeneficiary(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  return { isRemovingBeneficiary, removeBenficiary };
};
export default DeleteBeneficiaryLogic;
