import "./LoyaltyLayout.scss";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const LoyaltyLayout = () => {
  const { business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (
      business_role !== "OWNER" &&
      business_role !== "ADMIN" &&
      business_role !== "BUSINESS_MANAGER" &&
      business_role !== "DEVELOPER"
    ) {
      return navigate("/app/transactions?all_business=true");
    }
  }, []);

  return (
    <div className="loyalty-page">
      <Outlet />
    </div>
  );
};

export default LoyaltyLayout;
