import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  option: yup.string().label("Option").oneOf(["email", "sms"]),
  email: yup.string().when("option", {
    is: (val) => val === "email",
    then: yup
      .string()
      .label("Email")
      .email("Invalid email address")
      .required("Email address is required"),
  }),
  phone_number: yup.string().when("option", {
    is: (val) => val === "sms",
    then: yup
      .string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
      .min(10, "Invalid phone number format")
      .max(10, "Invalid phone number format"),
  }),
});

const SendReceiptFormValidation = (email, phone_number) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      email: email,
      phone_number: phone_number,
    },
  });
};

export default SendReceiptFormValidation;
