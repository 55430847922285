/* eslint-disable react/jsx-key */
import "./ApprovedTransfersTable.scss";
import ApprovedTransfersTableLogic from "./ApprovedTransfersTableLogic";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import Images from "../../../assets/images";

const ApprovedTransfersTable = ({
  statusType,
  isRefreshTransfers,
  setIsRefreshTransfers,
  setNumberOfPendingTransfers,
}) => {
  const {
    instance,
    isFetchingTransfers,
    transfers,
    gotoPage,
    searchTnx,
    searchValue,
    transferPageDetails,
    setDateFilter,
    startDate,
    endDate,
    tags,
    isFetchingTags,
    handleTagChange,
    handleCreateTag,
  } = ApprovedTransfersTableLogic({
    statusType,
    isRefreshTransfers,
    setIsRefreshTransfers,
    setNumberOfPendingTransfers,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="transfers-table">
      <div className="transfers-table__header">
        {/* header section */}
        <div className="search-section">
          <primaryComponents.SearchField
            trigger={(value) => searchTnx(value)}
            value={searchValue}
            placeholder="Search transfers by ( beneficiary name, account number or bank name)"
          />
        </div>

        {/* date filter */}
        <primaryComponents.DateRangeInput
          onChange={setDateFilter}
          startDate={startDate}
          endDate={endDate}
        />
      </div>

      {/* table section */}
      <div className="table-section">
        <secondaryComponents.LoaderHelper
          isLoading={isFetchingTransfers}
          classNames="mt-5"
        >
          {transfers.length > 0 ? (
            <>
              {/* show on desktop */}
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <th
                            className="font-weight-semibold"
                            {...column.getHeaderProps()}
                          >
                            {column.render("Header")}
                          </th>
                        );
                      })}
                      <th className="font-weight-semibold">Tags</th>
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps({ key: i })}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                        <td
                          style={{
                            maxWidth: "200px",
                            width: "max-content",
                          }}
                        >
                          <primaryComponents.TagsMultiSelect
                            options={tags}
                            idKey="id"
                            nameKey="name"
                            isDisabled={isFetchingTags}
                            value={row.original?.tag.map(({ id, name }) => ({
                              value: id,
                              label: name,
                            }))}
                            onChange={(value, datas) => {
                              const transfer_id = row.original?.id;
                              handleTagChange({
                                inputValue: value,
                                transfer_id,
                                datas,
                              });
                            }}
                            onCreateOption={(value) => {
                              handleCreateTag({
                                value,
                                transfer_id: row.original.id,
                              });
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {/* display on mobile */}
              <div className="mobile-table">
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <div
                      className="mobile-table__row"
                      {...row.getRowProps({ key: i })}
                    >
                      {row.cells.map((cell, j) => (
                        <div
                          className="row-item"
                          {...cell.getCellProps({
                            key: j,
                          })}
                        >
                          <h6 className="row-item__title">
                            {cell.column.Header}
                          </h6>
                          <p className="row-item__value">
                            {cell.render("Cell")}
                          </p>
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            // show if transaction is empty
            <div className="text-center mt-5">
              <img
                width="250px"
                height="262px"
                src={Images.tnxEmptyState}
                alt="empty state"
              />
              <h4>You have no transfers</h4>
              <p>When you do, they would appear here.</p>
            </div>
          )}
        </secondaryComponents.LoaderHelper>
      </div>

      {/* pagination section */}
      {transfers.length > 0 && (
        <secondaryComponents.Pagination
          totalPageNumber={transferPageDetails.last_page}
          handlePageClick={(value) => gotoPage(value.selected + 1)}
          currentPage={transferPageDetails.current_page}
        />
      )}
    </div>
  );
};
export default ApprovedTransfersTable;
