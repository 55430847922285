import styles from "./StoreLayout.module.scss";
import { Outlet } from "react-router-dom";
import primaryComponents from "../../components/primaryComponents";
import { useNavigate, NavLink } from "react-router-dom";
import StoreLayoutLogic from "./StoreLayoutLogic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

const StoreLayout = () => {
  const navigate = useNavigate();
  const {
    categoryId,
    categories,
    business_name,
    refreshProducts,
    searchValue,
    productContainer,
    setRefreshProducts,
    handleSearchProducts,
    selectCategory,
  } = StoreLayoutLogic();

  const categoryContainer = document.getElementById("categorySlider");
  const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
  const [scrolEnd, setscrolEnd] = useState(false); // For detecting end of scrolling
  const slideCategory = (shift) => {
    categoryContainer.scrollLeft += shift;
    setscrollX(scrollX + shift); // Updates the latest scrolled postion

    //For checking if the scroll has ended
    if (
      Math.floor(
        categoryContainer.scrollWidth - categoryContainer.scrollLeft
      ) <= categoryContainer.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //This will check scroll event and checks for scroll end
  const scrollCheck = () => {
    setscrollX(categoryContainer.scrollLeft);
    if (
      Math.floor(
        categoryContainer.scrollWidth - categoryContainer.scrollLeft
      ) <= categoryContainer.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  useEffect(() => {
    //Check width of the scollings
    if (
      categoryContainer &&
      categoryContainer?.scrollWidth === categoryContainer?.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
    return () => {};
  }, [categoryContainer?.scrollWidth, categoryContainer?.offsetWidth]);

  return (
    <>
      {business_name !== null && (
        <div className={styles.storeLayout} ref={productContainer}>
          <div className={styles.storeLayout__header}>
            <div className={styles.storeLayout__header__top}>
              <div className={styles.navSection}>
                <div>
                  <primaryComponents.Button
                    classNames="btn btn--outline font-weight-semibold"
                    onClick={() => navigate("/app/dashboard?all_business=true")}
                  >
                    <FontAwesomeIcon
                      icon="angle-left"
                      className="mr-3"
                      style={{ fontSize: "18px" }}
                    />
                    <span>Back</span>
                  </primaryComponents.Button>
                </div>
                <primaryComponents.SearchField
                  trigger={(value) => handleSearchProducts(value)}
                  value={searchValue}
                  placeholder="Search for a product"
                  classNames={styles.searchSection}
                  triggerOnInput={true}
                />

                <div></div>
              </div>
            </div>

            <div className={styles.storeLayout__bottom}>
              <div className={styles.storeLayout__categories}>
                {/* scroll left */}
                <div
                  className={`${styles.categoryScroller} ${
                    scrollX !== 0 && styles.active
                  }`}
                  onClick={() => slideCategory(-100)}
                >
                  <FontAwesomeIcon icon="angle-left" />
                </div>

                <div
                  className={styles.categoryContainer}
                  id="categorySlider"
                  onScroll={scrollCheck}
                >
                  <NavLink
                    to="/store"
                    className={`${styles.category} ${
                      categoryId === null ? styles.isActive : ""
                    }`}
                    onClick={() => selectCategory(null)}
                  >
                    All categories
                  </NavLink>
                  {categories.map((category) => (
                    <NavLink
                      to={`/store?category_id=${category?.id}`}
                      className={`${styles.category} ${
                        categoryId === category?.id ? styles.isActive : ""
                      }`}
                      key={category?.id}
                      onClick={() => selectCategory(category?.id)}
                    >
                      {category?.name}
                    </NavLink>
                  ))}
                </div>
                {/* scroll right */}
                <div
                  className={`${styles.categoryScroller} ${
                    !scrolEnd && styles.active
                  } ml-auto`}
                  onClick={() => slideCategory(100)}
                >
                  <FontAwesomeIcon icon="angle-right" />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.storeLayout__content}>
            <Outlet
              context={{
                refreshProducts,
                setRefreshProducts,
                productContainer,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default StoreLayout;
