import { useSelector, useDispatch } from "react-redux";
import { useContext, useState, useEffect } from "react";
import { refreshTransactions } from "../../../../services/transaction";
import { ToastContext, RequestLoader } from "../../../../hooks/context";
import { fetchDashboard } from "../../../../services/dashboard";
import { setDashboardDetails } from "../../../../store/modules/dashboard";
import { getRandomIntInclusive, getUrlQuerysection } from "../../../../helpers";

const OverviewLogic = ({ isRefresh, setIsRefresh }) => {
  const [isRefreshingBalance, setIsRefreshingBalance] = useState(false);
  const { business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const { has_wallet } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails
  );
  const {
    total_revenue,
    available_balance,
    total_transaction_count,
    verification_status,
    total_revenue_trend,
    total_transaction_count_trend,
    five_most_recent_transactions,
    revenue_summary,
    business,
  } = useSelector((state) => state.dashboardReducer.dashboardDetails);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const dispatch = useDispatch();

  const revenue_summary_count =
    revenue_summary &&
    revenue_summary.reduce((acc, curr) => {
      return acc + curr.total_revenue;
    }, 0);

  useEffect(() => {
    getDashboardDetails();
  }, []);

  useEffect(() => {
    if (isRefresh) getDashboardDetails();
  }, [isRefresh]);

  const refreshBalance = () => {
    setIsRefreshingBalance(true);
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    refreshTransactions()
      .then(() => {
        setRequestLoaderProgress(100);
        setIsRefreshingBalance(false);
        triggerToast("Account balance successfully updated", "success");
        getDashboardDetails();
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsRefreshingBalance(false);
        if (error.response === undefined) return;
        if (error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  const getDashboardDetails = () => {
    setRequestLoaderProgress(getRandomIntInclusive(5, 25));
    let queries = getUrlQuerysection();
    fetchDashboard(queries)
      .then((response) => {
        dispatch(setDashboardDetails(response.data.data));
        setRequestLoaderProgress(100);
      })
      .catch(() => {
        setRequestLoaderProgress(100);
      });
    setIsRefresh(false);
  };

  return {
    business_role,
    total_revenue,
    available_balance,
    total_transaction_count,
    verification_status,
    total_revenue_trend,
    total_transaction_count_trend,
    isRefreshingBalance,
    refreshBalance,
    five_most_recent_transactions,
    revenue_summary_count,
    revenue_summary,
    has_wallet,
    business,
  };
};

export default OverviewLogic;
