import { useTable } from "react-table";
import { useMemo, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getCustomers } from "../../../services/customer";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { getUrlQuerysection, convertToTilteCase } from "../../../helpers";
import { getRandomIntInclusive } from "../../../helpers";

const useCustomersTable = ({ isRefresh, setIsRefresh }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isFetchingCustomers, setIsFetchingCustomers] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customersPageDetails, setCustomersPageDetails] = useState({});
  const [searchValue] = useState(
    queryParams.get("query") !== null ? [queryParams.get("query")] : ""
  );
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (isRefresh) fetchCustomers();
  }, [isRefresh]);

  const fetchCustomers = () => {
    setIsFetchingCustomers(true);
    setRequestLoaderProgress(getRandomIntInclusive(5, 30));
    let queries = getUrlQuerysection();
    getCustomers(queries)
      .then((response) => {
        setCustomers(response.data.data);
        setCustomersPageDetails(response.data.meta);
        setRequestLoaderProgress(100);
        setIsFetchingCustomers(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsFetchingCustomers(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
    setIsRefresh(false);
  };

  const gotoPage = (pageNumber) => {
    let queries = getUrlQuerysection();
    const queryParams = new URLSearchParams(window.location.search);
    let page = queryParams.get("page");
    if (page === null) {
      let symbol = queries.includes("?") ? "&" : "?";
      navigate(`${queries}${symbol}page=${pageNumber}`);
      return fetchCustomers();
    }

    let splittedQuery = queries.split(`page=${page}`);
    splittedQuery = splittedQuery[0] + `page=${pageNumber}` + splittedQuery[1];
    navigate(`${splittedQuery}`);
    fetchCustomers();
  };

  const filterCustomers = (is_blacklisted = false) => {
    const queries = getUrlQuerysection();
    const queryParams = new URLSearchParams(window.location.search);
    const filter = queryParams.get("is_blacklisted");
    if (filter === null) {
      let symbol = queries.includes("?") ? "&" : "?";
      navigate(`${queries}${symbol}is_blacklisted=${is_blacklisted}`);
      fetchCustomers();
    }
  };

  const searchCustomer = (value) => {
    let searchQuery = "";
    if (queryParams.get("business_id") !== null)
      searchQuery = `?business_id=${queryParams.get("business_id")}`;
    else searchQuery = "?all_business=true";
    searchQuery = value === "" ? searchQuery : `${searchQuery}&query=${value}`;
    navigate(`/app/customers${searchQuery}`);
    fetchCustomers();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Full Name",
        accessor: "full_name",
        Cell: ({ cell: { value, row } }) => (
          <Link
            className="purple--text"
            to={`${row.original["id"]}`}
            style={{ fontSize: "12px", textDecoration: "underline" }}
          >
            {value ? convertToTilteCase(value) : "N/A"}
          </Link>
        ),
      },
      {
        Header: "Email Address",
        accessor: "email",
        Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      },
    ],
    []
  );

  const instance = useTable({ columns, data: customers });
  return {
    instance,
    isFetchingCustomers,
    customers,
    gotoPage,
    filterCustomers,
    customersPageDetails,
    searchCustomer,
    searchValue,
  };
};

export default useCustomersTable;
