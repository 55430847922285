import "./AddProducts.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import AddProductsLogic from "./AddProductLogic";
import { motion } from "framer-motion";

const AddProducts = () => {
  const product = AddProductsLogic();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0 }}
      className="add-products-page"
    >
      <div className="add-products-page__content">
        <div className="add-products-page__content__header">
          <secondaryComponents.ProductStepper
            currentStep={product.currentStep}
            navigateToStep={product.navigatetToStep}
          />
        </div>
        <div className="add-products-page__content__body">
          {product.currentStep === 0 && (
            <secondaryComponents.StoreForm product={product} />
          )}
          {product.currentStep === 1 && (
            <secondaryComponents.CategoryForm product={product} />
          )}
          {product.currentStep === 2 && (
            <secondaryComponents.ProductForm product={product} />
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default AddProducts;
