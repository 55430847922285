import "./SalesTransactions.scss";
import secondaryComponents from "../../../../components/secondaryComponents";

const SalesTransactions = () => {
  return (
    <div className="sales-transactions">
      <div className="sales-transactions__content">
        <secondaryComponents.SalesTransactionsTable />
      </div>
    </div>
  );
};

export default SalesTransactions;
