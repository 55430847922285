import "./EditInviteUser.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import EditInviteUserLogic from "./EditInviteUserLogic";
import EditInviteValidation from "./EditInviteUserValidation";
import { Controller } from "react-hook-form";
import { useEffect } from "react";

const EditInviteUser = ({
  showInviteModal,
  setShowInviteModal,
  userEmail = "",
  userRole = "",
  subAccountList,
  fetchUsersList = () => {},
}) => {
  const {
    email,
    setEmail,
    role,
    setRole,
    errorMessage,
    isInviting,
    updateUser,
    resetForm,
    businessId,
    setRoleDescription,
    roleDescription,
    subAccounts,
    setBusinessId,
    userRoles,
    defaultBusinesses,
  } = EditInviteUserLogic({
    setShowInviteModal,
    userEmail,
    userRole,
    fetchUsersList,
    subAccountList,
  });
  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    reset,
    formState: { errors },
    trigger,
    setError,
  } = EditInviteValidation(email, role, businessId);

  useEffect(() => {
    window.setInviteError = setError;
    window.clearInviteError = clearErrors;
    window.resetInviteForm = reset;
  }, []);

  return (
    <secondaryComponents.Modal
      width="550px"
      isActive={showInviteModal}
      closeModal={() => {
        resetForm();
        setShowInviteModal(false);
      }}
    >
      <div className="invite-user__modal">
        <h4 className="font-weight-semibold text-center">
          Edit user Permission
        </h4>
        {/* error message */}
        {errorMessage !== null && (
          <p className="text-danger text-center mb-1">{errorMessage}</p>
        )}
        <form
          onSubmit={handleSubmit(updateUser)}
          className="invite-user__modal__form"
        >
          {/* email address */}
          <div>
            <Controller
              name="email"
              control={control}
              defaultValue={email}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  label="Email Address"
                  placeholder="Enter your email address"
                  isRequired={true}
                  type="email"
                  name="email"
                  isDisabled={true}
                  errorMessage={errors.email}
                  onKeyUp={() => errors.email !== undefined && trigger("email")}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setEmail(value);
                  }}
                  register={register}
                />
              )}
            />
          </div>
          {/* users role */}
          <div>
            <Controller
              control={control}
              defaultValue={role}
              name="role"
              render={({ field: { onChange } }) => (
                <primaryComponents.SelectField
                  label="Select"
                  isRequired={true}
                  placeholder="Select role"
                  name="role"
                  value={role === "" ? "Select role..." : role}
                  errorMessage={errors.role}
                  options={userRoles}
                  isDisabled={role === "CASHIER" || role === "Cashier"}
                  idKey="value"
                  nameKey="label"
                  onChange={(value) => {
                    onChange(value);
                    setRole(value);
                    setRoleDescription(
                      userRoles.find((role) => role.value === value).description
                    );
                  }}
                />
              )}
            />
          </div>
          {role !== "" && (
            <div className="mb-3">
              <h6 className="font-weight-semibold mb-0">Description</h6>
              <p className="purple--text" style={{ fontSize: "14px" }}>
                {roleDescription}
              </p>
            </div>
          )}

          {/* user sub accounts */}
          <div className="mt-2">
            <Controller
              control={control}
              defaultValue={businessId}
              name="businessId"
              render={({ field: { onChange } }) => (
                <primaryComponents.MultipleSelectField
                  label="Select the account you want this user to manage"
                  isRequired={true}
                  placeholder="Select account"
                  value={defaultBusinesses}
                  name="businessId"
                  errorMessage={errors.businessId}
                  options={subAccounts}
                  idKey="id"
                  nameKey="tag"
                  onChange={(values) => {
                    onChange(values);
                    let newValues = [];
                    values.forEach((item) => {
                      newValues.push(item.value);
                    });
                    setBusinessId(newValues);
                  }}
                />
              )}
            />
          </div>

          <div className="invite-user__modal__actions">
            {/* cancel button */}
            <primaryComponents.Button
              onClick={() => {
                resetForm();
                setShowInviteModal(false);
              }}
              classNames={"btn btn--outline"}
            >
              Cancel
            </primaryComponents.Button>
            {/* Invite button */}
            <primaryComponents.Button
              classNames={"btn btn--primary"}
              isLoading={isInviting}
              isDisabled={isInviting}
              loadingText="Updating..."
              type="submit"
            >
              Update user Permission
            </primaryComponents.Button>
          </div>
        </form>
      </div>
    </secondaryComponents.Modal>
  );
};

export default EditInviteUser;
