import "./SearchField.scss";
import Icon from "../../../assets/icons";
import primaryComponents from "..";
import { useState } from "react";

const SearchField = ({
  name,
  placeholder,
  trigger,
  value,
  triggerOnInput = false,
  showsearchBtn = false,
  classNames = "",
}) => {
  const [searchValue, setSearchValue] = useState(value);
  return (
    <div className={`search__field ${classNames}`}>
      <img className="mr-2" src={Icon.search} alt="search transactions" />
      <input
        type="text"
        placeholder={placeholder}
        name={name}
        value={searchValue}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            trigger(event.target.value);
          }
        }}
        onInput={(event) => {
          if (triggerOnInput) trigger(event.target.value);
        }}
        onChange={(event) => {
          setSearchValue(event.target.value);
          if (event.target.value === "") trigger(event.target.value);
        }}
      />
      {(!triggerOnInput || showsearchBtn) && (
        <primaryComponents.Button
          classNames="btn btn--primary search-btn"
          onClick={() => trigger(searchValue)}
        >
          Search
        </primaryComponents.Button>
      )}
    </div>
  );
};

export default SearchField;
