import { useState, useContext } from "react";
import { ToastContext, RequestLoader } from "../../../../hooks/context";
import { createSubAccount } from "../../../../services/business";
import { useSelector, useDispatch } from "react-redux";
import { getRandomIntInclusive } from "../../../../helpers";
import { useNavigate } from "react-router-dom";
import { getProfile } from "../../../../services/settings";
import { setProfileDetails } from "../../../../store/modules/profile";

const CreateSubAccountLogic = () => {
  const { email, phone_number } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const [subAccountName, setSubAccountName] = useState("");
  const [subAccountDescription, setSubAccountDescription] = useState("");
  const [subAccountPhoneNumber, setSubAccountPhoneNumber] =
    useState(phone_number);
  const [subAccountEmail, setSubAccountEmail] = useState(email);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { verification_status } = useSelector(
    (state) => state.dashboardReducer.dashboardDetails
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submission = () => {
    setIsSubmitting(true);
    setRequestLoaderProgress(getRandomIntInclusive(0, 30));
    createSubAccount({
      sub_account_tag: subAccountName,
      account_description: subAccountDescription,
      sub_account_phone_number: `234${subAccountPhoneNumber}`,
      sub_account_email: subAccountEmail,
    })
      .then(() => {
        setRequestLoaderProgress(65);
        triggerToast("Account creation successful", "success");
        navigate("/app/sub-accounts");
        getProfile()
          .then((response) => {
            dispatch(setProfileDetails(response.data.data));
            setRequestLoaderProgress(100);
          })
          .catch(() => {
            setRequestLoaderProgress(100);
          });
      })
      .catch((error) => {
        setIsSubmitting(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
        if (error.response.status === 422) {
          if (error.response.data.errors.sub_account_phone_number) {
            window.setAccountError(
              "subAccountPhoneNumber",
              {
                type: "validate",
                message: "Phone number already exist",
              },
              { shouldFocus: true }
            );
          }

          if (error.response.data.errors.sub_account_email) {
            window.setAccountError(
              "subAccountEmail",
              {
                type: "validate",
                message: "Email already exist",
              },
              { shouldFocus: true }
            );
          }
        }
      });
  };

  return {
    subAccountName,
    subAccountDescription,
    subAccountPhoneNumber,
    subAccountEmail,
    setSubAccountName,
    setSubAccountDescription,
    setSubAccountPhoneNumber,
    setSubAccountEmail,
    submission,
    isSubmitting,
    verification_status,
  };
};

export default CreateSubAccountLogic;
