import { useState, useContext, useEffect } from "react";
import { verifyUserEmail, sendOTP } from "../../../services/auth";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAccessToken } from "../../../store/modules/profile";

const EmailVerificationLogic = () => {
  const params = new URLSearchParams(window.location.search);
  const email = params.get("email");
  const token = params.get("token");
  const [isVerifing, setIsVerifing] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [countDown, setCountDown] = useState(60);
  const [isResendingOTP, setIsResendingOTP] = useState(false);
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const dispatch = useDispatch();

  useEffect(() => {
    let interval = setInterval(() => {
      if (countDown <= 0) return clearInterval(interval);
      setCountDown((prevState) => prevState - 1);
    }, 1000);

    return () => clearInterval(interval);
  });

  const handleOTPInputs = (value) => {
    setOtp(value);
    if (value.length !== 6) return;
    setIsVerifing(true);
    setRequestLoaderProgress(40);
    verifyUserEmail(token, { code: `${value}` })
      .then((response) => {
        setRequestLoaderProgress(100);
        setIsVerifing(false);
        localStorage.setItem("refreshToken", response.data.data.refresh);
        dispatch(setAccessToken(response.data.data.access));
        triggerToast("Email verification succesful", "success");
        navigate("/app/dashboard?all_true");
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsVerifing(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          return triggerToast(error.response.data.detail, "warning");
        if (error.response.status === 401) {
          triggerToast("Token expired, please login", "warning");
          navigate("/login");
        }
      });
  };

  const resendOTP = () => {
    setIsResendingOTP(true);
    sendOTP(token)
      .then(() => {
        triggerToast("OTP resent successful", "success");
        setIsResendingOTP(false);
        setCountDown(60);
      })
      .catch((error) => {
        setIsResendingOTP(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          return triggerToast(error.response.data.detail, "warning");
        if (error.response.status === 401) {
          triggerToast("Token expired, please login", "warning");
          navigate("/login");
        }
      });
  };

  return {
    email,
    handleOTPInputs,
    isVerified,
    otp,
    isVerifing,
    countDown,
    setIsVerified,
    resendOTP,
    isResendingOTP,
  };
};
export default EmailVerificationLogic;
