import { useStep } from "react-hooks-helper";
import { useState, useContext } from "react";
import { createUser } from "../../../services/auth";
import { validateData } from "../../../services/auth";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { useNavigate } from "react-router-dom";
import { getRandomIntInclusive } from "../../../helpers";

const SignupLogic = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessSize, setBusinessSize] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [isValidatingData, setisValidatingData] = useState(false);
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const { index, navigation } = useStep({
    steps: 2,
    initialStep: 0,
  });
  const handleNextStep = () => {
    validatEmailAndPhoneNumber();
  };

  const handlePreviousStep = () => {
    navigation.previous();
  };

  const validatEmailAndPhoneNumber = () => {
    setisValidatingData(true);
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    validateData({
      email: email,
      phone_number: `${phoneNumber}`,
      country_code: "234",
    })
      .then((response) => {
        setisValidatingData(false);
        setRequestLoaderProgress(100);
        if (response.data.data.email.available === false) {
          window.setError(
            "email",
            {
              type: "validate",
              message: "Email already exist",
            },
            { shouldFocus: true }
          );
        }
        if (response.data.data.phone_number.available === false) {
          window.setError(
            "phoneNumber",
            {
              type: "validate",
              message: "Phone number already exist",
            },
            { shouldFocus: true }
          );
        }
        if (
          response.data.data.email.available === false ||
          response.data.data.phone_number.available === false
        )
          return;
        navigation.next();
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setisValidatingData(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const createAccount = () => {
    setRequestLoaderProgress(getRandomIntInclusive(5, 30));
    setIsCreatingAccount(true);
    createUser({
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone_number: `${phoneNumber}`,
      country_code: "234",
      business_name: businessName,
      job_title: jobTitle,
      size: businessSize,
      password: password,
      confirm_password: confirmPassword,
      agree_terms: agreeToTerms,
      is_web: true,
    })
      .then((response) => {
        setRequestLoaderProgress(100);
        navigate(
          `/email-verification?email=${email}&token=${response.data.data.access}`
        );
        triggerToast("Registration successful", "success");
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsCreatingAccount(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  return {
    handleNextStep,
    handlePreviousStep,
    setEmail,
    email,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phoneNumber,
    setPhoneNumber,
    businessName,
    setBusinessName,
    businessSize,
    setBusinessSize,
    jobTitle,
    setJobTitle,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    index,
    agreeToTerms,
    setAgreeToTerms,
    createAccount,
    isCreatingAccount,
    isValidatingData,
  };
};

export default SignupLogic;
