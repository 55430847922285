import "./Pagination.scss";
import ReactPaginate from "react-paginate";

const Pagination = ({
  handlePageClick,
  totalPageNumber = 1,
  currentPage = 1,
}) => {
  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel="Next >"
      onPageChange={(value) => {
        if (currentPage === value.selected + 1) return;
        handlePageClick(value);
      }}
      pageRangeDisplayed={3}
      pageCount={totalPageNumber}
      previousLabel="< Prev"
      initialPage={currentPage - 1}
      renderOnZeroPageCount={null}
      containerClassName="pagination-conatiner"
      previousLinkClassName="previous-Bttn"
      nextLinkClassName="next-Bttn"
      disabledClassName="pagination-btn-disabled"
      activeClassName="pagination-active"
    />
  );
};

export default Pagination;
