import { useState, useEffect, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import { fetchTerminalDetails } from "../../../../services/pos-terminals";
import { getRandomIntInclusive } from "../../../../helpers";
import { useParams } from "react-router-dom";

const TerminalDetailsLogic = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [terminalDetails, setTerminalDetails] = useState({});
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { terminalId } = useParams();

  useEffect(() => {
    getTerminalDetails();
  }, []);

  const getTerminalDetails = () => {
    setIsLoading(true);
    setRequestLoaderProgress(getRandomIntInclusive(20, 50));
    fetchTerminalDetails(terminalId)
      .then((response) => {
        setTerminalDetails(response.data.data);
        setRequestLoaderProgress(100);
        setIsLoading(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsLoading(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  return {
    isLoading,
    terminalDetails,
  };
};
export default TerminalDetailsLogic;
