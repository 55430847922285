import "./ProductForm.scss";
import { motion } from "framer-motion";
import primaryComponents from "../../primaryComponents";
import { Link } from "react-router-dom";
import Icon from "../../../assets/icons";

const ProductForm = ({ product }) => {
  const { handlePreviousStep } = product;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.6 } }}
      className="product-form"
    >
      <h4 className="font-weight-semibold text-center">Create new product</h4>
      <p className="text-center">Create a simple or multiple product at once</p>
      <div className="product-form__main">
        <Link
          className="product-form__main__item"
          to="/app/products/add/single"
        >
          <div className="icon orange">
            <img src={Icon.product_box_1} alt="" />
          </div>
          <div>
            <p className="font-weight-semibold dark--text">Single product</p>
            <p>Choose for a single product</p>
          </div>
          <img className="ml-auto" src={Icon.chevron_right_arrow} alt="" />
        </Link>
        <Link
          className="product-form__main__item"
          to="/app/products/add/multiple"
        >
          <div className="icon green">
            <img src={Icon.product_box_2} alt="" />
          </div>
          <div>
            <p className="font-weight-semibold dark--text">Multiple product</p>
            <p>Choose for multiple products</p>
          </div>
          <img className="ml-auto" src={Icon.chevron_right_arrow} alt="" />
        </Link>
        <div>
          <primaryComponents.Button
            type="button"
            onClick={handlePreviousStep}
            classNames={"btn btn--outline mt-4"}
          >
            Cancel
          </primaryComponents.Button>
        </div>
      </div>
    </motion.div>
  );
};

export default ProductForm;
