import "./EditGiftCardForm.scss";
import primaryComponents from "../../primaryComponents";
import { motion } from "framer-motion";
import { Controller } from "react-hook-form";
import { useEditGiftCardFormLogic } from "./EditGiftCardFormLogic";
import { useGiftcardFormValidation } from "../GiftcardForm/GiftcardFormValidation";

const EditGiftCardForm = ({ giftcardDetails }) => {
  const {
    giftcardPoints,
    giftcardAmount,
    giftcardName,
    giftcardValidity,
    isSubmitting,
    setGiftcardAmount,
    setGiftcardName,
    setGiftcardPoints,
    setGiftcardValidity,
    handleUpdateGiftCard,
  } = useEditGiftCardFormLogic({
    giftcard_id: giftcardDetails?.id,
    giftcard: giftcardDetails,
  });
  const {
    control,
    formState: { errors },
    trigger,
    handleSubmit,
  } = useGiftcardFormValidation({
    giftcardName,
    amount: giftcardAmount,
    points: giftcardPoints,
    validity_preiod: giftcardValidity,
  });
  return (
    <motion.form
      onSubmit={handleSubmit(handleUpdateGiftCard)}
      className="edit-giftcard-form"
      initial={{ opacity: 0, marginTop: "50px" }}
      animate={{ opacity: 1, marginTop: "0px", transition: { duration: 0.3 } }}
      exit={{ opacity: 0 }}
    >
      <div className="edit-giftcard-form__fields">
        <div className="edit-giftcard-form__fields-group">
          <div>
            <label
              className="default-font-size font-weight-semibold mb-1"
              htmlFor="giftcardName"
            >
              Giftcard Name
            </label>
            <Controller
              control={control}
              name="giftcardName"
              defaultValue={giftcardName}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  name="giftcardName"
                  type="text"
                  placeholder="Enter your chosen giftcard name"
                  classNames="white"
                  isRequired={true}
                  value={giftcardName}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setGiftcardName(value);
                  }}
                  errorMessage={errors.giftcardName}
                  onKeyUp={() =>
                    errors.giftcardName !== undefined && trigger("giftcardName")
                  }
                />
              )}
            />
          </div>
          <div>
            <label
              className="default-font-size font-weight-semibold mb-1"
              htmlFor="gitfcardAmount"
            >
              Amount
            </label>
            <Controller
              control={control}
              defaultValue={giftcardAmount}
              name="amount"
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  name="amount"
                  type="text"
                  classNames="white"
                  placeholder="Enter amount"
                  isRequired={true}
                  errorMessage={errors.amount}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setGiftcardAmount(value);
                  }}
                  onKeyUp={() =>
                    errors.amount !== undefined && trigger("amount")
                  }
                />
              )}
            />
          </div>
        </div>
        <div className="edit-giftcard-form__fields-group">
          <div>
            <label
              className="default-font-size font-weight-semibold mb-1"
              htmlFor="giftcardPoints"
            >
              Required Number of Points
            </label>
            <Controller
              control={control}
              name="points"
              defaultValue={giftcardPoints}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  name="points"
                  type="text"
                  placeholder="Enter number"
                  classNames="white"
                  isRequired={true}
                  errorMessage={errors.points}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setGiftcardPoints(value);
                  }}
                  onKeyUp={() =>
                    errors.points !== undefined && trigger("points")
                  }
                />
              )}
            />
          </div>
          <div>
            <label
              className="default-font-size font-weight-semibold mb-1"
              htmlFor="giftcardValidity"
            >
              Validity Period
            </label>
            <Controller
              control={control}
              name="validity_preiod"
              defaultValue={giftcardValidity}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  name="validity_period"
                  type="text"
                  placeholder="Enter number"
                  classNames="white"
                  isRequired={true}
                  errorMessage={errors.validity_preiod}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setGiftcardValidity(value);
                  }}
                  onKeyUp={() =>
                    errors.validity_preiod !== undefined &&
                    trigger("validity_preiod")
                  }
                />
              )}
            />
          </div>
        </div>
        <div className="edit-giftcard-form__action">
          <primaryComponents.Button
            type="submit"
            isLoading={isSubmitting}
            loadingText="Saving..."
            isDisabled={isSubmitting}
            classNames={"btn btn--primary"}
          >
            Update Giftcard
          </primaryComponents.Button>
        </div>
      </div>
    </motion.form>
  );
};

export default EditGiftCardForm;
