import { useState, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { createStore } from "../../../services/inventory";
import { useSelector } from "react-redux";

const StoresTableModalLogic = ({ setIsActive, setIsRefresh }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [storeAddress, setStoreAddress] = useState("");
  const [settlementAccount, setSettlementAccount] = useState("");
  const [vat, setVat] = useState(0);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);

  const { business_list } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );

  const handleAddStore = () => {
    setIsLoading(true);
    setRequestLoaderProgress(40);

    createStore({
      name: storeName,
      address: storeAddress,
      subaccount: parseInt(settlementAccount),
      VAT: vat === "" ? 0 : vat,
    })
      .then(() => {
        setIsLoading(false);
        setRequestLoaderProgress(100);
        setIsRefresh(true);
        setIsActive(false);
        triggerToast("Store added successfully", "success");
      })
      .catch((error) => {
        setIsLoading(false);
        setRequestLoaderProgress(100);
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");

        if (error.response.status === 422) {
          if (error.response.data.errors.settlement_account) {
            window.setNewStoreError(
              "settlementAccount",
              {
                type: "validate",
                message: error.response.data.errors.settlement_account[0],
              },
              { shouldFocus: true }
            );
            triggerToast(
              error.response.data.errors.settlement_account[0],
              "warning"
            );
          }
        }
      });
  };

  return {
    isLoading,
    storeName,
    storeAddress,
    settlementAccount,
    business_list,
    vat,
    setVat,
    handleAddStore,
    setStoreName,
    setStoreAddress,
    setSettlementAccount,
  };
};

export default StoresTableModalLogic;
