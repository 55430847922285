import "./BiodataForm.scss";
import Icon from "../../../../assets/icons";
import BiodataFormValidation from "./BiodataFormValidation";
import primaryComponents from "../../../../components/primaryComponents";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { Controller } from "react-hook-form";

const BiodataForm = ({ SignupLogic }) => {
  const {
    handleNextStep,
    email,
    lastName,
    firstName,
    phoneNumber,
    setEmail,
    setFirstName,
    setLastName,
    setPhoneNumber,
    isValidatingData,
  } = SignupLogic;
  const {
    register,
    handleSubmit,
    setError,
    control,
    trigger,
    formState: { errors },
  } = BiodataFormValidation(email, firstName, lastName, phoneNumber);

  useEffect(() => {
    window.setError = setError;
  }, []);

  return (
    <motion.form
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className="biodata-form"
      onSubmit={handleSubmit(handleNextStep)}
    >
      <div>
        <h3 className="mb-0 font-weight-normal">Tell us about you</h3>
        <p>Kindly provide the following details about yourself.</p>
      </div>
      <div className="biodata-form__body">
        {/* email address */}
        <div className="mt-2 mb-1">
          <Controller
            name="email"
            control={control}
            defaultValue={email}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                label="Email Address"
                placeholder="Enter your email address"
                isRequired={true}
                type="email"
                name="email"
                isDisabled={true}
                errorMessage={errors.email}
                onKeyUp={() => errors.email !== undefined && trigger("email")}
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setEmail(value);
                }}
                register={register}
              />
            )}
          />
        </div>
        {/* first name */}
        <div className="mb-1">
          <Controller
            name="firstName"
            control={control}
            defaultValue={firstName}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                label="First Name"
                placeholder="Enter your first Name"
                isRequired={true}
                type="text"
                name="firstName"
                errorMessage={errors.firstName}
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setFirstName(value);
                }}
                onKeyUp={() =>
                  errors.firstName !== undefined && trigger("firstName")
                }
                register={register}
              />
            )}
          />
        </div>
        {/* last name */}
        <div className="mb-1">
          <Controller
            name="lastName"
            control={control}
            defaultValue={firstName}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                label="Last Name"
                placeholder="Enter your last Name"
                isRequired={true}
                type="text"
                name="lastName"
                errorMessage={errors.lastName}
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setLastName(value);
                }}
                onKeyUp={() =>
                  errors.lastName !== undefined && trigger("lastName")
                }
                register={register}
              />
            )}
          />
        </div>
        {/* phone number */}
        <div className="mb-1">
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue={phoneNumber}
            render={({ field: { onChange } }) => (
              <primaryComponents.PhoneNumberInputField
                label="Phone Number"
                name="phoneNumber"
                placeholder="Enter your phone number"
                errorMessage={errors.phoneNumber}
                onKeyUp={() =>
                  errors.phoneNumber !== undefined && trigger("phoneNumber")
                }
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setPhoneNumber(value);
                }}
                isRequired={true}
                register={register}
              />
            )}
          />
        </div>
        <div className="d-flex justify-content-end mt-3">
          <primaryComponents.Button
            type="submit"
            classNames={"btn btn--primary"}
            isDisabled={isValidatingData}
            loadingText="Validating..."
            isLoading={isValidatingData}
          >
            <span>Next</span>
            <img className="ml-2" src={Icon.arrow_right} alt="" width="15px" />
          </primaryComponents.Button>
        </div>
      </div>
    </motion.form>
  );
};

export default BiodataForm;
