import "./TagsLayout.scss";
import { Outlet, useNavigate } from "react-router-dom";
import primaryComponents from "../../components/primaryComponents";
import secondaryComponents from "../../components/secondaryComponents";
import { NavLink } from "react-router-dom";
import TagsLayoutLogic from "./TagsLayoutLogic";
import { useEffect } from "react";

const TagsLayout = () => {
  const {
    isAddingTag,
    setIsAddingTag,
    isEditingTag,
    setIsEditingTag,
    isRefresh,
    setIsRefresh,
    tagDetails,
    setTagDetails,
    isDeletingTag,
    setIsDeletingTag,
    business_role,
  } = TagsLayoutLogic();

  const navigate = useNavigate();

  useEffect(() => {
    if (business_role === "CUSTOMER_SUPPORT" || business_role === "CASHIER") {
      return navigate("/app/transactions?all_business=true");
    }
  }, []);

  return (
    <div className="tags-layout">
      <div className="tags-layout__header">
        <h3 className="font-weight-semibold">Tags</h3>
        <primaryComponents.Button
          onClick={() => {
            setTagDetails(null);
            setIsAddingTag(true);
          }}
          classNames={"btn btn--primary"}
        >
          Add new tag
        </primaryComponents.Button>
      </div>
      <div className="tags-layout__tab">
        <NavLink className="tags-layout__tab__item" to="/app/tags">
          All Tags
        </NavLink>
      </div>
      <div className="tags-layout__content">
        <Outlet
          context={{
            setIsAddingTag,
            setIsEditingTag,
            isRefresh,
            setIsRefresh,
            setTagDetails,
            setIsDeletingTag,
          }}
        />
      </div>
      {isAddingTag && (
        <secondaryComponents.AddTransactionsTag
          isOpen={isAddingTag}
          setIsOpen={setIsAddingTag}
          isEditMode={isEditingTag}
          setIsEditMode={setIsEditingTag}
          setIsRefresh={setIsRefresh}
          tagDetails={tagDetails}
        />
      )}
      {isDeletingTag && (
        <secondaryComponents.DeleteTagDialog
          isOpen={isDeletingTag}
          setIsOpen={setIsDeletingTag}
          tagDetails={tagDetails}
          setIsRefresh={setIsRefresh}
        />
      )}
    </div>
  );
};

export default TagsLayout;
