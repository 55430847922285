import { useState, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { linkCustomerTransaction } from "../../../services/transaction";

export const useLinkCustomerLogic = ({
  closeWidget,
  transactionId,
  setRefreshDetails,
}) => {
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const handleLinkCustomer = () => {
    setIsSubmitting(true);
    setRequestLoaderProgress(30);
    linkCustomerTransaction(transactionId, {
      phone_number: customerPhoneNumber,
    })
      .then(() => {
        setIsSubmitting(false);
        setRequestLoaderProgress(100);
        setRefreshDetails(true);
        triggerToast("Customer updated successfully", "success");
        closeWidget();
      })
      .catch((error) => {
        setIsSubmitting(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
        if (error.response.status === 422) {
          const errors = error?.response?.data?.errors;
          if (errors?.phone_number) {
            window.setCustomerTransactionError("customerPhoneNUmber", {
              type: "validate",
              message: error.response.data.errors.Phone_number[0],
            });
          }
        }
      });
  };

  return {
    customerPhoneNumber,
    isSubmitting,
    setCustomerPhoneNumber,
    handleLinkCustomer,
  };
};
