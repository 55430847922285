import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const StoreCustomerValidationSchema = yup.object({
  customerName: yup.string().required("Customer name is required"),
  customerPhoneNumber: yup
    .string()
    .matches(/^[0-9]+$/, "Phone number must contain only digits")
    .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
    .min(10, "Invalid phone number format")
    .max(10, "Invalid phone number format")
    .required("Customer phone number is required"),
});

const StoreCustomerValidation = (customerName, customerPhoneNumber) => {
  return useForm({
    resolver: yupResolver(StoreCustomerValidationSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      customerName,
      customerPhoneNumber,
    },
  });
};

export default StoreCustomerValidation;
