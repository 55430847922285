import { useState, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { useNavigate } from "react-router-dom";
import { updateGiftCard } from "../../../services/giftcards";

export const useEditGiftCardFormLogic = ({ giftcard_id, giftcard }) => {
  const [giftcardName, setGiftcardName] = useState(giftcard?.name || "");
  const [giftcardAmount, setGiftcardAmount] = useState(
    giftcard?.amount_in_naira || ""
  );
  const [giftcardPoints, setGiftcardPoints] = useState(
    giftcard?.amount_in_points || 0
  );
  const [giftcardValidity, setGiftcardValidity] = useState(
    giftcard?.redemption_period || ""
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const handleUpdateGiftCard = () => {
    setIsSubmitting(true);
    setRequestLoaderProgress(30);
    updateGiftCard(giftcard_id, {
      name: giftcardName,
      amount_in_naira: giftcardAmount,
      redemption_period: giftcardValidity,
      amount_in_points: giftcardPoints,
    })
      .then((response) => {
        setIsSubmitting(false);
        setRequestLoaderProgress(100);
        triggerToast("Giftcard updated successfully", "success");
        navigate("/app/loyalty/manage/giftcards");
      })
      .catch((error) => {
        setIsSubmitting(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  return {
    giftcardPoints,
    giftcardAmount,
    giftcardName,
    giftcardValidity,
    isSubmitting,
    setGiftcardAmount,
    setGiftcardName,
    setGiftcardValidity,
    setGiftcardPoints,
    handleUpdateGiftCard,
  };
};
