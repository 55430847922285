import "./AddSingleProductForm.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import Images from "../../../assets/images";
import Icon from "../../../assets/icons";

import { useNavigate } from "react-router-dom";
import { Controller } from "react-hook-form";
import { useAddSingleProductFormLogic } from "./AddSingleProductFormLogic";
import { useAddSingleProductFormValidation } from "./AddSingleProductFormValidation";
import { formatCurrency, formatDate } from "../../../helpers";

const AddSingleProductForm = () => {
  const navigate = useNavigate();
  const {
    isFetchingCategories,
    hasVariants,
    productName,
    productCategory,
    productCostPrice,
    productExpiryDate,
    productSellingPrice,
    productSku,
    productImage,
    productMinStockQuantity,
    productProfit,
    productStockQuantity,
    productVariants,
    categories,
    inputRef,
    isUploadingImage,
    isAddingProduct,
    setHasVariants,
    setProductName,
    setProductCategory,
    setProductCostPrice,
    setProductExpiryDate,
    setProductProfit,
    setProductSellingPrice,
    setProductSku,
    setProductStockMinStockQuantity,
    setProductStockQuantity,
    setProductVariants,
    handleAddVariantField,
    handleRemoveVariantField,
    handleAddProduct,
    handleProductImageUpload,
  } = useAddSingleProductFormLogic();

  const {
    control,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useAddSingleProductFormValidation({
    productName,
    productCategory,
    productSku,
    productPrice: productCostPrice,
    sellingPrice: productSellingPrice,
    expiryDate: productExpiryDate,
    stockQuantity: productStockQuantity,
    minStockQuantity: productMinStockQuantity,
    variants: productVariants,
  });

  return (
    <secondaryComponents.LoaderHelper
      classNames={"mt-3"}
      isLoading={isFetchingCategories}
    >
      <div className="add-single-product">
        <form
          onSubmit={handleSubmit(handleAddProduct)}
          id="productForm"
          className="add-single-product-form"
        >
          <div className="form-section">
            <div className="flex-fill">
              <Controller
                name="productName"
                control={control}
                defaultValue={productName}
                render={({ field: { onChange } }) => (
                  <primaryComponents.InputField
                    placeholder={"Enter product name"}
                    classNames="white"
                    label="Product Name"
                    isRequired
                    errorMessage={errors.productName}
                    onKeyUp={() =>
                      errors.productName !== undefined && trigger("productName")
                    }
                    onChange={({ target: { value } }) => {
                      onChange(value);
                      setProductName(value);
                    }}
                  />
                )}
              />
              <Controller
                name="productCategory"
                control={control}
                defaultValue={productCategory}
                render={({ field: { onChange } }) => (
                  <primaryComponents.SelectField
                    label="Product Category"
                    isRequired={true}
                    options={categories || []}
                    placeholder="Enter product name"
                    value={
                      productCategory ? productCategory : "Choose category"
                    }
                    idKey={"id"}
                    nameKey={"name"}
                    onKeyUp={() =>
                      errors.productCategory !== undefined &&
                      trigger("productCategory")
                    }
                    onChange={(value) => {
                      onChange(value);
                      setProductCategory(value);
                    }}
                    errorMessage={errors.productCategory}
                  />
                )}
              />
              <Controller
                name="productSku"
                defaultValue={productSku}
                control={control}
                render={({ field: { onChange } }) => (
                  <primaryComponents.InputField
                    label="Product SKU"
                    placeholder="Enter SKU"
                    classNames="white"
                    value={productSku}
                    errorMessage={errors.productSku}
                    onKeyUp={() =>
                      errors.productSku !== undefined && trigger("productSku")
                    }
                    onChange={({ target: { value } }) => {
                      onChange(value);
                      setProductSku(value);
                    }}
                  />
                )}
              />
            </div>
            <div className="product-image-section">
              <div className="product-image-container">
                <img
                  src={productImage ? productImage : Images.product_avatar}
                  alt=""
                />
                <input
                  style={{ display: "none" }}
                  ref={inputRef}
                  type="file"
                  name="product-img"
                  id="product-img"
                  onChange={handleProductImageUpload}
                />
              </div>
              <primaryComponents.Button
                type="button"
                onClick={() => inputRef.current.click()}
                classNames={"btn btn--outline upload-button"}
                isLoading={isUploadingImage}
                loadingText={"uploading..."}
              >
                Upload Product Image
              </primaryComponents.Button>
            </div>
          </div>
          <fieldset disabled={hasVariants} className="form-section-two">
            <Controller
              name="productPrice"
              control={control}
              defaultValue={productCostPrice}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  label="Cost Price"
                  isRequired={true}
                  classNames="white"
                  placeholder="Enter Amount"
                  value={productCostPrice}
                  onKeyUp={() =>
                    errors.productPrice !== undefined && trigger("productPrice")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setProductCostPrice(value);
                    setProductProfit(setProductProfit - value);
                  }}
                  errorMessage={errors.productPrice}
                />
              )}
            />
            <Controller
              name="sellingPrice"
              control={control}
              defaultValue={productSellingPrice}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  label="Selling Price"
                  isRequired={true}
                  classNames="white"
                  value={productSellingPrice}
                  onKeyUp={() =>
                    errors.sellingPrice !== undefined && trigger("sellingPrice")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setProductSellingPrice(value);
                    setProductProfit(value - productCostPrice);
                  }}
                  errorMessage={errors.sellingPrice}
                />
              )}
            />
            <Controller
              name="stockQuantity"
              control={control}
              defaultValue={productStockQuantity}
              render={({ field: { onChange } }) => (
                <primaryComponents.StockUnitInput
                  label="Quantity"
                  placeholder="Enter Stock Quantity"
                  value={productStockQuantity}
                  onKeyUp={() =>
                    errors.stockQuantity !== undefined &&
                    trigger("stockQuantity")
                  }
                  // errorMessage={errors.stockQuantity}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setProductStockQuantity(value);
                  }}
                />
              )}
            />
            <div className="profit-margin-input">
              <label htmlFor="profit_margin_container">Profit Per Unit</label>
              <div className="profit_margin_container">
                {formatCurrency(productProfit)}
              </div>
            </div>
            <Controller
              name="expiryDate"
              control={control}
              defaultValue={productExpiryDate}
              render={({ field: { onChange } }) => (
                <primaryComponents.DateInput
                  label="Expiry Date"
                  classNames="white"
                  placeholder="Enter expiry date"
                  value={productExpiryDate}
                  onKeyUp={() =>
                    errors.expiryDate !== undefined && trigger("expiryDate")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setProductExpiryDate(value);
                  }}
                  errorMessage={errors.expiryDate}
                />
              )}
            />
            <Controller
              name="minStockQuantity"
              control={control}
              defaultValue={productMinStockQuantity}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  label="Stock Level Alert"
                  classNames="white"
                  isRequired={true}
                  // placeholder="Minimum Stock Quantity"
                  value={productMinStockQuantity}
                  onKeyUp={() =>
                    errors.minStockQuantity !== undefined &&
                    trigger("minStockQuantity")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setProductStockMinStockQuantity(value);
                  }}
                  errorMessage={errors.minStockQuantity}
                />
              )}
            />
          </fieldset>
          <label
            style={{ maxWidth: "fit-content" }}
            className="d-flex align-items-center mt-4"
            htmlFor="hasVariants"
          >
            <primaryComponents.Checkbox
              isChecked={hasVariants === true}
              onChange={(value) => setHasVariants(value)}
              id="hasVariants"
            />
            <p className="dark--text ml-2 pt-1">Has Variants?</p>
          </label>
          {hasVariants && (
            <fieldset className="product-variants-section">
              <h4 style={{ fontSize: "20px", marginBottom: "24px" }}>
                Add Product Variants
              </h4>
              <div className="product-variants-list">
                {productVariants.map((productVariant, index) => (
                  <div key={index} className="product-variants-list__item">
                    <div className="flex-fill">
                      <Controller
                        name={`variants.${index}.option`}
                        control={control}
                        defaultValue={productVariant?.option || ""}
                        render={({ field: { onChange } }) => (
                          <primaryComponents.InputField
                            placeholder={
                              "Enter your option eg. size, color, flavor etc"
                            }
                            classNames="white"
                            name={`variants.${index}.option`}
                            value={productVariant?.option || ""}
                            onChange={({ target: { value } }) => {
                              onChange(value);
                              const _variants = [...productVariants];
                              _variants[index].option = value;

                              setProductVariants(_variants);
                            }}
                          />
                        )}
                      />
                      <div
                        style={{ gap: "32px" }}
                        className="d-flex align-items-center"
                      >
                        <Controller
                          name={`variants.${index}.cost_price`}
                          control={control}
                          defaultValue={productVariant?.cost_price || ""}
                          render={({ field: { onChange } }) => (
                            <primaryComponents.InputField
                              label="Cost Price"
                              classNames="white"
                              name={`variants.${index}.cost_price`}
                              value={productVariant?.cost_price || ""}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                const _variants = [...productVariants];
                                _variants[index].cost_price = value;
                                _variants[index].profit =
                                  _variants[index].selling_price - value;

                                setProductVariants(_variants);
                              }}
                            />
                          )}
                        />
                        <Controller
                          name={`variants.${index}.selling_price`}
                          control={control}
                          defaultValue={productVariant?.selling_price || ""}
                          render={({ field: { onChange } }) => (
                            <primaryComponents.InputField
                              label="Selling Price"
                              classNames="white"
                              name={`variants.${index}.selling_price`}
                              value={productVariant?.selling_price || ""}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                const _variants = [...productVariants];
                                _variants[index].selling_price = value;
                                _variants[index].profit =
                                  value - _variants[index].cost_price;

                                setProductVariants(_variants);
                              }}
                            />
                          )}
                        />
                        <Controller
                          name={`variants.${index}.stock_quantity`}
                          control={control}
                          defaultValue={productVariant?.stock_quantity || ""}
                          render={({ field: { onChange } }) => (
                            <primaryComponents.StockUnitInput
                              label="Quantity"
                              name={`variants.${index}.stock_quantity`}
                              value={productVariant?.stock_quantity || ""}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                const _variants = [...productVariants];
                                _variants[index].stock_quantity = value;

                                setProductVariants(_variants);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex-fill">
                      <div className="d-flex align-items-center justify-content-between">
                        <Controller
                          name={`variants.${index}.value`}
                          control={control}
                          defaultValue={productVariant?.value || ""}
                          render={({ field: { onChange } }) => (
                            <primaryComponents.InputField
                              placeholder={
                                "Enter your value eg. size, color, flavor etc"
                              }
                              classNames="white"
                              name={`variants.${index}.value`}
                              value={productVariant?.value || ""}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                const _variants = [...productVariants];
                                _variants[index].value = value;

                                setProductVariants(_variants);
                              }}
                            />
                          )}
                        />
                        <button
                          onClick={() => handleRemoveVariantField(index)}
                          type="button"
                          className="variant-delete-icon"
                        >
                          <img src={Icon.deleteIcon} alt="" />
                        </button>
                      </div>
                      <div
                        style={{ gap: "32px" }}
                        className="d-flex align-items-center"
                      >
                        <div className="profit-margin-input">
                          <label htmlFor="profit_margin_container">
                            Profit
                          </label>
                          <div className="profit_margin_container">
                            {formatCurrency(productVariant?.profit)}
                          </div>
                        </div>
                        <Controller
                          name={`variants.${index}.expiry_date`}
                          control={control}
                          defaultValue={productVariant?.expiry_date || ""}
                          render={({ field: { onChange } }) => (
                            <primaryComponents.DateInput
                              label="Expiry Date"
                              classNames="white"
                              name={`variants.${index}.expiry_date`}
                              value={productVariant?.expiry_date || ""}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                const _variants = [...productVariants];
                                _variants[index].expiry_date =
                                  formatDate(value);

                                setProductVariants(_variants);
                              }}
                            />
                          )}
                        />
                        <Controller
                          name={`variants.${index}.minimum_stock_quantity`}
                          control={control}
                          defaultValue={
                            productVariant?.minimum_stock_quantity || ""
                          }
                          render={({ field: { onChange } }) => (
                            <primaryComponents.InputField
                              label="Stock Level Alert"
                              classNames="white"
                              name={`variants.${index}.minimum_stock_quantity`}
                              value={
                                productVariant?.minimum_stock_quantity || ""
                              }
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                const _variants = [...productVariants];
                                _variants[index].minimum_stock_quantity = value;

                                setProductVariants(_variants);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  className="d-flex align-items-center my-4 add-variant-button"
                  onClick={handleAddVariantField}
                >
                  <img
                    src={Icon.add_blue}
                    alt=""
                    width={"10px"}
                    height={"10px"}
                  />
                  <span>
                    {productVariants.length
                      ? "Add another option"
                      : "Add option"}
                  </span>
                </button>
              </div>
            </fieldset>
          )}
        </form>
        <div className="add-single-product__actions">
          <primaryComponents.Button
            classNames={"btn btn--outline"}
            type="button"
            onClick={() => navigate(-1)}
          >
            Cancel
          </primaryComponents.Button>
          <primaryComponents.Button
            classNames={"btn btn--primary"}
            type="submit"
            form="productForm"
            loadingText={"Creating..."}
            isLoading={isAddingProduct}
          >
            Create product
          </primaryComponents.Button>
        </div>
      </div>
    </secondaryComponents.LoaderHelper>
  );
};

export default AddSingleProductForm;
