import "./RejectedTransfers.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import { useOutletContext } from "react-router-dom";

const RejectedTransfers = () => {
  const { isRefresh, setIsRefresh, setNumberOfPendingTransfers } =
    useOutletContext();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Transfers - Rejected"
      description="View all your transfers to other banks"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className="transfers-page"
        >
          {/* transfer table */}
          <secondaryComponents.ApprovedTransfersTable
            statusType="rejected"
            isRefreshTransfers={isRefresh}
            setIsRefreshTransfers={setIsRefresh}
            setNumberOfPendingTransfers={setNumberOfPendingTransfers}
          />
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default RejectedTransfers;
