import "./GiftCardItem.scss";
import primaryComponents from "../../primaryComponents";
import { formatCurrency } from "../../../helpers";
import { useGiftCardItemLogic } from "./GiftCardLogic";
import Images from "../../../assets/images";

const GiftCardItem = ({ giftcardDetails }) => {
  const { setStatus, status, toggleGiftCardStatus } = useGiftCardItemLogic({
    giftcard_id: giftcardDetails?.id,
    giftcard_status: giftcardDetails?.status,
  });

  return (
    <div className="giftcard-item">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <p className="dark--text small-text-size">Validity Period</p>
        <p className="error--text small-text-size">
          {giftcardDetails?.redemption_period} days
        </p>
      </div>
      <div className="giftcard-item__container">
        <div className="giftcard-icon">
          <img src={Images.giftcard_image} alt="giftcard-icon" />
        </div>
        <h4 className="font-weight-semibold">{giftcardDetails?.name}</h4>
        <div className="giftcard-item__container__points">
          <span>
            {formatCurrency(giftcardDetails?.amount_in_naira) || "N/A"}
          </span>
          <span>
            {giftcardDetails?.amount_in_points?.toLocaleString("en-US") ||
              "N/A"}{" "}
            pts
          </span>
        </div>
        <primaryComponents.Switch
          name={giftcardDetails?.id}
          isOn={giftcardDetails?.status === "ACTIVE"}
          onChange={() =>
            setStatus((prev) => !prev, toggleGiftCardStatus(status))
          }
        />
      </div>
    </div>
  );
};

export default GiftCardItem;
