import httpClient from "../axiosBase/axiosHandler";

export const getSettlementAccounts = () => {
  const response = httpClient.get("/settlement-accounts/");
  return response;
};

export const addSettlementAccount = (payload) => {
  const response = httpClient.post("/settlement-accounts/", payload);
  return response;
};

export const changeSettlementType = (settlement_type) => {
  const response = httpClient.post("/settlement-accounts/change-type/", {
    settlement_type,
  });
  return response;
};

export const resolveSettlementAccountNumber = (payload) => {
  const response = httpClient.post(
    "/settlement-accounts/resolve-bank/",
    payload
  );
  return response;
};

export const updateSettlementAccount = (account_id, updates) => {
  const response = httpClient.put(`/settlement-accounts/${account_id}/`, {
    ...updates,
  });
  return response;
};

export const deleteSettlementAccount = (account_id) => {
  const response = httpClient.delete(`/settlement-accounts/${account_id}/`);
  return response;
};

export const getSettlementBankList = () => {
  const response = httpClient.get("/transfers/bank_list/");
  return response;
};

export const getAvailableSubaccounts = () => {
  const response = httpClient.get("/settlement-accounts/no-account-business/");
  return response;
};
