import "./RadioButton.scss";
import { useState } from "react";

const RadioButton = ({
  value,
  name,
  id,
  isDisabled = false,
  checked = false,
  setRadioValue = () => {},
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  return (
    <label htmlFor={id} className="custom-radio">
      <input
        type="radio"
        className="custom-radio__input"
        value={value}
        name={name}
        id={id}
        disabled={isDisabled}
        defaultChecked={isChecked}
        onChange={(e) => {
          setRadioValue(e.target.value);
          setIsChecked(!isChecked);
        }}
      />
      <label htmlFor={id} className="custom-radio__tag"></label>
    </label>
  );
};
export default RadioButton;
