import "./EditProductCategory.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import { Controller } from "react-hook-form";
import EditProductCategoryLogic from "./EditProductCategoryLogic";
import EditProductCategoryValidation from "./EditProductCategoryValidation";

const EditProductCategory = ({
  isOpen = false,
  setIsOpen = () => {},
  category,
  setIsRefresh,
}) => {
  const {
    categoryDescription,
    categoryName,
    setCategoryDescripion,
    setCategoryName,
    isEdited,
    setIsEdited,
    isUpdatingCategory,
    handleUpdateCategory,
  } = EditProductCategoryLogic({ category, setIsOpen, setIsRefresh });
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    control,
  } = EditProductCategoryValidation({ categoryDescription, categoryName });

  return (
    <secondaryComponents.Modal
      width={"500px"}
      isActive={isOpen}
      closeModal={() => setIsOpen(false)}
    >
      <div className="edit-product-category">
        <h4 className="font-weight-semibold mb-3">Edit Product Category</h4>
        <form onSubmit={handleSubmit(handleUpdateCategory)} className="mt-4">
          {/* Category name */}
          <Controller
            name="categoryName"
            control={control}
            defaultValue={categoryName}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                label={"Category Name"}
                isRequired={true}
                placeholder="Enter your category name"
                type="text"
                name="categoryName"
                errorMessage={errors.categoryName}
                onKeyUp={() =>
                  errors.categoryName !== undefined && trigger("categoryName")
                }
                onChange={({ target: { value } }) => {
                  setIsEdited(true);
                  onChange(value);
                  setCategoryName(value);
                }}
                register={register}
              />
            )}
          />
          {/* Category Description */}
          <Controller
            name="categoryDescription"
            control={control}
            defaultValue={categoryDescription}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                label={"Category Description"}
                isRequired={true}
                placeholder="Enter your category description"
                type="text"
                name="categoryDescription"
                errorMessage={errors.categoryDescription}
                onKeyUp={() =>
                  errors.categoryDescription !== undefined &&
                  trigger("categoryDescription")
                }
                onChange={({ target: { value } }) => {
                  setIsEdited(true);
                  onChange(value);
                  setCategoryDescripion(value);
                }}
                register={register}
              />
            )}
          />

          <div className="modal-buttons">
            <primaryComponents.Button
              classNames={"btn btn--outline"}
              onClick={() => setIsOpen(false)}
              type="button"
            >
              Cancel
            </primaryComponents.Button>

            <primaryComponents.Button
              classNames={"btn btn--primary "}
              isDisabled={
                errors.categoryDescription ||
                errors.categoryName ||
                !isEdited ||
                isUpdatingCategory
              }
              isLoading={isUpdatingCategory}
              loadingText="Updating category..."
            >
              Save
            </primaryComponents.Button>
          </div>
        </form>
      </div>
    </secondaryComponents.Modal>
  );
};

export default EditProductCategory;
