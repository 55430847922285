import { useContext, useState } from "react";
import { ToastContext } from "../../../../hooks/context";
import { checkoutWithCash } from "../../../../services/sales-point";

const CashPaymentLogic = ({ closePaymentSession, orderDetails }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("order_id");
  const triggerToast = useContext(ToastContext);
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
  const [amount, setAmount] = useState(orderDetails?.total_amount || "");

  const payViaCash = () => {
    setIsConfirmingPayment(true);
    checkoutWithCash({ order_id: orderId, amount: amount })
      .then(() => {
        setIsConfirmingPayment(false);
        triggerToast("Payment confirmation successful", "success");
        closePaymentSession();
      })
      .catch((error) => {
        setIsConfirmingPayment(false);
        if (error?.response === undefined) return;
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      });
  };

  return {
    isConfirmingPayment,
    amount,
    setAmount,
    payViaCash,
  };
};
export default CashPaymentLogic;
