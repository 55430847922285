import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency } from "../../../../helpers";
import primaryComponents from "../../../primaryComponents";
import "./CardPayment.scss";
import CardPaymentLogic from "./CardPaymentLogic";
import secondaryComponents from "../..";

const CardPayment = ({ closePaymentSession }) => {
  const {
    cardPayments,
    isLoadingcardPayments,
    selectedCardPayments,
    isEnabled,
    isConfirmingPayment,
    loadingExternal,
    setSelectedCardPayments,
    setIsEnabled,
    payViaCard,
    payViaExternal,
  } = CardPaymentLogic({ closePaymentSession });

  return (
    <div className="card-payment">
      <p
        className="text-center dark--text mx-auto small-text-size"
        style={{ maxWidth: "75%" }}
      >
        Kindly accept card payment for this order with your Lumi P0S. Then
        select the transaction processed below to proceed
      </p>

      <div className="d-flex justify-content-end mt-4">
        <primaryComponents.Button
          classNames="btn btn--outline refresh-btn"
          isLoading={isEnabled && !isLoadingcardPayments}
          loadingText="Refreshing..."
          isDisabled={isEnabled && !isLoadingcardPayments}
          onClick={() => setIsEnabled(true)}
        >
          <FontAwesomeIcon
            icon="refresh"
            className="mr-2"
            style={{ fontSize: "18px" }}
          />
          Refesh
        </primaryComponents.Button>
      </div>

      <secondaryComponents.LoaderHelper
        isLoading={isLoadingcardPayments || isEnabled}
        classNames="mt-4"
      >
        {cardPayments?.length > 0 ? (
          <div className="card-payment__transactions">
            {cardPayments?.map((item) => (
              <div
                className={`transaction-item ${
                  selectedCardPayments === item?.reference && "active"
                }`}
                key={item?.id}
                onClick={() => {
                  setSelectedCardPayments(item?.reference);
                }}
              >
                <div className="d-flex align-items-center">
                  <div className="checked-item">
                    <FontAwesomeIcon
                      icon="check"
                      style={{ fontSize: "15px" }}
                    />
                  </div>
                  <div>
                    <p className="dark--text smallest-text-size">
                      {new Date(item?.transaction_time).toLocaleString("en-us")}
                    </p>
                    <p className="dark--text smallest-text-size">
                      {item?.payer}
                    </p>
                  </div>
                </div>
                <div className="text-right">
                  <h6 className="small-text-size mb-1">
                    {formatCurrency(item?.amount)}
                  </h6>
                  {/* <h6 className="smallest-text-size">{item?.bank_name}</h6> */}
                  <h6 className="smallest-text-size">{item?.reference}</h6>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <h5 className="text-center mt-5">No data available</h5>
        )}
      </secondaryComponents.LoaderHelper>

      <div className="card-payment__bottom">
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isLoading={isConfirmingPayment}
          loadingText="Confirming..."
          isDisabled={isConfirmingPayment || selectedCardPayments === ""}
          onClick={payViaCard}
        >
          Confirm
        </primaryComponents.Button>
        <primaryComponents.Button
          classNames="btn btn--outline cover mt-2"
          isLoading={loadingExternal}
          loadingText="Confirming..."
          isDisabled={loadingExternal}
          onClick={payViaExternal}
        >
          Skip Linking of Card
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default CardPayment;
