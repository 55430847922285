import { useMemo, useEffect, useContext, useState } from "react";
import { useExpanded, useTable } from "react-table";
import {
  formatCurrency,
  isMoreThan30DaysAgo,
  getUrlQuerysection,
  getRandomIntInclusive,
} from "../../../helpers";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { getProductList, deleteProduct } from "../../../services/inventory";
import { useSearchParams, useNavigate } from "react-router-dom";
import Icon from "../../../assets/icons";

import ProductTableActions from "./ProductTableActions/ProductTableActions";

const ProductsTableLogic = ({ setInventoryAnalytics }) => {
  const [products, setProducts] = useState([]);
  const [productsDetails, setProductsDetails] = useState(null);
  const [isfetchingProducts, setIsfetchingProducts] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isDeletingProduct, setIsDeletingProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openRestockProductWidget, setOpenRestockProductWidget] =
    useState(false);

  const [showOptions, setShowOptions] = useState(true);

  const [search, setSearch] = useSearchParams();
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const [searchValue] = useState(
    queryParams.get("query") !== null ? [queryParams.get("query")] : ""
  );

  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "name",
      },
      {
        Header: "Variant",
        accessor: (originalRow) => {
          if (originalRow?.variants.length) {
            return `${originalRow?.option}-${originalRow?.value}`;
          }
        },
        Cell: ({ cell: { value }, row }) => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              {row?.depth !== 1 ? (
                <span className="flex-fill">
                  {value?.length ? "Yes" : "No"}
                </span>
              ) : (
                <span>{`${value}` || "N/A"}</span>
              )}
              {row?.canExpand && (
                <span
                  {...row?.getToggleRowExpandedProps({
                    style: {
                      // We can even use the row.depth property
                      // and paddingLeft to indicate the depth
                      // of the row
                      // paddingRight: `${row.depth * 2}rem`,
                    },
                  })}
                  className="flex-fill"
                >
                  <span
                    style={{
                      width: "14px",
                      height: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#E4DAFC",
                      borderRadius: "2.33333px",
                    }}
                  >
                    <img
                      src={
                        row?.isExpanded
                          ? Icon.chevron_up_arrow
                          : Icon.chevron_down_arrow
                      }
                      alt=""
                      width={"7px"}
                      height={"7px"}
                    />
                  </span>
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: "SKU",
        accessor: "sku",
        Cell: ({ cell: { value } }) => value || "N/A",
      },
      {
        Header: "Cost Price",
        accessor: "cost_price",
        Cell: ({ cell: { value } }) => formatCurrency(value),
      },
      {
        Header: "Selling Price",
        accessor: "selling_price",
        Cell: ({ cell: { value } }) => formatCurrency(value),
      },
      {
        Header: "Stock Left",
        accessor: "stock_quantity",
        Cell: ({ cell: { value, row } }) => (
          <span
            style={{
              color:
                value > row.original["minimum_stock_quantity"]
                  ? "#19AE57"
                  : "red",
              backgroundColor:
                value > row.original["minimum_stock_quantity"]
                  ? "#E6FFF0"
                  : "#FFEEE9",
              borderRadius: "4px",
              padding: "4px",
              height: "26px",
              fontSize: "12px",
            }}
          >
            {value || "N/A"}
          </span>
        ),
      },
      {
        Header: "Expiry Date",
        accessor: "expiry_date",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              color: isMoreThan30DaysAgo(new Date(value)) ? "red" : "green",
            }}
          >
            {value === null || value === undefined
              ? "N/A"
              : new Date(value).toLocaleDateString("en-GB")}
          </span>
        ),
      },
      {
        Header: "Actions",
        accessor: "action",
        Cell: ({ cell: { row } }) =>
          row?.depth === 1 ? null : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                position: "relative",
              }}
            >
              <span
                onClick={() => {
                  setSelectedProduct(row?.original);
                  setOpenRestockProductWidget(true);
                }}
                style={{
                  backgroundColor: "#7647EE",
                  display: "inline-block",
                  padding: "10px 16px",
                  fontSize: "12px",
                  borderRadius: "8px",
                  color: "white",
                  cursor: "pointer",
                  userSelect: "none",
                }}
              >
                Restock
              </span>
              <span
                onClick={() =>
                  navigate(`/app/products/${row.original["id"]}/edit`)
                }
                style={{
                  backgroundColor: "white",
                  display: "inline-block",
                  padding: "10px 16px",
                  fontSize: "12px",
                  borderRadius: "8px",
                  border: "1px solid #7647EE",
                  color: "#7647EE",
                  cursor: "pointer",
                  userSelect: "none",
                }}
              >
                Update
              </span>
              <ProductTableActions
                product={row?.original}
                setIsOpenDeleteDialog={setIsOpenDeleteDialog}
                setSelectedProduct={setSelectedProduct}
              />
            </div>
          ),
      },
    ],
    []
  );

  useEffect(() => {
    handleFetchproducts();
  }, []);

  const handleFetchproducts = (showSpinner = true) => {
    setIsfetchingProducts(showSpinner);
    if (showSpinner) setRequestLoaderProgress(getRandomIntInclusive(20, 50));
    let queries = getUrlQuerysection();
    getProductList(queries)
      .then((response) => {
        setIsfetchingProducts(false);
        setRequestLoaderProgress(100);
        setProducts(
          response.data?.data.map((product) => ({
            ...product,
            subRows: product?.variants.map((variant) => ({
              ...variant,
              name: product?.name,
              sku: product?.sku,
              variants: product?.variants,
            })),
          }))
        );
        setProductsDetails(response.data?.meta);
        setInventoryAnalytics((analytics) => ({
          ...analytics,
          productCount: response.data?.data.length,
        }));
      })
      .catch((error) => {
        setIsfetchingProducts(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  const handleSearchProduct = (value) => {
    if (value.length === 0) {
      search.delete("query");
      setSearch(search, { replace: true });
      handleFetchproducts();
    } else {
      search.set("query", value);
      setSearch(search, { replace: true });
      handleFetchproducts();
    }
  };

  const handleDeleteProduct = () => {
    if (selectedProduct === null) return;
    const { id } = selectedProduct;

    setIsDeletingProduct(true);
    setRequestLoaderProgress(30);

    deleteProduct(id)
      .then(() => {
        handleFetchproducts(false);
        setIsDeletingProduct(false);
        setRequestLoaderProgress(100);
        setIsOpenDeleteDialog(false);
      })
      .catch((error) => {
        setIsDeletingProduct(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 400 || error.response.status === 401) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  const gotoPage = (pageNumber) => {
    let queries = getUrlQuerysection();
    let page = queryParams.get("page");
    if (page === null) {
      let symbol = queries.includes("?") ? "&" : "?";
      navigate(`${queries}${symbol}page=${pageNumber}`);
      return handleFetchproducts();
    }

    let splitedQuery = queries.split(`page=${page}`);
    splitedQuery = splitedQuery[0] + `page=${pageNumber}` + splitedQuery[1];
    navigate(`${splitedQuery}`);
    handleFetchproducts();
  };

  const instance = useTable(
    {
      columns,
      data: products,
    },
    useExpanded
  );

  return {
    instance,
    isfetchingProducts,
    products,
    selectedProduct,
    isDeletingProduct,
    isOpenDeleteDialog,
    searchValue,
    productsDetails,
    openRestockProductWidget,
    setOpenRestockProductWidget,
    setIsOpenDeleteDialog,
    gotoPage,
    search,
    handleFetchproducts,
    handleSearchProduct,
    handleDeleteProduct,
    showOptions,
    setShowOptions,
  };
};

export default ProductsTableLogic;
