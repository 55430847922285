import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import useLoyaltyCustomeHistory from "./useLoyaltyCustomeHistory";

const LoyaltyCustomeHistory = () => {
  const { isRefresh, setIsRefresh } = useLoyaltyCustomeHistory();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Customer History"
      description="Customer history"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          <secondaryComponents.CustomerHistoryTable
            isRefresh={isRefresh}
            setIsRefresh={setIsRefresh}
          />
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default LoyaltyCustomeHistory;
