import { CopyToClipboard } from "react-copy-to-clipboard";
import Icon from "../../../assets/icons";
import { Fragment } from "react";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";
import SubAccountCardLogic from "./SubAccountCardLogic";
import { subAccountColours } from "../../../helpers";

const SubAccountCard = ({
  account,
  index,
  getSubAccountsList,
  triggerToast,
}) => {
  const { openEditSubAccountWidget, setOpenEditSubAccountWidget } =
    SubAccountCardLogic();

  return (
    <Fragment>
      <div className="sub-accounts__user">
        <div className="sub-accounts__top">
          <primaryComponents.Button
            classNames="btn sub-accounts__user-branch"
            style={{
              backgroundColor: `${subAccountColours[index].background}`,
              color: `${subAccountColours[index].color}`,
            }}
          >
            {account.business_tag}
          </primaryComponents.Button>
          <span
            onClick={() => setOpenEditSubAccountWidget(true)}
            className="sub-accounts__edit-user-account"
          >
            Edit
          </span>
        </div>
        <div className="sub-accounts__user-info-wrapper">
          <div className="sub-accounts__user-info">
            <p className="sub-accounts__acct-text">Account number:</p>
            <div className="sub-accounts__acct-no-wrapper">
              <p className="sub-accounts__acct-no">{account.account_number}</p>
              <CopyToClipboard
                onCopy={() =>
                  triggerToast(`Copied ${account.account_number}`, "success")
                }
                text={account.account_number}
              >
                <img
                  className="sub-accounts__copy-acct-no"
                  src={Icon.copy}
                  style={{ cursor: "pointer" }}
                  alt="copy-icon"
                />
              </CopyToClipboard>
            </div>
          </div>
          <div className="sub-accounts__user-info">
            <p className="sub-accounts__acct-text">Account Name:</p>
            <div className="sub-accounts__acct-no-wrapper">
              <p className="sub-accounts__acct-no">{account.account_name}</p>
            </div>
          </div>
          <div className="sub-accounts__user-info">
            <p className="sub-accounts__acct-text">Bank:</p>
            <div className="sub-accounts__acct-no-wrapper">
              <p className="sub-accounts__acct-no">{account.bank_name}</p>
            </div>
          </div>
          <div className="sub-accounts__user-info">
            <p className="sub-accounts__acct-text">Business Phone number:</p>
            <div className="sub-accounts__acct-no-wrapper">
              <p className="sub-accounts__acct-no">
                {account.business_phone_number}
              </p>
            </div>
          </div>
          <div className="sub-accounts__user-info">
            <p className="sub-accounts__acct-text">Business Email:</p>
            <div className="sub-accounts__acct-no-wrapper">
              <p className="sub-accounts__acct-no">{account.business_email}</p>
            </div>
          </div>
        </div>
      </div>
      {openEditSubAccountWidget && (
        <secondaryComponents.EditSubAccountForm
          openEditWidget={openEditSubAccountWidget}
          setOpenEditWidget={setOpenEditSubAccountWidget}
          account={account}
          getSubAccountsList={getSubAccountsList}
        />
      )}
    </Fragment>
  );
};

export default SubAccountCard;
