/* eslint-disable react/jsx-key */
import "./StoresTable.scss";
import Images from "../../../assets/images";
import StoresTableLogic from "./StoresTableLogic";
import { Fragment } from "react";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StoresTable = ({ setInventoryAnalytics, isRefresh, setIsRefresh }) => {
  const { instance, isFetchingStores, storeList } = StoresTableLogic({
    setInventoryAnalytics,
    isRefresh,
    setIsRefresh,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="stores-table">
      <secondaryComponents.LoaderHelper
        isLoading={isFetchingStores}
        classNames="mt-3"
      >
        {storeList.length > 0 ? (
          <Fragment>
            {/* <div className="stores-table__header">
              <div className="stores-table__header__search-section">
                <primaryComponents.SearchField
                  placeholder={"Search for a store"}
                />
              </div>
            </div> */}
            <div className="stores-table__content">
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          className="font-weight-semibold"
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps({ key: i })}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* card */}
              <div className="stores-table__content-mobile">
                {storeList.map((list) => (
                  <div
                    key={list.id}
                    className="stores-table__content-mobile-card"
                  >
                    <div className="stores-table__content-mobile-card__title">
                      <p>Store Name:</p>
                      <p>{list.name}</p>
                    </div>
                    <div className="stores-table__content-mobile-card__title">
                      <p>Account Details:</p>
                      <p>{list.subaccount}</p>
                    </div>
                    <div className="stores-table__content-mobile-card__title">
                      <p>Last Updated on:</p>
                      <p>{new Date(list.created_at).toLocaleDateString()}</p>
                    </div>
                    <div className="stores-table__content-mobile-card__title">
                      <p>Status:</p>
                      <p className="status">{list.status}</p>
                    </div>
                    <primaryComponents.Button
                      classNames={"btn btn--primary view-details"}
                    >
                      View details
                    </primaryComponents.Button>
                  </div>
                ))}
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="stores-table__no-product d-column">
            <img
              src={Images.storeProductEmpty}
              width="250px"
              alt="no-store-product"
            />
            <div className="stores-table__no-product-content">
              <h4 className="font-weight-semibold text-center mt-4">
                You have no stores
              </h4>
              <p className="text-center">
                Use the button above to add your stores. When you do, they would
                appear here.
              </p>
            </div>
          </div>
        )}
      </secondaryComponents.LoaderHelper>
    </div>
  );
};

export default StoresTable;
