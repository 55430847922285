import "./CustomerChartTooltip.scss";
import { formatCurrency, setToDateMonthYearInWords } from "../../../helpers";

const CustomerChartTooltip = ({ active, payload, label, isMoney = false }) => {
  if (active) {
    return (
      <div className="customer-chart-tooltip">
        <p className="customer-chart-tooltip__value purple--text">
          {isMoney ? formatCurrency(payload[0].value) : payload[0].value}
        </p>
        <p className="customer-chart-tooltip__label">
          {setToDateMonthYearInWords(label)}
        </p>
      </div>
    );
  }
  return null;
};

export default CustomerChartTooltip;
