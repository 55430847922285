import { useMemo, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTable } from "react-table";
import { getStoreList } from "../../../services/inventory";
import primaryComponents from "../../primaryComponents";
import { ToastContext, RequestLoader } from "../../../hooks/context";

const StoresTableLogic = ({
  setInventoryAnalytics,
  isRefresh,
  setIsRefresh,
}) => {
  const [isFetchingStores, setIsFetchingStores] = useState(false);
  const [storeList, setStoreList] = useState([]);

  const navigate = useNavigate();
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const columns = useMemo(
    () => [
      {
        Header: "Store Name",
        accessor: "name",
      },
      {
        Header: "Account Details",
        accessor: "subaccount",
      },
      {
        Header: "Last updated on",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleDateString(),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              color: "#19AE57",
              backgroundColor: "#E6FFF0",
              borderRadius: "8px",
              padding: "10px 16px",
              height: "28px",
            }}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ cell: { value, row } }) => (
          <span>
            <primaryComponents.Button
              classNames={"btn btn--outline detail__btn"}
              onClick={() =>
                navigate(`/app/products/stores/${value}`, {
                  state: { store: row.original },
                })
              }
            >
              Edit details
            </primaryComponents.Button>
          </span>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    handleFetchStores();
  }, []);

  useEffect(() => {
    if (isRefresh) {
      handleFetchStores(false);
    }
  }, [isRefresh]);

  const handleFetchStores = (showLoader = true) => {
    if (showLoader) setIsFetchingStores(true);
    setRequestLoaderProgress(40);
    getStoreList()
      .then((response) => {
        setIsFetchingStores(false);
        setRequestLoaderProgress(100);
        setStoreList(response.data?.data);
        setInventoryAnalytics((analytics) => ({
          ...analytics,
          storeCount: response.data?.data.length,
        }));
        setIsRefresh(false);
      })
      .catch((error) => {
        setIsFetchingStores(false);
        setRequestLoaderProgress(100);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  // const rows = useMemo(
  //     () => [
  //         {
  //             store_name: "Remote Pro",
  //             account_details: "Main Account- VFD",
  //             updated_at: "11/6/2021 11:33:17",
  //             status: "Active",
  //             action: "Open it",
  //         },
  //     ],
  //     []
  // );

  const instance = useTable({ columns, data: storeList });

  return { instance, isFetchingStores, storeList };
};

export default StoresTableLogic;
