import { useState, useEffect, useContext } from "react";
import { getProductList } from "../../../services/inventory";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { addToCart, removeFromCart } from "../../../services/sales-point";
import { getRandomIntInclusive, getUrlQuerysection } from "../../../helpers";
import { useSelector } from "react-redux";
import { useOutletContext, useNavigate } from "react-router-dom";

const ProductsPageLogic = () => {
  const { refreshProducts, setRefreshProducts, productContainer } =
    useOutletContext();
  const [isfetchingProducts, setIsfetchingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const [refetchCart, setRefetchCart] = useState(false);
  const [updatingCart, setUpdatingCart] = useState(false);
  const { data: cartItems } = useSelector(
    (state) => state.cartReducer.cartDetails
  );
  const [selectedItemId, setSelectedItemId] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [productPageDetails, setProductPageDetails] = useState({});

  useEffect(() => {
    handleFetchProducts();
  }, []);

  useEffect(() => {
    if (refreshProducts) handleFetchProducts();
  }, [refreshProducts]);

  const handleFetchProducts = () => {
    setIsfetchingProducts(true);
    setRequestLoaderProgress(getRandomIntInclusive(20, 40));
    let queries = getUrlQuerysection();
    getProductList(queries)
      .then((response) => {
        setRequestLoaderProgress(100);
        setIsfetchingProducts(false);
        setProducts(response?.data?.data);
        setProductPageDetails(response?.data?.meta);
        setRefreshProducts(false);
      })
      .catch((error) => {
        setIsfetchingProducts(false);
        setRequestLoaderProgress(100);
        if (error?.response?.status === 400)
          triggerToast(error.response?.data?.detail, "warning");
      });
  };

  const handleAddToCart = (productId, quantity, variantId) => {
    updateCart("add", productId, quantity, variantId);
  };

  const handleReduceCartItem = (productId, quantity, cartId, variantId) => {
    if (quantity === 0) return handleRemoveFromCart(productId, cartId);
    updateCart("reduce", productId, quantity, variantId);
  };

  const handleRemoveFromCart = (productId, cartId) => {
    setUpdatingCart(true);
    setSelectedItemId(productId);
    removeFromCart(cartId)
      .then(() => {
        setRefetchCart(true);
        setTimeout(() => {
          setUpdatingCart(false);
        }, 500);
        triggerToast("Item removed from cart", "success");
      })
      .catch((error) => {
        setUpdatingCart(false);
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      });
  };

  const updateCart = (status, productId, quantity, variantId = null) => {
    setUpdatingCart(true);
    setSelectedItemId(variantId || productId);
    addToCart({
      product_id: productId,
      quantity: quantity,
      variant_id: variantId,
    })
      .then(() => {
        setRefetchCart(true);
        setTimeout(() => {
          setUpdatingCart(false);
        }, 500);
        const message =
          status === "reduce"
            ? "Item quantity updated"
            : "Product Added Sucessfully";
        triggerToast(message, "success");
      })
      .catch((error) => {
        setUpdatingCart(false);
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      });
  };

  const getCartItems = (productId) => {
    const fountItem = cartItems.find((item) => item?.product?.id === productId);
    return fountItem;
  };

  const closeStoreCheckout = () => {
    navigate("/store");
  };

  const gotoPage = (pageNumber) => {
    let queries = getUrlQuerysection();
    let page = queryParams.get("page");
    productContainer.current.scrollTo(0, 0);
    if (page === null) {
      let symbol = queries.includes("?") ? "&" : "?";
      navigate(`${queries}${symbol}page=${pageNumber}`);
      return handleFetchProducts();
    }

    let splitedQuery = queries.split(`page=${page}`);
    splitedQuery = splitedQuery[0] + `page=${pageNumber}` + splitedQuery[1];
    navigate(`${splitedQuery}`);
    handleFetchProducts();
  };

  return {
    products,
    isfetchingProducts,
    refetchCart,
    updatingCart,
    cartItems,
    selectedItemId,
    queryParams,
    productPageDetails,
    handleReduceCartItem,
    getCartItems,
    setRefetchCart,
    handleAddToCart,
    handleRemoveFromCart,
    closeStoreCheckout,
    setRefreshProducts,
    gotoPage,
  };
};

export default ProductsPageLogic;
