import { useMemo } from "react";
import { useTable } from "react-table";
import { formatCurrency, truncateString } from "../../../helpers";
import { Link } from "react-router-dom";

const RecentTransactionTableLogic = ({ mostRecentTransaction }) => {
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleString("en-us"),
      },
      {
        Header: "",
        accessor: "payer",
        Cell: ({ cell: { value, row } }) => (
          <Link
            to={`/app/transactions/${row.original["id"]}`}
            className="purple--text"
          >
            {truncateString(value || "", 12)}
          </Link>
        ),
      },
      {
        Header: "",
        accessor: "amount",
        Cell: ({ cell: { value } }) => formatCurrency(value),
      },
    ],
    []
  );

  const instance = useTable({ columns, data: mostRecentTransaction });

  return {
    data: mostRecentTransaction,
    instance,
  };
};

export default RecentTransactionTableLogic;
