import "./ProductTableActions.scss";
import Icon from "../../../../assets/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ProductTableActions = ({
  setIsOpenDeleteDialog,
  setSelectedProduct,
  product,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="product-table-options">
      <button onClick={() => setIsOpen((prev) => !prev)}>
        <img src={Icon.option_alt} alt="" />
      </button>
      {isOpen && (
        <ul>
          <li
            onClick={() => {
              setIsOpen(false);
              setIsOpenDeleteDialog(true);
              setSelectedProduct(product);
            }}
            className="error--text"
          >
            Delete
          </li>
          <li
            onClick={() => {
              setIsOpen(false);
              navigate(`/app/products/${product?.id}/stock-history`);
            }}
          >
            Stock History
          </li>
        </ul>
      )}
    </div>
  );
};

export default ProductTableActions;
