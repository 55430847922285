import { useTable } from "react-table";
import { useMemo, useState, useEffect, useContext } from "react";
import { getCustomerTransactions } from "../../../services/customer";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { formatCurrency, convertToTilteCase } from "../../../helpers";
import { Link } from "react-router-dom";

const useCustomerTransactionsTable = (customerId) => {
  const [isFetchingTransactions, setIsFetchingTransactions] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = () => {
    setIsFetchingTransactions(true);
    setRequestLoaderProgress(40);
    getCustomerTransactions(customerId)
      .then((response) => {
        setTransactions(response.data?.data);
        setRequestLoaderProgress(100);
        setIsFetchingTransactions(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsFetchingTransactions(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleString("en-us"),
      },
      {
        Header: "Customer Name",
        accessor: "_customer",
        Cell: ({ cell: { value, row } }) => (
          <Link
            className="purple--text"
            to={`/app/transactions/${row.original["id"]}`}
            style={{
              fontSize: "12px",
              textDecoration: "underline",
            }}
          >
            {convertToTilteCase(value)}
          </Link>
        ),
      },
      {
        Header: "Gift Card",
        accessor: "gift_card",
        // Cell: "Movie ticket",
        Cell: ({ cell: { value } }) => (
          <span className="green--text">Movie ticket</span>
        ),
      },

      {
        Header: "Cost of Good",
        accessor: "amount",
        Cell: ({ cell: { value } }) => formatCurrency(value),
      },
      {
        Header: "Reward Earned",
        accessor: "cash_earned",
        Cell: ({ cell: { value } }) => formatCurrency(value),
      },
      {
        Header: "Points Redeemed",
        accessor: "settlement_fee",
        Cell: ({ cell: { value } }) => formatCurrency(value),
      },
      {
        Header: "Reward Balance",
        accessor: "amount_settled",
        Cell: ({ cell: { value } }) => formatCurrency(value),
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   Cell: ({ cell: { value } }) => (
      //     <span
      //       className={`${
      //         value === "settled"
      //           ? "success--text"
      //           : value === "unpaid"
      //           ? "pending--text"
      //           : "failed--text"
      //       }`}
      //     >
      //       {value}
      //     </span>
      //   ),
      // },
    ],
    []
  );

  const instance = useTable({ columns, data: transactions });

  return {
    transactions,
    isFetchingTransactions,
    instance,
  };
};

export default useCustomerTransactionsTable;
