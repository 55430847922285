import Modal from "./Modal/Modal";
import ResponseModal from "./ResponseModal/ResponseModal";
import ProfilePersonalInfoForm from "./ProfilePersonalInfoForm/ProfilePersonalInfoForm";
import ProfileBusinessInfoForm from "./ProfileBusinessInfoForm/ProfileBusinessInfoForm";
import SettlementsTable from "./SettlementsTable/SettlementsTable";
import UsersTable from "./UsersTable/UsersTable";
import AccountTypeButtonGroup from "./AccountTypeButtonGroup/AccountTypeButtonGroup";
import TransactionsTable from "./TransactionsTable/TransactionsTable";
import RequestLoaderTrigger from "./RequestLoaderTrigger/RequestLoaderTrigger";
import ToastMessage from "./ToastMessage/ToastMessage";
import CustomersTable from "./CustomersTable/CustomersTable";
import OnboardingGuard from "./OnboardingGuard/OnboardingGuard";
import AppGuard from "./AppGuard/AppGuard";
import CustomerTransactionsTable from "./CustomerTransactionsTable/CustomerTransactionsTable";
import CampaignCard from "./CampaignCard/CampaignCard";
import CampaignList from "./CampaignList/CampaignList";
import InviteUser from "./InviteUser/InviteUser";
import UserRoles from "./UserRoles/UserRoles";
import UserTableActions from "./UserTableActions/UserTableActions";
import CampaignStepper from "./CampaignStepper/CampaignStepper";
import CampaignForm from "./CampaignForm/CampaignForm";
import ComingSoon from "./ComingSoon/ComingSoon";
// import RewardTypeRadioGroup from "./RewardTypeRadioGroup/RewardTypeRadioGroup";
import MetaSetter from "./MetaSetter/MetaSetter";
import ImageUploadModal from "./ImageUploadModal/ImageUploadModal";
import AccountDetailWidget from "./AccountDetailWidget/AccountDetailWidget";
import UnverifiedAccountWidget from "./UnverifiedAccountWidget/UnverifiedAccountWidget";
import AccountVerificationWidget from "./AccountVerificationWidget/AccountVerificationWidget";
import FundTransferWidget from "./FundTransferWidget/FundTransferWidget";
import WalletOption from "./WalletOption/WalletOption";
import KYBVerificationResponseWidget from "./KYBVerificationResponseWidget/KYBVerificationResponseWidget";
import WalletDetailWidget from "./WalletDetailWidget/WalletDetailWidget";
import LoaderHelper from "./LoaderHelper/LoaderHelper";
import OfferCard from "./OfferCard/OfferCard";
import Pagination from "./Pagination/Pagination";
import TransactionsFilter from "./TransactionsFilter/TransactionsFilter";
import ApiErrorHandler from "./ApiErrorHandler/ApiErrorHandler";
import EditInviteUser from "./EditInviteUser/EditInviteUser";
import DeleteInvitedUser from "./DeleteInvitedUser/DeleteInvitedUser";
import ApprovedTransfersTable from "./ApprovedTransfersTable/ApprovedTransfersTable";
import PendingTransfersTable from "./PendingTransferTable/PendingTransfersTable";
import TransferApprovalModal from "./TransferApprovalModal/TransferApprovalModal";
import TransfersFilter from "./TransfersFilter/TransfersFilter";
import SendReceiptWidget from "./SendReceiptWidget/SendReceiptWidget";
import SubAccountModal from "./SubAccountModal/SubAccountModal";
import ChartWidget from "./ChartWidget/ChartWidget";
import RevenueChart from "./RevenueChart/RevenueChart";
import RevenueChartTooltip from "./RevenueChartTooltip/RevenueChartTooltip";
import RecentTransactionTable from "./RecentTransactionsTable/RecentTransactionsTable";
import SubAccountsList from "./SubAccountsList/SubAccountsList";
import TerminalRequestTable from "./TerminalRequestTable/TerminalRequestTable";
import TerminalRequestForm from "./TerminalRequestForm/TerminalRequestForm";
import TerminalsTable from "./TerminalsTable/TerminalsTable";
import EditSubAccountForm from "./EditSubAccountForm/EditSubAccountForm";
import SubAccountCard from "./SubAccountCard/SubAccountCard";
import AddTransactionsTag from "./AddTransactionsTag/AddTransactionsTag";
import CashflowTrend from "./CashflowTrend/CashflowTrend";
import TransactionCount from "./TransactionCount/TransactionCount";
import TopFiveCustomersTable from "./TopFiveCustomersTable/TopFiveCustomersTable";
import AverageSpendChart from "./AverageSpendChart/AverageSpendChart";
import NewCustomersChart from "./NewCustomersChart/NewCustomersChart";
import ReturningCustomersChart from "./ReturningCustomersChart/ReturningCustomersChart";
import TotalCustomersChart from "./TotalCustomersChart/TotalCustomersChart";
import CustomerChartTooltip from "./CustomerChartToolTip/CustomerChartTooltip";
import AddSettlementAccount from "./AddSettlementAccount/AddSettlementAccount";
import ConfirmDialog from "./ConfirmDialog/ConfirmDialog";
import EditSettlementAccount from "./EditSettlementAccount/EditSettlementAccount";
import StatementOfAccount from "./StatementOfAccount/StatementOfAccount";
import BeneficiariesTable from "./BeneficiariesTable/BeneficiariesTable";
import DeleteBeneficiary from "./DeleteBeneficiary/DeleteBeneficiary";
import TagsTable from "./TagsTable/TagsTable";
import DeleteTagDialog from "./DeleteTagDialog/DeleteTagDialog";
import SpendAnalytics from "./SpendAnalytics/SpendAnalytics";
import RevenueAnalytics from "./RevenueAnalytics/RevenueAnalytics";
import IndividualKYBForm from "./IndividualKYBForm/IndividualKYBForm";
import SoleProprietorKYBForm from "./SoleProprietorKYBForm/SoleProprietorKYBForm";
import LLCKYBForm from "./LLCKYBForm/LLCKYBForm";
import BankVerificationOtpWidget from "./BankVerificationOtpWidget/BankVerificationOtpWidget";
import ProductsTable from "./ProductsTable/ProductsTable";
import ProductCategoryModal from "./ProductCategoryModal/ProductCategoryModal";
import ProductStepper from "./ProductStepper/ProductStepper";
import StoreForm from "./StoreForm/StoreForm";
import CategoryForm from "./CategoryForm/CategoryForm";
import ProductForm from "./ProductForm/ProductForm";
import AddSingleProductForm from "./AddSingleProductForm/AddSingleProductForm";
import ProductCategoryTable from "./ProductCategoryTable/ProductCategoryTable";
import StoresTable from "./StoresTable/StoresTable";
import StoresTableModal from "./StoresTableModal/StoresTableModal";
import SalesTransactionsTable from "./SalesTransactionsTable/SalesTransactionsTable";
import StoreInventoryTable from "./StoreInventoryTable/StoreInventoryTable";
import StoreDevicesTable from "./StoreDevicesTable/StoreDevicesTable";
import SalesTransactionWidget from "./SalesTransactionWidget/SalesTransactionWidget";
import ProductCard from "./ProductCard/ProductCard";
import ProductCarting from "./ProductCarting/ProductCarting";
import ServiceLevelAgreementModal from "./ServiceLevelAgreementModal/ServiceLevelAgreementModal";
import EditProductCategory from "./EditProductCategory/EditProductCategory";
import CartItemsWidget from "./CartItemsWidget/CartItemsWidget";
import CartItemsConfirmation from "./CartItemsConfirmation/CartItemsConfirmation";
import CartItemsStepper from "./CartItemsStepper/CartItemsStepper";
import CartItemsCheckout from "./CartItemsCheckout/CartItemsCheckout";
import CheckoutByTransfer from "./CheckoutByTransfer/CheckoutByTransfer";
import AddProductWidget from "./AddProductWidget/AddProductWidget";
import EditProductForm from "./EditProductForm/EditProductForm";
import NavLink from "./NavLink/NavLink";
import StoreCart from "./StoreCart/StoreCart";
import SideModal from "./SideModal/SideModal";
import StoreCheckout from "./StoreCheckout/StoreCheckout";
import AddCustomerWidget from "./AddCustomerWidget/AddCustomerWidget";
import GiftCardWidget from "./GiftCardWidget/GiftCardWidget";
import GiftCardSuccessModal from "./GiftCardSuccessModal/GiftCardSuccessModal";
import CustomerHistoryTable from "./CustomerHistoryTable/CustomerHistoryTable";
import GiftcardForm from "./GiftcardForm/GiftcardForm";
import EditCampaignForm from "./EditCampaignForm/EditCampaignForm";
import GiftCardList from "./GiftCardList/GiftCardList";
import GiftCardItem from "./GiftCardItem/GiftCardItem";
import EditGiftCardForm from "./EditGiftCardForm/EditGiftCardForm";
import LinkCustomerWidget from "./LinkCustomerWidget/LinkCustomerWidget";
import LoyaltyCustomersTable from "./LoyaltyCustomersTable";
import LoyaltyCustomerTransactionTable from "./LoyaltyCustomerTransactionTable";
import ProductVariantCard from "./ProductVariantCard";
import StockHistoryTable from "./StockHistoryTable/StockHistoryTable";
import RestockProductWidget from "./RestockProductWidget/RestockProductWidget";

const secondaryComponents = {
  Modal,
  ResponseModal,
  ProfilePersonalInfoForm,
  ProfileBusinessInfoForm,
  SettlementsTable,
  UsersTable,
  AccountTypeButtonGroup,
  TransactionsTable,
  RequestLoaderTrigger,
  ToastMessage,
  CustomersTable,
  OnboardingGuard,
  AppGuard,
  CustomerTransactionsTable,
  CampaignCard,
  CampaignList,
  InviteUser,
  UserRoles,
  UserTableActions,
  CampaignStepper,
  CampaignForm,
  ComingSoon,
  MetaSetter,
  ImageUploadModal,
  AccountDetailWidget,
  UnverifiedAccountWidget,
  AccountVerificationWidget,
  FundTransferWidget,
  WalletOption,
  KYBVerificationResponseWidget,
  WalletDetailWidget,
  LoaderHelper,
  OfferCard,
  Pagination,
  TransactionsFilter,
  ApiErrorHandler,
  EditInviteUser,
  DeleteInvitedUser,
  ApprovedTransfersTable,
  PendingTransfersTable,
  TransfersFilter,
  TransferApprovalModal,
  SendReceiptWidget,
  SubAccountModal,
  ChartWidget,
  RevenueChart,
  RevenueChartTooltip,
  RecentTransactionTable,
  SubAccountsList,
  TerminalRequestTable,
  TerminalRequestForm,
  TerminalsTable,
  EditSubAccountForm,
  SubAccountCard,
  AddTransactionsTag,
  CashflowTrend,
  TransactionCount,
  TopFiveCustomersTable,
  AverageSpendChart,
  TotalCustomersChart,
  ReturningCustomersChart,
  NewCustomersChart,
  CustomerChartTooltip,
  AddSettlementAccount,
  ConfirmDialog,
  EditSettlementAccount,
  StatementOfAccount,
  BeneficiariesTable,
  DeleteBeneficiary,
  TagsTable,
  DeleteTagDialog,
  SpendAnalytics,
  RevenueAnalytics,
  IndividualKYBForm,
  SoleProprietorKYBForm,
  LLCKYBForm,
  BankVerificationOtpWidget,
  ProductsTable,
  ProductCategoryModal,
  ProductStepper,
  StoreForm,
  CategoryForm,
  ProductForm,
  AddSingleProductForm,
  ProductCategoryTable,
  StoresTable,
  StoresTableModal,
  SalesTransactionsTable,
  StoreInventoryTable,
  StoreDevicesTable,
  SalesTransactionWidget,
  ProductCard,
  ProductCarting,
  ServiceLevelAgreementModal,
  EditProductCategory,
  CartItemsWidget,
  CartItemsStepper,
  CartItemsCheckout,
  CartItemsConfirmation,
  CheckoutByTransfer,
  AddProductWidget,
  EditProductForm,
  NavLink,
  StoreCart,
  SideModal,
  StoreCheckout,
  AddCustomerWidget,
  GiftCardWidget,
  GiftCardSuccessModal,
  CustomerHistoryTable,
  GiftcardForm,
  EditCampaignForm,
  GiftCardList,
  GiftCardItem,
  EditGiftCardForm,
  LinkCustomerWidget,
  LoyaltyCustomersTable,
  LoyaltyCustomerTransactionTable,
  ProductVariantCard,
  StockHistoryTable,
  RestockProductWidget,
};

export default secondaryComponents;
