import "./PhoneNumberInputField.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Icon from "../../../assets/icons";

const PhoneNumberInputField = ({
  placeholder = "",
  name = "",
  isRequired,
  label = null,
  isDisabled = false,
  errorMessage = undefined,
  onChange = () => {},
  value = "",
  register = () => {},
  onKeyUp = () => {},
}) => {
  return (
    <div className="phone-container">
      {label !== null && (
        <label htmlFor={name} data-testid="label">
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}
      <div
        className={`phone-container__input-section ${
          errorMessage !== undefined && "error-border"
        }`}
      >
        <span className="d-flex">
          <img src={Icon.nigerian_flag} alt="" />
          <span style={{ paddingTop: "4px" }}>+234</span>
        </span>
        <input
          type="tel"
          inputMode="numeric"
          name={name}
          placeholder={placeholder}
          id={name}
          disabled={isDisabled}
          defaultValue={value}
          {...register(name, { required: isRequired })}
          onChange={onChange}
          onKeyUp={onKeyUp}
        />
      </div>
      <p
        className={`error-message text-danger ${
          errorMessage !== undefined ? "show" : ""
        }`}
        data-testid="error-message"
      >
        <FontAwesomeIcon
          icon={["fas", "exclamation-circle"]}
          className="mr-2"
          style={{ fontSize: "13px" }}
        />
        {errorMessage !== undefined && <span>{errorMessage.message}</span>}
      </p>
    </div>
  );
};

export default PhoneNumberInputField;
