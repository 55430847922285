import { useState, useRef, useEffect } from "react";
import { last7Days } from "../../../helpers";

const DateRangeInputLogic = ({ startDate, endDate }) => {
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef(null);
  const [dateRange, setDateRange] = useState([
    {
      startDate:
        startDate !== null
          ? new Date(startDate.replaceAll("-", "/"))
          : new Date(last7Days()[1].replaceAll("-", "/")),
      endDate:
        endDate !== null
          ? new Date(endDate.replaceAll("-", "/"))
          : new Date(last7Days()[0].replaceAll("-", "/")),
      key: "selection",
    },
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target))
        setIsActive(false);
    };
    document.addEventListener("click", handleClickOutside);
  }, [inputRef]);

  return {
    isActive,
    setIsActive,
    inputRef,
    dateRange,
    setDateRange,
  };
};
export default DateRangeInputLogic;
