import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const InventoryLayoutLogic = () => {
  const [inventoryAnalytics, setInventoryAnalytics] = useState({
    storeCount: 0,
    productCount: 0,
    categoryCount: 0,
  });
  const { business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );
  const navigate = useNavigate();
  if (
    business_role !== "OWNER" &&
    business_role !== "ADMIN" &&
    business_role !== "BUSINESS_MANAGER"
  ) {
    return navigate("/app/dashboard?all_business=true");
  }

  return { inventoryAnalytics, setInventoryAnalytics };
};

export default InventoryLayoutLogic;
