import { useState, useContext } from "react";
import {
  getLocalGovernments,
  fetchStates,
} from "../../../services/nigerian-states";
import { verifyBusiness, verifyBvn } from "../../../services/kyb-verification";
import { ToastContext, RequestLoader } from "../../../hooks/context";

const useSolePropretorKyb = ({ verificationData, navigation }) => {
  const [business_category, setBusinessCategory] = useState(
    verificationData?.business_category || ""
  );
  const [business_description, setBusinessDescription] = useState(
    verificationData?.business_description || ""
  );
  const [brand_name, setBrandName] = useState(
    verificationData?.business_name || ""
  );
  const [business_state, setBusinesState] = useState(
    verificationData?.state || ""
  );
  const [business_lga, setBusinessLga] = useState(verificationData?.lga || "");
  const [business_address, setBusinessAddress] = useState(
    verificationData?.address || ""
  );
  const [cac_reg_number, setCacRegNumber] = useState(
    verificationData?.cac_rc_number || ""
  );
  const [tax_id_number, setTaxIdNumber] = useState(verificationData?.tin || "");
  const [directors_bvn, setDirectorsBvn] = useState(
    verificationData?.bvn_is_verified ? verificationData?.bvn : ""
  );
  const [govt_id, setGovtId] = useState(
    verificationData?.government_issued_id || ""
  );
  const [inc_date, setIncDate] = useState(verificationData?.inc_date || "");
  const [cac_reg_form, setCacRegForm] = useState(
    verificationData?.cac_registration_form || ""
  );
  const [business_name_cert, setBusinessNameCert] = useState(
    verificationData?.certificate_of_registration_business_name || ""
  );
  const [agree_terms, setAgreeTerms] = useState(
    verificationData?.terms_accepted || false
  );
  const [showBankVerificationOtpWidget, setShowBankVerificationOtpWidget] =
    useState(false);
  const [isVerifyingAccount, setIsVerifyingAccount] = useState(false);
  const [isVerifyingBvn, setIsVerifyingBvn] = useState(false);
  const [isOpenAgreementModal, setIsOpenAgreementModal] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  // TODO: Remove this list and replace with category list later.
  const business_categories = [
    "supermarket",
    "Pharmacy",
    "Restaurant",
    "Bar",
    "Grocery",
    "Hotel",
    "Spa",
    "Technology/IT",
  ];

  const handleVerifyAccount = () => {
    if (!verificationData?.bvn_is_verified)
      return triggerToast(
        "Please ensure you have verified your BVN before proceeding",
        "warning"
      );
    setIsVerifyingAccount(true);
    verifyBusiness({
      cac_rc_number: cac_reg_number,
      tin: tax_id_number,
      inc_date: inc_date,
      business_name: brand_name,
      business_type: "SoleProprietor",
      business_description: business_description,
      business_category: business_category,
      terms_accepted: agree_terms,
      state: business_state,
      lga: business_lga,
      address: business_address,
    })
      .then(() => {
        setRequestLoaderProgress(100);
        setIsVerifyingAccount(false);
        navigation.next();
        triggerToast("Account successfully verified", "success");
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsVerifyingAccount(false);
        if (error?.response?.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  const handleVerifyBvn = (bvn_number) => {
    setIsVerifyingBvn(true);
    setRequestLoaderProgress(40);
    verifyBvn({ bvn: bvn_number })
      .then(() => {
        setIsVerifyingBvn(false);
        setRequestLoaderProgress(100);
        setShowBankVerificationOtpWidget(true);
      })
      .catch((error) => {
        setIsVerifyingBvn(false);
        setRequestLoaderProgress(100);
        if (error?.response?.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  // const uploadBusinessLogo = (document) => {
  //     setRequestLoaderProgress(50);

  //     const formData = new FormData();
  //     formData.append("type", document.type);
  //     formData.append("image", document.file);

  //     uploadImage(formData)
  //         .then((response) => {
  //             setBusinessLogo(response.data.data.url);
  //             setRequestLoaderProgress(100);
  //             triggerToast("Document successfully uploaded", "success");
  //         })
  //         .catch((error) => {
  //             setRequestLoaderProgress(100);
  //             if (error.response === undefined) return;
  //             if (error.response.status === 400)
  //                 triggerToast(error.response.data.detail, "warning");
  //             if (error.response.status === 422)
  //                 triggerToast(error.response.data.detail, "warning");
  //         });
  // };

  return {
    business_category,
    business_description,
    brand_name,
    business_state,
    business_lga,
    business_address,
    cac_reg_number,
    tax_id_number,
    directors_bvn,
    // business_logo,
    cac_reg_form,
    business_name_cert,
    agree_terms,
    inc_date,
    nigerian_states: fetchStates() || [],
    local_government_areas: getLocalGovernments(business_state),
    business_categories,
    showBankVerificationOtpWidget,
    isVerifyingAccount,
    isVerifyingBvn,
    verification_status:
      verificationData?.verification_status === "pending_review" ||
      verificationData?.verification_status === "verified",
    govt_id,
    isOpenAgreementModal,
    setIsOpenAgreementModal,
    setGovtId,
    setShowBankVerificationOtpWidget,
    setIncDate,
    setBusinessCategory,
    setBusinessDescription,
    setBrandName,
    setBusinesState,
    setBusinessLga,
    setBusinessAddress,
    setCacRegNumber,
    setTaxIdNumber,
    setDirectorsBvn,
    // setBusinessLogo,
    setCacRegForm,
    setBusinessNameCert,
    setAgreeTerms,
    handleVerifyBvn,
    handleVerifyAccount,
    // uploadBusinessLogo,
  };
};

export default useSolePropretorKyb;
