import "./StoreForm.scss";
import { motion } from "framer-motion";
import primaryComponents from "../../primaryComponents";
import StoreFormLogic from "./StoreFormLogic";
import StoreFormValidation from "./StoreFormValidation";
import { Controller } from "react-hook-form";

const StoreForm = ({ product }) => {
  const { handleNextStep, handlePreviousStep } = product;
  const {
    isLoading,
    storeAddress,
    storeName,
    settlementAccount,
    business_list,
    setStoreAddress,
    setStoreName,
    setSettlementAccount,
    handleAddStore,
  } = StoreFormLogic({ handleNextStep });
  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    register,
  } = StoreFormValidation({
    storeAddress,
    storeName,
    settlementAccount,
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.6 } }}
      className="store-form"
    >
      <h4 className="font-weight-semibold text-center">Add new store</h4>
      <form
        onSubmit={handleSubmit(handleAddStore)}
        className="store-form__main"
      >
        <Controller
          name="storeName"
          control={control}
          defaultValue={storeName}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label="Store Name"
              placeholder="Enter your store name"
              isRequired={true}
              type="text"
              name="storeName"
              errorMessage={errors.storeName}
              onKeyUp={() =>
                errors.storeName !== undefined && trigger("storeName")
              }
              onChange={({ target: { value } }) => {
                onChange(value);
                setStoreName(value);
              }}
              register={register}
            />
          )}
        />
        <Controller
          name="storeAddress"
          control={control}
          defaultValue={storeAddress}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label="Store Address"
              placeholder="Enter your store street address"
              isRequired={true}
              type="text"
              name="storeAddress"
              errorMessage={errors.storeAddress}
              onKeyUp={() =>
                errors.storeAddress !== undefined && trigger("storeAddress")
              }
              onChange={({ target: { value } }) => {
                onChange(value);
                setStoreAddress(value);
              }}
              register={register}
            />
          )}
        />
        <Controller
          name="settlementAccount"
          control={control}
          defaultValue={settlementAccount}
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              label="Settlement Account"
              placeholder="Select settlement account"
              isRequired={true}
              value={settlementAccount || "Select settlement account"}
              options={business_list}
              nameKey="tag"
              idKey="id"
              errorMessage={errors.settlementAccount}
              onChange={(value) => {
                onChange(value);
                setSettlementAccount(value);
              }}
            />
          )}
        />
        <div className="d-flex align-items-center justify-content-between store-form__main__actions ">
          <primaryComponents.Button
            type="button"
            classNames={"btn btn--outline"}
            onClick={handlePreviousStep}
          >
            Cancel
          </primaryComponents.Button>
          <primaryComponents.Button
            classNames={"btn btn--primary"}
            isLoading={isLoading}
            loadingText="Adding store..."
            isDisabled={isLoading}
          >
            Next
          </primaryComponents.Button>
        </div>
      </form>
    </motion.div>
  );
};

export default StoreForm;
