import "./TerminalsLayout.scss";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const TerminalsLayout = () => {
  const { business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (business_role === "CUSTOMER_SUPPORT" || business_role === "CASHIER") {
      return navigate("/app/transactions?all_business=true");
    }
  }, []);

  return (
    <div className="terminals-layout">
      <Outlet />
    </div>
  );
};

export default TerminalsLayout;
