import Button from "./Button/Button";
import InputField from "./InputField/InputField";
import Checkbox from "./Checkbox/Checkbox";
import OtpInputField from "./OtpInputField/OtpInputField";
import PasswordInputField from "./PasswordInputField/PasswordInputField";
import PhoneNumberInputField from "./PhoneNumberInputField/PhoneNumberInputField";
import SelectField from "./SelectField/SelectField";
import SearchField from "./SearchField/SearchField";
import CampaignSearchField from "./CampaignSearchField/CampaignSearchField";
import TextArea from "./TextArea/TextArea";
import TimeInput from "./TimeInput/TimeInput";
import DateInput from "./DateInput/DateInput";
import RadioInput from "./RadioInput/RadioInput";
import ImageInputField from "./ImageInputField/ImageInputField";
import RadioButton from "./RadioButton/RadioButton";
import BoxedRadioInput from "./BoxedRadioInput/BoxedRadioInput";
import FileInput from "./FileInput/FileInput";
import Switch from "./Switch/Switch";
import MultipleSelectField from "./MultipleSelectField/MultipleSelectField";
import DateRangeInput from "./DateRangeInput/DateRangeInput";
import TagsMultiSelect from "./TagsMultiSelect/TagsMultiSelect";
import StockUnitInput from "./StockUnitInput/StockUnitInput";
import UrlInputField from "./UrlInputField/UrlInputFIeld";
import PercentageInputField from "./PercentageInputField/PercentageInputField";
import SlugInputField from "./SlugInputField/SlugInputField";
import CurrencyInputField from "./CurrencyInputField/CurrencyInputField";
import PointsInputField from "./PointsInputField/PointsInputField";
import DaysInputField from "./DaysInputField/DaysInputField";

const primaryComponents = {
  Button: Button,
  InputField: InputField,
  Checkbox: Checkbox,
  OtpInputField: OtpInputField,
  PasswordInputField: PasswordInputField,
  PhoneNumberInputField: PhoneNumberInputField,
  SelectField: SelectField,
  SearchField: SearchField,
  CampaignSearchField: CampaignSearchField,
  TextArea: TextArea,
  TimeInput: TimeInput,
  DateInput: DateInput,
  RadioInput: RadioInput,
  ImageInputField: ImageInputField,
  RadioButton: RadioButton,
  BoxedRadioInput: BoxedRadioInput,
  FileInput: FileInput,
  Switch: Switch,
  MultipleSelectField: MultipleSelectField,
  DateRangeInput,
  TagsMultiSelect,
  StockUnitInput,
  UrlInputField,
  PercentageInputField,
  SlugInputField,
  CurrencyInputField,
  PointsInputField,
  DaysInputField,
};

export default primaryComponents;
