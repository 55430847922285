import { useState, useEffect, useContext } from "react";
import { getCustomer } from "../../../../services/customer";
import { ToastContext, RequestLoader } from "../../../../hooks/context";

const CustomerDetailsLogic = (customerId) => {
  const [isFetchingCustomer, setIsFetchingCustomer] = useState(false);
  const [customer, setCustomer] = useState(null);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  useEffect(() => {
    fetchCustomer();
  }, [customerId]);

  const fetchCustomer = () => {
    setIsFetchingCustomer(true);
    setRequestLoaderProgress(40);
    getCustomer(customerId)
      .then((response) => {
        setCustomer(response.data.data);
        setRequestLoaderProgress(100);
        setIsFetchingCustomer(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsFetchingCustomer(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  return {
    isFetchingCustomer,
    customer,
  };
};

export default CustomerDetailsLogic;
