import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const businessSchema = yup
  .object({
    businessName: yup
      .string()
      .max(30, "Maximum of 30 characters is allowed")
      .required("Business name is required"),
    jobTitle: yup
      .string()
      .max(30, "Maximum of 30 characters is allowed")
      .required("Job title is required"),
    businessSize: yup.string().required("Business size is required"),
    password: yup
      .string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        "Password should contain a minimumm of 8 characters, at least a lowercase, an uppercase and a number"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords do not match!")
      .required("Confirm password is required"),
  })
  .required();

const BusinessFormValidation = (
  businessName,
  jobTitle,
  businessSize,
  password,
  confirmPassword
) => {
  return useForm({
    resolver: yupResolver(businessSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      businessName: businessName,
      jobTitle: jobTitle,
      businessSize: businessSize,
      password: password,
      confirmPassword: confirmPassword,
    },
  });
};

export default BusinessFormValidation;
