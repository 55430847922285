import "./FileInput.scss";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFileInputLogic from "./FileInputLogic";

const FileInput = ({
  name = "",
  onChange = () => {},
  errorMessage = undefined,
  isDisabled,
}) => {
  const inputRef = useRef();
  const fileInputLogic = useFileInputLogic({ inputRef, onChange });

  return (
    <div className={"file-input__container " + (isDisabled ? "disabled" : "")}>
      <label
        // onClick={() => fileInputLogic.handleClick()}
        className="file-input"
        htmlFor={name}
      >
        <input
          ref={inputRef}
          type="file"
          id={name}
          name={name}
          // accept=".pdf, .doc, .docx, .xls, .xlsx, .csv, .txt"
          accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
          onChange={fileInputLogic.handleChange}
          disabled={isDisabled}
        />
        {fileInputLogic.fileName ? (
          <p>{fileInputLogic.fileName}</p>
        ) : (
          <p className="file-input__text">
            Drag and drop document here or{" "}
            <span className="purple--text">Browse</span>
          </p>
        )}
      </label>
      <p
        className={`error-message text-danger ${
          errorMessage !== undefined ? "show" : ""
        }`}
      >
        <FontAwesomeIcon
          icon={["fas", "exclamation-circle"]}
          className="mr-2"
          style={{ fontSize: "13px" }}
        />
        {errorMessage !== undefined && (
          <span style={{ fontSize: "14px" }}>{errorMessage.message}</span>
        )}
      </p>
    </div>
  );
};

export default FileInput;
