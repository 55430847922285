import "./InventoryLayout.scss";
import { Outlet, NavLink } from "react-router-dom";
import InventoryLayoutLogic from "./InventoryLayoutLogic";

const InventoryLayout = () => {
  const { inventoryAnalytics, setInventoryAnalytics } = InventoryLayoutLogic();

  return (
    <div className="inventory-layout">
      <h3 className="font-weight-semibold">Inventory</h3>
      <div className="inventory-layout__tab">
        <div className="inventory-layout__tab__menu">
          <NavLink
            className={"inventory-layout__tab__menu__item"}
            to="/app/products"
            end
          >
            Products
          </NavLink>
          <NavLink
            className="inventory-layout__tab__menu__item"
            to="/app/products/categories"
            end
          >
            Category
          </NavLink>
          <NavLink
            className="inventory-layout__tab__menu__item"
            to="/app/products/stores"
            end
          >
            Store
          </NavLink>
          <NavLink
            className="inventory-layout__tab__menu__item"
            to="/app/products/sales-transactions"
            end
          >
            Sales Transactions
          </NavLink>
          <NavLink
            className="inventory-layout__tab__menu__item"
            to="/app/products/stock-history"
            end
          >
            Stock History
          </NavLink>
        </div>
      </div>
      <div className="inventory-layout__content">
        <Outlet context={{ inventoryAnalytics, setInventoryAnalytics }} />
      </div>
    </div>
  );
};

export default InventoryLayout;
