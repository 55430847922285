import axios from "axios";
import store from "../store";
import { setAccessToken, setRequestingTokens } from "../store/modules/profile";
import { setApiErrorMessage } from "../store/modules/apiErrorHandler";
import { logoutHandler, getUrlQuerysection } from "../helpers";

// base url
// eslint-disable-next-line no-undef
let baseUrlBusiness = process.env.REACT_APP_LUMI_MERCHANT_APP_BASE_URL;

const httpClient = axios.create({
  baseURL: baseUrlBusiness,
});

// setting authorization header
const httpRequest = (config) => {
  const state = store.getState();
  const { accessToken } = state.profileDetailsReducer;
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
};

// Response interceptors
const onFulfilled = (response) => {
  return Promise.resolve(response);
};

const onRejected = async (error) => {
  if (error.response) {
    if (error.response.status) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        const state = store.getState();
        const { requestingTokens } = state.profileDetailsReducer;
        if (requestingTokens) return httpClient(originalRequest);
        if (localStorage.getItem("refreshToken")) {
          originalRequest._retry = true;
          store.dispatch(setRequestingTokens(true));
          // eslint-disable-next-line no-undef
          return axios
            .post(
              `${process.env.REACT_APP_LUMI_MERCHANT_APP_BASE_URL}/tokens/refresh/`,
              {
                refresh: localStorage.getItem("refreshToken"),
              }
            )
            .then((res) => {
              store.dispatch(setRequestingTokens(false));
              localStorage.setItem("refreshToken", res.data.refresh);
              store.dispatch(setAccessToken(res.data.access));
              // Change Authorization header
              httpClient.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${res.data.access}`;
              // return originalRequest object with Axios.
              return httpClient(originalRequest);
            })
            .catch(() => {
              store.dispatch(setRequestingTokens(false));
              localStorage.setItem(
                "nextRoute",
                `${window.location.pathname}${getUrlQuerysection()}`
              );
              logoutHandler();
            });
        }
        localStorage.setItem(
          "nextRoute",
          `${window.location.pathname}${getUrlQuerysection()}`
        );
        logoutHandler();
      }

      if (error.response.status === 403) {
        // Do something
        store.dispatch(
          setApiErrorMessage(
            "You don't have the permission to perform this action"
          )
        );
      }
      if (error.response.status === 500 || error.response.status === 503) {
        // Do something
        store.dispatch(
          setApiErrorMessage("Something went wrong, please try again")
        );
      }
      if (error.response.status === 404) {
        // Do something
        store.dispatch(setApiErrorMessage("This resource was not found"));
      }

      return Promise.reject(error);
    }
    return;
  }
  if (!navigator.onLine) {
    store.dispatch(
      setApiErrorMessage(
        "No internet connection, please check your internet connectivity"
      )
    );
  }
};

/** Adding the request interceptors */
httpClient.interceptors.request.use(httpRequest);
/** Adding the response interceptors */
httpClient.interceptors.response.use(onFulfilled, onRejected);

export default httpClient;
