import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const CartItemsConfirmationValidationSchema = yup
  .object({
    customerName: yup.string().required("Customer name is required"),

    phoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, "Amount must contain only digits")
      .test(
        "num",
        "Please, enter the correct amount collected from customer",
        (val) => !val.startsWith("0")
      )
      .min(10, "Invalid phone number format")
      .max(10, "Invalid phone number format")
      .required("Please, enter the amount collected from customer"),
  })
  .required();

const CartItemsConfirmationValidation = (customerName, phoneNumber) => {
  return useForm({
    resolver: yupResolver(CartItemsConfirmationValidationSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      customerName: customerName,
      phoneNumber: phoneNumber,
    },
  });
};

export default CartItemsConfirmationValidation;
