import "./CustomerDetails.scss";
import { useParams, useNavigate } from "react-router-dom";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import CustomerDetailsLogic from "./CustomerDetailsLogic";
import { formatCurrency, convertToTilteCase } from "../../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomerDetails = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const { isFetchingCustomer, customer } = CustomerDetailsLogic(customerId);

  return (
    <div className="customer">
      <primaryComponents.Button
        classNames="btn btn--outline"
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon
          icon="angle-left"
          className="mr-3"
          style={{ fontSize: "18px" }}
        />
        <span>Back</span>
      </primaryComponents.Button>
      <div className="customer__content">
        <secondaryComponents.LoaderHelper
          isLoading={isFetchingCustomer}
          classNames="mt-5"
        >
          {customer !== null && (
            <>
              <div className="customer__content__header">
                <div className="customer__content__header__title">
                  <h4 className="font-weight-semibold">
                    {customer.full_name
                      ? convertToTilteCase(customer.full_name)
                      : "N/A"}
                  </h4>
                  <span
                    style={{ fontSize: "10px" }}
                    className="success--text ml-3"
                  >
                    Active
                  </span>
                </div>
              </div>
              {/* <p>Last active Monday 11th June 2022</p> */}
              <div className="customer__content__details">
                <div>
                  <p className="font-weight-semibold dark--text">
                    Email Address
                  </p>
                  <span className="gray--text">
                    {customer.email ? customer.email : "N/A"}
                  </span>
                </div>
                <div>
                  <p className="font-weight-semibold dark--text">
                    Phone Number
                  </p>
                  <span className="gray--text">
                    {customer.phone ? customer.phone : "N/A"}
                  </span>
                </div>
                <div className="d-flex">
                  <div>
                    <p className="font-weight-semibold dark--text">Location</p>
                    <span className="gray--text">
                      {customer.location ? customer.location : "N/A"}
                    </span>
                  </div>
                </div>
                <div>
                  <p className="font-weight-semibold dark--text">
                    Total Purchase
                  </p>
                  <span className="gray--text">
                    {formatCurrency(customer.total_purchases)}
                  </span>
                </div>
                <div>
                  <p className="font-weight-semibold dark--text">
                    Total Rewards Earnings
                  </p>
                  <span className="gray--text">
                    {formatCurrency(customer.total_cash_earned)}
                  </span>
                </div>
                <div>
                  <p className="font-weight-semibold dark--text">
                    Number of transactions
                  </p>
                  <span className="gray--text">
                    {customer.total_completed_transactions
                      ? customer.total_completed_transactions
                      : "N/A"}
                  </span>
                </div>
              </div>
            </>
          )}
        </secondaryComponents.LoaderHelper>

        <div className="customer__content__transactions mt-4">
          <h5 className="font-weight-semibold">Transactions</h5>
          <secondaryComponents.CustomerTransactionsTable
            customerId={customerId}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
