import "./StoresTransaction.scss";
import { motion } from "framer-motion";
import primaryComponents from "../../../components/primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, Outlet, useNavigate } from "react-router-dom";

const StoresTransaction = () => {
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="stores-transaction"
    >
      <div className="stores-transaction">
        <h3 className="font-weight-semibold">Inventory</h3>
        <primaryComponents.Button
          classNames={"btn btn--outline"}
          style={{ marginTop: "1.5em" }}
          onClick={() => navigate("/app/inventory/products/stores")}
        >
          <FontAwesomeIcon icon="chevron-left" style={{ marginRight: "8px" }} />
          Back
        </primaryComponents.Button>
        <div className="stores-transaction__tab">
          <div className="stores-transaction__tab__menu">
            {/* <NavLink
                        className={"stores-transaction__tab__menu__item"}
                        to="/app/inventory/stores"
                        end
                    >
                        Sales Transactions
                    </NavLink> */}
            {/* <NavLink
                        className={"stores-transaction__tab__menu__item"}
                        to="/app/inventory/stores/shop-details"
                        end
                    >
                        Shop Details
                    </NavLink> */}
            <NavLink
              className={"stores-transaction__tab__menu__item"}
              to="/app/inventory/stores"
              end
            >
              Inventory
            </NavLink>
            <NavLink
              className={"stores-transaction__tab__menu__item"}
              to="/app/inventory/stores/devices"
              end
            >
              Devices
            </NavLink>
          </div>
        </div>
        <div className="stores-transaction__content">
          <Outlet />
        </div>
      </div>
    </motion.div>
  );
};

export default StoresTransaction;
