import "./AllTransactions.scss";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import AllTransactionsLogic from "./AllTransactionsLogic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AllTransactions = () => {
  const {
    RefreshTransactions,
    isRefreshingTransactions,
    newBusinessId,
    business_list,
    business_role,
    filterBaseOnAccount,
    showAllBusiness,
    refeshTnx,
    setRefreshTnx,
    showStatementOfAccount,
    setShowStatementOfAccount,
  } = AllTransactionsLogic();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Transactions"
      description="View all transactions that have taken place"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className="all-transactions"
          data-testid="all-transactions"
        >
          <div className="all-transactions__header">
            <h3 className="font-weight-semibold">Transactions</h3>
            <div className="d-flex ml-auto">
              <primaryComponents.Button
                classNames={"btn btn--primary"}
                onClick={() => RefreshTransactions()}
                isDisabled={isRefreshingTransactions}
                isLoading={isRefreshingTransactions}
                loadingText="Refreshing..."
                data-testid="refresh-tnx"
              >
                Refresh Transactions
              </primaryComponents.Button>
              {business_role === "CUSTOMER_SUPPORT" ? null : (
                <primaryComponents.Button
                  classNames="ml-2 btn btn--primary"
                  onClick={() => setShowStatementOfAccount(true)}
                  data-testid="download-statement"
                >
                  Statement
                  <FontAwesomeIcon icon="download" className="ml-3" />
                </primaryComponents.Button>
              )}
            </div>
          </div>

          <secondaryComponents.SubAccountsList
            newBusinessId={newBusinessId}
            businessList={business_list}
            filterBaseOnAccount={filterBaseOnAccount}
            showAllBusiness={showAllBusiness}
          />

          {/* transaction table */}
          <secondaryComponents.TransactionsTable
            isRefeshTnx={refeshTnx}
            setRefreshTnx={setRefreshTnx}
          />

          {/* statement of account widget */}
          {showStatementOfAccount && (
            <secondaryComponents.StatementOfAccount
              closeWidget={setShowStatementOfAccount}
            />
          )}
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default AllTransactions;
