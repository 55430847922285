import { useSelector } from "react-redux";

const LoyaltyNotificationLogic = () => {
  const { has_wallet } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails
  );

  return { has_wallet };
};

export default LoyaltyNotificationLogic;
