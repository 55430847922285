import { useTable } from "react-table";
import { useMemo, useState, useContext } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import {
  getSettlementAccounts,
  deleteSettlementAccount,
} from "../../../services/settlements";
import { useEffect } from "react";

const useSettlementsTableLogic = (setHasAccount) => {
  const [isFetchingAccounts, setIsFetchingAccounts] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenSettlementForm, setIsOpenSettlementForm] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  useEffect(() => {
    handleFetchSettlementAccounts();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "SN",
        accessor: "id",
        Cell: ({ cell: { row } }) => row.index + 1,
      },
      {
        Header: "Bank Name",
        accessor: "bank_name",
      },
      {
        Header: "Account Number",
        accessor: "decrypted_nuban",
      },
      {
        Header: "Account Name",
        accessor: "account_name",
      },
      {
        Header: "Currency",
        accessor: "currency",
      },
      // {
      //     Header: "Type",
      //     accessor: "is_primary",
      //     Cell: ({ cell: { value } }) => (
      //         <span
      //             className={`${value === true
      //                 ? "primary--account"
      //                 : "secondary--account"
      //                 }`}
      //         >
      //             {value === true
      //                 ? "Primary account"
      //                 : "Secondary account"}
      //         </span>
      //     ),
      // },
      {
        Header: "",
        accessor: "edit",
        Cell: ({ cell: { row } }) => (
          <span
            onClick={() => {
              setIsOpenEditDialog(true);
              setSelectedAccount(row.original);
            }}
            style={{
              backgroundColor: "#7647EE",
              display: "inline-block",
              padding: "10px 16px",
              fontSize: "12px",
              borderRadius: "8px",
              color: "white",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            Edit
          </span>
        ),
      },
      {
        Header: "",
        accessor: "delete",
        Cell: ({ cell: { row } }) => (
          <span
            onClick={() => {
              setSelectedAccount(row.original);
              setIsOpenDeleteDialog(true);
            }}
            style={{
              backgroundColor: "#E02020",
              display: "inline-block",
              padding: "10px 16px",
              fontSize: "12px",
              borderRadius: "8px",
              color: "white",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            Delete
          </span>
        ),
      },
    ],
    []
  );

  const instance = useTable({ columns, data: accountList });

  const handleFetchSettlementAccounts = () => {
    setIsFetchingAccounts(true);
    setRequestLoaderProgress(40);

    getSettlementAccounts()
      .then((response) => {
        setRequestLoaderProgress(100);
        setIsFetchingAccounts(false);
        setAccountList(response.data?.data);
        if (response.data?.data.length >= 1) {
          setHasAccount(true);
        }

        if (response.data?.data.length <= 0)
          return setIsOpenSettlementForm(true);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsFetchingAccounts(false);
        if (error.response === undefined) return;
        if (error.response.status === 401 || error.response.status === 400) {
          triggerToast(error.response.data.detail, "warning");
        }
      });
  };

  const handleDeleteSettlementAccount = () => {
    if (selectedAccount === null) return;
    const { id } = selectedAccount;

    setIsDeletingAccount(true);
    setRequestLoaderProgress(40);

    deleteSettlementAccount(id)
      .then(() => {
        handleFetchSettlementAccounts();
        setIsDeletingAccount(false);
        setRequestLoaderProgress(100);
        setIsOpenDeleteDialog(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsDeletingAccount(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          triggerToast(error.response.data.detail, "warning");
      });
  };

  return {
    instance,
    isFetchingAccounts,
    accountList,
    isOpenDeleteDialog,
    isDeletingAccount,
    selectedAccount,
    isOpenEditDialog,
    isOpenSettlementForm,
    setSelectedAccount,
    setIsOpenSettlementForm,
    setIsOpenEditDialog,
    setIsOpenDeleteDialog,
    handleDeleteSettlementAccount,
    handleFetchSettlementAccounts,
  };
};

export default useSettlementsTableLogic;
