import "./RevenueChartTooltip.scss";
import { formatCurrency, setToDateMonthYearInWords } from "../../../helpers";

const RevenueChartTooltip = ({
  active,
  payload = [],
  label,
  isMoney = true,
}) => {
  if (active) {
    return (
      <div className="revenue-chart-tooltip">
        <p className="revenue-chart-tooltip__value purple--text">
          {isMoney ? formatCurrency(payload[0]?.value) : payload[0]?.value}
        </p>
        <p className="revenue-chart-tooltip__label">
          {setToDateMonthYearInWords(label)}
        </p>
      </div>
    );
  }
  return null;
};

export default RevenueChartTooltip;
